<template class="text_google">
  <v-app>
    <v-system-bar
      class="noprint"
      color="grey darken-2"
      dark
      height="40"
      app
      lights-out
    >
      <v-toolbar-items class="ml-5">
        <v-btn
          href="javascript:window.print()"
          class="title elevation-0 mr-2"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-printer</v-icon>&nbsp; พิมพ์
        </v-btn>
        <v-btn
          @click.stop="Export2Doc('exportContent', 'word-content')"
          class="title elevation-0"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>
    <div id="exportContent" style="mso-page-orientation: landscape;">
      <div class="page">
        <div align="center">
          <v-img max-height="30mm" max-width="25mm" src="ovec.png"></v-img>
          <div class="head">
            เกณฑ์อัตรากำลังครูต่อจำนวนนักเรียน ประจำปี {{ yearsReport }}
          </div>
          <div class="head">{{ rate_work_g_query.college_name }}</div>
          <div>___________________________</div>
        </div>

        <br />

        <v-simple-table class="table">
          <thead>
            <tr>
              <td width="5%" class="text-center font-weight-bold th">
                <span class="bold16">ลำดับ</span>
              </td>
              <td width="20%" class="text-center font-weight-bold th">
                <span class="bold16">ประเภท</span>
              </td>
              <td width="10%" class="text-center font-weight-bold th">
                <span class="bold16"> หลักสูตร</span>
              </td>
              <td width="30%" class="text-center font-weight-bold th">
                <span class="bold16"> สาขาวิชา</span>
              </td>
              <td width="15%" class="text-center font-weight-bold th">
                <span class="bold16">จำนวนนักเรียน</span>
              </td>
              <td width="20%" class="text-center font-weight-bold th">
                <span class="bold16"> อัตรากำลัง/สาขา </span>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="4" class="text-center th">
                <span class="regular16">แผนกวิชาสามัญสัมพันธ์</span>
              </td>
              <td class="text-center th">
                <span class="regular16">{{
                  parseInt(rate_work_g_query.std_vc) +
                    parseInt(rate_work_g_query.std_hvc)
                }}</span>
              </td>
              <td class="text-center th">
                <span class="regular16">
                  {{
                    parseFloat(rate_work_g_query.rate_work_g_gs_ta_official) +
                      parseFloat(rate_work_g_query.rate_work_g_gs_tb_civil)
                  }}</span
                >
              </td>
            </tr>
            <tr
              v-for="(item, index) in rate_work_g_query_cal_idbranchs"
              :key="item.index"
            >
              <td class="text-center th">
                <span class="regular16">{{ index + 1 }}</span>
              </td>
              <td class="text-center th">
                <span class="regular16">{{ item.type_course }}</span>
              </td>
              <td class="text-center th">
                <span class="regular16">{{ item.year_course }}</span>
              </td>
              <td class="th">
                <span class="regular16">{{ item.branch_name_th }}</span>
              </td>
              <td class="text-center th">
                <span class="regular16">{{ item.sumStudent }}</span>
              </td>

              <td class="text-center th">
                <span class="regular16">
                  {{
                    parseFloat(
                      (((parseFloat(item.sumStudent) * 100) / item.sumAllStd) *
                        item.manPower) /
                        100
                    ).toFixed(2)
                  }}</span
                >
              </td>
            </tr>
            <tr>
              <td
                width="5%"
                colspan="4"
                class="text-center font-weight-bold th regular16"
              >
                <span class="regular16">รวม</span>
              </td>

              <td width="15%" class="text-center font-weight-bold th regular16">
                <span class="regular16"> {{ sumstd }}</span>
              </td>
              <td width="20%" class="text-center font-weight-bold th regular16">
                <span class="regular16"> {{ sumManpower }}</span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <br /><br />
        <table border="0" width="100%" align="center">
          <tr>
            <td width="60%"></td>
            <td width="40%">
              <div class="regular16">
                ลงชื่อ............................................................................................................
              </div>
            </td>
          </tr>
          <tr>
            <td width="60%"></td>
            <td width="40%" align="center">
              <div class="regular16">
                (.................................................)
              </div>
            </td>
          </tr>
          <tr>
            <td width="60%"></td>
            <td width="40%" align="center">
              <div class="regular16">
                ผู้อำนวยการ{{ rate_work_g_query.college_name }}
              </div>
            </td>
          </tr>
        </table>

        <!--  {{ id_ref_url }} -->
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    rate_work_g_query: [],
    rate_work_g_query_cal_idbranchs: [],
    index: 1
  }),

  async mounted() {
    await this.rate_work_gQueryAll();
    await this.rate_work_g_cal_idbranchQueryAll();
  },

  methods: {
    async rate_work_g_cal_idbranchQueryAll() {
      let result = await this.$http.post("rate_work_college_cal_idbranch.php", {
        ApiKey: this.ApiKey,
        rate_work_college_code: this.collegeCode,
        rate_work_college_year: this.years
      });
      this.rate_work_g_query_cal_idbranchs = result.data;
    },

    async rate_work_gQueryAll() {
      let result = await this.$http.post("rate_work_g.php", {
        ApiKey: this.ApiKey,
        rate_work_g_college_code: this.collegeCode,
        rate_work_g_year: this.yearsReport
      });
      this.rate_work_g_query = result.data;
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  props: {
    source: String
  },

  computed: {
    years() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },
    collegeCode() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[7];
    },

    yearsReport() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },

    collegeCode() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[7];
    },

    sumstd() {
      let data = this.rate_work_g_query_cal_idbranchs;
      let sum = 0;
      data.forEach(value => {
        sum = sum + parseInt(value.sumStudent);
      });
      return sum;
    },
    sumManpower() {
      let data = this.rate_work_g_query_cal_idbranchs;
      let sum = 0;
      let result;
      data.forEach(value => {
        sum =
          sum +
          (((parseFloat(value.sumStudent) * 100) / value.sumAllStd) *
            value.manPower) /
            100;
      });
      result =
        sum +
        parseFloat(this.rate_work_g_query.rate_work_g_gs_ta_official) +
        parseFloat(this.rate_work_g_query.rate_work_g_gs_tb_civil);
      return parseFloat(result).toFixed(2);
    }
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@100&display=swap");

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 29.7cm;
  min-height: 21cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 1cm;
  padding-left: 1cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
       height: 247mm;
     }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
         font-size: 18px;
         font-weight: bold;
       }
       .regular12 {
         font-size: 12px;
       }
       .blod12 {
         font-size: 12px;
         font-weight: bold;
       }
       .blackRegula8 {
         font-size: 8px;
       } */
  .noprint {
    display: none;
  }
}
</style>
