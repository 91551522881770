<template>
  <div>
    <!-- ประกาศรายชื่อ ผู้ผ่านคุณสมบัติ รองผู้อำนวยการ  -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_sedirector_notice.period_sedirector_enable === '1'"
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> ประกาศ
                  </h2>
                  <h2>
                    รายชื่อผู้มีสิทธิเข้ารับการคัดเลือก
                    <u> ตำแหน่ง รองผู้อำนวยการสถานศึกษา</u>
                  </h2>
                  <h3>
                    ผู้สมัคร และ วิทยาลัยสามารถตรวจสอบข้อมูลผ่านระบบ
                  </h3>
                  <h4>
                    * เฉพาะวิทยาลัยที่ปรากฎผู้สมัคร
                  </h4>
                </v-col>
                <v-col class="shrink"> </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ วันที่ :
                {{
                  period_sedirector_notice.period_sedirector_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_sedirector_notice.period_sedirector_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- วิทยาลัยรายงานตัว รองผู้อำนวยการ  -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_college_assistantA.period_college_enable === '1'"
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h2>ประเมิน <u> ภาค ก ตำแหน่ง รองผู้อำนวยการ</u></h2>
                  <h3>
                    ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                    {{
                      period_college_assistantA.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <h4>
                    * เฉพาะวิทยาลัยที่ปรากฎผู้สมัคร
                  </h4>
                </v-col>
                <v-col class="shrink"> </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ วันที่ :
                {{
                  period_college_assistantA.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_college_assistantA.period_college_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- วิทยาลัยรายงานตัว รองผู้อำนวยการ  -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_college_redirector.period_college_enable === '1'"
      >
        <v-row>
          <v-col cols="7">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h2>รับรายงานตัว <u> รองผู้อำนวยการ</u></h2>
                  <h3>
                    ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                    {{
                      period_college_redirector.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <h4>
                    * เฉพาะวิทยาลัยที่ได้รับ รองผู้อำนวยการวิทยาลัย
                  </h4>
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="viewDatasecDirect()" color="info" outlined>
                    <v-icon>mdi-magnify</v-icon> ตรวจสอบรายชื่อ</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="5">
            <div class="text-center">
              <h3>
                ปิดระบบ วันที่ :
                {{
                  period_college_redirector.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_college_redirector.period_college_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- วิทยาลัยรายงานตัว ครูย้าย  -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          period_colleges_reportmv.period_college_enable === '1' &&
            period_colleges_reportmv.period_college_type ===
              'reportmove_teacher'
        "
      >
        <v-row>
          <v-col cols="12" md="7">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row>
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h2>รับรายงานตัวครู <u> ย้ายกรณีปกติ</u></h2>
                  <h3>
                    ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                    {{
                      period_colleges_reportmv.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                  <h4>
                    * เฉพาะวิทยาลัยที่ได้รับครูจากการย้ายกรณีปกติตามประกาศ
                  </h4>
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="viewDataMoveTeach()" color="info" outlined>
                    <v-icon>mdi-magnify</v-icon> ตรวจสอบรายชื่อ</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="5">
            <div class="text-center">
              <h3>
                ปิดระบบ วันที่ :
                <br />
                {{
                  period_colleges_reportmv.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_colleges_reportmv.period_college_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- วิทยาลัยรายงานอัตรากำลัง  -->
  <!--   <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          period_colleges.period_college_enable === '1' &&
            period_colleges.period_college_type === 'update_college'
        "
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h2>รายงาน<u>อัตรากำลัง</u></h2>
                  <h3>
                    ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                    {{
                      period_colleges.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ งานอัตรากำลังวันที่ :
                {{
                  period_colleges.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="period_colleges.period_college_stop + ' 16:30:00'"
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover> -->



        <!-- วิทยาลัย แจ้งสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง  -->
        <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          period_colleges_manpower.period_college_enable === '1'
        "
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h2><u>แจ้งสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง</u></h2>
                  <h3>
                    ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่
                    {{
                      period_colleges_manpower.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ งานอัตรากำลังวันที่ :
                {{
                  period_colleges_manpower.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="period_colleges_manpower.period_college_stop + ' 16:30:00'"
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>



    <!-- แจ้งเตือนรวบรวมแบบคำร้องขอย้าย -->

    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          period_teacher_requests.period_college_enable === '1' &&
            period_teacher_requests.period_college_type === 'teacher_request'
        "
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h2>
                    ดำเนินการ
                    <u>แนบไฟล์แบบคำร้องขอย้ายและเอกสารประกอบการพิจารณา </u>
                  </h2>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      period_teacher_requests.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                {{
                  period_teacher_requests.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_teacher_requests.period_college_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- แจ้งเตือนเงือนไขการย้าย -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          period_college_moves.period_college_enable === '1' &&
            period_college_moves.period_college_type === 'movement_college'
        "
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h2>
                    ดำเนินการ
                    <u>แจ้งเงื่อนไขสาขาวิชา </u>
                  </h2>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      period_college_moves.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    เวลา 16.30 น.
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                {{
                  period_college_moves.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                เวลา 16.30 น.
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_college_moves.period_college_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- แจ้งเตือนเงือนไขการย้าย แนบไฟล์ -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          period_college_moves_attactment.period_college_enable_file === '1' &&
            period_college_moves_attactment.period_college_type ===
              'movement_college'
        "
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h2>
                    ดำเนินการ
                    <u>แนบไฟล์เงือนไขสาขาวิชา </u>
                  </h2>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      period_college_moves_attactment.period_college_attactmentstop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    เวลา 16.30 น.
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                {{
                  period_college_moves_attactment.period_college_attactmentstop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                เวลา 16.30 น.
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_college_moves_attactment.period_college_attactmentstop +
                    ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- พิจารณารับย้ายสายการสอนครู -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          period_teacher_moves.period_college_enable === '1' &&
            period_teacher_moves.period_college_type === 'discuss_teacher'
        "
      >
        <v-row>
          <v-col cols="12" md="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h3>
                    ดำเนินการขอความคิดเห็นจากคณะกรรมการวิทยาลัย
                    <u>ต่อผู้ประสงค์ขอย้ายเข้า รับย้ายสายการสอน (ครู)</u>
                  </h3>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      period_teacher_moves.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-center">
              <h3>
                ปิดระบบ รายงานผลการพิจารณา <u>รับย้ายสายการสอน</u> :
                {{
                  perioddiscuss.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_teacher_moves.period_college_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- พิจารณารับยายสายบริหาร -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          perioddiscuss.period_college_enable === '1' &&
            perioddiscuss.period_college_type === 'discuss_college'
        "
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>
                  <h3>
                    ดำเนินการขอความคิดเห็นจากคณะกรรมการวิทยาลัย
                    <u>ต่อผู้ประสงค์ขอย้ายเข้า รับย้ายสายบริหาร</u>
                  </h3>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      perioddiscuss.period_college_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ ผลการพิจารณา <u>รับย้ายสายบริหาร</u> :
                {{
                  perioddiscuss.period_college_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="perioddiscuss.period_college_stop + ' 16:30:00'"
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- รายงานตัวครูผู้ช่วย รอบ-->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="periodassteachs.periodAssTeachEnable === '1'"
      >
        <v-row>
          <v-col cols="12" md="7">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row>
                <v-col class="grow">
                  <h2 class="blue--text">
                    <v-icon color="blue">mdi-school</v-icon> วิทยาลัย
                  </h2>

                  <h2>
                    ดำเนินการ <u> รับรายงานตัว ครูผู้ช่วย </u>
                    <br />
                    ก่อนระบบปิดในวันที่
                    {{
                      periodassteachs.periodAssTeachStop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                    : เวลา 16:30:00 น.
                  </h2>
                </v-col>
                <v-col class="shrink">
                  <v-btn @click="viewData()" color="info" outlined>
                    <v-icon>mdi-magnify</v-icon> ตรวจสอบรายชื่อ</v-btn
                  >
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="5">
            <div class="text-center">
              <h3>
                ปิดระบบ รับรายงานตัว ครูผู้ช่วย : <br />
                {{
                  periodassteachs.periodAssTeachStop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="periodassteachs.periodAssTeachStop + ' 16:30:00'"
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
    <Manage_assistant_teacher
      v-bind:OpenDialog_manage_assistant_teacher="
        OpenDialog_manage_assistant_teachers
      "
      @toggle-favorite="receiveEmit"
    />

    <Conditons_transfer_success_Vue
      v-bind:OpenDialog_conditons_transfer_success="
        OpenDialog_conditons_transfer_successs
      "
      @toggle-favorite="receiveEmit"
    />

    <Sedirector_app
      v-bind:OpenDialog_sedirector_app="OpenDialog_sedirector_apps"
      @toggle-favorite="receiveEmit"
    />
  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
import Manage_assistant_teacher from "./manage_assistant_teacher.vue";
import Conditons_transfer_success_Vue from "./conditons_transfer_success.vue";
import Sedirector_app from "./sedirector_app.vue";
export default {
  name: "HRvecAlertMissionCollege",
  components: {
    FlipCountdown,
    Manage_assistant_teacher,
    Conditons_transfer_success_Vue,
    Sedirector_app
  },

  data() {
    return {
      ApiKey: "HRvec2021",
      period_colleges: [],
      period_colleges_reportmv: [],
      period_colleges_manpower: [],
      period_teacher_requests: [],
      period_college_moves: [],
      period_college_moves_attactment: [],
      period_teacher_moves: [],
      perioddiscuss: [],
      periodassteachs: [],
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      period_college_redirector: [],
      period_college_assistantA: [],
      OpenDialog_manage_assistant_teacher: "",
      OpenDialog_manage_assistant_teachers: "",

      OpenDialog_conditons_transfer_success: "",
      OpenDialog_conditons_transfer_successs: "",

      OpenDialog_sedirector_app: "",
      OpenDialog_sedirector_apps: "",

      period_sedirector_notice: []
    };
  },

  async mounted() {
    await this.period_collegeQuery();
    await this.period_collegemvQuery();
    await this.teacher_requestQuery();
    await this.period_college_moveQuery();
    await this.period_teacher_moveQuery();
    await this.periodDiscussQuery();
    await this.periodassteach();
    await this.period_collegeSedirectors();
    await this.period_college_move_attactment();
    await this.period_collegeSedirectors_assistantA();
    await this.period_collegeSedirectors_Notice();
    await this.period_college_manpowerQuery();

    this.OpenDialog_manage_assistant_teachers = false;
    this.OpenDialog_conditons_transfer_successs = false;
  },

  methods: {
    async viewDatasecDirect() {
      this.OpenDialog_sedirector_apps = true;
      this.OpenDialog_sedirector_app = this.OpenDialog_sedirector_apps;
    },
    async viewDataMoveTeach() {
      this.OpenDialog_conditons_transfer_successs = true;
      this.OpenDialog_conditons_transfer_success = this.OpenDialog_conditons_transfer_successs;
    },

    async viewData() {
      this.OpenDialog_manage_assistant_teachers = true;
      this.OpenDialog_manage_assistant_teacher = this.OpenDialog_manage_assistant_teachers;
    },
    async receiveEmit() {
      this.OpenDialog_manage_assistant_teachers = false;
      this.OpenDialog_conditons_transfer_successs = false;
      this.OpenDialog_sedirector_apps = false;
    },


    async period_college_manpowerQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
          period_college_id: "701"
      });
      this.period_colleges_manpower = result_period_college.data;

      if (this.period_colleges_manpower.period_college_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_colleges_manpower.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        /*    this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_colleges.ApiKey = this.ApiKey;
            this.period_colleges.period_college_enable = "0";
            await this.$http.post(
              "period_college.update.php",
              this.period_colleges
            );
            await this.period_collegeQuery();
          }
        }, 3000); */
      }
    },


    async period_collegeQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "501"
      });
      this.period_colleges = result_period_college.data;

      if (this.period_colleges.period_college_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_colleges.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        /*    this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_colleges.ApiKey = this.ApiKey;
            this.period_colleges.period_college_enable = "0";
            await this.$http.post(
              "period_college.update.php",
              this.period_colleges
            );
            await this.period_collegeQuery();
          }
        }, 3000); */
      }
    },

    async period_collegeSedirectors() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "20101"
      });
      this.period_college_redirector = result_period_college.data;

      if (this.period_college_redirector.period_college_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_college_redirector.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
      }
    },

    async period_collegeSedirectors_Notice() {
      let result;
      result = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_enable: "1",
        period_sedirector_id: "202"
      });
      this.period_sedirector_notice = result.data;

      /*  if (this.period_sedirector_notice.period_sedirector_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_sedirector_notice.period_sedirector_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_sedirector_notice.ApiKey = this.ApiKey;
            this.period_sedirector_notice.period_sedirector_enable = "0";
            await this.$http.post(
              "period_college.update.php",
              this.period_sedirector_notice
            );
            await this.period_collegeSedirectors_Notice();
          }
        }, 3000);
      } */
    },

    async period_collegeSedirectors_assistantA() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "20100"
      });
      this.period_college_assistantA = result_period_college.data;

      /*  if (this.period_college_assistantA.period_college_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_college_assistantA.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_college_assistantA.ApiKey = this.ApiKey;
            this.period_college_assistantA.period_college_enable = "0";
            await this.$http.post(
              "period_college.update.php",
              this.period_college_assistantA
            );
            await this.period_collegeSedirectors();
          }
        }, 3000);
      } */
    },

    async period_collegemvQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "30102"
      });
      this.period_colleges_reportmv = result_period_college.data;

      if (this.period_colleges_reportmv.period_college_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_colleges_reportmv.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        /*         this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_colleges_reportmv.ApiKey = this.ApiKey;
            this.period_colleges_reportmv.period_college_enable = "0";
            await this.$http.post(
              "period_college.update.php",
              this.period_colleges_reportmv
            );
            await this.period_collegemvQuery();
          }
        }, 3000); */
      }
    },

    async teacher_requestQuery() {
      let result;
      result = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "30101"
      });
      this.period_teacher_requests = result.data;

      if (this.period_teacher_requests.period_college_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_teacher_requests.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        /*       this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_teacher_requests.ApiKey = this.ApiKey;
            this.period_teacher_requests.period_college_enable = "0";
            await this.$http.post(
              "period_college.update.php",
              this.period_teacher_requests
            );
            await this.teacher_requestQuery();
          }
        }, 3000); */
      }
    },

    async period_college_moveQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "401"
      });
      this.period_college_moves = result_period_college.data;

      if (this.period_college_moves.period_college_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_college_moves.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        /*      this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_college_moves.ApiKey = this.ApiKey;
            this.period_college_moves.period_college_enable = "0";
            await this.$http.post(
              "period_college.update.php",
              this.period_college_moves
            );
            await this.period_college_moveQuery();
          }
        }, 3000); */
      }
    },

    async period_college_move_attactment() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable_file: "1",
        period_college_type: "movement_college"
      });
      this.period_college_moves_attactment = result_period_college.data;

      if (
        this.period_college_moves_attactment.period_college_enable_file == "1"
      ) {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_college_moves_attactment.period_college_attactmentstop +
            " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        /*         this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_college_moves_attactment.ApiKey = this.ApiKey;
            this.period_college_moves_attactment.period_college_enable_file =
              "0";
            await this.$http.post(
              "period_college.update.php",
              this.period_college_moves_attactment
            );
            await this.period_college_move_attactment();
          }
        }, 3000); */
      }
    },

    async period_teacher_moveQuery() {
      let result_period_college;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "30103"
      });
      this.period_teacher_moves = result_period_college.data;

      if (this.period_teacher_moves.period_college_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_teacher_moves.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        /*        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_teacher_moves.ApiKey = this.ApiKey;
            this.period_teacher_moves.period_college_enable = "0";
            await this.$http.post(
              "period_college.update.php",
              this.period_teacher_moves
            );
            await this.period_teacher_moveQuery();
          }
        }, 3000); */
      }
    },

    async periodDiscussQuery() {
      let result_period;
      result_period = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_id: "201"
      });
      this.perioddiscuss = result_period.data;
      if (this.perioddiscuss.period_college_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.perioddiscuss.period_college_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        /*        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.perioddiscuss.ApiKey = this.ApiKey;
            this.perioddiscuss.period_college_enable = "0";
            await this.$http.post(
              "period_college.update.php",
              this.perioddiscuss
            );
            await this.period_teacher_moveQuery();
          }
        }, 3000); */
      }
    },

    async periodassteach() {
      let result_period;
      result_period = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnable: "1",
        periodAssTeachID: "102"
      });
      this.periodassteachs = result_period.data;

      if (this.periodassteachs.periodAssTeachEnable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.periodassteachs.periodAssTeachStop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        /*       this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.periodassteachs.ApiKey = this.ApiKey;
            this.periodassteachs.periodAssTeachEnable = "0";
            await this.$http.post(
              "periodassteach.update.php",
              this.periodassteachs
            );
            await this.periodassteach();
          }
        }, 3000); */
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
