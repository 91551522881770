<template>
  <div>
    <AssisstantBar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รายงานผลการประเมิน ครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2" outlined>
          <v-row>
            <!--  <v-col cols="12" md="12" >
              <Caledar_assesteach/>
            </v-col> -->
            <v-col cols="12" md="12" class="red--text font-weight-bold">
              * รายงานผลการประเมินฯ ครูผู้ช่วย ผ่านระบบ
              ของครูผู้ช่วยที่บรรจุตั้งแต่ ปี 2566 เป็นต้นไป
              <br />
              * รอบบรรจุปี 2565 ให้จัดส่งผ่านระบบ e-office
              และส่งตัวจริงมายังสอศ. สำนักอำนวยการ กลุ่มงานจัดการงานบุคคล2
              เท่านั้น
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          :headers="headers"
          :items="manage_assistant_teachers"
          class="elevation-1"
          :loading="loading"
          :search="search"
          disable-pagination
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
          </template>
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="22">
              <span>
                <v-icon @click="toggle"
                  >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
                </v-icon>
                บรรจุ :
                <span class="red--text">
                  {{
                    items[0].mt_date_app_now
                      | moment("add", "543 year")
                      | moment("D MMMM YYYY")
                  }}
                </span>

                อายุงาน :
                <span class="yellow blue--text">{{
                  get_govMonth_Age(items[0].mt_date_app_now)
                }}</span>

                <span class="red--text ml-5">ประเมิน</span>
                [ (ครั้งที่ 1) : {{ items[0].month6 | moment("D MMMM YYYY") }} ,
                (ครั้งที่ 2) : {{ items[0].month12 | moment("D MMMM YYYY") }} ,
                (ครั้งที่ 3) : {{ items[0].month18 | moment("D MMMM YYYY") }} ,
                (ครั้งที่ 4) : {{ items[0].month24 | moment("D MMMM YYYY") }}

                ]
              </span>
            </th>
          </template>
          <!-- เสนอรายชื่อกรรมการ -->
          <template v-slot:item.mt_committe_file="{ item }">
            <div v-if="item.mt_appoin_year >= 2567">
              <div>
                <div v-if="item.mt_committe_ipaapprove === 'pass'">
                  <v-icon color="success">mdi-check-circle</v-icon>
                </div>
                <div
                  v-else-if="item.mt_committe_ipaapprove === 'dontpass'"
                  class="error--text"
                >
                  <v-icon color="error">mdi-alert</v-icon>
                  {{ item.mt_committe_ipaapprove_detail }}
                </div>
              </div>

              <div v-if="item.mt_committe_status === 'CF'">
                <div v-if="item.mt_committe_file">
                  <v-btn
                    @click="viewpdfmt_committe_file(item.mt_committe_file)"
                    color="info"
                    icon
                  >
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    to="/college/assistantcommittee"
                    color="success"
                    rounded
                    outlined
                  >
                    <v-icon>mdi-attachment</v-icon> แนบไฟล์
                    <v-icon color="red">mdi-alert</v-icon>
                  </v-btn>
                </div>
              </div>
              <div v-else>
                <v-btn
                  outlined
                  rounded
                  to="/college/assistantcommittee"
                  color="red"
                  dark
                >
                  <v-icon>mdi-alert</v-icon> ยังไม่ได้แนบไฟล์</v-btn
                >
              </div>
            </div>
            <div v-else class="grey--text">
              ก่อนปี 2567 ไม่ต้องดำเนินการ
            </div>
          </template>

          <template v-slot:item.pertem_assistantFileIpastatus="{ item }">
            <div v-if="item.pertem_assistantFileIpastatus === 'notice'">
              <v-btn
                @click="
                  viewpertem_assistantFileIpa(item.pertem_assistantFileIpa)
                "
                fab
                small
                outlined
                color="info"
              >
                <v-icon size="30">mdi-printer</v-icon></v-btn
              >
            </div>
          </template>

          <!-- ใบประกอบวิชาชีพ -->
          <template v-slot:item.pt_licence="{ item }">
            <div v-if="item.pt_licence_file_ipa === 'pass'">
              <v-icon color="success">mdi-check-circle</v-icon>
            </div>

            <div
              v-if="item.pt_licence_file_ipa === 'dontpass'"
              class="red--text"
            >
              {{ item.pt_licence_file_ipacomment }}
            </div>

            <div v-if="item.pt_licence">
              <div v-if="String(item.pt_licence_file).length < 10">
                <v-chip color="error" outlined>
                  <v-icon>mdi-alert</v-icon> ไม่ได้แนบไฟล์
                </v-chip>
              </div>
              <div v-else>
                <v-chip
                  @click="viewpdflicenseteach(item.pt_licence_file)"
                  outlined
                  color="info"
                >
                  <v-icon>mdi-magnify</v-icon> {{ item.pt_licence }}
                </v-chip>
              </div>
              หมดอายุวันที่ <br />
              {{
                item.pt_enddate
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
            <div v-else>
              <v-chip outlined color="warning">
                <v-icon>mdi-alert</v-icon> ไม่มี/กรอกข้อมูลไม่ถูกต้อง
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_time_1_score="{ item }">
            <div>
              <v-chip outlined v-if="item.mt_time_1_score > 0">{{
                item.month6 | moment("D MMMM YYYY")
              }}</v-chip>
              <v-chip v-else outlined color="warning">
                <v-icon>mdi-alert</v-icon>
                {{ item.month6 | moment("D MMMM YYYY") }}</v-chip
              >
            </div>
            <h3 v-if="item.mt_time_1_ck === 'Pass'" class="success--text">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              {{ item.mt_time_1_score }}
            </h3>
            <h3 v-else-if="item.mt_time_1_ck === 'NotPass'" class="red--text">
              <v-icon color="red">mdi-close-circle</v-icon>
              {{ item.mt_time_1_score }}
            </h3>
            <h3 v-else class="grey--text">
              <span v-if="item.mt_time_1_file">
                {{ item.mt_time_1_score }}
              </span>
            </h3>
            <div v-if="item.mt_time_1_score > 0">
              <span v-if="item.mt_time_1_score_a >= 24" class="success--text"
                >[1] {{ item.mt_time_1_score_a }}</span
              >

              <span v-else-if="item.mt_time_1_score_a < 1" class="grey--text"
                >[1] {{ item.mt_time_1_score_a }}</span
              >
              <span v-else class="error--text"
                >[1] {{ item.mt_time_1_score_a }}</span
              >

              :
              <span v-if="item.mt_time_1_score_b >= 36" class="success--text"
                >[2] {{ item.mt_time_1_score_b }}</span
              >

              <span v-else-if="item.mt_time_1_score_b < 1" class="grey--text"
                >[2] {{ item.mt_time_1_score_b }}</span
              >

              <span v-else class="success--text"
                >[2] {{ item.mt_time_1_score_b }}</span
              >
            </div>
          </template>

          <template v-slot:item.mt_time_2_score="{ item }">
            <div>
              <v-chip outlined v-if="item.mt_time_2_score > 0">{{
                item.month12 | moment("D MMMM YYYY")
              }}</v-chip>
              <v-chip v-else outlined color="warning">
                <v-icon>mdi-alert</v-icon>
                {{ item.month12 | moment("D MMMM YYYY") }}</v-chip
              >
            </div>

            <h3 v-if="item.mt_time_2_ck === 'Pass'" class="success--text">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              {{ item.mt_time_2_score }}
            </h3>
            <h3 v-else-if="item.mt_time_2_ck === 'NotPass'" class="red--text">
              <v-icon color="red">mdi-close-circle</v-icon>
              {{ item.mt_time_2_score }}
            </h3>
            <h3 v-else class="grey--text">
              <span v-if="item.mt_time_2_file">
                {{ item.mt_time_2_score }}
              </span>
            </h3>

            <div v-if="item.mt_time_2_score > 0">
              <span v-if="item.mt_time_2_score_a >= 24" class="success--text"
                >[1] {{ item.mt_time_2_score_a }}</span
              >

              <span v-else-if="item.mt_time_2_score_a < 1" class="grey--text"
                >[1] {{ item.mt_time_2_score_a }}</span
              >
              <span v-else class="error--text"
                >[1] {{ item.mt_time_2_score_a }}</span
              >

              :
              <span v-if="item.mt_time_2_score_b >= 36" class="success--text"
                >[2] {{ item.mt_time_2_score_b }}</span
              >

              <span v-else-if="item.mt_time_2_score_b < 1" class="grey--text"
                >[2] {{ item.mt_time_2_score_b }}</span
              >

              <span v-else class="success--text"
                >[2] {{ item.mt_time_2_score_b }}</span
              >
            </div>
          </template>

          <template v-slot:item.mt_time_3_score="{ item }">
            <div>
              <v-chip outlined v-if="item.mt_time_3_score > 0">{{
                item.month18 | moment("D MMMM YYYY")
              }}</v-chip>
              <v-chip v-else outlined color="warning">
                <v-icon>mdi-alert</v-icon>
                {{ item.month18 | moment("D MMMM YYYY") }}</v-chip
              >
            </div>

            <h3 v-if="item.mt_time_3_ck === 'Pass'" class="success--text">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              {{ item.mt_time_3_score }}
            </h3>
            <h3 v-else-if="item.mt_time_3_ck === 'NotPass'" class="red--text">
              <v-icon color="red">mdi-close-circle</v-icon>
              {{ item.mt_time_3_score }}
            </h3>
            <h3 v-else class="grey--text">
              <span v-if="item.mt_time_3_file">
                {{ item.mt_time_3_score }}
              </span>
            </h3>

            <div v-if="item.mt_time_3_score > 0">
              <span v-if="item.mt_time_3_score_a >= 28" class="success--text"
                >[1] {{ item.mt_time_3_score_a }}</span
              >

              <span v-else-if="item.mt_time_3_score_a < 1" class="grey--text"
                >[1] {{ item.mt_time_3_score_a }}</span
              >
              <span v-else class="error--text"
                >[1] {{ item.mt_time_3_score_a }}</span
              >

              :
              <span v-if="item.mt_time_3_score_b >= 42" class="success--text"
                >[2] {{ item.mt_time_3_score_b }}</span
              >

              <span v-else-if="item.mt_time_3_score_b < 1" class="grey--text"
                >[2] {{ item.mt_time_3_score_b }}</span
              >

              <span v-else class="success--text"
                >[2] {{ item.mt_time_3_score_b }}</span
              >
            </div>
          </template>

          <template v-slot:item.mt_time_4_score="{ item }">
            <div>
              <v-chip outlined v-if="item.mt_time_4_score > 0">{{
                item.month24 | moment("D MMMM YYYY")
              }}</v-chip>
              <v-chip v-else outlined color="warning">
                <v-icon>mdi-alert</v-icon>
                {{ item.month24 | moment("D MMMM YYYY") }}</v-chip
              >
            </div>

            <h3 v-if="item.mt_time_4_ck === 'Pass'" class="success--text">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              {{ item.mt_time_4_score }}
            </h3>
            <h3 v-else-if="item.mt_time_4_ck === 'NotPass'" class="red--text">
              <v-icon color="red">mdi-close-circle</v-icon>
              {{ item.mt_time_4_score }}
            </h3>
            <h3 v-else class="grey--text">
              <span v-if="item.mt_time_4_file">
                {{ item.mt_time_4_score }}
              </span>
            </h3>

            <div v-if="item.mt_time_4_score > 0">
              <span v-if="item.mt_time_4_score_a >= 28" class="success--text"
                >[1] {{ item.mt_time_4_score_a }}</span
              >

              <span v-else-if="item.mt_time_4_score_a < 1" class="grey--text"
                >[1] {{ item.mt_time_4_score_a }}</span
              >
              <span v-else class="error--text"
                >[1] {{ item.mt_time_4_score_a }}</span
              >

              :
              <span v-if="item.mt_time_4_score_b >= 42" class="success--text"
                >[2] {{ item.mt_time_4_score_b }}</span
              >

              <span v-else-if="item.mt_time_4_score_b < 1" class="grey--text"
                >[2] {{ item.mt_time_4_score_b }}</span
              >

              <span v-else class="success--text"
                >[2] {{ item.mt_time_4_score_b }}</span
              >
            </div>
          </template>

          <template v-slot:item.pertem_assistantFile="{ item }">
            <div>
              <div v-if="item.pertem_assistantFile_ipacheck === 'pass'">
                <v-icon color="success">mdi-check-circle</v-icon>
              </div>
              <div
                v-else-if="item.pertem_assistantFile_ipacheck === 'dontpass'"
                class="red--text"
              >
                {{ item.pertem_assistantFile_ipacheck_detail }}
              </div>

              <div v-if="item.pertem_assistantFile">
                <div>
                  <v-btn
                    color="info"
                    icon
                    @click="viewfilePDF(item.pertem_assistantFile)"
                    ><v-icon>mdi-printer</v-icon></v-btn
                  >
                  <v-btn
                    color="red"
                    icon
                    @click="pertem_assistantFileCC(item.mt_id_card)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </div>
                <div v-if="item.pertem_assistantFileCC === 'CC'">
                  <v-chip dark color="red">คำขอยกเลิกแนบไฟล์</v-chip>
                  <div>
                    {{ item.pertem_assistantFileCC_detail }}
                  </div>
                </div>
              </div>
              <div v-else>
                <v-btn
                  rounded
                  color="warning"
                  @click="pertem_assistantFileAdd(item.mt_id_card)"
                >
                  <v-icon class="pr-3">mdi-file-plus</v-icon> แนบคำสั่ง
                </v-btn>
              </div>
            </div>
          </template>

          <template v-slot:item.committee="{ item }">
            <v-btn rounded color="info" @click="commiteeAdd(item.mt_id_card)">
              <v-icon class="pr-3">mdi-account-multiple </v-icon> กรรมการประเมิน
            </v-btn>
          </template>

          <template v-slot:item.position_name="{ item }">
            <template>
              <div>
                {{ item.position_name }}
              </div>
            </template>
          </template>

          <template v-slot:item.mt_brith_day="{ item }">
            {{
              item.mt_brith_day +
                "/" +
                item.mt_brith_month +
                "/" +
                item.mt_brith_year
            }}
            <v-chip color="#66BB6A" dark>
              {{
                get_Age(
                  item.mt_brith_year,
                  item.mt_brith_month,
                  item.mt_brith_day
                )
              }}
            </v-chip>
          </template>

          <template v-slot:item.evaluate_s="{ item }">
            <div v-if="item.assistant_teacher_re_status === 'CC'">
              <v-icon color="error" size="30">mdi-alert</v-icon>
              <span class="error--text">{{
                item.assistant_teacher_re_status_detail
              }}</span>
              <div class="text-center error--text">
                รอส่วนกลางตรวจสอบและยกเลิก
              </div>
            </div>
            <div v-else>
              <v-chip
                color="green"
                dark
                v-if="item.assistant_teacher_re_id_card === item.mt_id_card"
                @click.stop="evaluate_assistant(item.mt_id_card)"
              >
                <h2>
                  <v-icon>mdi-checkbox-marked-circle</v-icon> ส่งผลการประเมิน
                </h2>
              </v-chip>
              <v-chip
                v-else-if="
                  item.last_time_ass === '1' ||
                    item.last_time_ass === '2' ||
                    item.last_time_ass === '3' ||
                    item.last_time_ass === '4'
                "
                color="info"
                @click.stop="evaluate_assistant(item.mt_id_card)"
              >
                <h2>
                  <v-avatar left class="green darken-4 pa-1">
                    <v-icon class="pa-1">mdi-cursor-pointer</v-icon>
                  </v-avatar>

                  ครั้งที่ :
                  <v-avatar right class="green darken-4 pa-1">
                    {{ item.last_time_ass }}
                  </v-avatar>
                </h2>
              </v-chip>
              <v-chip
                v-else
                color="warning"
                @click.stop="evaluate_assistant(item.mt_id_card)"
              >
                <h2>
                  <v-icon class="pa-1">mdi-cursor-pointer</v-icon>
                  ไม่ได้เริ่มประเมิน
                </h2>
              </v-chip>
            </div>
          </template>

          <template v-slot:item.prints="{ item }">
            <v-chip
              color="green"
              dark
              v-if="item.assistant_teacher_re_id_card === item.mt_id_card"
            >
              <h2><v-icon>mdi-cube-send</v-icon> รายงานผล</h2>
            </v-chip>
            <v-chip v-else-if="item.last_time_ass === ''" color="warning">
              <h2>
                <v-icon class="pa-1">mdi-timer-sand</v-icon>
              </h2>
            </v-chip>
            <v-chip v-else color="warning">
              <h2>
                <v-icon class="pa-1">mdi-timer-sand</v-icon>
              </h2>
            </v-chip>
          </template>
          <template v-slot:item.mt_date_app_now="{ item }">
            {{
              item.mt_date_app_now
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}

            <div>
              <v-chip color="#F48FB1" dark>{{
                get_gov_Age(item.mt_date_app_now)
              }}</v-chip>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="manage_assistant_teacherEdit(item.mt_id_card)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>

        <div class="ma-5">
          <v-chip outlined color="warning"
            ><v-icon>mdi-alert</v-icon> ไม่มี
          </v-chip>
          <span class="red--text font-weigh-bold">
            * กรุณากรอกข้อมูลใบอนุญาตประกอบวิชาชีพครู โดยงานบุคลากรกรอกข้อมูล
            หรือ ติดต่อครูในสถานศึกษาให้กรอกข้อมูลใบอนุญต</span
          >
        </div>
      </base-material-card>

      <!-- V-model evaluate_assistantdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="evaluate_assistantdialog" max-width="90%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="บันทึกคะแนนการประเมิน รายบุคคล"
              class="px-5 py-3"
            ></base-material-card>
            <v-card>
              <v-form ref="evaluate_assistantform" lazy-validation>
                <v-card class="pa-1">
                  <template slot="progress">
                    <v-progress-linear
                      color="deep-purple"
                      height="10"
                      indeterminate
                    ></v-progress-linear>
                  </template>

                  <v-card-title
                    ><v-icon large color="info">mdi-account-check</v-icon>
                    <h2>ข้อมูลครูผู้ช่วย ผู้รับการประเมิน</h2></v-card-title
                  >
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ manage_assistant_teacher_mt_id_card.agency }}
                      </h3>
                      <!--  รหัสบัตรประชาชน :
                      {{ manage_assistant_teacher_mt_id_card.mt_id_card }} -->
                      <br />
                      ชื่อ-นามสกุล :
                      {{ manage_assistant_teacher_mt_id_card.mt_title_s
                      }}{{ manage_assistant_teacher_mt_id_card.mt_frist_name }}
                      {{ manage_assistant_teacher_mt_id_card.mt_last_name }}
                      <br />
                      วันที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_date_app_now }}
                      <br />
                      คำสั่งที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_order_app }}
                      <h3 v-if="times_s === 1">
                        ครั้งที่ประเมิน : {{ times_s }} กำหนดวันที่
                        {{
                          manage_assistant_teacher_mt_id_card.month6
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                      <h3 v-else-if="times_s === 2">
                        ครั้งที่ประเมิน : {{ times_s }} กำหนดวันที่
                        {{
                          manage_assistant_teacher_mt_id_card.month12
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                      <h3 v-else-if="times_s === 3">
                        ครั้งที่ประเมิน : {{ times_s }} กำหนดวันที่
                        {{
                          manage_assistant_teacher_mt_id_card.month18
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                      <h3 v-else-if="times_s === 4">
                        ครั้งที่ประเมิน : {{ times_s }} กำหนดวันที่
                        {{
                          manage_assistant_teacher_mt_id_card.month24
                            | moment("D MMMM YYYY")
                        }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-title class="text-center">
                    <h3>รายงานผลการประเมิน</h3>
                  </v-card-title>
                  <div class="text-right" v-if="checkDateAss === true">
                    <v-btn
                      v-if="times_s !== 'end'"
                      color="success"
                      elevation="4"
                      rounded
                      @click="assistant_teacher_add()"
                    >
                      <v-icon>mdi-content-save</v-icon>
                      <span>กรอกผลการประเมิน</span>
                    </v-btn>
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers_aassistant_teacher"
                        :items="assistant_teachers"
                        class="elevation-1"
                        :loading="loading"
                        disable-pagination
                        hide-default-footer
                      >
                        <template
                          v-slot:item.assistant_teacher_datetime="{
                            item
                          }"
                        >
                          {{
                            item.assistant_teacher_datetime
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </template>


                        <template
                          v-slot:item.assistant_teacher_datetimest="{
                            item
                          }"
                        >
                        <span v-if="item.assistant_teacher_datetimest !=='0000-00-00 00:00:00'">
                          {{
                            item.assistant_teacher_datetimest
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </span>
                        
                        </template>



                        <template
                          v-slot:item.assistant_teacher_score="{
                            item
                          }"
                        >
                          <v-chip
                            color="red"
                            dark
                            v-if="
                              item.assistant_teacher_times === '1' &&
                                item.assistant_teacher_score < 60
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="green"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '1' &&
                                item.assistant_teacher_score >= 60
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="red"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '2' &&
                                item.assistant_teacher_score < 60
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="green"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '2' &&
                                item.assistant_teacher_score >= 60
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="red"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '3' &&
                                item.assistant_teacher_score < 70
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="green"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '3' &&
                                item.assistant_teacher_score >= 70
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="red"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '4' &&
                                item.assistant_teacher_score < 70
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                          <v-chip
                            color="green"
                            dark
                            v-else-if="
                              item.assistant_teacher_times === '4' &&
                                item.assistant_teacher_score >= 70
                            "
                          >
                            <h2>{{ item.assistant_teacher_score }}</h2>
                          </v-chip>
                        </template>

                        <template
                          v-slot:item.assistant_teacher_status="{ item }"
                        >
                          <v-icon
                            class="pa-1"
                            color="green"
                            v-if="item.assistant_teacher_status === 'confirm'"
                            >mdi-checkbox-marked-circle</v-icon
                          >

                          <v-icon class="pa-1" v-else color="warning"
                            >mdi-timer-sand</v-icon
                          >
                        </template>

                        <template v-slot:item.assistant_teacher_file="{ item }">
                          <v-btn
                            v-if="item.assistant_teacher_file"
                            large
                            icon
                            color="info"
                            @click="viewfilePDF(item.assistant_teacher_file)"
                          >
                            <v-icon>mdi-printer</v-icon>
                          </v-btn>
                        </template>

                        <template
                          v-slot:item.assistant_teacher_file_update="{
                            item
                          }"
                        >
                          <div
                            v-if="
                              String(assisstantCount) ===
                                String(item.assistant_teacher_times)
                            "
                          >
                            <v-icon
                              v-if="item.assistant_teacher_status === 'confirm'"
                              color="green"
                              large
                            >
                              mdi-cube-send
                            </v-icon>
                            <v-icon
                              v-else
                              large
                              color="red"
                              @click.stop="
                                assistant_teacherFileEdit(
                                  item.assistant_teacher_id
                                )
                              "
                            >
                              mdi-delete-circle
                            </v-icon>
                          </div>
                        </template>

                        <template
                          v-slot:item.assistant_teacher_comment="{ item }"
                        >
                          <div
                            v-if="
                              item.assistant_teacher_ipacheck === 'Pass' ||
                                item.assistant_teacher_status === 'confirm'
                            "
                          >
                            <div
                              v-if="
                                item.assistant_teacher_comment === 'collegeCC'
                              "
                            >
                              <v-chip color="red" dark>
                                <v-icon>mdi-alert</v-icon> แจ้งยกเลิก
                                รอดำเนินการ</v-chip
                              >
                            </div>
                            <div v-else>
                              <v-chip
                                color="warning"
                                @click.stop="
                                  assistant_teacherCancel(
                                    item.assistant_teacher_id
                                  )
                                "
                                outlined
                              >
                                <v-icon>
                                  mdi-alert
                                </v-icon>
                                ขอยกเลิกรายการ
                              </v-chip>
                            </div>

                            <!--    <div>
                              <v-icon
                                color="warning"
                                @click.stop="
                                  assistant_teacherEdit(
                                    item.assistant_teacher_id
                                  )
                                "
                              >
                                mdi-pencil
                              </v-icon>
                            </div> -->
                          </div>
                          <div v-else>
                            <div>
                              <v-icon
                                large
                                color="warning"
                                @click.stop="
                                  assistant_teacherEdit(
                                    item.assistant_teacher_id
                                  )
                                "
                              >
                                mdi-pencil
                              </v-icon>
                            </div>
                          </div>
                        </template>

                        <template
                          v-slot:item.assistant_teacher_ipacheck="{ item }"
                        >
                          <div>
                            <div
                              v-if="item.assistant_teacher_ipacheck === 'Pass'"
                              class="success--text"
                            >
                              <v-icon color="success"
                                >mdi-checkbox-marked-circle-outline</v-icon
                              >
                              ถูกต้อง
                            </div>
                            <div
                              v-else-if="
                                item.assistant_teacher_ipacheck === 'NotPass'
                              "
                                  class="red--text"
                            >
                              <v-icon color="red"
                                >mdi-close-circle-outline</v-icon
                              >
                              ไม่ถูกต้อง
                            </div>

                            <v-icon color="grey" v-else
                              >mdi-checkbox-blank-circle-outline</v-icon
                            >
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                      v-if="result_assistant_teacher_reports"
                    >
                      <v-simple-table>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              วันที่ส่งรายงาน :
                              {{
                                result_assistant_teacher_reports.assistant_teacher_re_datetime
                              }}
                            </td>
                            <td class="text-center">
                              คะแนนเฉลี่ยรวม :
                              {{
                                result_assistant_teacher_reports.assistant_teacher_re_avg_score
                              }}
                            </td>

                            <td class="text-center">
                              จำนวนวันลา :
                              {{
                                result_assistant_teacher_reports.assistant_teacher_re_leaveday
                              }}
                              วัน
                            </td>

                            <td class="text-center">
                              หนังสือรับรอง :
                              <v-btn
                                @click="
                                  viewFilePdfReport(
                                    result_assistant_teacher_reports.assistant_teacher_re_leaveguarantee
                                  )
                                "
                                color="info"
                                fab
                                small
                                ><v-icon>mdi-printer</v-icon></v-btn
                              >
                            </td>

                            <td class="text-center">
                              <v-btn
                                @click="cancelReport()"
                                outlined
                                color="warning"
                              >
                                <v-icon size="30" color="warning"
                                  >mdi-alert</v-icon
                                >
                                ขอยกเลิกรายการ</v-btn
                              >
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <span class="red--text">
                      *หากต้องการแก้ไขการประเมิน ให้กดรายงานแจ้งยกเลิก
                      พร้อมระบุเหตุผล</span
                    >
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-col cols="12" md="12" class="text-right">
                        <div
                          v-if="
                            times_s === 'end' &&
                              result_assistant_teacher_reports.assistant_teacher_re_id_card !==
                                manage_assistant_teacher_mt_id_card.mt_id_card
                          "
                        >
                          <v-icon size="30" color="red">mdi-alert</v-icon>
                          <v-btn
                            color="info"
                            rounded
                            outlinded
                            @click="downloadFile()"
                          >
                            <v-icon>mdi-printer</v-icon>
                            แบบฟอร์มสรุปวันลา</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col cols="12" class="text-right">
                        <v-btn
                          outlined
                          color="grey"
                          @click.stop="evaluate_assistantdialog = false"
                          rounded
                        >
                          <v-icon dark>mdi-close</v-icon>ปิด
                        </v-btn>
                        <!--  
                          " -->
                        <v-btn
                          v-if="
                            times_s === 'end' &&
                              result_assistant_teacher_reports.assistant_teacher_re_id_card !==
                                manage_assistant_teacher_mt_id_card.mt_id_card
                          "
                          color="green"
                          @click.stop="evaluate_assistantSubmit()"
                          rounded
                        >
                          <v-icon dark>mdi-content-save</v-icon
                          >&nbsp;รายงานผลการประเมิน
                        </v-btn>
                        <!--   <v-btn
                          color="info"
                          large
                          rounded
                          v-if="
                            result_assistant_teacher_reports.assistant_teacher_re_id_card ===
                              manage_assistant_teacher_mt_id_card.mt_id_card
                          "
                        >
                          <v-icon>mdi-printer</v-icon>
                          &nbsp; พิมพ์รายงาน
                        </v-btn> -->
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-card>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="assistant_teacherdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="บันทึกคะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ manage_assistant_teacher_mt_id_card.agency }}
                      </h3>
                      <!--   รหัสบัตรประชาชน :
                      {{ manage_assistant_teacher_mt_id_card.mt_id_card }} -->
                      <br />
                      ชื่อ-นามสกุล :
                      {{ manage_assistant_teacher_mt_id_card.mt_title_s
                      }}{{ manage_assistant_teacher_mt_id_card.mt_frist_name }}
                      {{ manage_assistant_teacher_mt_id_card.mt_last_name }}
                      <br />
                      วันที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_date_app_now }}
                      <br />
                      คำสั่งที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_order_app }}
                      <br />
                      <br />

                      <h3>ครั้งที่ประเมิน : {{ times_s }}</h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12 class="text-center">
                      <h3>คะแนนประเมิน</h3>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        v-model="addassistant_teacher.assistant_teacher_score_a"
                        type="number"
                        label="ด้านที่ 1 ด้านการปฏิบัติตน"
                        prepend-icon="mdi-numeric"
                        outlined
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 40) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                        @input="calScore()"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        v-model="addassistant_teacher.assistant_teacher_score_b"
                        type="number"
                        label="ด้านที่ 2 ด้านการปฏิบัติงาน"
                        prepend-icon="mdi-numeric"
                        outlined
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 60) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                        @input="calScore()"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        v-model="addassistant_teacher.assistant_teacher_score"
                        label="คะแนน"
                        prepend-icon="mdi-numeric"
                        outlined
                        readonly
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 100) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              addassistant_teacher.assistant_teacher_datetime
                            "
                            label="วันที่ประเมิน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            outlined
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            addassistant_teacher.assistant_teacher_datetime
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex md6>
                      <v-file-input
                        v-model="documentsLinkfiles"
                        accept=".pdf"
                        name="documentsLinkfiles"
                        color="deep-purple accent-4"
                        counter
                        label=" แบบสรุปผลการประเมิน และแบบสรุปข้อสังเกต ข้อเสนอแนะฯ .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text"
                  >* ครั้งที่ 1 - 2 (ไม่ต่ำกว่าร้อยละ 60 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
                <br />
                <strong class="red--text"
                  >* ครั้งที่ 3 - 4 (ไม่ต่ำกว่าร้อยละ 70 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                outlined
                @click.stop="assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="assistant_teacherdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherEditdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherEditdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="แก้ไขคะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherEditform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ manage_assistant_teacher_mt_id_card.agency }}
                      </h3>
                      <!--    รหัสบัตรประชาชน :
                      {{ manage_assistant_teacher_mt_id_card.mt_id_card }} -->
                      <br />
                      ชื่อ-นามสกุล :
                      {{ manage_assistant_teacher_mt_id_card.mt_title_s
                      }}{{ manage_assistant_teacher_mt_id_card.mt_frist_name }}
                      {{ manage_assistant_teacher_mt_id_card.mt_last_name }}
                      <br />
                      วันที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_date_app_now }}
                      <br />
                      คำสั่งที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_order_app }}
                      <br />
                      <br />

                      <h3>
                        ครั้งที่ประเมิน :
                        {{ assistant_teachers_edit.assistant_teacher_times }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12 class="text-center">
                      <h3>คะแนนประเมิน</h3>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        v-model="
                          assistant_teachers_edit.assistant_teacher_score_a
                        "
                        type="number"
                        label="ด้านที่ 1 ด้านการปฏิบัติตน"
                        prepend-icon="mdi-numeric"
                        outlined
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 40) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                        @input="calScoreEdit()"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        v-model="
                          assistant_teachers_edit.assistant_teacher_score_b
                        "
                        type="number"
                        label="ด้านที่ 2 ด้านการปฏิบัติงาน"
                        prepend-icon="mdi-numeric"
                        outlined
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 60) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                        @input="calScoreEdit()"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        v-model="
                          assistant_teachers_edit.assistant_teacher_score
                        "
                        type="number"
                        label="คะแนน"
                        prepend-icon="mdi-numeric"
                        outlined
                        readonly
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 100) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      md6
                      v-if="
                        assistant_teachers_edit.assistant_teacher_ipacheck !=
                          'Pass'
                      "
                    >
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              assistant_teachers_edit.assistant_teacher_datetime
                            "
                            label="วันที่ประเมิน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            outlined
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            assistant_teachers_edit.assistant_teacher_datetime
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                    <v-flex
                      md6
                      v-if="
                        assistant_teachers_edit.assistant_teacher_ipacheck !=
                          'Pass'
                      "
                    >
                      <v-file-input
                        v-model="documentsLinkfiles"
                        accept=".pdf"
                        name="documentsLinkfiles"
                        color="deep-purple accent-4"
                        counter
                        label=" แบบสรุปผลการประเมิน และแบบสรุปข้อสังเกต ข้อเสนอแนะฯ .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>

                      <div class="text-center">
                        <v-btn
                          v-if="assistant_teachers_edit.assistant_teacher_file"
                          large
                          icon
                          color="info"
                          @click="
                            viewfilePDF(
                              assistant_teachers_edit.assistant_teacher_file
                            )
                          "
                        >
                          <v-icon>mdi-printer</v-icon>
                        </v-btn>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text"
                  >* ครั้งที่ 1 - 2 (ไม่ต่ำกว่าร้อยละ 60 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
                <br />
                <strong class="red--text"
                  >* ครั้งที่ 3 - 4 (ไม่ต่ำกว่าร้อยละ 70 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                outlined
                @click.stop="assistant_teacherEditdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                color="green"
                @click.stop="assistant_teacherEditdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherFileEditdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherFileEditdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ยกเลิกไฟล์คะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherFileEditdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ manage_assistant_teacher_mt_id_card.agency }}
                      </h3>
                      <!--  รหัสบัตรประชาชน :
                      {{ manage_assistant_teacher_mt_id_card.mt_id_card }} -->
                      <br />
                      ชื่อ-นามสกุล :
                      {{ manage_assistant_teacher_mt_id_card.mt_title_s
                      }}{{ manage_assistant_teacher_mt_id_card.mt_frist_name }}
                      {{ manage_assistant_teacher_mt_id_card.mt_last_name }}
                      <br />
                      วันที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_date_app_now }}
                      <br />
                      คำสั่งที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_order_app }}
                      <br />
                      <br />

                      <h3>
                        ครั้งที่ประเมิน :
                        {{ assistant_teachers_edit.assistant_teacher_times }}
                      </h3>

                      <h3>
                        ไฟล์แนบ :
                        {{ assistant_teachers_edit.assistant_teacher_file }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap> </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="assistant_teacherFileEditdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="red"
                @click.stop="assistant_teacherFileEditdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-delete-forever</v-icon>&nbsp;ลบไฟล์
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherUploadFiledialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherUploadFiledialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="แก้ไขไฟล์คะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="assistant_teacherUploadFiledialogform"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ manage_assistant_teacher_mt_id_card.agency }}
                      </h3>
                      <!--  รหัสบัตรประชาชน :
                      {{ manage_assistant_teacher_mt_id_card.mt_id_card }} -->
                      <br />
                      ชื่อ-นามสกุล :
                      {{ manage_assistant_teacher_mt_id_card.mt_title_s
                      }}{{ manage_assistant_teacher_mt_id_card.mt_frist_name }}
                      {{ manage_assistant_teacher_mt_id_card.mt_last_name }}
                      <br />
                      วันที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_date_app_now }}
                      <br />
                      คำสั่งที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_order_app }}
                      <br />
                      <br />

                      <h3>
                        ครั้งที่ประเมิน :
                        {{ assistant_teachers_edit.assistant_teacher_times }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12>
                      แบบสรุปผลการประเมิน และแบบสรุปข้อสังเกต ข้อเสนอแนะฯ : PDF
                      File
                      <input type="file" ref="file2" id="file2" />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="assistant_teacherUploadFiledialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="assistant_teacherUploadFiledialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherConfirmdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherConfirmdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="success"
              icon="mdi-clipboard-text"
              title="ยืนยันผลการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherConfirmdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ manage_assistant_teacher_mt_id_card.agency }}
                      </h3>
                      <!--  รหัสบัตรประชาชน :
                      {{ manage_assistant_teacher_mt_id_card.mt_id_card }} -->
                      <br />
                      ชื่อ-นามสกุล :
                      {{ manage_assistant_teacher_mt_id_card.mt_title_s
                      }}{{ manage_assistant_teacher_mt_id_card.mt_frist_name }}
                      {{ manage_assistant_teacher_mt_id_card.mt_last_name }}
                      <br />
                      วันที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_date_app_now }}
                      <br />
                      คำสั่งที่บรรจุ :
                      {{ manage_assistant_teacher_mt_id_card.mt_order_app }}
                      <br />
                      <br />

                      <h3>
                        คะแนนเฉลี่ย :
                        {{
                          parseFloat(
                            manage_assistant_teacher_mt_id_card.avg_score
                          ).toFixed[2]
                        }}
                      </h3>
                      <br />

                      <v-flex md6> </v-flex>
                    </div>
                  </v-card-text>

                  <v-layout wrap>
                    <v-flex md4>
                      <v-text-field
                        v-model="
                          assistant_teacher_report_s.assistant_teacher_re_leaveday
                        "
                        type="number"
                        label="จำนวนวันลา"
                        prepend-icon="mdi-numeric"
                        outlined
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v >= 1) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                        @input="calScore()"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md8>
                      <v-file-input
                        v-model="assistant_teacher_re_leaveguaranteefile"
                        accept=".pdf"
                        name="assistant_teacher_re_leaveguaranteefile"
                        color="deep-purple accent-4"
                        counter
                        label="หนังสือรับรอง : .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>

            <div class="text-center">
              <h3 class="red--text">
                หากกดปุ่มยืนยันแล้วจะไม่สามารถดำเนินการแก้ไขได้
                กรุณาตรวจสอบให้ถูกต้องก่อนกดปุ่มยืนยัน
              </h3>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                @click.stop="assistant_teacherConfirmdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="success"
                @click.stop="assistant_teacherConfirmdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันผลการประเมิน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editmanage_assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editmanage_assistant_teacherdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ปรับปรุงข้อมูลบุคลากร"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editmanage_assistant_teacherform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <h3>ข้อมูลการเริ่มปฏิบัติหน้าที่ในสถานศึกษาปัจจุบัน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="คำสั่งที่ ระบุเพียง : 783/2563"
                        v-model="editmanage_assistant_teacher.mt_order_app"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              editmanage_assistant_teacher.mt_date_app_now
                            "
                            label="วันเดือนเริ่มปฏิบัติงาน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editmanage_assistant_teacher.mt_date_app_now"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu3 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu3.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text">* จำเป็น</strong>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="editmanage_assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="editmanage_assistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pertem_assistantFiledialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="pertem_assistantFiledialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="success"
              icon="mdi-clipboard-text"
              title="แนบคำสั่งแต่งตั้งกรรมการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="pertem_assistantFileform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div class="my-4">
                        <h3>
                          สถานศึกษา :
                          {{ editmanage_assistant_teacher.agency }}
                        </h3>
                        <!--  รหัสบัตรประชาชน :
                        {{ editmanage_assistant_teacher.mt_id_card }} <br /> -->
                        ชื่อ-นามสกุล :
                        {{ editmanage_assistant_teacher.mt_title_s
                        }}{{ editmanage_assistant_teacher.mt_frist_name }}
                        {{ editmanage_assistant_teacher.mt_last_name }} <br />
                        วันที่บรรจุ :
                        {{ editmanage_assistant_teacher.mt_date_app_now }}
                        <br />
                        คำสั่งที่บรรจุ :
                        {{ editmanage_assistant_teacher.mt_order_app }}
                      </div>
                    </v-flex>
                    <v-flex md12>
                      <v-file-input
                        v-model="pdfFiles"
                        accept=".pdf"
                        name="pdfFiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :show-size="1000"
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text">* จำเป็น</strong>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                outlined
                @click.stop="pertem_assistantFiledialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon> ปิด
              </v-btn>
              <v-btn
                color="green"
                @click.stop="pertem_assistantFileSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pertem_assistantFileCCdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="pertem_assistantFileCCdialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ขอยกเลิกแนบคำสั่งแต่งตั้งกรรมการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="pertem_assistantFileCCform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div class="my-4">
                        <h3>
                          สถานศึกษา :
                          {{ editmanage_assistant_teacher.agency }}
                        </h3>
                        <!--   รหัสบัตรประชาชน :
                        {{ editmanage_assistant_teacher.mt_id_card }} <br /> -->
                        ชื่อ-นามสกุล :
                        {{ editmanage_assistant_teacher.mt_title_s
                        }}{{ editmanage_assistant_teacher.mt_frist_name }}
                        {{ editmanage_assistant_teacher.mt_last_name }}

                        <br />
                        วันที่บรรจุ :
                        {{ editmanage_assistant_teacher.mt_date_app_now }}
                        <br />
                        คำสั่งที่บรรจุ :
                        {{ editmanage_assistant_teacher.mt_order_app }}
                      </div>
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        v-model="
                          editmanage_assistant_teacher.pertem_assistantFileCC_detail
                        "
                        label="เนื่องจาก"
                        prepend-icon="mdi-information-outline"
                        outlined
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green"
                @click.stop="pertem_assistantFileCCSubmit()"
                rounded
              >
                <v-icon dark>mdi-checkbox-marked-circle</v-icon
                >&nbsp;ยืนยันยกเลิก
              </v-btn>
              <v-btn
                outlined
                color="warning"
                @click.stop="pertem_assistantFileCCdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model committeedialog -->
      <v-layout row justify-center>
        <v-dialog v-model="committeedialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เสนอชื่อคณะกรรมการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="committeedialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-simple-table fixed-header height="300px">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-center" width="40%">
                                    ชื่อ-นามสกุล
                                  </th>
                                  <th class="text-center" width="25%">
                                    ทำหน้าที่
                                  </th>
                                  <th class="text-center" width="35%">
                                    ข้อมูล
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="manage_assistant_teachers"
                                      item-text="mt_frist_names"
                                      item-value="mt_id_card"
                                      v-model="commiteeIdCard[0]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="mt_id_cardSearch1()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.mt_frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    ประธานกรรมการ
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="manage_assistant_teachers"
                                      item-text="mt_frist_names"
                                      item-value="mt_id_card"
                                      v-model="commiteeIdCard[1]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="mt_id_cardSearch2()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.mt_frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>
                                    กรรมการ
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td class="pa-2">
                                    <v-autocomplete
                                      :items="manage_assistant_teachers"
                                      item-text="mt_frist_names"
                                      item-value="mt_id_card"
                                      v-model="commiteeIdCard[2]"
                                      prepend-icon="mdi-account"
                                      dense
                                      label="ค้นหา : ชื่อ-นามสกุล"
                                      request
                                      :rules="[v => !!v || '']"
                                      rounded
                                      outlined
                                      @change="mt_id_cardSearch3()"
                                    >
                                      <template v-slot:item="{ item }">
                                        <div class="pa-2">
                                          <div class="font-weight-black">
                                            {{ item.mt_frist_names }}
                                          </div>

                                          <div class="divTab50">
                                            {{ item.position_name }} :
                                            {{ item.college_name }}
                                          </div>
                                        </div>
                                      </template>
                                    </v-autocomplete>
                                  </td>
                                  <td>กรรมการ</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text">* จำเป็น</strong>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="committeedialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="committeedialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdffileviewDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdffileviewDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <!-- V-model pdffileviewDialog -->
    <v-layout row justify-center>
      <v-dialog v-model="pdffileviewReportAssDialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'https://hrvec.ovec.go.th/HRvecAssistant_report/' + pdf_files"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- V-model assistant_teacherCanceldialog -->
    <v-layout row justify-center>
      <v-dialog
        v-model="assistant_teacherCanceldialog"
        persistent
        max-width="60%"
      >
        <v-card class="mx-auto pa-6">
          <base-material-card
            color="green"
            icon="mdi-clipboard-text"
            title="แจ้งยกเลิกการรายงานคะแนน"
            class="px-5 py-3 text_google"
          ></base-material-card>
          <v-card-text>
            <v-form ref="assistant_teacherCanceldialogform" lazy-validation>
              <v-container grid-list-md>
                <v-card-text>
                  <div class="my-4">
                    <!--  รหัสบัตรประชาชน :
                    {{ assistant_teachers_edit.mt_id_card }} <br /> -->
                    ชื่อ-นามสกุล :
                    {{ assistant_teachers_edit.frist_names }} <br />
                    ครั้งที่ :
                    {{ assistant_teachers_edit.assistant_teacher_times }}
                  </div>
                  <div class="my-4"></div>
                </v-card-text>
                <v-layout wrap>
                  <v-flex md12>
                    <v-text-field
                      v-model="
                        assistant_teachers_edit.assistant_teacher_comment_detail
                      "
                      label="เนื่องจาก"
                      prepend-icon="mdi-information-outline"
                      outlined
                      required
                      :rules="[v => !!v || '']"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              fab
              small
              outlined
              @click.stop="assistant_teacherCanceldialog = false"
              rounded
            >
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
            <v-btn
              color="green"
              @click.stop="assistant_teacherCanceldialogSubmit()"
              rounded
            >
              <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-layout row justify-center>
      <v-dialog v-model="pdflicenseteachdialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRvecLicenseTeach/' + pt_licence_files"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- V-model assistant_teacher_reportCanceldialog -->
    <v-layout row justify-center>
      <v-dialog
        v-model="assistant_teacher_reportCanceldialog"
        persistent
        max-width="50%"
      >
        <v-card class="mx-auto pa-6">
          <base-material-card
            color="warning"
            icon="mdi-clipboard-text"
            title="ยกเลิกการรายงานสรุปวันลาและไฟล์แนบ"
            class="px-5 py-3 text_google"
          ></base-material-card>
          <v-card-text>
            <v-form ref="assistant_teacher_reportform" lazy-validation>
              <v-container grid-list-md>
                <v-card-text>
                  <div class="my-4">
                    <h3>
                      สถานศึกษา :
                      {{ manage_assistant_teacher_mt_id_card.agency }}
                    </h3>
                    <!--  รหัสบัตรประชาชน :
                    {{ manage_assistant_teacher_mt_id_card.mt_id_card }} <br /> -->
                    ชื่อ-นามสกุล :
                    {{ manage_assistant_teacher_mt_id_card.mt_title_s
                    }}{{ manage_assistant_teacher_mt_id_card.mt_frist_name }}
                    {{ manage_assistant_teacher_mt_id_card.mt_last_name }}
                    <br />
                    วันที่บรรจุ :
                    {{ manage_assistant_teacher_mt_id_card.mt_date_app_now }}
                    <br />
                    คำสั่งที่บรรจุ :
                    {{ manage_assistant_teacher_mt_id_card.mt_order_app }}
                    <br />
                    <br />
                  </div>
                  <div class="my-4"></div>
                </v-card-text>
                <v-layout wrap>
                  <v-flex md12>
                    <v-text-field
                      label="เหตุผลในการขอยกเลิก"
                      :rules="[v => !!v || '']"
                      filled
                      outlined
                      v-model="
                        result_assistant_teacher_reports.assistant_teacher_re_status_detail
                      "
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              outlined
              @click.stop="assistant_teacher_reportCanceldialog = false"
              rounded
            >
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
            <v-btn
              outlined
              color="warning"
              @click.stop="assistant_teacher_reportSubmit()"
              rounded
            >
              <v-icon dark>mdi-archive-cancel</v-icon>&nbsp;ดำเนินการยกเลิก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- V-model pertem_assistantFileIpadialog -->
    <v-dialog v-model="pertem_assistantFileIpadialog" max-width="80%">
      <v-card class="" elevation="2">
        <embed
          :src="'/HRCommitteeQf/' + pdffiles"
          width="100%"
          height="700px"
        />
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import AssisstantBar from "../../components/college/assisstantBar.vue";
import Caledar_assesteach from "../../components/college/caledar_assesteach.vue";

export default {
  data() {
    return {
      loading: true,
      pdflicenseteachdialog: false,
      ApiKey: "HRvec2021",
      valid: true,
      addmanage_assistant_teacherdialog: false,
      editmanage_assistant_teacherdialog: false,
      deletemanage_assistant_teacherdialog: false,
      evaluate_assistantdialog: false,
      assistant_teacherdialog: false,
      assistant_teacherEditdialog: false,
      assistant_teacherConfirmdialog: false,
      assistant_teacherFileEditdialog: false,
      assistant_teacherUploadFiledialog: false,
      pertem_assistantFiledialog: false,
      committeedialog: false,
      pertem_assistantFileCCdialog: false,
      file2: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_assistant_teachers: [],
      addmanage_assistant_teacher: {},
      editmanage_assistant_teacher: {},
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],

      selectedHeaders: [],
      headers: [
        /*  { text: "รหัสบัตรประชาชน", align: "left", value: "mt_id_card" }, */
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "สกุล", align: "left", value: "mt_last_name" },
        /* { text: "ตำแหน่ง", align: "center", value: "position_name" }, */
        { text: "เลขที่ตำแหน่ง", align: "center", value: "mt_id_position" },
        {
          text: "เสนอรายชื่อกรรมการประเมิน",
          align: "center",
          value: "mt_committe_file"
        },
        {
          text: "ประกาศคำสั่งแต่งตั้งกรรมการฯจาก สอศ.",
          align: "center",
          value: "pertem_assistantFileIpastatus"
        },
        {
          text: "คำสั่งแต่งตั้งกรรมการประเมิน(เฉพาะราย)",
          align: "center",
          value: "pertem_assistantFile"
        },
        { text: "ใบประกอบวิชาชีพ", align: "center", value: "pt_licence" },
        { text: "การประเมิน", align: "center", value: "evaluate_s" },
        { text: "ครั้งที่ 1", align: "center", value: "mt_time_1_score" },
        { text: "ครั้งที่ 2", align: "center", value: "mt_time_2_score" },
        { text: "ครั้งที่ 3", align: "center", value: "mt_time_3_score" },
        { text: "ครั้งที่ 4", align: "center", value: "mt_time_4_score" },
        /*  { text: "สถานะ", align: "center", value: "prints" }, */
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "วันเดือนปีเกิด", align: "center", value: "mt_brith_day" },
        { text: "วันเดือนปีบรรจุ", align: "center", value: "mt_date_app_now" }
        /*  {
          text: "วันที่ปฏิบัติหน้าที่ ณ สถานศึกษาปัจจุบัน",
          align: "center",
          value: "mt_date_app_now"
        }, */
        /* { text: "เกษียณ", align: "center", value: "retrire_year" }, */
        /*   {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        } */
      ],
      headers_aassistant_teacher: [
        { text: "ครั้งที่", align: "center", value: "assistant_teacher_times" },
        {
          text: "รหัสบัตรประชาชน",
          align: "left",
          value: "assistant_teacher_id_card"
        },
        {
          text: "ชื่อ-นามสกุล",
          align: "left",
          value: "frist_names"
        },
        {
          text: "วันที่ประเมิน",
          align: "center",
          value: "assistant_teacher_datetime"
        },

        {
          text: "วันที่รายงาน",
          align: "center",
          value: "assistant_teacher_datetimest"
        },

        
        {
          text: "ด้านที่ 1",
          align: "center",
          value: "assistant_teacher_score_a"
        },
        {
          text: "ด้านที่ 2",
          align: "center",
          value: "assistant_teacher_score_b"
        },
        { text: "คะแนน", align: "center", value: "assistant_teacher_score" },
       /*  { text: "สถานะ", align: "center", value: "assistant_teacher_status" }, */
        { text: "ไฟล์แนบ", align: "center", value: "assistant_teacher_file" },
        {
          text: "รายงาน",
          align: "center",
          value: "assistant_teacher_comment"
        },

        {
          text: "รายละเอียด",
          align: "center",
          value: "assistant_teacher_comment_detail"
        },

        {
          text: "สอศ.ตรวจสอบ",
          align: "center",
          value: "assistant_teacher_ipacheck"
        },
        {
          text: "หมายเหตุ",
          align: "center",
          value: "assistant_teacher_ipacomment"
        }
        /*  {
          text: "ลบรายการ/ไฟล์แนบ",
          align: "center",
          value: "assistant_teacher_file_update"
        }, */
      ],
      rang_names: [
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],
      rang_levels: ["-", 1, 2, 3, 4, 5],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      userstatus: [],
      man_powers: [],
      collgegs: [],
      manage_assistant_teacherstatus: [],
      regions: [],
      region_ena: true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      manage_assistant_teacher_mt_id_card: [],
      assistant_teachers: [],
      addassistant_teacher: {},
      assistant_teachers_edit: {},
      assistant_teachers_confrim: {},
      assistant_teacher_report_s: {},
      result_assistant_teacher_reports: [],
      documentsLinkfiles: null,
      commiteeIdCard: [],
      pdfFiles: null,
      Updatemanage_assistant_teacher: {},
      linealerts: {},
      assisstantCount: [],
      pdf_files: [],
      pdffileviewDialog: false,
      checkDateAss: false,
      massege_cc: [],
      assistant_teacherCanceldialog: false,
      pt_licence_files: "",
      times_s: "",
      assistant_teacher_re_leaveguaranteefile: null,
      pdffileviewReportAssDialog: false,
      assistant_teacher_reportCanceldialog: false,
      users: [],
      pdffiles: "",
      pertem_assistantFileIpadialog: false
    };
  },

  async mounted() {
    await this.userQuery();
    await this.manage_assistant_teacherQueryAll();
  },
  methods: {
    async viewpertem_assistantFileIpa(pertem_assistantFileIpa) {
      this.pdffiles = pertem_assistantFileIpa;
      this.pertem_assistantFileIpadialog = true;
    },

    async viewpdfmt_committe_file(mt_committe_file) {
      this.pdf_files = mt_committe_file;

      this.pdffileviewReportAssDialog = true;
    },

    async userQuery() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        ApiKey: "HRvec2021"
      });
      this.users = result.data;
    },

    async assistant_teacher_reportSubmit() {
      if (this.$refs.assistant_teacher_reportform.validate()) {
        this.result_assistant_teacher_reports.ApiKey = this.ApiKey;
        this.result_assistant_teacher_reports.assistant_teacher_re_status =
          "CC";
        let result = await this.$http.post(
          "assistant_teacher_report.update.php",
          this.result_assistant_teacher_reports
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacher_reportCanceldialog = false;
      }
    },
    async cancelReport() {
      this.assistant_teacher_reportCanceldialog = true;
    },
    async downloadFile() {
      var downloadLink = document.createElement("a");
      var url = "https://hrvec.ovec.go.th/docEx/formSummary.docx";
      var filename = "formSummary.docx";
      downloadLink.href = url;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },

    async calScore() {
      let ascore = parseFloat(
        this.addassistant_teacher.assistant_teacher_score_a || 0
      );
      let bscore = parseFloat(
        this.addassistant_teacher.assistant_teacher_score_b || 0
      );
      let sum = 0;
      sum = parseFloat(ascore + bscore).toFixed(2);
      this.addassistant_teacher.assistant_teacher_score = sum;
    },

    async calScoreEdit() {
      let ascore = parseFloat(
        this.assistant_teachers_edit.assistant_teacher_score_a || 0
      );
      let bscore = parseFloat(
        this.assistant_teachers_edit.assistant_teacher_score_b || 0
      );
      let sum = 0;
      sum = parseFloat(ascore + bscore).toFixed(2);
      this.assistant_teachers_edit.assistant_teacher_score = sum;
    },

    async viewpdflicenseteach(pt_licence_file) {
      this.pt_licence_files = pt_licence_file;
      let pt_licence_fileCk = String(pt_licence_file);
      if (pt_licence_fileCk.length > 4) {
        this.pdflicenseteachdialog = true;
      } else {
        Swal.fire({
          icon: "warning",
          title: "ไม่ได้แนบไฟล์",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async viewfilePDF(file) {
      this.pdf_files = file;
      this.pdffileviewDialog = true;
    },

    async viewFilePdfReport(file) {
      this.pdf_files = file;
      this.pdffileviewReportAssDialog = true;
    },

    async pertem_assistantFileCCSubmit() {
      if (this.$refs.pertem_assistantFileCCform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        this.editmanage_assistant_teacher.pertem_assistantFileCC = "CC";

        let result = await this.$http.post(
          "manage_assistant_teacher.update.php",
          this.editmanage_assistant_teacher
        );

        if (result.data.status == true) {
          this.linealerts.ApiKey = this.ApiKey;
          this.linealerts.college_name = this.users.user_firstname;
          this.linealerts.details = String(
            "งานครูผู้ช่วย : ขอยกเลิกแนบไฟล์คำสั่งแต่งตั้งคณะกรรมการ ประเมินครูผู้ช่วย : " +
              this.editmanage_assistant_teacher.mt_id_card +
              " : " +
              this.editmanage_assistant_teacher.mt_title_s +
              this.editmanage_assistant_teacher.mt_frist_name +
              " " +
              this.editmanage_assistant_teacher.mt_last_name
          );

          this.linealerts.datetime = this.date_today;

          let result_line = await this.$http.post(
            "linenotify_hr.php",
            this.linealerts
          );

          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.pertem_assistantFileCCdialog = false;
      }
    },
    async pertem_assistantFileCC(mt_id_card) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id_card: mt_id_card,
        flash: "ok"
      });
      this.editmanage_assistant_teacher = result.data;

      this.pertem_assistantFileCCdialog = true;
    },
    async pertem_assistantFileSubmit() {
      let result = "";
      let uploaded = null;
      if (this.$refs.pertem_assistantFileform.validate()) {
        if (this.pdfFiles != "") {
          let formData = new FormData();
          let filename =
            this.editmanage_assistant_teacher.mt_id_card +
            "." +
            this.time_stamp +
            "." +
            "assistantCommittee.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.pdfFiles);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.Updatemanage_assistant_teacher.pertem_assistantFile = filename;

          uploaded = true;
        } else {
          uploaded = false;
        }
      } else {
        upload = false;
      }
      this.Updatemanage_assistant_teacher.ApiKey = this.ApiKey;
      this.Updatemanage_assistant_teacher.id_card = this.editmanage_assistant_teacher.mt_id_card;
      this.Updatemanage_assistant_teacher.mt_id_card = this.editmanage_assistant_teacher.mt_id_card;
      this.Updatemanage_assistant_teacher.pertem_assistantFileCC = "CF";
      /* 
      this.Updatemanage_assistant_teacher.mt_committe_datetime = new Date();
 */
      let result_p = await this.$http.post(
        "personnel_temporary.update.php",
        this.Updatemanage_assistant_teacher
      );
      let result_m = await this.$http.post(
        "manage_assistant_teacher.update.idcard.php",
        this.Updatemanage_assistant_teacher
      );

      if (
        result.data.status == true &&
        result_m.data.status == true &&
        result_p.data.status == true
      ) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.manage_assistant_teacherQueryAll();
      } else {
        if (!uploaded && upload) {
          Swal.fire({
            icon: "warning",
            title: "แนบไฟล์ไม่ถูกต้อง",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.pertem_assistantFiledialog = false;
    },
    async pertem_assistantFileAdd(mt_id_card) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id_card: mt_id_card,
        flash: "ok"
      });
      this.editmanage_assistant_teacher = result.data;
      this.pertem_assistantFiledialog = true;
    },
    async commiteeAdd(mt_id_card) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id_card: mt_id_card,
        flash: "ok"
      });
      this.editmanage_assistant_teacher = result.data;
      this.committeedialog = true;
    },
    async manage_assistant_teacherEdit(mt_id_card) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id_card: mt_id_card,
        flash: "ok"
      });
      this.editmanage_assistant_teacher = result.data;
      this.editmanage_assistant_teacher.p_word = "";
      this.editmanage_assistant_teacherdialog = true;
    },

    async evaluate_assistant(mt_id_card) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id_card: mt_id_card,
        flash: "ok"
      });
      this.manage_assistant_teacher_mt_id_card = result.data;
      await this.times_sQuery();

      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id_card: mt_id_card
      });
      this.assistant_teachers = result_assistant.data;
      let dataas = this.assistant_teachers;
      let countas = 0;
      dataas.forEach(value => {
        countas += 1;
      });
      this.assisstantCount = countas;

      let result_assistant_teacher_report = await this.$http.post(
        "assistant_teacher_report.php",
        {
          ApiKey: this.ApiKey,
          assistant_teacher_re_id_card: mt_id_card
        }
      );
      this.result_assistant_teacher_reports =
        result_assistant_teacher_report.data;
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear() + 543;
      let dateNow = new Date(yyyy + "-" + mm + "-" + dd);
      let dateNows = dateNow.getTime();
      this.checkDateAss = false;
      //this.checkDateAss = true;
      if (this.times_s == 1) {
        let dateStop = new Date(
          this.manage_assistant_teacher_mt_id_card.month6
        );
        let dateStops = dateStop.getTime();
        if (dateNows > dateStops) {
          this.checkDateAss = true;
        }
      } else if (this.times_s == 2) {
        let dateStop = new Date(
          this.manage_assistant_teacher_mt_id_card.month12
        );
        let dateStops = dateStop.getTime();
        if (dateNows > dateStops) {
          this.checkDateAss = true;
        }
      } else if (this.times_s == 3) {
        let dateStop = new Date(
          this.manage_assistant_teacher_mt_id_card.month18
        );
        let dateStops = dateStop.getTime();
        if (dateNows > dateStops) {
          this.checkDateAss = true;
        }
      } else if (this.times_s == 4) {
        let dateStop = new Date(
          this.manage_assistant_teacher_mt_id_card.month24
        );
        let dateStops = dateStop.getTime();
        if (dateNows > dateStops) {
          this.checkDateAss = true;
        }
      }
      this.documentsLinkfiles = null;
      this.evaluate_assistantdialog = true;
    },

    async assistant_teacher_add() {
      this.addassistant_teacher = {};
      this.assistant_teacherdialog = true;
    },

    async assistant_teacherFileEdit(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.assistant_teacherFileEditdialog = true;
    },
    async assistant_teacherUploadFile(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.assistant_teacherUploadFiledialog = true;
    },

    async assistant_teacherCancel(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.assistant_teacherCanceldialog = true;
    },

    async assistant_teacherCanceldialogSubmit() {
      if (this.$refs.assistant_teacherCanceldialogform.validate()) {
        this.assistant_teachers_edit.ApiKey = this.ApiKey;
        this.assistant_teachers_edit.assistant_teacher_comment = "collegeCC";
        let result = await this.$http.post(
          "assistant_teacher.update.php",
          this.assistant_teachers_edit
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.evaluate_assistant(
            this.assistant_teachers_edit.mt_id_card
          );
          this.linealerts.ApiKey = this.ApiKey;
          this.linealerts.college_name = this.assistant_teachers_edit.college_name;
          this.linealerts.details =
            "งานครูผู้ช่วย : ขอยกเลิกเสนอชื่อคณะกรรมการประเมินครูผู้ช่วย";
          this.linealerts.datetime = this.date_today;

          let result_line = await this.$http.post(
            "linenotify_hr.php",
            this.linealerts
          );
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacherCanceldialog = false;
      }
    },

    async assistant_teacherEdit(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.assistant_teacherEditdialog = true;
    },

    async manage_assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_college_code: this.users.user_name,
          fastQ: "Ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },
    async assistant_teacherdialogSubmit() {
      let result = "";
      let uploaded = null;
      if (this.$refs.assistant_teacherdialogform.validate()) {
        this.addassistant_teacher.ApiKey = this.ApiKey;
        this.addassistant_teacher.assistant_teacher_college_code = this.manage_assistant_teacher_mt_id_card.mt_college_code;
        this.addassistant_teacher.assistant_teacher_id_card = this.manage_assistant_teacher_mt_id_card.mt_id_card;
        this.addassistant_teacher.assistant_teacher_times = this.times_s;
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.manage_assistant_teacher_mt_id_card.mt_id_card +
            "." +
            this.times_s +
            "." +
            this.time_stamp +
            "." +
            "assistant.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          this.addassistant_teacher.assistant_teacher_file = filename;
          uploaded = true;
        }

        result = await this.$http.post(
          "assistant_teacher.insert.php",
          this.addassistant_teacher
        );

        let assistant_teacher_id = result.data.assistant_teacher_id;
        this.Updatemanage_assistant_teacher.ApiKey = this.ApiKey;
        this.Updatemanage_assistant_teacher.mt_id_card = this.addassistant_teacher.assistant_teacher_id_card;
        if (this.addassistant_teacher.assistant_teacher_times == "1") {
          this.Updatemanage_assistant_teacher.mt_time_1_id = assistant_teacher_id;
          this.Updatemanage_assistant_teacher.mt_time_1_file = this.addassistant_teacher.assistant_teacher_file;
          this.Updatemanage_assistant_teacher.mt_time_1_score_a = this.addassistant_teacher.assistant_teacher_score_a;
          this.Updatemanage_assistant_teacher.mt_time_1_score_b = this.addassistant_teacher.assistant_teacher_score_b;
          this.Updatemanage_assistant_teacher.mt_time_1_score = this.addassistant_teacher.assistant_teacher_score;
        } else if (this.addassistant_teacher.assistant_teacher_times == "2") {
          this.Updatemanage_assistant_teacher.mt_time_2_id = assistant_teacher_id;

          this.Updatemanage_assistant_teacher.mt_time_2_file = this.addassistant_teacher.assistant_teacher_file;
          this.Updatemanage_assistant_teacher.mt_time_2_score_a = this.addassistant_teacher.assistant_teacher_score_a;
          this.Updatemanage_assistant_teacher.mt_time_2_score_b = this.addassistant_teacher.assistant_teacher_score_b;
          this.Updatemanage_assistant_teacher.mt_time_2_score = this.addassistant_teacher.assistant_teacher_score;
        } else if (this.addassistant_teacher.assistant_teacher_times == "3") {
          this.Updatemanage_assistant_teacher.mt_time_3_id = assistant_teacher_id;

          this.Updatemanage_assistant_teacher.mt_time_3_file = this.addassistant_teacher.assistant_teacher_file;
          this.Updatemanage_assistant_teacher.mt_time_3_score_a = this.addassistant_teacher.assistant_teacher_score_a;
          this.Updatemanage_assistant_teacher.mt_time_3_score_b = this.addassistant_teacher.assistant_teacher_score_b;
          this.Updatemanage_assistant_teacher.mt_time_3_score = this.addassistant_teacher.assistant_teacher_score;
        } else if (this.addassistant_teacher.assistant_teacher_times == "4") {
          this.Updatemanage_assistant_teacher.mt_time_4_id = assistant_teacher_id;
          this.Updatemanage_assistant_teacher.mt_time_4_file = this.addassistant_teacher.assistant_teacher_file;
          this.Updatemanage_assistant_teacher.mt_time_4_score_a = this.addassistant_teacher.assistant_teacher_score_a;
          this.Updatemanage_assistant_teacher.mt_time_4_score_b = this.addassistant_teacher.assistant_teacher_score_b;
          this.Updatemanage_assistant_teacher.mt_time_4_score = this.addassistant_teacher.assistant_teacher_score;
        }

        let result_m = await this.$http.post(
          "manage_assistant_teacher.update.idcard.php",
          this.Updatemanage_assistant_teacher
        );

        if (result.data.status == true || result_m.data.status == true) {
          this.evaluate_assistantdialog = false;

          await this.evaluate_assistant(
            this.manage_assistant_teacher_mt_id_card.mt_id_card
          );
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.manage_assistant_teacherQueryAll();
        } else {
          if (!uploaded) {
            this.snackbar.icon = "mdi-close-network";
            this.snackbar.color = "red";
            this.snackbar.text = "ไฟล์ที่แนบไม่ถูกต้อง";
            this.snackbar.show = true;
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        this.assistant_teacherdialog = false;
      }
    },
    async assistant_teacherUploadFiledialogSubmit() {
      let result = "";
      let uploaded = null;
      if (this.$refs.file2.files[0]) {
        if (this.$refs.file2.files[0].type != "") {
          let formData = new FormData();
          let filename =
            this.assistant_teachers_edit.assistant_teacher_id_card +
            "." +
            this.assistant_teachers_edit.assistant_teacher_times +
            "." +
            this.time_stamp +
            "." +
            "assistant.pdf";
          formData.append("file", this.$refs.file2.files[0]);
          formData.append("filename", "../HRvecfiles/" + filename);
          formData.append("ApiKey", this.ApiKey);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.assistant_teachers_edit.assistant_teacher_file = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      }
      this.assistant_teachers_edit.ApiKey = this.ApiKey;
      let result_update = await this.$http.post(
        "assistant_teacher.update.php",
        this.assistant_teachers_edit
      );
      if (
        result.data.status ||
        (uploaded && result_update.data.status == true)
      ) {
        let result = await this.$http.post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_id_card: this.assistant_teachers_edit.assistant_teacher_id_card,
          flash: "ok"
        });
        this.manage_assistant_teacher_mt_id_card = result.data;
        let result_assistant = await this.$http.post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_id_card: this.assistant_teachers_edit
            .assistant_teacher_id_card
        });
        this.assistant_teachers = result_assistant.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.manage_assistant_teacherQueryAll();
      } else {
        if (!uploaded) {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ไฟล์ที่แนบไม่ถูกต้อง";
          this.snackbar.show = true;
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.assistant_teacherUploadFiledialog = false;
    },
    async assistant_teacherFileEditdialogSubmit() {
      let resultPic = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename:
          "../HRvecfiles/" + this.assistant_teachers_edit.assistant_teacher_file
      });

      let resultRec = await this.$http.post("assistant_teacher.delete.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: this.assistant_teachers_edit.assistant_teacher_id
      });
      if (resultPic.data.status == true && resultRec.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการลบผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id_card: this.manage_assistant_teacher_mt_id_card.mt_id_card
      });
      this.manage_assistant_teacher_mt_id_card = result.data;
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id_card: this.manage_assistant_teacher_mt_id_card
          .mt_id_card
      });
      this.assistant_teachers = result_assistant.data;
      await this.manage_assistant_teacherQueryAll();
      this.assistant_teacherFileEditdialog = false;
    },
    //Update
    async assistant_teacherEditdialogSubmit() {
      let uploaded;
      if (this.$refs.assistant_teacherEditform.validate()) {
        this.assistant_teachers_edit.ApiKey = this.ApiKey;
        this.Updatemanage_assistant_teacher.ApiKey = this.ApiKey;
        this.Updatemanage_assistant_teacher.mt_id_card = this.assistant_teachers_edit.assistant_teacher_id_card;

        if (this.assistant_teachers_edit.assistant_teacher_ipacheck == "Pass") {
        } else {
          this.assistant_teachers_edit.assistant_teacher_ipacheck = "";
          this.assistant_teachers_edit.assistant_teacher_comment_detail = "";
        }

        if (this.documentsLinkfiles != "") {
          let resultFile = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.assistant_teachers_edit.assistant_teacher_file
          });
          this.assistant_teachers_edit.assistant_teacher_file = "";

          let formData = new FormData();
          let filename =
            this.manage_assistant_teacher_mt_id_card.mt_id_card +
            "." +
            this.assistant_teachers_edit.assistant_teacher_times +
            "." +
            this.time_stamp +
            "." +
            "assistant.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          this.assistant_teachers_edit.assistant_teacher_file = filename;

          uploaded = true;
        }

        this.assistant_teachers_edit.assistant_teacher_comment = "";

        let result = await this.$http.post(
          "assistant_teacher.update.php",
          this.assistant_teachers_edit
        );

        if (this.assistant_teachers_edit.assistant_teacher_times == "1") {
          this.Updatemanage_assistant_teacher.mt_time_1_file = this.assistant_teachers_edit.assistant_teacher_file;
          this.Updatemanage_assistant_teacher.mt_time_1_score_a = this.assistant_teachers_edit.assistant_teacher_score_a;
          this.Updatemanage_assistant_teacher.mt_time_1_score_b = this.assistant_teachers_edit.assistant_teacher_score_b;
          this.Updatemanage_assistant_teacher.mt_time_1_score = this.assistant_teachers_edit.assistant_teacher_score;
          this.Updatemanage_assistant_teacher.mt_time_1_ck = "";
        } else if (
          this.assistant_teachers_edit.assistant_teacher_times == "2"
        ) {
          this.Updatemanage_assistant_teacher.mt_time_2_file = this.assistant_teachers_edit.assistant_teacher_file;
          this.Updatemanage_assistant_teacher.mt_time_2_score_a = this.assistant_teachers_edit.assistant_teacher_score_a;
          this.Updatemanage_assistant_teacher.mt_time_2_score_b = this.assistant_teachers_edit.assistant_teacher_score_b;
          this.Updatemanage_assistant_teacher.mt_time_2_score = this.assistant_teachers_edit.assistant_teacher_score;
          this.Updatemanage_assistant_teacher.mt_time_2_ck = "";
        } else if (
          this.assistant_teachers_edit.assistant_teacher_times == "3"
        ) {
          this.Updatemanage_assistant_teacher.mt_time_3_file = this.assistant_teachers_edit.assistant_teacher_file;
          this.Updatemanage_assistant_teacher.mt_time_3_score_a = this.assistant_teachers_edit.assistant_teacher_score_a;
          this.Updatemanage_assistant_teacher.mt_time_3_score_b = this.assistant_teachers_edit.assistant_teacher_score_b;
          this.Updatemanage_assistant_teacher.mt_time_3_score = this.assistant_teachers_edit.assistant_teacher_score;
          this.Updatemanage_assistant_teacher.mt_time_3_ck = "";
        } else if (
          this.assistant_teachers_edit.assistant_teacher_times == "4"
        ) {
          this.Updatemanage_assistant_teacher.mt_time_4_file = this.assistant_teachers_edit.assistant_teacher_file;
          this.Updatemanage_assistant_teacher.mt_time_4_score_a = this.assistant_teachers_edit.assistant_teacher_score_a;
          this.Updatemanage_assistant_teacher.mt_time_4_score_b = this.assistant_teachers_edit.assistant_teacher_score_b;
          this.Updatemanage_assistant_teacher.mt_time_4_score = this.assistant_teachers_edit.assistant_teacher_score;
          this.Updatemanage_assistant_teacher.mt_time_4_ck = "";
        }

        let result_m = await this.$http.post(
          "manage_assistant_teacher.update.idcard.php",
          this.Updatemanage_assistant_teacher
        );

        if (result.data.status == true || result_m.data.status == true) {
          let result = await this.$http.post("manage_assistant_teacher.php", {
            ApiKey: this.ApiKey,
            mt_id_card: this.manage_assistant_teacher_mt_id_card.mt_id_card,
            flash: "ok"
          });
          this.manage_assistant_teacher_mt_id_card = result.data;
          let result_assistant = await this.$http.post(
            "assistant_teacher.php",
            {
              ApiKey: this.ApiKey,
              assistant_teacher_id_card: this
                .manage_assistant_teacher_mt_id_card.mt_id_card
            }
          );
          this.assistant_teachers = result_assistant.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacherEditdialog = false;
      }
    },
    async evaluate_assistantSubmit() {
      this.assistant_teacherConfirmdialog = true;
    },
    async assistant_teacherConfirmdialogSubmit() {
      if (this.$refs.assistant_teacherConfirmdialogform.validate()) {
        let result = "";
        let uploaded = null;

        if (this.assistant_teacher_re_leaveguaranteefile != null) {
          let formData = new FormData();
          let filename =
            this.manage_assistant_teacher_mt_id_card.mt_id_card +
            "." +
            this.times_s +
            "." +
            this.time_stamp +
            "." +
            "finalRp.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.assistant_teacher_re_leaveguaranteefile);
          formData.append("filename", "../HRvecAssistant_report/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          this.assistant_teacher_report_s.assistant_teacher_re_leaveguarantee = filename;
          uploaded = true;
        }

        this.assistant_teachers_confrim.ApiKey = this.ApiKey;
        this.assistant_teachers_confrim.assistant_teacher_id_card = this.manage_assistant_teacher_mt_id_card.mt_id_card;
        this.assistant_teachers_confrim.assistant_teacher_status = "confirm";
        this.assistant_teacher_report_s.ApiKey = this.ApiKey;
        this.assistant_teacher_report_s.assistant_teacher_re_college_code = this.manage_assistant_teacher_mt_id_card.mt_college_code;
        this.assistant_teacher_report_s.assistant_teacher_re_id_card = this.manage_assistant_teacher_mt_id_card.mt_id_card;
        this.assistant_teacher_report_s.assistant_teacher_re_datetime = this.date_today;
        this.assistant_teacher_report_s.assistant_teacher_re_avg_score = this.manage_assistant_teacher_mt_id_card.avg_score;

        let resultup = await this.$http.post(
          "assistant_teacher.update.confirm.php",
          this.assistant_teachers_confrim
        );
        let result_assistant_teacher_report = await this.$http.post(
          "assistant_teacher_report.insert.php",
          this.assistant_teacher_report_s
        );

        if (
          resultup.data.status == true &&
          result_assistant_teacher_report.data.status == true
        ) {
          let result = await this.$http.post("manage_assistant_teacher.php", {
            ApiKey: this.ApiKey,
            mt_id_card: this.manage_assistant_teacher_mt_id_card.mt_id_card,
            flash: "ok"
          });
          this.manage_assistant_teacher_mt_id_card = result.data;
          let result_assistant = await this.$http.post(
            "assistant_teacher.php",
            {
              ApiKey: this.ApiKey,
              assistant_teacher_id_card: this
                .manage_assistant_teacher_mt_id_card.mt_id_card
            }
          );
          let result_assistant_teacher_report = await this.$http.post(
            "assistant_teacher_report.php",
            {
              ApiKey: this.ApiKey,
              assistant_teacher_re_id_card: this
                .manage_assistant_teacher_mt_id_card.mt_id_card
            }
          );
          this.result_assistant_teacher_reports =
            result_assistant_teacher_report.data;

          this.assistant_teachers = result_assistant.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacherConfirmdialog = false;
      }
    },
    async editmanage_assistant_teacherSubmit() {
      if (this.$refs.editmanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        if (this.editmanage_assistant_teacher.p_word == "")
          delete this.editmanage_assistant_teacher.p_word;
        let result = await this.$http.post(
          "manage_assistant_teacher.update.admin.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editmanage_assistant_teacherdialog = false;
      }
    },
    date_today_cal() {
      let today = new Date();
      return today;
    },
    get_gov_Age(mt_date_app_now) {
      let DOB = new Date(mt_date_app_now);
      var today = today || new Date(),
        result = {
          years: 0,
          months: 0,
          days: 0,
          toString: function() {
            return (
              (this.years ? this.years + " ปี " : "") +
              (this.months ? this.months + " เดือน " : "") +
              (this.days ? this.days + " วัน" : "")
            );
          }
        };
      result.months =
        today.getFullYear() * 12 +
        (today.getMonth() + 1) -
        (DOB.getFullYear() * 12 + (DOB.getMonth() + 1));
      if (0 > (result.days = today.getDate() - DOB.getDate())) {
        var y = today.getFullYear(),
          m = today.getMonth();
        m = --m < 0 ? 11 : m;
        result.days +=
          [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m] +
          (1 == m && y % 4 == 0 && (y % 100 > 0 || y % 400 == 0) ? 1 : 0);
        --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = result.months % 12;
      return result;
    },
    row_classes(item) {
      if (
        this.get_govMonth_Age(item.mt_date_app_now) == 6 ||
        this.get_govMonth_Age(item.mt_date_app_now) == 12 ||
        this.get_govMonth_Age(item.mt_date_app_now) == 18 ||
        this.get_govMonth_Age(item.mt_date_app_now) == 24
      ) {
        return "#F0F4C3";
      }
    },
    getColor(get_govMonth_Age) {
      if (get_govMonth_Age > 0) return "info";
      else return "";
    },
    get_govMonth_Age(mt_date_app_now) {
      let DOB = new Date(mt_date_app_now);
      var today = today || new Date(),
        result = {
          years: 0,
          months: 0,
          days: 0,
          toString: function() {
            return (
              (this.years ? this.years + " ปี " : "") +
              (this.months ? this.months + " เดือน " : "") +
              (this.days ? this.days + " วัน" : "")
            );
          }
        };
      result.months =
        today.getFullYear() * 12 +
        (today.getMonth() + 1) -
        (DOB.getFullYear() * 12 + (DOB.getMonth() + 1));
      if (0 > (result.days = today.getDate() - DOB.getDate())) {
        var y = today.getFullYear(),
          m = today.getMonth();
        m = --m < 0 ? 11 : m;
        result.days +=
          [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][m] +
          (1 == m && y % 4 == 0 && (y % 100 > 0 || y % 400 == 0) ? 1 : 0);
        --result.months;
      }
      result.years = (result.months - (result.months % 12)) / 12;
      result.months = result.months % 12;
      return result;
    },
    get_Age(mt_brith_year, mt_brith_month, mt_brith_day) {
      let today = new Date();
      let DOB = new Date(
        parseInt(mt_brith_year) -
          543 +
          "-" +
          mt_brith_month +
          "-" +
          mt_brith_day
      );
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;
      let days;
      let months;
      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },

    async times_sQuery() {
      let times = this.manage_assistant_teacher_mt_id_card.last_time_ass;
      let result;
      if (times == "1") {
        result = 2;
      } else if (times == "2") {
        result = 3;
      } else if (times == "3") {
        result = 4;
      } else if (times == "4") {
        result = "end";
      } else {
        result = 1;
      }
      this.times_s = result;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },

    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    time_stamp() {
      const d = new Date();
      let time = d.getTime();
      return time;
    },
    color() {
      return "blue-grey";
    }
  },
  components: { AssisstantBar, Caledar_assesteach }
};
</script>

<style scoped>
.text-alert {
  font-size: 10pt;
}
</style>
