<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'รายงานผล ภาค ข. ผู้สมัครคัดเลือก ตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
            period_vecprovinces.period_vecprovince_times +
            ' ปี : ' +
            period_vecprovinces.period_vecprovince_year
        "
      >
        <div class="text-right font-weight-bold">
          <h2>ภาค ข (กรรมการ สำนักงานอาชีวศึกษาจังหวัด)</h2>

          <v-btn color="info" rounded @click="downloadFileB()">
            <v-icon>mdi-download</v-icon> ดาว์นโหลดแบบทำการ ภาค ข.</v-btn
          >

          <v-btn
            color="info"
            rounded
            :href="
              '#/vecprovince/print_sedirector_app_score_b_vp_dir/?time=' +
                period_vecprovinces.period_vecprovince_times +
                '&year=' +
                period_vecprovinces.period_vecprovince_year +
                '&pid=' +
                users.user_province_ID
            "
            target="_blank"
          >
            <v-icon>mdi-printer</v-icon> พิมพ์แบบสรุปผลคะแนนการประเมิน ภาค
            ข.</v-btn
          >
        </div>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
          :item-class="row_classes"
        >
          <template v-slot:item.sedirector_app_file="{ item }">
            <v-btn
              large
              text
              rounded
              color="green"
              @click="pdfHrvecRegisDir(item.sedirector_app_file)"
              ><v-icon large>mdi-printer</v-icon></v-btn
            >
          </template>

          <template v-slot:item.sedirector_app_file_B="{ item }">
            <v-btn
              text
              rounded
              color="green"
              large
              @click="pdfHrvecRegisDir(item.sedirector_app_file_B)"
              ><v-icon large>mdi-printer</v-icon></v-btn
            >
          </template>

          <template v-slot:item.sedirector_app_pvcscore_B="{ item }">
            <v-chip color="grey" outlined dark>
              <h2>{{ item.sedirector_app_pvcscore_B || "0" }}</h2>
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_B_2="{ item }">
            <v-chip color="grey" outlined dark>
              <h2>
                {{ item.sedirector_app_pvcscore_B_2 || "0" }}
              </h2>
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_B_3="{ item }">
            <v-chip color="grey" outlined dark>
              <h2>
                {{ item.sedirector_app_pvcscore_B_3 || "0" }}
              </h2>
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_B_all="{ item }">
            <v-chip color="info" outlined dark>
              <h2>
                {{
                  calAllscore(
                    item.sedirector_app_pvcscore_B,
                    item.sedirector_app_pvcscore_B_2,
                    item.sedirector_app_pvcscore_B_3
                  )
                }}
              </h2>
            </v-chip>

            <div
              class="mt-2"
              v-if="String(item.sedirector_app_pvcscore_B_file).length <= 4"
            >
              <v-btn
                @click="
                  sedirector_app_pvcscore_Breport(item.sedirector_app_idref)
                "
                dark
                color="success"
                rounded
              >
                <v-icon>
                  mdi-account-plus
                </v-icon>
                รายงานผล
              </v-btn>
            </div>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_B_avg="{ item }">
            <v-chip color="success" dark>
              {{
                calAllscoreAvg(
                  item.sedirector_app_pvcscore_B,
                  item.sedirector_app_pvcscore_B_2,
                  item.sedirector_app_pvcscore_B_3
                )
              }}
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_B_file="{ item }">
            <div v-if="item.sedirector_app_pvcscore_B_file">
              <v-btn
                @click="
                  viewsedirector_app_pvcscore_B_file(
                    item.sedirector_app_pvcscore_B_file
                  )
                "
                fab
                small
                color="success"
                dark
              >
                <v-icon>mdi-printer</v-icon>
                <!-- {{ item.sedirector_app_pvcscore_B_file }} -->
              </v-btn>
            </div>
            <div v-else>
              <v-chip outlined dark color="error">
               <v-icon>mdi-alert</v-icon> ไม่ได้รายงานผล
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_B_committee="{ item }">
            <div v-if="item.sedirector_app_pvcscore_B_committee">
              <v-btn
                @click="
                  viewsedirector_app_pvcscore_B_committee(
                    item.sedirector_app_pvcscore_B_committee
                  )
                "
                fab
                small
                color="success"
                dark
              >
                <v-icon>mdi-printer</v-icon>
                <!-- {{ item.sedirector_app_pvcscore_B_file }} -->
              </v-btn>
            </div>
            <div v-else>
              <v-chip outlined dark color="error">
               <v-icon>mdi-alert</v-icon> ไม่ได้รายงานผล
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_pvcscore_AB_CC="{ item }">
            <div v-if="item.sedirector_app_pvcscore_AB_CC === 'BCC'">
              <v-chip color="red" dark>
                <v-icon>mdi-information-outline</v-icon> ขอยกเลิกไฟล์</v-chip
              >
            </div>
            <div v-else>
              <div
                v-if="String(item.sedirector_app_pvcscore_B_file).length <= 4"
              >
              <v-chip outlined dark color="error">
               <v-icon>mdi-alert</v-icon> ไม่ได้รายงานผล
              </v-chip>
              </div>
              <div v-else>
                <v-icon color="success"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-btn
                  @click="cancelpvcScoreB(item.sedirector_app_idref)"
                  color="warning"
                  outlined
                  rounded
                >
                  <v-icon>mdi-alert</v-icon> ขอยกเลิกรายการ</v-btn
                >
              </div>
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model CancelFilePDFDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="CancelFilePDFDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการแนบไฟล์"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="CancelFilePDFDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                        {{ sedirector_apps_id_ref.sedirector_app_year }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ sedirector_apps_id_ref.sedirector_app_idcard }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }}
                      </h3>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ this.sedirector_apps_id_ref.year_ss }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="CancelFilePDFDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="CandelFilePDFSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model sedirector_app_pvcscore_BDrdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="sedirector_app_pvcscore_BDrdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="success"
              icon="mdi-clipboard-text"
              title="รายงานผลการประเมิน ภาค ข (กรรมการ สำนักงานอาชีวศึกษาจังหวัด)"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="sedirector_app_pvcscore_BDrdialogform"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                      </h3>
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }} : ตำแหน่ง
                        {{ sedirector_apps_id_ref.position_name }}
                      </h3>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ sedirector_apps_id_ref.sedirector_app_year }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          sedirector_apps_id_ref.sedirector_app_pvcscore_B
                        "
                        prepend-icon="mdi-calculator"
                        rounded
                        outlined
                        label="ส่วนที่ 1 ประสบการณ์และการพัฒนาตนเอง"
                        type="number"
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 10) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          sedirector_apps_id_ref.sedirector_app_pvcscore_B_2
                        "
                        prepend-icon="mdi-calculator"
                        rounded
                        outlined
                        label="ส่วนที่ 2 ผลงานที่เกิดขึ้นจากการปฏิบัติงาน"
                        type="number"
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 25) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="
                          sedirector_apps_id_ref.sedirector_app_pvcscore_B_3
                        "
                        prepend-icon="mdi-calculator"
                        rounded
                        outlined
                        label="ส่วนที่ 3 วินัยและจรรยาบรรณวิชาชีพ"
                        type="number"
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 5) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12"
                      ><v-file-input
                        v-model="sedirector_app_pvcscore_B_file"
                        accept=".pdf"
                        name="sedirector_app_pvcscore_B_file"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .pdf"
                        placeholder="เอกสารสรุปผลการประเมิน ภาค ข."
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :rules="rules"
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input></v-col
                    >

                    <v-col cols="12" md="12"
                      ><v-file-input
                        v-model="sedirector_app_pvcscore_B_committee"
                        accept=".pdf"
                        name="sedirector_app_pvcscore_B_committee"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .pdf"
                        placeholder="คำสั่งแต่งตั้งคณะกรรมการประเมิน ภาค ข."
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :rules="rules"
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input></v-col
                    >
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="sedirector_app_pvcscore_BDrdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="success"
                @click.stop="sedirector_app_pvcscore_BSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันบันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model director_app_pvcscore_BCancelDrdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="director_app_pvcscore_BCancelDrdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ขอยกเลิกการรายงานข้อมูล"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="director_app_pvcscore_BCancelDrdialogform"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                      </h3>
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }} : ตำแหน่ง
                        {{ sedirector_apps_id_ref.position_name }}
                      </h3>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ sedirector_apps_id_ref.sedirector_app_year }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-textarea
                        v-model="
                          sedirector_apps_id_ref.sedirector_app_pvcscore_AB_CComment
                        "
                        prepend-icon="mdi-calculator"
                        rounded
                        outlined
                        label="เหตุผลในการยกเลิก"
                        :rules="[v => !!v || '']"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="director_app_pvcscore_BCancelDrdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="success"
                @click.stop="director_app_pvcscore_BCancelSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfHrvecRegisDirdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfHrvecRegisDirdialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model sedirector_app_pvcscore_B_filedialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="sedirector_app_pvcscore_B_filedialog"
          max-width="80%"
        >
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      branch_s: [],
      sedirector_app_idref: [],
      valid: true,
      CancelFilePDFDrdialog: false,
      CancelFilePDFProvinceDrdialog: false,
      pdfHrvecRegisDirdialog: false,
      director_app_pvcscore_BCancelDrdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      sedirector_apps: [],
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "จังหวัด", align: "center", value: "province_name" },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "user_firstname" },
        { text: "ใบสมัครและเอกสารประกอบฯ", align: "center", value: "sedirector_app_file" },
      /*   {
          text: "เอกสารภาค ข.",
          align: "center",
          value: "sedirector_app_file_B"
        }, */

        {
          text: "ส่วน 1 ประวัติ",
          align: "center",
          value: "sedirector_app_pvcscore_B"
        },
        {
          text: "ส่วนที่ 2 ผลงาน",
          align: "center",
          value: "sedirector_app_pvcscore_B_2"
        },
        {
          text: "ส่วนที่ 3 วินัย",
          align: "center",
          value: "sedirector_app_pvcscore_B_3"
        },

        {
          text: "รวม",
          align: "center",
          value: "sedirector_app_pvcscore_B_all"
        },
        /*  {
          text: "ร้อยละ",
          align: "center",
          value: "sedirector_app_pvcscore_B_avg"
        },
 */
        {
          text: "ไฟล์คะแนน ภาค ข.",
          align: "center",
          value: "sedirector_app_pvcscore_B_file"
        },

        {
          text: "คำสั่งแต่งตั้งคณะกรรมการ",
          align: "center",
          value: "sedirector_app_pvcscore_B_committee"
        },

        {
          text: "สถานะ",
          align: "center",
          value: "sedirector_app_pvcscore_AB_CC"
        }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      sedirector_apps_id_ref: [],
      period_vecprovinces: [],
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      users: [],
      sedirector_app_pvcscore_B_filedialog: false,
      pdffiles: "",
      sedirector_app_pvcscore_BDrdialog: false,
      sedirector_app_pvcscore_B_file: null,
      sedirector_app_pvcscore_B_committee: null,
      countCheck: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.userQuery();
    await this.period_vecprovinceQuery();
  },
  methods: {
    async pdfHrvecRegisDir(pdffile) {
      this.pdffiles = "";
      this.pdffiles = pdffile;

      this.pdfHrvecRegisDirdialog = true;
    },

    async downloadFileB() {
      var downloadLink = document.createElement("a");
      var url = "https://hrvec.ovec.go.th/docEx/DirectorB.xlsx";
      var filename = "DirectorB.xlsx";
      downloadLink.href = url;
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    calAllscore(a, b, c) {
      let aa = parseFloat(a) || 0;
      let bb = parseFloat(b) || 0;
      let cc = parseFloat(c) || 0;
      let result = parseFloat(aa) + parseFloat(bb) + parseFloat(cc);
      return result;
    },

    calAllscoreAvg(a, b, c) {
      let aa = parseFloat(a) || 0;
      let bb = parseFloat(b) || 0;
      let cc = parseFloat(c) || 0;
      let sum = parseFloat(aa) + parseFloat(bb) + parseFloat(cc);
      let result = parseFloat((sum * 100) / 150).toFixed(2);
      return result;
    },

    async userQuery() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        provice: "check",
        ApiKey: "HRvec2021"
      });
      this.users = result.data;
    },
    async sedirector_app_pvcscore_Breport(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.sedirector_app_pvcscore_B_file = null;
      this.sedirector_app_pvcscore_BDrdialog = true;
    },
    async period_vecprovinceQuery() {
      let result_period_vecprovince;
      result_period_vecprovince = await this.$http.post(
        "period_vecprovince.php",
        {
          ApiKey: this.ApiKey,
          period_vecprovinc_id: "40102"
        }
      );
      this.period_vecprovinces = result_period_vecprovince.data;
      let data = this.period_vecprovinces.period_vecprovince_enable;
      if (data != 1) {
        Swal.fire({
          icon: "error",
          title: "ระบบไม่เปิดใช้งาน",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/vecprovince");
          }
        });
      } else {
        await this.sedirector_appQueryAll();
      }
    },
    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype: 3,
          sedirector_app_time: this.period_vecprovinces
            .period_vecprovince_times,
          sedirector_app_year: this.period_vecprovinces.period_vecprovince_year,
          province_ID: this.users.user_province_ID,
          ivens:'notshow',
        })
        .finally(() => (this.loading = false));
      ;
      this.sedirector_apps = result.data;
      let data = this.sedirector_apps;
      let counts = 0;
      data.forEach(async value => {
        counts += 1;
      });

      if (counts > 0) {
      } else {
        Swal.fire({
          icon: "error",
          title: "ไม่พบผู้ผ่านคุณสมบัติ เพื่อประเมิน ภาค ข",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/vecprovince");
          }
        });
      }
    },

    async sedirector_app_pvcscore_BSubmit() {
      this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
      let result;
      if (this.$refs.sedirector_app_pvcscore_BDrdialogform.validate()) {
        if (this.sedirector_app_pvcscore_B_file != null) {
          let formData = new FormData();
          let filename =
            this.sedirector_apps_id_ref.sedirector_app_idref +
            this.time_stamp +
            "." +
            "sedirect_A_score.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.sedirector_app_pvcscore_B_file);
          formData.append("filename", "../HrvecRegisDir/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.sedirector_apps_id_ref.sedirector_app_pvcscore_B_file = filename;
        }

        if (this.sedirector_app_pvcscore_B_committee != null) {
          let formData = new FormData();
          let filename =
            this.sedirector_apps_id_ref.sedirector_app_idref +
            this.time_stamp +
            "." +
            "sedirect_A_committee.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.sedirector_app_pvcscore_B_committee);
          formData.append("filename", "../HrvecRegisDir/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.sedirector_apps_id_ref.sedirector_app_pvcscore_B_committee = filename;
        }
      }
      this.sedirector_apps_id_ref.sedirector_app_pvcscore_AB_CC = "CF";

      let resultup = await this.$http.post(
        "sedirector_app.update.php",
        this.sedirector_apps_id_ref
      );

      if (resultup.data.status == true) {
        Swal.fire({
          title: "ดำเนินการข้อมูลเรียบร้อย",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        await this.sedirector_appQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.sedirector_app_pvcscore_BDrdialog = false;
    },

    async viewsedirector_app_pvcscore_B_file(sedirector_app_pvcscore_B_file) {
      this.pdffiles = sedirector_app_pvcscore_B_file;
      this.sedirector_app_pvcscore_B_filedialog = true;
    },

    async viewsedirector_app_pvcscore_B_committee(
      sedirector_app_pvcscore_B_committee
    ) {
      this.pdffiles = sedirector_app_pvcscore_B_committee;
      this.sedirector_app_pvcscore_B_filedialog = true;
    },

    async cancelpvcScoreB(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.director_app_pvcscore_BCancelDrdialog = true;
    },

    async director_app_pvcscore_BCancelSubmit() {
      if (this.$refs.director_app_pvcscore_BCancelDrdialogform.validate()) {
        let text = this.sedirector_apps_id_ref.fristnames;
        Swal.fire({
          title: "คุณต้องการขอยกเลิกรายงานผลภาค ข ?",
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ขอยกเลิก",
          cancelButtonText: "ปิด"
        }).then(async result => {
          if (result.isConfirmed) {
            this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
            this.sedirector_apps_id_ref.sedirector_app_pvcscore_AB_CC = "BCC";

            let result = await this.$http.post(
              "sedirector_app.update.php",
              this.sedirector_apps_id_ref
            );
            if (result.data.status == true) {
              Swal.fire({
                icon: "success",
                title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
                showConfirmButton: false,
                timer: 1500
              });
              this.sedirector_appQueryAll();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        });
        this.director_app_pvcscore_BCancelDrdialog = false;
      }
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    },
    row_classes(item) {
      if (item.sedirector_app_pvcscore_AB_CC == "BCC") {
        return "yellow";
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_vecprovince_years() {
      let yyyy = this.period_vecprovinces.period_vecprovince_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: {}
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
