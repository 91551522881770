<template>
  <div>
    <manageAssistantTeacherBarVue />
    <v-container fluid>
      <base-material-card
        icon="mdi-account-star"
        title="ข้อมูลครูผู้ช่วย รายงานตัว ณ สถานศึกษา (โอนย้าย)"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="text-right pa-1">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
              <v-btn fab @click="manage_assistant_teacheridSeach()" color="info"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" md="3" class="text-right pa-1">
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_assistant_teacherQueryAll()"
              >
                <v-icon>mdi-clipboard-text</v-icon>แสดงข้อมูลทั้งหมด
              </v-btn>

              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_assistant_teacherCancelQueryAll()"
              >
                <v-icon>mdi-clipboard-text</v-icon>คำร้องขอยกเลิก
              </v-btn>
            </v-col>
            <v-col cols="12" md="3" class="text-right pa-1">
              <v-btn @click="ApproveCollegeReport()" color="warning" block>
                <v-icon>mdi-checkbox-multiple-marked</v-icon
                >บันทึกอนุมัติการรายงานตัว
              </v-btn>
            </v-col>

            <v-col cols="12" md="12">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-center">จำนวน</th>
                    <th class="text-center">รายงานตัว</th>
                    <th class="text-center">โอนย้าย</th>
                    <th class="text-center">สละสิทธิ์</th>
                    <th class="text-center">ยังไม่ดำเนินการ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <v-btn
                        @click="manage_assistant_teacherQueryAll()"
                        color="info"
                        fab
                        class="font-weight-bold"
                        small
                        >{{ count_alls }}</v-btn
                      >
                    </td>
                    <td class="text-center">
                      <v-btn
                        @click="
                          manage_assistant_teacherSearchStatus(
                            (status = 'receipt')
                          )
                        "
                        color="success"
                        fab
                        class="font-weight-bold"
                        small
                        >{{ count_receipts }}</v-btn
                      >
                    </td>

                    <td class="text-center">
                      <v-btn
                        @click="
                          manage_assistant_teacherSearchTypeTran(
                            (status = 'transfer_position')
                          )
                        "
                        color="warning"
                        fab
                        class="font-weight-bold"
                        small
                        >{{ count_transfer_position }}</v-btn
                      >
                    </td>

                    <td class="text-center">
                      <v-btn
                        @click="
                          manage_assistant_teacherSearchStatus(
                            (status = 'missing')
                          )
                        "
                        color="red"
                        fab
                        class="font-weight-bold"
                        small
                        >{{ count_missings }}</v-btn
                      >
                    </td>

                    <td class="text-center">
                      <v-btn
                        @click="
                          manage_assistant_teacherSearchStatus(
                            (status = 'send')
                          )
                        "
                        color="warning"
                        fab
                        class="font-weight-bold"
                        small
                        >{{ count_sends }}</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="manage_assistant_teachers"
          :search="search"
        
          class="elevation-1"
          :item-class="row_classes"
        >
       <!--    <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="20">
              <h2>
                <v-icon @click="toggle">{{
                  isOpen ? "mdi-minus" : "mdi-plus"
                }}</v-icon>
                {{ items[0].mt_college_code + " : " + items[0].college_name }}
              </h2>
            </th>
          </template> -->

          <template v-slot:item.mt_date_app_now="{ item }">
            <div>
              {{
                item.mt_date_app_now
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.mt_examgroup="{ item }">
            <div class="text-center">
              <v-chip
                dark
                color="purple"
                v-if="item.mt_examgroup === 'general'"
              >
                ทั่วไป
              </v-chip>
              <v-chip dark color="indigo" v-else>
                จชต.
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'transfer_position'"
              >
                ครูโอนย้าย
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'local_dev'"
              >
                ครูพัฒนาท้องถิ่น
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_transfer_status="{ item }">
            <div v-if="item.mt_id === editedItem.mt_id">
              <v-select
                label="เลือกสถานะ"
                :items="mt_transfer_status"
                item-text="text"
                item-value="value"
                v-model="editedItem.mt_transfer_status"
              ></v-select>
            </div>
            <div v-else>
              <v-chip dark color="red" v-if="item.mt_transfer_status === '1'">
                <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
              </v-chip>
              <v-chip dark color="green" v-else>
                <v-icon>mdi-lightbulb-on-outline</v-icon>
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_sequence="{ item }">
            <v-chip dark color="info">
              <h2>{{ item.mt_sequence }}</h2>
            </v-chip>
          </template>

          <template v-slot:item.mt_committe_status="{ item }">
            <v-chip
              dark
              color="primary"
              v-if="item.mt_committe_status === 'CF'"
            >
              <v-icon>mdi-checkbox-multiple-marked</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_admissions_status="{ item }">
            <div v-if="item.mt_id === editedItem.mt_id">
              <v-select
                label="เลือกสถานะ"
                :items="mt_admissions_statusSe"
                item-text="text"
                item-value="value"
                v-model="editedItem.mt_admissions_status"
              ></v-select>
            </div>
            <div v-else>
              <v-chip
                dark
                color="red"
                v-if="item.mt_admissions_status === 'missing'"
              >
                <v-icon>mdi-information</v-icon>ไม่มารายงานตัว
              </v-chip>
              <v-chip
                dark
                color="primary"
                v-else-if="item.mt_admissions_status === 'receipt'"
              >
                <v-icon>mdi-information</v-icon>มารายงานตัวปกติ
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-else
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                <v-icon>mdi-information</v-icon>ไม่ได้ดำเนินการ
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_admissions_file="{ item }">
            <div class="text-center">
              <v-chip
                dark
                color="info"
                v-if="item.mt_admissions_file"
                @click="viewadmissionsFile(item.mt_admissions_file)"
              >
                <v-icon>mdi-printer</v-icon>
              </v-chip>
              {{ item.mt_admissions_date }}
            </div>
          </template>

          <!--       :href="'/HRvecfiles/' + item.mt_admissions_file"
                  target="_blank" -->

          <template v-slot:item.mt_collegeCC="{ item }">
            <v-chip
              dark
              color="red"
              v-if="item.mt_collegeCC === 'CC'"
              @click="CancelAdmissions(item.mt_id)"
            >
              <v-icon>mdi-information</v-icon>ขอยกเลิกข้อมูล
            </v-chip>
            <v-chip dark color="primary" v-else-if="item.mt_collegeCC === 'CF'">
              <v-icon>mdi-account-check</v-icon>อนุมัติบุคคล
            </v-chip>
          </template>

          <template v-slot:item.mt_status="{ item }">
            <v-chip dark color="red" v-if="item.mt_status === 'disclaim'">
              <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
            </v-chip>
            <v-chip dark color="green" v-else>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.actionSelect="{ item }">
            <div v-if="item.mt_status !== 'disclaim'">
              <div v-if="item.mt_id === editedItem.mt_id">
                <v-icon color="red" class="mr-3" @click="close"
                  >mdi-window-close</v-icon
                >
                <v-icon color="green" @click="save()">mdi-content-save</v-icon>
              </div>
              <div v-else>
                <v-icon color="green" class="mr-3" @click="editItem(item)"
                  >mdi-pencil</v-icon
                >
                <v-icon color="red" @click="deleteItem(item.mt_id, item.mt_id)"
                  >mdi-delete</v-icon
                >
              </div>
            </div>
          </template>

      

          <template v-slot:item.mt_id_position="{ item }">
            <span>{{ item.mt_id_position }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              fab
              color="warning"
              small
              @click="manage_assistant_teacherEdit(item.mt_id)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              v-if="
                item.mt_admissions_status !== 'send' &&
                  item.mt_admissions_status !== 'receipt'
              "
              color="red"
              @click.stop="manage_assistant_teacherDelete(item.mt_id)"
              >mdi-delete</v-icon
            >
            <v-chip v-else dark color="warning">
              <v-icon dark color="black">mdi-cube-send</v-icon>
            </v-chip>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model editmanage_assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editmanage_assistant_teacherdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-pencil"
              title="แก้ไขข้อมูลการขอยกเลิกข้อมูล"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editmanage_assistant_teacherform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div class="text-center font-weight-bold">
                        บัตรประชาชน :
                        {{ editmanage_assistant_teacher.mt_id_card }}
                        ชื่อ-นามสกุล :
                        {{ editmanage_assistant_teacher.mt_title_s
                        }}{{ editmanage_assistant_teacher.mt_frist_name }}
                        {{ editmanage_assistant_teacher.mt_last_name }}
                      </div>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                        :items="branchs"
                        item-text="name_branch"
                        item-value="id_branch"
                        outlined
                        label="สาขาวิชา"
                        v-model="editmanage_assistant_teacher.mt_id_branch"
                        required
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.id_branch }} :
                              {{ item.name_branch }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="ลำดับที่สอบได้"
                        v-model="editmanage_assistant_teacher.mt_sequence"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เบอร์โทร"
                        v-model="editmanage_assistant_teacher.mt_tel_p"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ชื่อย่อวุฒิการศึกษา"
                        v-model="editmanage_assistant_teacher.mt_ed_abb"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="สาขาวิชาเอก"
                        v-model="editmanage_assistant_teacher.mt_ed_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="คำสั่งที่"
                        v-model="editmanage_assistant_teacher.mt_order_app"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_status_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="สถานะ"
                        v-model="editmanage_assistant_teacher.mt_status"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_examtype_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="วิธีการ"
                        v-model="editmanage_assistant_teacher.mt_examtype"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_examgroup_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="เขตพื้นที่"
                        v-model="editmanage_assistant_teacher.mt_examgroup"
                        required
                        :rules="[v => !!v || '']"
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="type_personnelApp"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="ประเภทการบรรจุ"
                        v-model="editmanage_assistant_teacher.mt_type_personnel"
                        required
                      >
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md4>
                      <v-menu
                        ref="menu5"
                        v-model="menu5"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="
                              editmanage_assistant_teacher.mt_date_app_now
                            "
                            label="วันที่บรรจุ/เริ่มปฏิบัติงาน ณ สถานศึกษาปัจจุบัน"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editmanage_assistant_teacher.mt_date_app_now"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu5 = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu5.save(date)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex md4>
                      <v-menu
                        ref="menu6"
                        v-model="menu6"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            v-model="
                              editmanage_assistant_teacher.mt_date_app_postitionnow
                            "
                            label="วันที่บรรจุ/เริ่มปฏิบัติงาน ณ ในตำแหน่งปัจจุบัน"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            editmanage_assistant_teacher.mt_date_app_postitionnow
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu6 = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu6.save(date)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex md12>
                      <v-radio-group
                        v-model="
                          editmanage_assistant_teacher.mt_transfer_status
                        "
                        row
                      >
                        <template v-slot:label>
                          <div><strong>เปิด/ปิดการับรายงานตัว</strong></div>
                        </template>
                        <v-radio value="0">
                          <template v-slot:label>
                            <div>
                              <strong class="success--text">เปิด</strong>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="1">
                          <template v-slot:label>
                            <div>
                              <strong class="warning--text">ปิด</strong>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>

                <v-flex md12>
                  <v-autocomplete
                    v-model="
                      editmanage_assistant_teacher.mt_admissions_statusSelect
                    "
                    :items="mt_admissions_status_select"
                    item-text="text"
                    item-value="value"
                    outlined
                    label="สถานะการรับรายงานตัว"
                    required
                  ></v-autocomplete>
                </v-flex>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                outlined
                @click.stop="editmanage_assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editmanage_assistant_teacherUpdateSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
              </v-btn>

              <v-btn
                large
                color="primary"
                @click.stop="editmanage_assistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;อนุมัติการขอยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model Confirmmanage_assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="Confirmmanage_assistant_teacherdialog"
          persistent
          max-width="70%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="info"
              icon="mdi-checkbox-multiple-marked-circle"
              :title="
                'ยืนยันการรายงานตัว ครูผู้ช่วยรอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3"
            ></base-material-card>
            <v-card class="mt-5">
              <v-form
                ref="Confirmmanage_assistant_teacherdialogform"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-account</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ editmanage_assistant_teacher.mt_id_card }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >เลขบัตรประชาชน</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-account</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            editmanage_assistant_teacher.mt_title_s +
                              editmanage_assistant_teacher.mt_frist_name +
                              " " +
                              editmanage_assistant_teacher.mt_last_name
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >ชื่อ-นามสกุล</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">mdi-school</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ editmanage_assistant_teacher.mt_id_branch }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          สาขาวิชา :
                          {{ editmanage_assistant_teacher.name_branch }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ editmanage_assistant_teacher.mt_tel_p }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Tel.</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon>mdi-message-text</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item
                      v-if="
                        editmanage_assistant_teacher.mt_admissions_status ===
                          'missing' ||
                          editmanage_assistant_teacher.mt_admissions_status ===
                            'receipt'
                      "
                    >
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          <h2
                            class="red--text"
                            v-if="
                              editmanage_assistant_teacher.mt_admissions_status ===
                                'missing'
                            "
                          >
                            ไม่มารายงานตัว
                          </h2>
                          <h2
                            class="green--text"
                            v-if="
                              editmanage_assistant_teacher.mt_admissions_status ===
                                'receipt'
                            "
                          >
                            มารายงานตัว
                          </h2>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >สถานะการรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip
                            :href="
                              '/HRvecfiles/' +
                                editmanage_assistant_teacher.mt_admissions_file
                            "
                            target="_blank"
                            color="info"
                            class="pa-2"
                          >
                            <v-icon class="mr-5">mdi-printer</v-icon>
                            <h2>หนังสือนำส่ง</h2>
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >หนังสือแจ้งรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon>mdi-message-text</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editmanage_assistant_teacher.mt_dateAppoint"
                          label="วันที่บรรจุ/เริ่มปฏิบัติงาน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editmanage_assistant_teacher.mt_dateAppoint"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu4 = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu4.save(date)"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-list>
                  <v-flex md12>
                    <v-data-table
                      :headers="assistantcommitteeHearder"
                      :items="assistantcommittees"
                    >
                      <template
                        v-slot:item.assistantcommitteeCollege="{ item }"
                        >{{ item.college_name }}</template
                      >

                      <template
                        v-slot:item.assistantcommitteePositon="{ item }"
                      >
                        <span v-if="item.assistantcommitteePositon === '1'"
                          >ผู้ทรงคุณวุฒิภายนอก</span
                        >
                        <span v-else-if="item.assistantcommitteePositon === '2'"
                          >ครูภายในสถานศึกษา</span
                        >
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-container>
              </v-form>
            </v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                outlined
                @click.stop="Confirmmanage_assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="primary"
                @click.stop="Confirmmanage_assistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon
                >&nbsp;บันทึกยืนยันการรายงานตัว
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherCountDialog -->
      <v-layout>
        <v-dialog
          v-model="manage_assistant_teacherCountDialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              :title="
                'ข้อมูลสรุปครูผู้ช่วย รอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3"
            ></base-material-card>

            <v-card-text>
              <v-card>
                <v-data-table
                  color="success"
                  :loading="loading"
                  :headers="headersCount"
                  :items="manage_assistant_teacherCount"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      color="info"
                      @click.stop="
                        matBranchQuery(
                          item.mt_times,
                          item.mt_years,
                          item.mt_id_branch
                        )
                      "
                      >mdi-account-search</v-icon
                    >
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="manage_assistant_teacherCountDialog = false"
                >
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherApproveDialog -->
      <v-layout>
        <v-dialog
          persistent
          v-model="manage_assistant_teacherApproveDialog"
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-checkbox-multiple-marked"
              :title="
                'ยืนยันการรายงานตัวครูผู้ช่วย รอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3"
            ></base-material-card>

            <v-card-text>
              <v-card>
                <v-text-field
                  v-model="searchApprove"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />

                <v-data-table
                  color="success"
                  :loading="loading"
                  :headers="headersApprove"
                  :items="manage_assistant_teachers"
                  :search="searchApprove"
                >
                  <template v-slot:group.header="{ items, isOpen, toggle }">
                    <th colspan="20">
                      <h2>
                        <v-icon @click="toggle">{{
                          isOpen ? "mdi-minus" : "mdi-plus"
                        }}</v-icon>
                        {{
                          items[0].mt_id_branch + " : " + items[0].name_branch
                        }}
                      </h2>
                    </th>
                  </template>

                  <template v-slot:item.mt_sequence="{ item }">
                    <v-chip dark color="info">
                      <h2>{{ item.mt_sequence }}</h2>
                    </v-chip>
                  </template>

                  <template v-slot:item.mt_id_card="{ item }">
                    <span class="hide-idcard-number">
                      {{ item.mt_id_card }}
                    </span>
                  </template>

                  <template v-slot:item.mt_status="{ item }">
                    <v-chip
                      dark
                      color="red"
                      v-if="item.mt_status === 'disclaim'"
                    >
                      <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
                    </v-chip>
                    <v-chip dark color="green" v-else>
                      <v-icon>mdi-lightbulb-on-outline</v-icon>
                    </v-chip>
                  </template>

                  <template v-slot:item.actionSelect="{ item }">
                    <div v-if="item.mt_status !== 'disclaim'">
                      <div v-if="item.mt_id === editedItem.mt_id">
                        <v-icon color="red" class="mr-3" @click="close"
                          >mdi-window-close</v-icon
                        >
                        <v-icon color="green" @click="save()"
                          >mdi-content-save</v-icon
                        >
                      </div>
                      <div v-else>
                        <v-icon
                          color="green"
                          class="mr-3"
                          @click="editItem(item)"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          color="red"
                          @click="deleteItem(item.mt_id, item.mt_id_position)"
                          >mdi-delete</v-icon
                        >
                      </div>
                    </div>
                  </template>

                  <template v-slot:item.mt_college_code="{ item }">
                    <span>{{ item.college_name }}</span>
                  </template>

                  <template v-slot:item.mt_admissions_status="{ item }">
                    <v-chip
                      dark
                      color="warning"
                      v-if="item.mt_admissions_status === 'send'"
                    >
                      <v-icon dark color="black">mdi-cube-send</v-icon>
                    </v-chip>
                    <v-chip dark color="red" v-else>
                      <v-icon>mdi-close-circle</v-icon>
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  color="primary"
                  @click="manage_assistant_teacherApproveSubmit()"
                >
                  <v-icon class="mr-5">mdi-checkbox-multiple-marked</v-icon
                  >ยืนยันข้อมูล
                </v-btn>
                <v-btn
                  color="warning"
                  @click="manage_assistant_teacherApproveCCSubmit()"
                >
                  <v-icon class="mr-5">mdi-closed-caption</v-icon
                  >ยกเลิกการยืนยัน
                </v-btn>
                <v-btn
                  color="blue-grey"
                  outlined
                  @click.stop="manage_assistant_teacherApproveDialog = false"
                >
                  <v-icon class="mr-5">mdi-close-circle-outline</v-icon>ปิด
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherApproveCFDialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="manage_assistant_teacherApproveCFDialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-checkbox-multiple-marked"
              :title="
                'ยืนยันการรายงานตัวครูผู้ช่วย รอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="manage_assistant_teacherApproveCFDialogform"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="text-center red--text">
                        <h2>
                          กรุณาตรวจสอบข้อมูลให้เรียบร้อยก่อนการกดปุ่มยืนยันข้อมูล
                        </h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                large
                @click.stop="manage_assistant_teacherApproveCFDialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="manage_assistant_teacherApproveCFDialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherApproveCCDialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="manage_assistant_teacherApproveCCDialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-closed-caption"
              :title="
                'ยกเลิกการยืนยันการรายงานตัวครูผู้ช่วย รอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="manage_assistant_teacherApproveCCDialogform"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="text-center red--text">
                        <h2>ยกเลิกข้อมูลการยืนยันรายการ</h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                large
                @click.stop="manage_assistant_teacherApproveCCDialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="manage_assistant_teacherApproveCCDialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfmt_admissions_filedialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfmt_admissions_filedialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import manageAssistantTeacherBarVue from "../../../components/admin/manageAssistantTeacherBar.vue";
export default {
  name: "HRvecManageAssistantTeacher",
  components: { manageAssistantTeacherBarVue },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      file2: null,
      editmanage_assistant_teacherdialog: false,
      deletemanage_assistant_teacherdialog: false,
      manage_assistant_teacherCountDialog: false,
      manage_assistant_teacherApproveDialog: false,
      manage_assistant_teacherApproveCFDialog: false,
      manage_assistant_teacherApproveCCDialog: false,
      Confirmmanage_assistant_teacherdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_assistant_teachers: [],
      editmanage_assistant_teacher: {},
      colleges: [],
      userstatus: [],
      search: "",
      searchApprove: "",
      SearchBranch: "",
      pagination: {},
      headers: [
        { text: "รอบที่", align: "left", value: "mt_timeyear" },
        { text: "วันที่บรรจุ", align: "left", value: "mt_date_app_now" },
        { text: "สาขาวิชา", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "ลำดับที่", align: "left", value: "mt_sequence" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        { text: "รหัส", align: "left", value: "mt_id_card" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "mt_name" },

        { text: "สถานะ", align: "left", value: "mt_status" },
        { text: "วิทยาลัย", align: "left", value: "mt_college_code" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        
        { text: "เลขที่ตำแหน่ง", align: "left", value: "mt_id_position" },
        { text: "ประเภท", align: "left", value: "mt_examgroup" },

        { text: "เลือก", align: "left", value: "actionSelect" },
        {
          text: "เปิด/ปิด รายงานตัว",
          align: "left",
          value: "mt_transfer_status"
        },

        { text: "รายงานตัว", align: "left", value: "mt_admissions_status" },
        { text: "น.รายงานตัว", align: "left", value: "mt_admissions_file" },
        { text: "น.กรรมการ", align: "left", value: "mt_committe_status" },
        { text: "เตือน", align: "left", value: "mt_collegeCC" }
        /*   { text: "ดำเนินการ", align: "left", value: "actions" } */
      ],
      headersApprove: [
        { text: "รอบที่", align: "left", value: "mt_timeyear" },
        { text: "สาขาวิชา", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "ลำดับที่", align: "left", value: "mt_sequence" },
        { text: "รหัส", align: "left", value: "mt_id_card" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "mt_name" },
        { text: "สถานะ", align: "left", value: "mt_status" },
        { text: "วิทยาลัย", align: "left", value: "mt_college_code" },
        { text: "เลขที่ตำแหน่ง", align: "left", value: "mt_id_position" },
        { text: "สถานะ", align: "left", value: "mt_admissions_status" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      collgegs: [],
      manage_assistant_teacherstatus: [],
      regions: [],
      region_ena: true,
      data_syslog: {},
      branchs: [],
      periodassteachs: [],
      headersCount: [
        { text: "รอบที่", align: "left", value: "mt_times" },
        { text: "ปี", align: "left", value: "mt_years" },
        { text: "รหัส", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "branchName" },
        { text: "จำนวน", align: "left", value: "branchGroup" },
        { text: "แสดง", align: "left", value: "actions" }
      ],
      manage_assistant_teacherCount: [],
      manage_assistant_teacherGroupBranch: [],
      mt_status_select: [
        { text: "ปกติ", value: "normal" },
        { text: "สละสิทธิ์", value: "disclaim" }
      ],

      assistantcommitteeHearder: [
        { text: "รอบที่", align: "left", value: "assistantcommitteeTime" },
        { text: "ปี", align: "left", value: "assistantcommitteeYear" },
        { text: "วิทยาลัย", align: "left", value: "assistantcommitteeCollege" },
        { text: "ผู้ประเมิน", align: "left", value: "mt_name" },
        { text: "กรรมการ", align: "left", value: "committeeName" },
        { text: "ตำแหน่ง", align: "left", value: "assistantcommitteePositon" }
      ],

      manage_college_manpowers: [],
      manage_college_manpowerIDpostion: [],
      editedIndex: -1,
      editedItem: {
        mt_id: 0,
        mt_college_code: "",
        statusAdd: ""
      },
      editedItemUpdate: {},
      defaultItem: {
        mt_id: 0,
        mt_college_code: "",
        statusAdd: "add"
      },
      deletedItemmat: {},
      deletedItemmcm: {},
      updatemanage_college_manpower: {},
      manage_college_manpowersMt_id: [],
      upadtemanage_assistant_teacher: {},
      assistantcommittees: [],
      manage_assistant_teacherCF: {},
      personnel_temporarys: {},
      menu4: false,
      menu5: false,
      menu6: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      manage_assistant_teacherGroupCollege: [],
      Searchcollege_code: "",
      mt_admissions_status_select: [
        { text: "มารายงานตัว", value: "receipt" },
        { text: "ไม่มารายงานตัว", value: "missing" }
      ],

      mt_examtype_select: [
        { text: "คัดเลือก(ภายใน)", value: "select" },
        { text: "ทั่วไป(ภายนอก)", value: "recruit" }
      ],
      mt_examgroup_select: [
        { text: "ทั่วไป", value: "general" },
        { text: "ชายแดนภาคใต้", value: "southern" }
      ],
      type_personnelApp: [
        { text: "ครูพัฒนาท้องถิ่น", value: "local_dev" },
        { text: "ครูโอนย้าย", value: "transfer_position" }
      ],

      insertselects: {},
      count_sends: [],
      count_receipts: [],
      count_missings: [],
      count_transfer_position: [],
      count_alls: [],
      pdf_files: [],
      pdfmt_admissions_filedialog: false,
      mt_admissions_statusSe: [
        { text: "มารายงานตัวปกติ", value: "receipt" },
        { text: "ไม่มารายงานตัว", value: "missing" }
      ],
      mt_transfer_status: [
        { text: "เปิดรับรายงานตัว", value: "0" },
        { text: "ปิดรับรายงานตัว", value: "1" }
      ]
    };
  },

  async mounted() {
    await this.periodassteachQuery();
    await this.branchQuery();
    await this.matCountQuery();
    await this.manage_assistant_teacherQueryAll();
    await this.manage_assistant_teacherGroupBranchQueryAll();
    await this.manage_assistant_teacherGroupCollegeCodeQueryAll();
  },

  methods: {
    async manage_assistant_teacheridSeach() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_id_card: this.search,
          report_selete: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async viewadmissionsFile(mt_admissions_file) {
      this.pdf_files = mt_admissions_file;

      this.pdfmt_admissions_filedialog = true;
    },

    async editmanage_assistant_teacherUpdateSubmit() {
      if (this.$refs.editmanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        this.editmanage_assistant_teacher.mt_admissions_status = this.editmanage_assistant_teacher.mt_admissions_statusSelect;
        this.editmanage_assistant_teacher.mt_admissions_date = this.date_today_log;

        let result = "";

        result = await this.$http.post(
          "manage_assistant_teacher.update.php",
          this.editmanage_assistant_teacher
        );

        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editmanage_assistant_teacherdialog = false;
      }
    },
    async ApproveCollegeReport() {
      this.insertselects.ApiKey = this.ApiKey;
      Swal.fire({
        title: "ต้องการอนุมัติการรายงานตัว ?",
        text: "กรณีที่รายงานตัว ณ สถานศึกษาเรียบร้อย",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let resultInsPerTem = await this.$http.post(
            "personnel_temporary.insertselect.tran.php",
            this.insertselects
          );
          let resultupdateInsPerEtc = await this.$http.post(
            "personnel_etc.insertselect.tran.php",
            this.insertselects
          );
          if (
            resultInsPerTem.data.status == true ||
            resultupdateInsPerEtc.data.status == true
          ) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async Confirmmanage_assistant_teacherSubmit() {
      if (this.$refs.Confirmmanage_assistant_teacherdialogform.validate()) {
        this.manage_assistant_teacherCF.ApiKey = this.ApiKey;
        this.manage_assistant_teacherCF.mt_collegeCC = "CF";
        this.manage_assistant_teacherCF.mt_id = this.editmanage_assistant_teacher.mt_id;
        this.manage_assistant_teacherCF.mt_dateAppoint = this.editmanage_assistant_teacher.mt_dateAppoint;

        this.personnel_temporarys.ApiKey = this.ApiKey;
        this.personnel_temporarys.college_code = this.editmanage_assistant_teacher.mt_college_code;
        this.personnel_temporarys.id_card = this.editmanage_assistant_teacher.mt_id_card;
        this.personnel_temporarys.title_s = this.editmanage_assistant_teacher.mt_title_s;
        this.personnel_temporarys.frist_name = this.editmanage_assistant_teacher.mt_frist_name;
        this.personnel_temporarys.last_name = this.editmanage_assistant_teacher.mt_last_name;
        this.personnel_temporarys.position_name = "ครูผู้ช่วย";
        this.personnel_temporarys.id_position = this.editmanage_assistant_teacher.mt_id_position;
        this.personnel_temporarys.salary_s = "";
        this.personnel_temporarys.type_positition = "ครูผู้ช่วย";
        this.personnel_temporarys.rang_name = "";
        this.personnel_temporarys.rang_condition = "";
        this.personnel_temporarys.rang_level = "ครูผู้ช่วย";
        this.personnel_temporarys.date_rang_level = this.editmanage_assistant_teacher.mt_dateAppoint;
        this.personnel_temporarys.ed_abb = this.editmanage_assistant_teacher.mt_ed_abb;
        this.personnel_temporarys.ed_name = this.editmanage_assistant_teacher.mt_ed_name;
        this.personnel_temporarys.brith_day = this.editmanage_assistant_teacher.mt_brith_day;
        this.personnel_temporarys.brith_month = this.editmanage_assistant_teacher.mt_brith_month;
        this.personnel_temporarys.brith_year = this.editmanage_assistant_teacher.mt_brith_year;
        this.personnel_temporarys.appoin_day = this.editmanage_assistant_teacher.mt_appoin_day;
        this.personnel_temporarys.appoin_month = this.editmanage_assistant_teacher.mt_appoin_month;
        this.personnel_temporarys.appoin_year = this.editmanage_assistant_teacher.mt_appoin_year;
        this.personnel_temporarys.order_app_now = this.editmanage_assistant_teacher.mt_order_app;
        this.personnel_temporarys.date_app_now = this.editmanage_assistant_teacher.mt_dateAppoint;
        this.personnel_temporarys.date_app_positonnow = this.editmanage_assistant_teacher.mt_dateAppoint;
        this.personnel_temporarys.tel_p = this.editmanage_assistant_teacher.mt_tel_p;
        this.personnel_temporarys.user_status = "teacher";

        let result = await this.$http.post(
          "manage_assistant_teacher.update.php",
          this.manage_assistant_teacherCF
        );

        let resultPm = await this.$http.post(
          "manage_assistant_teacherAssistant.insert.php",
          this.personnel_temporarys
        );
        if (result.data.status == true && resultPm.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        await this.manage_assistant_teacherQueryAll();
        this.Confirmmanage_assistant_teacherdialog = false;
      }
    },

    async ConfirmAssistantTeach(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;

      let resultas = await this.$http.post("assistantcommittee.php", {
        ApiKey: this.ApiKey,
        assistantcommitteeTime: this.editmanage_assistant_teacher.mt_times,
        assistantcommitteeYear: this.editmanage_assistant_teacher.mt_years,
        assistantcommitteeIDCardTeach: this.editmanage_assistant_teacher
          .mt_id_card
      });
      this.assistantcommittees = resultas.data;
      this.Confirmmanage_assistant_teacherdialog = true;
    },
    async CancelAdmissions(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.editmanage_assistant_teacherdialog = true;
    },

    async manage_assistant_teacherApprove() {
      this.manage_assistant_teacherApproveDialog = true;
    },
    async manage_assistant_teacherApproveSubmit() {
      this.manage_assistant_teacherApproveCFDialog = true;
    },
    async manage_assistant_teacherApproveCFDialogSubmit() {
      this.upadtemanage_assistant_teacher.ApiKey = this.ApiKey;
      this.upadtemanage_assistant_teacher.mt_times = this.periodassteachs.periodAssTeachTimes;
      this.upadtemanage_assistant_teacher.mt_years = this.periodassteachs.periodAssTeachYear;
      this.upadtemanage_assistant_teacher.mt_admissions_status = "send";

      let resultmat = await this.$http.post(
        "manage_assistant_teacherApprove.update.php",
        this.upadtemanage_assistant_teacher
      );

      this.updatemanage_college_manpower.ApiKey = this.ApiKey;
      this.updatemanage_college_manpower.mcm_times = this.periodassteachs.periodAssTeachTimes;
      this.updatemanage_college_manpower.mcm_years = this.periodassteachs.periodAssTeachYear;
      this.updatemanage_college_manpower.mcm_status = "send";

      let resultmcm = await this.$http.post(
        "manage_college_manpowerApprove.update.php",
        this.updatemanage_college_manpower
      );

      if (resultmat.data.status == true && resultmcm.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "บันทึกข้อมูลผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      await this.manage_assistant_teacherQueryAll();
      this.manage_assistant_teacherApproveCFDialog = false;
    },

    async manage_assistant_teacherApproveCCSubmit() {
      this.manage_assistant_teacherApproveCCDialog = true;
    },

    async manage_assistant_teacherApproveCCDialogSubmit() {
      this.upadtemanage_assistant_teacher.ApiKey = this.ApiKey;
      this.upadtemanage_assistant_teacher.mt_times = this.periodassteachs.periodAssTeachTimes;
      this.upadtemanage_assistant_teacher.mt_years = this.periodassteachs.periodAssTeachYear;
      this.upadtemanage_assistant_teacher.mt_admissions_status = "";

      let result = await this.$http.post(
        "manage_assistant_teacherApprove.update.php",
        this.upadtemanage_assistant_teacher
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "บันทึกข้อมูลผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      await this.manage_assistant_teacherQueryAll();
      this.manage_assistant_teacherApproveCCDialog = false;
    },

    async editItem(item) {
      this.editedIndex = this.manage_assistant_teachers.indexOf(item);
      this.editedItem = Object.assign({}, item);

      let result = await this.$http.post("manage_college_manpower.php", {
        ApiKey: this.ApiKey,
        mcm_times: item.mt_times,
        mcm_years: item.mt_years,
        mcm_id_branch: item.mt_id_branch
      });
      this.manage_college_manpowers = result.data;
    },

    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async collegeIDpositionid(
      mt_times,
      mt_years,
      mt_college_code,
      mt_id_branch
    ) {
      let result = await this.$http.post("manage_college_manpower.php", {
        ApiKey: this.ApiKey,
        mcm_times: mt_times,
        mcm_years: mt_years,
        mcm_college_code: mt_college_code,
        mcm_id_branch: mt_id_branch
      });
      this.manage_college_manpowerIDpostion = result.data;
    },

    async matBranchQuery(mt_times, mt_years, mt_id_branch) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_times: mt_times,
        mt_years: mt_years,
        mt_id_branch: mt_id_branch
      });
      this.manage_assistant_teachers = result.data;
      this.manage_assistant_teacherCountDialog = false;
    },

    async matCountQuery() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_times: this.periodassteachs.periodAssTeachTimes,
        mt_years: this.periodassteachs.periodAssTeachYear,
        mt_count: "Ok"
      });
      this.manage_assistant_teacherCount = result.data;
    },

    async periodassteachQuery() {
      let result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnablePro: "1"
      });
      this.periodassteachs = result.data;
    },

    async branchQuery() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branchs = result.data;
    },

    async manage_assistant_teacherTotal() {
      await this.matCountQuery();
      this.manage_assistant_teacherCountDialog = true;
    },

    async sweetAlertLoading() {
      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then(result => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      });
    },

    async manage_assistant_teacherReplace() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          manage_assistant_teacherCheck: "Ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async manage_assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_type_personnel: "transfer_position"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;

      let data = this.manage_assistant_teachers;
      let count_all = 0;
      let count_send = 0;
      let count_receipt = 0;
      let count_missing = 0;
      let count_transfer_positions = 0;
      data.forEach(async value => {
        count_all += 1;
        if (value.mt_admissions_status == "send") {
          count_send += 1;
        } else if (value.mt_admissions_status == "receipt") {
          count_receipt += 1;
        } else if (value.mt_admissions_status == "missing") {
          count_missing += 1;
        }
        if (value.mt_type_personnel == "transfer_position") {
          count_transfer_positions += 1;
        }
        this.count_alls = count_all;
        this.count_sends = count_send;
        this.count_receipts = count_receipt;
        this.count_missings = count_missing;
        this.count_transfer_position = count_transfer_positions;
      });
    },

    async manage_assistant_teacherCancelQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          mt_collegeCC: "CC"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async manage_assistant_teacherSearchBranchQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          mt_id_branch: this.SearchBranch
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async manage_assistant_teacherGroupBranchQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          mt_SELECTBranch: "Ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teacherGroupBranch = result.data;
    },

    async manage_assistant_teacherSearchCollegeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          mt_college_code: this.Searchcollege_code
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },
    async manage_assistant_teacherSearchTypeTran(status) {
      let data = this.manage_assistant_teachers;
      let obj = {};
      let arr = [];
      let statussch = status;
      data.forEach(value => {
        let colleges = String(value.mt_college_code);
        if (colleges.length > 5 && value.mt_type_personnel == statussch) {
          obj["mt_id"] = value.mt_id;
          obj["mt_timeyear"] = value.mt_timeyear;
          obj["mt_id_branch"] = value.mt_id_branch;
          obj["name_branch"] = value.name_branch;
          obj["mt_sequence"] = value.mt_sequence;
          obj["mt_id_card"] = value.mt_id_card;
          obj["mt_name"] = value.mt_name;
          obj["mt_status"] = value.mt_status;
          obj["mt_college_code"] = value.mt_college_code;
          obj["mt_id_position"] = value.mt_id_position;
          obj["mt_examgroup"] = value.mt_examgroup;
          obj["mt_admissions_status"] = value.mt_admissions_status;
          obj["mt_admissions_file"] = value.mt_admissions_file;
          obj["mt_committe_status"] = value.mt_committe_status;
          obj["mt_collegeCC"] = value.mt_collegeCC;
          obj["mt_type_personnel"] = value.mt_type_personnel;
          obj["actions"] = value.actions;
          arr.push({
            mt_id: obj["mt_id"],
            mt_timeyear: obj["mt_timeyear"],
            mt_id_branch: obj["mt_id_branch"],
            name_branch: obj["name_branch"],
            mt_sequence: obj["mt_sequence"],
            mt_id_card: obj["mt_id_card"],
            mt_name: obj["mt_name"],
            mt_status: obj["mt_status"],
            mt_college_code: obj["mt_college_code"],
            mt_id_position: obj["mt_id_position"],
            mt_examgroup: obj["mt_examgroup"],
            mt_admissions_status: obj["mt_admissions_status"],
            mt_admissions_file: obj["mt_admissions_file"],
            mt_committe_status: obj["mt_committe_status"],
            mt_collegeCC: obj["mt_collegeCC"],
            mt_type_personnel: obj["mt_type_personnel"],
            actions: obj["actions"]
          });
        }
      });
      this.manage_assistant_teachers = arr;
    },

    async manage_assistant_teacherSearchStatus(status) {
      let data = this.manage_assistant_teachers;
      let obj = {};
      let arr = [];
      let statussch = status;
      data.forEach(value => {
        let colleges = String(value.mt_college_code);
        if (colleges.length > 5 && value.mt_admissions_status == statussch) {
          obj["mt_id"] = value.mt_id;
          obj["mt_timeyear"] = value.mt_timeyear;
          obj["mt_id_branch"] = value.mt_id_branch;
          obj["name_branch"] = value.name_branch;
          obj["mt_sequence"] = value.mt_sequence;
          obj["mt_id_card"] = value.mt_id_card;
          obj["mt_name"] = value.mt_name;
          obj["mt_status"] = value.mt_status;
          obj["mt_college_code"] = value.mt_college_code;
          obj["mt_id_position"] = value.mt_id_position;
          obj["mt_examgroup"] = value.mt_examgroup;
          obj["mt_admissions_status"] = value.mt_admissions_status;
          obj["mt_admissions_file"] = value.mt_admissions_file;
          obj["mt_committe_status"] = value.mt_committe_status;
          obj["mt_collegeCC"] = value.mt_collegeCC;
          obj["mt_type_personnel"] = value.mt_type_personnel;
          obj["actions"] = value.actions;
          arr.push({
            mt_id: obj["mt_id"],
            mt_timeyear: obj["mt_timeyear"],
            mt_id_branch: obj["mt_id_branch"],
            name_branch: obj["name_branch"],
            mt_sequence: obj["mt_sequence"],
            mt_id_card: obj["mt_id_card"],
            mt_name: obj["mt_name"],
            mt_status: obj["mt_status"],
            mt_college_code: obj["mt_college_code"],
            mt_id_position: obj["mt_id_position"],
            mt_examgroup: obj["mt_examgroup"],
            mt_admissions_status: obj["mt_admissions_status"],
            mt_admissions_file: obj["mt_admissions_file"],
            mt_committe_status: obj["mt_committe_status"],
            mt_collegeCC: obj["mt_collegeCC"],
            mt_type_personnel: obj["mt_type_personnel"],
            actions: obj["actions"]
          });
        }
      });
      this.manage_assistant_teachers = arr;
    },

    async manage_assistant_teacherGroupCollegeCodeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          mt_selectCollege: "Ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teacherGroupCollege = result.data;
    },

    async manage_assistant_teacherBranchIDQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_id_branch: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async save() {
      this.editedItem.ApiKey = this.ApiKey;
      Object.assign(
        this.manage_assistant_teachers[this.editedIndex],
        this.editedItem
      );

      let resultmat = await this.$http.post(
        "manage_assistant_teacher.update.php",
        this.editedItem
      );

      if (resultmat.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ทำรายการซ้ำ",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.manage_assistant_teacherQueryAll();
      this.close();
    },

    async manage_assistant_teacherEdit(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.editmanage_assistant_teacherdialog = true;
    },
    async editmanage_assistant_teacherSubmit() {
      if (this.$refs.editmanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        this.editmanage_assistant_teacher.mt_admissions_status = "send";
        this.editmanage_assistant_teacher.mt_admissions_date = "";
        this.editmanage_assistant_teacher.mt_collegeCC = "";

        let result = "";
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" +
            this.editmanage_assistant_teacher.mt_admissions_file
        });

        this.editmanage_assistant_teacher.mt_admissions_file = "";

        result = await this.$http.post(
          "manage_assistant_teacher.update.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.manage_assistant_teacherQueryAll();
        this.editmanage_assistant_teacherdialog = false;
      }
    },
    async manage_assistant_teacherDelete(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.deletemanage_assistant_teacherdialog = true;
    },
    async deletemanage_assistant_teacherSubmit() {
      if (this.$refs.deletemanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "manage_assistant_teacher.delete.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "manage_assistant_teacher";
          this.data_syslog.table_log = "manage_assistant_teacher";
          this.data_syslog.detail_log =
            this.editmanage_assistant_teacher.id_position +
            " : " +
            this.editmanage_assistant_teacher.college_code +
            " : " +
            this.editmanage_assistant_teacher.case_vacancy;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletemanage_assistant_teacherdialog = false;
      }
    },

    async deleteItem(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.manage_college_manpowersMt_id = result.data;

      let college_name =
        this.manage_college_manpowersMt_id.mt_title_s +
        this.manage_college_manpowersMt_id.mt_frist_name +
        this.manage_college_manpowersMt_id.mt_last_name +
        " : " +
        this.manage_college_manpowersMt_id.college_name +
        " : " +
        this.manage_college_manpowersMt_id.mt_id_position;
      Swal.fire({
        title: "คุณต้องการยกเลิกการรับรายงานตัว?",
        text: college_name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรายการ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.deletedItemmat.ApiKey = this.ApiKey;
          this.deletedItemmat.mt_id = mt_id;
          this.deletedItemmat.mt_admissions_status = "send";
          this.deletedItemmat.mt_admissions_date = "";
          this.deletedItemmat.mt_collegeCC = "";
          this.deletedItemmat.mt_committe_status = "";
          this.deletedItemmat.mt_committe_file = "";
          this.deletedItemmat.mt_committe_datetime = "";
          this.deletedItemmat.mt_committe_ipaapprove = "";
          this.deletedItemmat.mt_committe_ipaapprove_detail = "";

          let result_delfile = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename: "../HRvecfiles/" + this.deletedItemmat.mt_admissions_file
          });

          let result_delcommitteefile = await this.$http.post(
            "deletefile.php",
            {
              ApiKey: this.ApiKey,
              filename:
                "../HRcommitteeAssistantTeach/" +
                this.deletedItemmat.mt_committe_file
            }
          );

          this.deletedItemmat.mt_admissions_file = "";
          this.deletedItemmat.mt_committe_file = "";

          let resultupdatemat = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.deletedItemmat
          );
        }
        this.manage_assistant_teacherQueryAll();
      });
    },

    row_classes(item) {
      if (item.mt_status == "disclaim") {
        return "lime";
      }
    }
  },

  computed: {
    color() {
      return "teal";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>

<style scoped>
.hide-idcard-number {
  display: inline-block;
  font-family: monospace, monospace;
  position: relative;
}

.hide-idcard-number::after {
  content: "XXXXXXXX";
  background: white;
  position: absolute;
  left: 0;
}
</style>
