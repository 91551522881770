<template>
  <div>
    <!-- รายงานตัวเลือกวิทยาลัย ตำแหน่งรองผู้อำนวยการ-->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_sedirectors_chooses.period_sedirector_enable === '1'"
      >
        <v-row cols="12" md="7">
          <v-col>
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <h2 class="red--text">
                <v-icon color="#C20000">mdi-domain</v-icon> กจ2. สอ. สอศ.
              </h2>

              <h2 class="primary--text">
                รายงานตัวเลือกสถานศึกษา ตำแหน่ง รองผู้อำนวยการวิทยาลัย
              </h2>

              <h3>
                ครั้งที่
                {{ period_sedirectors_chooses.period_sedirector_times }}/{{
                  period_sedirectors_chooses.period_sedirector_year
                }}
                <br />
              </h3>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-center">
              <v-btn color="info" to="/pages/sedirector_app_choose"
                >ติดตามการรายงานตัวเลือกวิทยาลัย</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>

    <!-- แจ้งสอบรองผู้อำนวยการ -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_sedirectors.period_sedirector_enable === '1'"
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="primary--text">
                    <v-icon color="primary">mdi-account</v-icon>
                    เปิดระบบแนบไฟล์ การรับสมัครคัดเลือกตำแหน่ง รองผู้อำนวยการวิทยาลัย
                  </h2>
                  <h3>ให้ผู้สมัครที่ผ่านการคัดเลือก แนบไฟล์ประกอบการสมัคร</h3>
                  <h2>สามารถดำเนินการ</h2>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      period_sedirectors.period_sedirector_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ :
                {{
                  period_sedirectors.period_sedirector_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_sedirectors.period_sedirector_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>


    <!-- แจ้งสอบรองผู้อำนวยการ -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_directors.period_sedirector_enable === '1'"
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="primary--text">
                    <v-icon color="primary">mdi-account</v-icon>
                    เปิดระบบ การรับสมัครคัดเลือกตำแหน่ง ผู้อำนวยการวิทยาลัย
                  </h2>               
                  <h2>สามารถดำเนินการ</h2>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      period_directors.period_sedirector_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ :
                {{
                  period_directors.period_sedirector_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_directors.period_sedirector_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>



    <!-- แจ้งเตือนย้ายผู้บริหาร -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="
          periodManages.period_enable === '1' &&
            periodManages.period_type === 'manage'
        "
      >
        <v-row>
          <v-col cols="6">
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <v-row align="center">
                <v-col class="grow">
                  <h2 class="primary--text">
                    <v-icon color="primary">mdi-account</v-icon>
                    ผู้อำนวยการวิทยาลัย
                  </h2>                  
                  <h2>สามารถดำเนินการ <u>ยื่นคำร้องขอย้าย </u> 
                    
                    <u v-if="periodManages.period_normal_case==='1'">[ กรณีปกติ ] </u>
                    <u v-if="periodManages.period_quality_case==='1'" class="info--text"> [ เพื่อพัฒนาคุณภาพการศึกษา ]</u>
                  
                  </h2>
                  <h3>
                    ก่อนระบบปิดในวันที่
                    {{
                      periodManages.period_stop
                        | moment("add", "543 years")
                        | moment("D MMMM YYYY")
                    }}
                  </h3>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="6">
            <div class="text-center">
              <h3>
                ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                {{
                  periodManages.period_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="periodManages.period_stop + ' 16:30:00'"
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "HRvecAlertMissionPersonnelDirector",
  components: { FlipCountdown },
  data() {
    return {
      ApiKey: "HRvec2021",
      period_sedirectors: [],
      period_directors: [],
      periodManages: [],
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      period_sedirectors_chooses: []
    };
  },

  async mounted() {
    await this.period_sedirectorQuery(); /* พิจารณาย้ายสายบริหาร */
    await this.periodQueryManage(); /* ย้ายผู้บริหาร */
    await this.period_sedirectorChooseQuery();
    await this.period_directorQuery();

    
  },

  methods: {
    async period_sedirectorChooseQuery() {
      let result_period_sedirector;
      result_period_sedirector = await this.$http.post(
        "period_sedirector.php",
        {
          ApiKey: this.ApiKey,
          period_sedirector_process: "1",
          period_sedirector_id: "206"
        }
      );
      this.period_sedirectors_chooses = result_period_sedirector.data;

      if (this.period_sedirectors_chooses.period_sedirector_process == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_sedirectors_chooses.period_sedirector_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_sedirectors_chooses.ApiKey = this.ApiKey;
            this.period_sedirectors_chooses.period_sedirector_process = "0";
            await this.$http.post(
              "period_sedirector.update.php",
              this.period_sedirectors_chooses
            );
            await this.period_sedirectorChooseQuery();
          }
        }, 3000);
      }
    },
    
    async period_directorQuery() {
      let result_period;
      result_period = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_enable: "1",
        period_sedirector_id: "301"
      });
      this.period_directors = result_period.data;

     /*  if (this.period_directors.period_sedirector_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_directors.period_sedirector_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_directors.ApiKey = this.ApiKey;
            this.period_directors.period_sedirector_enable = "0";
            await this.$http.post(
              "period_sedirector.update.php",
              this.period_directors
            );
            await this.period_sedirectorQuery();
          }
        }, 3000);
      } */
    },

    async period_sedirectorQuery() {
      let result_period;
      result_period = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_enable: "1",
        period_sedirector_id: "201"
      });
      this.period_sedirectors = result_period.data;

     /*  if (this.period_sedirectors.period_sedirector_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_sedirectors.period_sedirector_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_sedirectors.ApiKey = this.ApiKey;
            this.period_sedirectors.period_sedirector_enable = "0";
            await this.$http.post(
              "period_sedirector.update.php",
              this.period_sedirectors
            );
            await this.period_sedirectorQuery();
          }
        }, 3000);
      } */
    },

    async periodQueryManage() {
      let result;
      result = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_id: "101"
      });
      this.periodManages = result.data;

      if (this.periodManages.period_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(this.periodManages.period_stop + " 16:30:00");
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.periodManages.ApiKey = this.ApiKey;
            this.periodManages.period_enable = "0";
            await this.$http.post(
              "period.update.php",
              this.periodManages
            );
            await this.periodQueryManage();
          }
        }, 3000);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
