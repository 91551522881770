<template>
    <div>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <v-card>
              <div class="font-weight-bold">
              <v-btn small class="ma-2" @click="period_collegeEdit()" fab color="warning"><v-icon>mdi-pencil</v-icon></v-btn>
              เปิด/ปิด แจ้งสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง
            </div>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>ครั้งที่</td>
                    <td class="text-center font-weight-bold">
                      {{ period_colleges.period_college_times }}
                    </td>
                    <td>ปี</td>
                    <td class="text-center font-weight-bold">
                      {{  period_colleges.period_college_year }}
                    </td>
                  </tr>
                  <tr>
                    <td>เริ่มวันที่</td>
                    <td class="text-center font-weight-bold">
                      {{ period_colleges.period_college_start   | moment("add", "543 years")
                        | moment("D MMMM YYYY") }}
                    </td>
  
                    <td>สิ้นสุดวันที่</td>
                    <td class="text-center font-weight-bold">
                      {{ period_colleges.period_college_stop   | moment("add", "543 years")
                        | moment("D MMMM YYYY") }}
                    </td>
                  </tr>
               
                  <tr>
                    <td>สถานะระบบ</td>
                    <td class="text-center font-weight-bold">
                  <v-chip color="green" dark v-if="period_colleges.period_college_enable==='1'">เปิด</v-chip>
                  <v-chip color="red" dark v-else-if="period_colleges.period_college_enable==='0'">ปิด</v-chip>
                    </td>
  
                    <td>สถานะ แนบไฟล์</td>
                    <td class="text-center font-weight-bold">
                      <v-chip color="green" dark v-if="period_colleges.period_college_enable_file==='1'">เปิด</v-chip>
                  <v-chip color="red" dark v-else-if="period_colleges.period_college_enable_file==='0'">ปิด</v-chip>
                
                    </td>
                  </tr>

                  <tr>
                    <td>
                    หมายเหตุ
                    </td>
                    <td colspan="3" class="text-center">
                      
                      <h3 v-if="period_colleges.period_college_type==='move'"> <v-icon color="red" size="30">mdi-alert</v-icon> ย้าย</h3>
                      <h3 v-if="period_colleges.period_college_type==='contain'"> <v-icon color="red" size="30">mdi-alert</v-icon> บรรจุ</h3>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card></v-col
          >       
        </v-row>
    
        <!-- V-model editperiod_collegedialog -->
        <v-layout row justify-center>
          <v-dialog v-model="editperiod_collegedialog" persistent max-width="80%">
            <v-card class="mx-auto pa-6">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขกำหนดการ แจ้งเงือนไขการย้าย"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editperiod_collegeform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 md6>
                        <v-select
                          v-model="editperiod_college.period_college_year"
                          :items="period_collegeselect"
                          item-text="text"
                          item-value="value"
                          label="เลือกปีการศึกษา"
                        >
                        </v-select>
                        <v-spacer></v-spacer>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="number"
                          label="ครั้งที่"
                          v-model="editperiod_college.period_college_times"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>                     
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่เริ่ม"
                          v-model="editperiod_college.period_college_start"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 md6>
                        <v-text-field
                          type="date"
                          label="วันที่สิ้นสุด"
                          v-model="editperiod_college.period_college_stop"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </v-flex>


                      <v-flex xs12 md6>
                        <v-select                         
                          label="หมายเหตุ"
                          :items="period_college_type"
                          item-text="text"
                          item-value="value"
                          v-model="editperiod_college.period_college_type"
                          required
                          :rules="[v => !!v || '']"
                        ></v-select>
                      </v-flex>


                      <v-flex xs12 md6>
                        <v-switch
                          v-model="editperiod_college.period_college_enable_sw"
                          label="เปิดใช้งานสถานศึกษา"
                        ></v-switch>
                      </v-flex>
  
                      <v-flex xs12 md6>
                        <v-switch
                          v-model="
                            editperiod_college.period_college_enable_file_sw
                          "
                          label="เปิดใช้งานการแนบไฟล์"
                        ></v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="editperiod_collegedialog = false"
                  rounded
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="editperiod_collegeSubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
  
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          multi-line
          vertical
          top
          auto-height
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <br />
          {{ snackbar.text }}
          <v-btn dark @click="snackbar.show = false">
            Close
          </v-btn>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  import VueMoment from "vue-moment";
  import moment from "moment-timezone";
  export default {
    name: "period_college",
    data() {
      return {
        VueMoment,
        moment,
        loading: true,
        ApiKey: "HRvec2021",
        valid: true,
        addperiod_collegedialog: false,
        editperiod_collegedialog: false,
        deleteperiod_collegedialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },       
        period_colleges: [],
        addperiod_college: {},
        editperiod_college: {},
        search: "",
        pagination: {},
        headers: [
          { text: "ครั้งที่", align: "center", value: "period_college_times" },
          { text: "ปี", align: "center", value: "period_college_year" },
          { text: "เริ่มวันที่", align: "center", value: "period_college_start" },
          {
            text: "สิ้นสุดวันที่",
            align: "center",
            value: "period_college_stop"
          },
  
          {
            text: "เกี่ยวข้องกับ",
            align: "center",
            value: "period_college_type"
          },
          {
            text: "สถานะรายงาน",
            align: "center",
            value: "period_college_enable"
          },
          {
            text: "สถานะแนบไฟล์",
            align: "center",
            value: "period_college_enable_file"
          },
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "action_s",
            icon: "mdi-delete-forever"
          }
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
        period_collegeselect: [
          {
            value: "2564",
            text: "2564"
          },
          {
            value: "2565",
            text: "2565"
          },
          {
            value: "2566",
            text: "2566"
          },
          {
            value: "2567",
            text: "2567"
          },
          {
            value: "2568",
            text: "2568"
          },
          {
            value: "2569",
            text: "2569"
          },
          {
            value: "2570",
            text: "2570"
          }
        ],
        data_syslog: {},
        line_topic: [],
        period_college_type:[
          {text:'บรรจุ',value:'contain'},
          {text:'ย้าย',value:'move'},
        ],
      };
    },
    async mounted() {
      this.period_collegeQueryAll();
    },
    methods: {
      async period_collegeQueryAll() {
        this.loading = true;
        let result = await this.$http
          .post("period_college.php", {
            ApiKey: this.ApiKey,
            period_college_id:"701"
          })
          .finally(() => (this.loading = false));
        this.period_colleges = result.data;
      },
 
      async linenotify(topics, details, datetime_close) {
        let data = {
          ApiKey: this.ApiKey,
          topics: topics,
          details: details,
          datetime_close: datetime_close
        };
        let result = await this.$http.post("linenotify_college.php", data);
        
      },
      async topics_data() {
        let topic = this.addperiod_college.period_college_type;
        let result;
        if (topic == "teacher_request") {
          result = "สถานศึกษารวบรวมคำร้องขอย้าย ตำแหน่งครู";
        } else if (topic == "movement_college") {
          result = "สถานศึกษาแจ้งเงือนไขการย้าย";
        } else if (topic == "update_college") {
          result = "รายงานอัตรากำลัง";
        } else if (topic == "discuss_college") {
          result = "ขอความเห็นจากคณะกรรมการสถานศึกษาย้ายสายบริหาร";
        } else if (topic == "discuss_teacher") {
          result = "ขอความเห็นจากคณะกรรมการสถานศึกษาย้าย ตำแหน่งครู";
        } else if (topic == "reportmove_teacher") {
          result = "รายงานตัว การย้าย ตำแหน่งครู";
        }
        this.line_topic = result;
      },
         async period_collegeEdit() {
        let result = await this.$http.post("period_college.php", {
          ApiKey: this.ApiKey,
          period_college_id:"701"       
        });
        this.editperiod_college = result.data;
        if (this.editperiod_college.period_college_enable == 1) {
          this.editperiod_college.period_college_enable_sw = true;
        } else {
          this.editperiod_college.period_college_enable_sw = false;
        }
  
        if (this.editperiod_college.period_college_enable_file == 1) {
          this.editperiod_college.period_college_enable_file_sw = true;
        } else {
          this.editperiod_college.period_college_enable_file_sw = false;
        }
        this.editperiod_collegedialog = true;
      },
  
      async editperiod_collegeSubmit() {
        if (this.editperiod_college.period_college_enable_sw == true) {
          this.editperiod_college.period_college_enable = "1";
          let timesy = this.editperiod_college.period_college_times + "/" + this.editperiod_college.period_college_year
        await this.topics_data()
        await this.linenotify(timesy,this.line_topic,this.editperiod_college.period_college_stop)
        } else {
          this.editperiod_college.period_college_enable = "0";
        }
  
        if (this.editperiod_college.period_college_enable_file_sw == true) {
          this.editperiod_college.period_college_enable_file = "1";   
        } else {
          this.editperiod_college.period_college_enable_file = "0";
        }
  
        this.editperiod_college.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "period_college.update.php",
          this.editperiod_college
        );
  
        
  
        if (result.data.status == true) {
          this.period_college = result.data;
  
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
  
          this.period_collegeQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "period_college";
          this.data_syslog.table_log = "period_college";
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "error",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editperiod_collegedialog = false;
      },   
 
    },
    computed: {
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      },
      date_today_log() {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, "0");
        let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = today.getFullYear() + 543;
        let time =
          today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        today = dd + "/" + mm + "/" + yyyy + "/" + time;
        return today;
      }
    }
  };
  </script>
  