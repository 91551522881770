import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),

      children: [
        // Dashboard
        {
          name: "ข้อมูลทั่วไป",
          path: "",
          component: () => import("@/views/dashboard/Dashboard")
        },

        {
          name: "Login เข้าสู่ระบบ",
          path: "pages/login",
          component: () => import("@/views/dashboard/pages/login")
        },

        {
          name: "รายงานตัวครูผุ้ช่วย ก่อนเลือกสถานศึกษา",
          path: "pages/manage_assistant_teacher",
          component: () =>
            import("@/views/dashboard/pages/manage_assistant_teacher")
        },

        {
          name: "Icon",
          path: "/icon",
          component: () => import("@/views/dashboard/icon")
        },

        {
          name: "รายงานตัวเลือกสถานศึกษา ตำแหน่งครูผู้ช่วย",
          path: "pages/manage_college_manpower",
          component: () =>
            import("@/views/dashboard/pages/manage_college_manpower")
        },

        {
          name:
            "รายงานตัวเลือกสถานศึกษา ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "pages/sedirector_app_choose",
          component: () =>
            import("@/views/dashboard/pages/sedirector_app_choose")
        },

        {
          name: "Login ระบบรายงานตัวเลือกสถานศึกษา ตำแหน่งครูผู้ช่วย",
          path: "pages/loginAssTeach",
          component: () => import("@/views/dashboard/pages/loginAssTeach")
        },

        {
          name: "ข่าวประชาสัมพันธ์ ข้อกำหนด เกณฑ์",
          path: "news",
          component: () => import("@/views/dashboard/news_munual")
        },
        {
          name: "Icons",
          path: "components/icons",
          component: () => import("@/views/dashboard/component/Icons")
        },
        {
          name: "Typography",
          path: "components/typography",
          component: () => import("@/views/dashboard/component/Typography")
        },
        {
          name: "Google Maps",
          path: "maps/google-maps",
          component: () => import("@/views/dashboard/maps/GoogleMaps")
        },
        {
          name: "เข้าสู่ระบบสำหรับ วิทยาลัย สอจ. สถาบัน กจ.2",
          path: "pages/loginAdmin",
          component: () => import("@/views/dashboard/pages/loginAdmin")
        },
        {
          name: "report chart",
          path: "pages/chart_report",
          component: () => import("@/views/dashboard/pages/chart_report")
        }
      ]
    },
    {
      path: "/admin",
      component: () => import("@/views/dashboard/admin"),
      children: [
        {
          name: "เมนูรายการ",
          path: "",
          component: () =>
            import("@/views/dashboard/pages/administrator/home_menu")
        },
        {
          name: "รายงาน",
          path: "/admin/Dashboard",
          component: () =>
            import("@/views/dashboard/pages/administrator/Dashboard")
        },
        {
          name: "ตั้งค่าระบบ",
          path: "/admin/setting",
          component: () =>
            import("@/views/dashboard/pages/administrator/setting")
        },
        {
          name: "เมนูรายการ",
          path: "/admin/home_menu",
          component: () =>
            import("@/views/dashboard/pages/administrator/home_menu")
        },

        {
          name: "จัดการผู้ดูแลระบบ",
          path: "/admin/userAdmin",
          component: () =>
            import("@/views/dashboard/pages/administrator/userAdmin")
        },

        {
          name: "กำหนดการย้าย 38 ค.(2)",
          path: "/admin/timelines/transfer_38",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/timelines/transfer_38"
            )
        },

        {
          name: "กำหนดการย้ายครู",
          path: "/admin/timelines/transfer_teachers",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/timelines/transfer_teachers"
            )
        },

        {
          name: "ข้อมูลสถิติลูกจ้าง",
          path: "/admin/personnel_etc_tem",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel_etc_tem")
        },

        {
          name: "กำหนดการย้ายสายบริหาร",
          path: "/admin/timelines/transfer_directors",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/timelines/transfer_directors"
            )
        },

        {
          name: "กำหนดการรายงานตัวครูผู้ช่วย",
          path: "/admin/timelines/report_assistantteacher",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/timelines/report_assistantteacher"
            )
        },

        {
          name: "กำหนดการสอบ  ( รองผู้อำนวยการวิทยาลัย) ",
          path: "/admin/timelines/examination_deputyDirector",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/timelines/examination_deputyDirector"
            )
        },

        {
          name: "กำหนดการสอบ  (ผู้อำนวยการวิทยาลัย) ",
          path: "/admin/timelines/examination_Director",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/timelines/examination_Director"
            )
        },

        {
          name: "รายงานตัวแสดงเจตจำนงเลือกสถานศึกษา ตำแหน่งครูผู้ช่วย",
          path: "/admin/assistantteach",
          component: () =>
            import("@/views/dashboard/pages/administrator/assistantteach")
        },

        {
          name: "กรรมการประเมินครูผู้ช่วย",
          path: "/admin/assistantcommittee",
          component: () =>
            import("@/views/dashboard/pages/administrator/assistantcommittee")
        },

        {
          name: "ปฏิทินกำหนดการสอบ ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
          path: "/admin/period_sedirector",
          component: () =>
            import("@/views/dashboard/pages/administrator/period_sedirector")
        },

        {
          name: "การรับรายงานตัว ย้ายครู กรณีปกติ",
          path: "/admin/conditons_transfer_success_reportmv",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/conditons_transfer_success_reportmv"
            )
        },

        {
          name: "ข้อมูลเรียกรายงานตัวครูผู้ช่วย",
          path: "/admin/assistant_teacher_report/manage_assistant_teacher",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacher_report/manage_assistant_teacher"
            )
        },

        {
          name: "ข้อมูลรายงานตัวครูผู้ช่วย ณ สถานศึกษา",
          path:
            "/admin/assistant_teacher_report/manage_assistant_CollegeReport",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacher_report/manage_assistant_CollegeReport"
            )
        },

        {
          name: "ข้อมูลรายงานตัวครูผู้ช่วย ณ สถานศึกษา ครูโอนย้าย",
          path:
            "/admin/assistant_teacher_report/manage_assistant_CollegeReportTran",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacher_report/manage_assistant_CollegeReportTran"
            )
        },

        {
          name: "ข้อมูล : การเสนอคณะกรรมการประเมิน",
          path:
            "/admin/assistant_teacher_report/manage_assistant_teacher_committee",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacher_report/manage_assistant_teacher_committee"
            )
        },

        {
          name: "ปฏิทิน การแต่งตั้งคณะกรรมการ และรอบการประเมิน",
          path:
            "/admin/assistant_teacher_report/personnel_temporary_assistant_period",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacher_report/personnel_temporary_assistant_period"
            )
        },

        {
          name: "ข้อมูลเลขที่ตำแหน่งวิทยาลัย",
          path: "/admin/assistant_teacher_report/manage_college_manpower",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacher_report/manage_college_manpower"
            )
        },
        {
          name: "รายงานการเลือกวิทยาลัย ครูผู้ช่วย",
          path:
            "/admin/assistant_teacher_report/teacher_college_manpower_report",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacher_report/teacher_college_manpower_report"
            )
        },

        {
          name: "ข้อมูลเกี่ยวกับบุคคล",
          path: "/admin/personnel",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel")
        },

        {
          name: "ข้อมูลเกี่ยวกับบุคลากรส่วนกลาง",
          path: "/admin/personnel_center/",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel_center")
        },

        {
          name: "ข้อมูลเกี่ยวกับศึกษานิเทศก์",
          path: "/admin/supervision",
          component: () =>
            import("@/views/dashboard/pages/administrator/supervision")
        },
        {
          name: "ข้อมูลบุคคลเกี่ยวกับสถาบัน",
          path: "/admin/personnel_veivocal",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel_veivocal")
        },
        {
          name: "สมัครสอบตำแหน่ง รองผู้อำนวยการวิทยาลัย",
          path: "/admin/sedirector_app",
          component: () =>
            import("@/views/dashboard/pages/administrator/sedirector_app")
        },
        {
          name: "สมัครสอบตำแหน่ง ผู้อำนวยการวิทยาลัย",
          path: "/admin/director_app",
          component: () =>
            import("@/views/dashboard/pages/administrator/director_app")
        },

        {
          name: "ภาค ก. คัดเลือก ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/admin/sedirector_assessment/sedirector_app_a",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/sedirector_assessment/sedirector_app_a"
            )
        },

        {
          name: "ภาค ข. คัดเลือก ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/admin/sedirector_assessment/sedirector_app_b",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/sedirector_assessment/sedirector_app_b"
            )
        },

        {
          name: "ภาค ค สัมภาษณ์",
          path: "/admin/sedirector_assessment/sedirector_app_c",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/sedirector_assessment/sedirector_app_c"
            )
        },

        {
          name: "ประกาศผล",
          path: "/admin/sedirector_assessment/sedirector_app_notice",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/sedirector_assessment/sedirector_app_notice"
            )
        },

        {
          name: "รายงานตัว ณ สถานศึกษา",
          path: "/admin/sedirector_assessment/sedirector_app_report_college",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/sedirector_assessment/sedirector_app_report_college"
            )
        },

        {
          name:
            "อัตราว่างสถานศึกษา ตำแหน่ง  ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
          path: "/admin/sedirector_assessment/director_college_manpower",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/sedirector_assessment/director_college_manpower"
            )
        },

        {
          name: "รายงานตัวเลือกสถานศึกษา",
          path: "/admin/sedirector_assessment/sedirector_app_choose",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/sedirector_assessment/sedirector_app_choose"
            )
        },

        /* Dir */

        {
          name: "ภาค ก. คัดเลือก ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/admin/director_assessment/director_app_a",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/director_assessment/director_app_a"
            )
        },

        {
          name: "ภาค ข. คัดเลือก ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/admin/director_assessment/director_app_b",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/director_assessment/director_app_b"
            )
        },

        {
          name: "ภาค ค สัมภาษณ์",
          path: "/admin/director_assessment/director_app_c",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/director_assessment/director_app_c"
            )
        },

        {
          name: "ประกาศผล",
          path: "/admin/director_assessment/director_app_notice",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/director_assessment/director_app_notice"
            )
        },

        {
          name: "รายงานตัว ณ สถานศึกษา",
          path: "/admin/director_assessment/director_app_report_college",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/director_assessment/director_app_report_college"
            )
        },

        {
          name:
            "อัตราว่างสถานศึกษา ตำแหน่ง  ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
          path: "/admin/director_assessment/director_college_manpower",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/director_assessment/director_college_manpower"
            )
        },

        {
          name: "รายงานตัวเลือกสถานศึกษา",
          path: "/admin/director_assessment/director_app_choose",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/director_assessment/director_app_choose"
            )
        },

        /*  */

        {
          name: "ข้อมูลใบอนุญาตประกอบวิชาชีพ",
          path: "/admin/personnel_temporaryLicence",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/personnel_temporaryLicence"
            )
        },
        {
          name: "ข้อมูลผู้พ้นจากราชการ",
          path: "/admin/personnel_temporary_del",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/personnel_temporary_del"
            )
        },

        {
          name: "ข้อมูลเกี่ยวกับขอย้ายบุคคล",
          path: "/admin/collegepersonnelreq",
          component: () =>
            import("@/views/dashboard/pages/administrator/collegepersonnelreq")
        },

        {
          name: "ข้อมูลคุณวุฒิการศึกษา",
          path: "/admin/personnel_education",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel_education")
        },
        {
          name: "ข้อมูลประสบการณ์",
          path: "/admin/personnel_experience",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel_experience")
        },
        {
          name: "ข้อมูลประวัติการรับราชการ",
          path: "/admin/personnel_work_history",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/personnel_work_history"
            )
        },
        {
          name: "ข้อมูลผลงาน รางวัล",
          path: "/admin/personnel_award",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel_award")
        },
        {
          name: "ข้อมูลโทษ วินัย",
          path: "/admin/personnel_discipline",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel_discipline")
        },
        {
          name: "กลุ่มผู้ใช้งานระบบ",
          path: "/admin/user",
          component: () => import("@/views/dashboard/pages/administrator/user")
        },
        {
          name: "สถานะผู้ใช้งานระบบ",
          path: "/admin/user_status",
          component: () =>
            import("@/views/dashboard/pages/administrator/user_status")
        },
        {
          name: "ข้อมูลประเภทสถานศึกษา",
          path: "/admin/collegetype",
          component: () =>
            import("@/views/dashboard/pages/administrator/collegetype")
        },
        {
          name: "กำหนดรอบการย้ายข้าราชการครูและบุคลากรทางการศึกษา",
          path: "/admin/period",
          component: () =>
            import("@/views/dashboard/pages/administrator/period")
        },
        {
          name: "รายงานสรุปผลคะแนนการประเมิน",
          path: "/admin/assistant_teacher_reports",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacher_reports"
            )
        },
        {
          name: "กำหนดการสถานศึกษา",
          path: "/admin/period_college",
          component: () =>
            import("@/views/dashboard/pages/administrator/period_college")
        },
        {
          name: "กำหนดการายงานตัวครูผู้ช่วย",
          path: "/admin/periodassteach",
          component: () =>
            import("@/views/dashboard/pages/administrator/periodassteach")
        },

        {
          name: "ข้อมูลบุคคลใช้งานระบบ",
          path: "/admin/personnel",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel")
        },
        {
          name: "ข้อมูลข้าราชการครูและบุคลการทางการศึกษา",
          path: "/admin/personnel_tem",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel_temporary")
        },

        {
          name: "ข้อมูลข้าราชการครูและบุคลการทางการศึกษา (ตำแหน่งครูผู้ช่วย)",
          path: "/admin/personnel_temporary_assisstant",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/personnel_temporary_assisstant"
            )
        },

        {
          name: "สาขาวิชา",
          path: "/admin/branch",
          component: () =>
            import("@/views/dashboard/pages/administrator/branch")
        },
        {
          name: "สาขางาน",
          path: "/admin/branch_sub",
          component: () =>
            import("@/views/dashboard/pages/administrator/branch_sub")
        },
        {
          name: "ประเภทวิชา",
          path: "/admin/subject_type",
          component: () =>
            import("@/views/dashboard/pages/administrator/subject_type")
        },

        {
          name: "หลักสูตรวิชาชีพระยะสั้น",
          path: "/admin/short_course",
          component: () =>
            import("@/views/dashboard/pages/administrator/short_course")
        },

        {
          name: "รายงานอัตรากำลังประจำปี สถานศึกษา",
          path: "/admin/rate_work_g",
          component: () =>
            import("@/views/dashboard/pages/administrator/rate_work_g")
        },
        {
          name: "จำนวนนักเรียน",
          path: "/admin/power_rate/rate_work_college",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/power_rate/rate_work_college"
            )
        },

        {
          name: "อัตรากำลังรับย้าย",
          path: "/admin/man_power",
          component: () =>
            import("@/views/dashboard/pages/administrator/man_power")
        },

        {
          name: "อัตรากำลังรับย้าย 38 ค.(2)",
          path: "/admin/man_power_38",
          component: () =>
            import("@/views/dashboard/pages/administrator/man_power_38")
        },

        {
          name: "อัตรากำลังขาด/เกิน พิจารณาย้ายครู",
          path: "/admin/man_powersummary",
          component: () =>
            import("@/views/dashboard/pages/administrator/man_powersummary")
        },

        {
          name: "สถานศึกษาที่ดำเนินการไม่ตรง ศธ.02",
          path: "/admin/college_updateedit",
          component: () =>
            import("@/views/dashboard/pages/administrator/college_updateedit")
        },
        {
          name: "สถานศึกษา",
          path: "/admin/college",
          component: () =>
            import("@/views/dashboard/pages/administrator/college")
        },
        {
          name: "รายละเอียดสถานศึกษา",
          path: "/admin/collegeinfo",
          component: () =>
            import("@/views/dashboard/pages/administrator/collegeinfo")
        },
        {
          name: "สถาบันการอาชีวศึกษา",
          path: "/admin/veivocal",
          component: () =>
            import("@/views/dashboard/pages/administrator/veivocal")
        },

        {
          name: "เงือนไขการรับย้ายของสถานศึกษา",
          path: "/admin/conditions_transfer",
          component: () =>
            import("@/views/dashboard/pages/administrator/conditions_transfer")
        },
        {
          name: "เงือนไขการรับย้ายสาขาวิชา",
          path: "/admin/conditions_branch",
          component: () =>
            import("@/views/dashboard/pages/administrator/conditions_branch")
        },
        {
          name: "สายงานการสอน",
          path: "/admin/transference_personnel",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_personnel"
            )
        },
        {
          name: "บุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2)",
          path: "/admin/transference_personnel_38",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_personnel_38"
            )
        },

        {
          name: "สายงานการสอน กรณีพิเศษ",
          path: "/admin/transference_personnel_sp",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_personnel_sp"
            )
        },
        {
          name: "สายงานการสอน รายละเอียด ประมวผลผลแบบที่ 1",
          path: "/admin/transference_location",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_location"
            )
        },

        {
          name: "สายงานการสอน ประกอบการพิจารณา",
          path: "/admin/transference_location_detail",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_location_detail"
            )
        },

        {
          name: "ข่าวสาร",
          path: "/admin/news_s",
          component: () =>
            import("@/views/dashboard/pages/administrator/news_s")
        },
        {
          name: "คู่มือ",
          path: "/admin/manual_s",
          component: () =>
            import("@/views/dashboard/pages/administrator/manual_s")
        },
        {
          name: "ดำเนินการประมวลผลการย้าย แบบที่ 2 ",
          path: "/admin/process_transfer",
          component: () =>
            import("@/views/dashboard/pages/administrator/process_transfer")
        },
        {
          name: "ดำเนินการประมวลผลการย้าย แบบ 3 สับเปลี่ยนตำแหน่ง",
          path: "/admin/process_transfer_switch",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/process_transfer_switch"
            )
        },
        {
          name: "ดำเนินการประมวลผลการย้าย กรณีสับเปลี่ยนแบบปกติ",
          path: "/admin/process_transfer_switch_normal",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/process_transfer_switch_normal"
            )
        },

        {
          name: "คำสั่งแต่งตั้งรักษาการในตำแหน่ง",
          path: "/admin/Order_appoint",
          component: () =>
            import("@/views/dashboard/pages/administrator/Order_appoint")
        },
        {
          name: "สรุปผลการประมวลผลการย้าย",
          path: "/admin/conditons_transfer_success",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/conditons_transfer_success"
            )
        },

        {
          name: "สรุปผลการประมวลผลการย้าย ผู้บริหาร",
          path: "/admin/transference_manage_report",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_manage_report"
            )
        },

        {
          name: "ศูนย์กลางส่งออกเอกสาร ประกอบการพิจารณาย้าย",
          path: "/admin/transference_export_report",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_export_report"
            )
        },

        {
          name: "ข้อมูล : การแต่งตั้งคณะกรรมการประเมิน",
          path: "/admin/assistant_teacher",
          component: () =>
            import("@/views/dashboard/pages/administrator/assistant_teacher")
        },
        {
          name: "ข้อมูล : ตรวจสอบผลการประเมิน",
          path: "/admin/assistant_teacher_detail",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacher_detail"
            )
        },

        {
          name: "ข้อมูล : ครูผู้ช่วย",
          path: "/admin/manage_assistant_teacher_data",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/manage_assistant_teacher_data"
            )
        },

        {
          name: "ข้อมูล : การประเมินครูผู้ช่วย",
          path: "/admin/manage_assistant_teacher",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/manage_assistant_teacher"
            )
        },

        

        {
          name: "รายงานผลการประเมินครู",
          path: "/admin/assistant_teacherReport",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/assistant_teacherReport"
            )
        },

        {
          name: "ประเภทวิชา/หมวดวิชา/สาขาวิชา",
          path: "/admin/rate_work_course_std",
          component: () =>
            import("@/views/dashboard/pages/administrator/rate_work_course_std")
        },

        {
          name: "สายงานบริหารสถานศึกษา",
          path: "/admin/transference_manage_main",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_manage_main"
            )
        },
        {
          name: "สายงานบริหารสถานศึกษา ประกอบการพิจารณา",
          path: "/admin/transference_manage_location_detail",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_manage_location_detail"
            )
        },
        {
          name: "สายงานบริหารสถานศึกษา รายละเอียด",
          path: "/admin/transference_manage_location",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/transference_manage_location"
            )
        },
        {
          name: "สายงานบริหารสถานศึกษา [ประมวลผลแบบ 1]",
          path: "/admin/transference_manage",
          component: () =>
            import("@/views/dashboard/pages/administrator/transference_manage")
        },
        {
          name: "ประวัติการเข้าใช้งานระบบ",
          path: "/admin/data_syslog",
          component: () =>
            import("@/views/dashboard/pages/administrator/data_syslog")
        },
        {
          name: "จ.18",
          path: "/admin/jor18",
          component: () => import("@/views/dashboard/pages/administrator/jor18")
        },

        {
          name: "จ.18 วิทยาลัยรายงาน",
          path: "/admin/jor18_report",
          component: () =>
            import("@/views/dashboard/pages/administrator/jor18_report")
        },

        {
          name: "จ.18 วิทยาลัยรายงาน (เลขที่ตำแหน่ง)",
          path: "/admin/college_manpower",
          component: () =>
            import("@/views/dashboard/pages/administrator/college_manpower")
        },

        {
          name:
            "ข้อมูล ข้าราชการ ลูกจ้างประจำ พนักงานราชการ ครูอัตราจ้างหรือลูกจ้างชั่วคราว",
          path: "/admin/personnel_etc",
          component: () =>
            import("@/views/dashboard/pages/administrator/personnel_etc")
        },

        {
          name: "ตั้งค่าระบบอัตรากำลัง",
          path: "/admin/sys_config_collegemanpower",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/sys_config_collegemanpower"
            )
        },

        {
          name: "ตั้งค่าระบบรายงานสาขาวิชาตำแหน่งว่าง",
          path: "/admin/sys_config_collegemanpower_report",
          component: () =>
            import(
              "@/views/dashboard/pages/administrator/sys_config_collegemanpower_report"
            )
        },

        {
          name: "ตั้งค่าระบบสำนักงานอาชีวศึกษาจังหวัด",
          path: "/admin/period_vecprovince",
          component: () =>
            import("@/views/dashboard/pages/administrator/period_vecprovince")
        },

        {
          name: "รายงาน คณะกรรมการ สอจ.",
          path: "/admin/commiteevecreport",
          component: () =>
            import("@/views/dashboard/pages/administrator/commiteevecreport")
        },

        {
          name: "รายชื่อคณะกรรมการ สอจ.",
          path: "/admin/commiteevecpro",
          component: () =>
            import("@/views/dashboard/pages/administrator/commiteevecpro")
        },
        {
          name: "จัดการคำนำหน้าชื่อ",
          path: "/admin/prefix",
          component: () =>
            import("@/views/dashboard/pages/administrator/prefix")
        }
      ]
    },
    {
      path: "/user",
      component: () => import("@/views/dashboard/user"),
      children: [
        {
          name: "ข้อมูลผู้ใช้งานระบบ",
          path: "",
          component: () => import("@/views/dashboard/pages/user/UserProfile")
        },
        {
          name: "ข้อมูลผู้ใช้งานระบบ",
          path: "/UserProfile",
          component: () => import("@/views/dashboard/pages/user/UserProfile")
        },
        {
          name: "ข้อมูลคุณวุฒิการศึกษา",
          path: "/personnel_education",
          component: () =>
            import("@/views/dashboard/pages/user/personnel_education")
        },
        {
          name: "ข้อมูลประสบการณ์",
          path: "/personnel_experience",
          component: () =>
            import("@/views/dashboard/pages/user/personnel_experience")
        },
        {
          name: "ข้อมูลประวัติการรับราชการ",
          path: "/personnel_work_history",
          component: () =>
            import("@/views/dashboard/pages/user/personnel_work_history")
        },
        {
          name: "ข้อมูลผลงาน รางวัล",
          path: "/personnel_award",
          component: () =>
            import("@/views/dashboard/pages/user/personnel_award")
        },
        {
          name: "ข้อมูลวินัย โทษ",
          path: "/personnel_discipline",
          component: () =>
            import("@/views/dashboard/pages/user/personnel_discipline")
        },
        {
          name: "การย้ายสายการสอน",
          path: "/transference_personnel",
          component: () =>
            import("@/views/dashboard/pages/user/transference_personnel")
        },

        {
          name: "การย้าย บุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2)",
          path: "/transference_personnel_38",
          component: () =>
            import("@/views/dashboard/pages/user/transference_personnel_38")
        },

        {
          name: "การย้ายสายการสอนกรณีพิเศษ",
          path: "/transference_personnel_sp",
          component: () =>
            import("@/views/dashboard/pages/user/transference_personnel_sp")
        },
        {
          name: "สมัคร สอบตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
          path: "/sedirector_app",
          component: () => import("@/views/dashboard/pages/user/sedirector_app")
        },

        {
          name: "สมัคร สอบตำแหน่ง ผู้อำนวยการวิทยาลัย ",
          path: "/director_app",
          component: () => import("@/views/dashboard/pages/user/director_app")
        },

        {
          name: "การย้ายสายบริหาร",
          path: "/transference_manage",
          component: () =>
            import("@/views/dashboard/pages/user/transference_manage")
        },
        {
          name: "การย้ายสายบริหาร ตำแหน่งผู้อำนวยการ",
          path: "/transference_manage_d",
          component: () =>
            import("@/views/dashboard/pages/user/transference_manage_d")
        },
        {
          name: "ข้อมูลประเมินครูผู้ช่วย",
          path: "/assistant_teacher",
          component: () =>
            import("@/views/dashboard/pages/user/assistant_teacher")
        },
        {
          name: "ข้อมูลการปฏิบัติหน้าที่",
          path: "/personnel_duty",
          component: () => import("@/views/dashboard/pages/user/personnel_duty")
        },
        {
          name: "อายุราชการในสาขาวิชาเอก",
          path: "/service_age",
          component: () => import("@/views/dashboard/pages/user/service_age")
        },
        {
          name: "ระยะเวลาการปฏิบัติหน้าที่สอนในสถานศึกษาปัจจุบัน",
          path: "/period_current",
          component: () => import("@/views/dashboard/pages/user/period_current")
        },
        {
          name: "สภาพความยากลำบากในการปฏฺิบัติงานในสถานศึกษาปัจจุบัน",
          path: "/work_difficulty",
          component: () =>
            import("@/views/dashboard/pages/user/work_difficulty")
        }
      ]
    },

    {
      path: "/userEtc",
      component: () => import("@/views/dashboard/userEtc"),
      children: [
        {
          name: "ข้อมูลผู้ใช้งานระบบ",
          path: "",
          component: () => import("@/views/dashboard/pages/userEtc/UserProfile")
        },
        {
          name: "ข้อมูลผู้ใช้งานระบบ",
          path: "/UserProfile",
          component: () => import("@/views/dashboard/pages/userEtc/UserProfile")
        },
        {
          name: "ข้อมูลคุณวุฒิการศึกษา",
          path: "/personnel_education",
          component: () =>
            import("@/views/dashboard/pages/userEtc/personnel_education")
        },
        {
          name: "ข้อมูลประสบการณ์",
          path: "/personnel_experience",
          component: () =>
            import("@/views/dashboard/pages/userEtc/personnel_experience")
        },
        {
          name: "ข้อมูลประวัติการรับราชการ",
          path: "/personnel_work_history",
          component: () =>
            import("@/views/dashboard/pages/userEtc/personnel_work_history")
        },
        {
          name: "ข้อมูลผลงาน รางวัล",
          path: "/personnel_award",
          component: () =>
            import("@/views/dashboard/pages/userEtc/personnel_award")
        },
        {
          name: "ข้อมูลวินัย โทษ",
          path: "/personnel_discipline",
          component: () =>
            import("@/views/dashboard/pages/userEtc/personnel_discipline")
        },
        {
          name: "การย้ายสายการสอน",
          path: "/transference_personnel",
          component: () =>
            import("@/views/dashboard/pages/userEtc/transference_personnel")
        },
        {
          name: "การย้ายสายการสอนกรณีพิเศษ",
          path: "/transference_personnel_sp",
          component: () =>
            import("@/views/dashboard/pages/userEtc/transference_personnel_sp")
        },
        {
          name: "สมัคร สอบตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
          path: "/sedirector_app",
          component: () =>
            import("@/views/dashboard/pages/userEtc/sedirector_app")
        },

        {
          name: "สมัคร สอบตำแหน่ง ผู้อำนวยการวิทยาลัย ",
          path: "/director_app",
          component: () =>
            import("@/views/dashboard/pages/userEtc/director_app")
        },

        {
          name: "การย้ายสายบริหาร",
          path: "/transference_manage",
          component: () =>
            import("@/views/dashboard/pages/userEtc/transference_manage")
        },
        {
          name: "การย้ายสายบริหาร ตำแหน่งผู้อำนวยการ",
          path: "/transference_manage_d",
          component: () =>
            import("@/views/dashboard/pages/userEtc/transference_manage_d")
        },
        {
          name: "ข้อมูลประเมินครูผู้ช่วย",
          path: "/assistant_teacher",
          component: () =>
            import("@/views/dashboard/pages/userEtc/assistant_teacher")
        },
        {
          name: "ข้อมูลการปฏิบัติหน้าที่",
          path: "/personnel_duty",
          component: () =>
            import("@/views/dashboard/pages/userEtc/personnel_duty")
        },
        {
          name: "อายุราชการในสาขาวิชาเอก",
          path: "/service_age",
          component: () => import("@/views/dashboard/pages/userEtc/service_age")
        },
        {
          name: "ระยะเวลาการปฏิบัติหน้าที่สอนในสถานศึกษาปัจจุบัน",
          path: "/period_current",
          component: () =>
            import("@/views/dashboard/pages/userEtc/period_current")
        },
        {
          name: "สภาพความยากลำบากในการปฏฺิบัติงานในสถานศึกษาปัจจุบัน",
          path: "/work_difficulty",
          component: () =>
            import("@/views/dashboard/pages/userEtc/work_difficulty")
        }
      ]
    },

    {
      path: "/assistantTeach",
      component: () => import("@/views/dashboard/assistantTeach"),
      children: [
        {
          name: "ข้อมูลผู้ใช้งานระบบ",
          path: "",
          component: () =>
            import("@/views/dashboard/pages/assistantTeach/UserProfile")
        }
      ]
    },
    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย",
      path: "/user/print_info/:id_ref",
      component: () => import("@/views/dashboard/pages/doc/print_info")
    },

    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย 38 ค.(2)",
      path: "/user/print_info_38/",
      component: () => import("@/views/dashboard/pages/doc/print_info_38")
    },

    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย 38 ค.(2)",
      path: "/user/print_info_38_view/",
      component: () => import("@/views/dashboard/pages/doc/print_info_38_view")
    },

    {
      name: "ประวัติอย่างย่อ",
      path: "/user/resume/",
      component: () => import("@/views/dashboard/pages/doc/resume")
    },

    {
      name: "ข้อมูลสถิติการรับนักเรียน นักศึกษา",
      path: "/admin/print_rate_work_college_std",
      component: () =>
        import("@/views/dashboard/pages/doc/print_rate_work_college_std")
    },

    {
      name: "รายชื่อสมัครคัดเลือก ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
      path: "/admin/print_sedirector_app",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app")
    },
    {
      name: "รายงานผล ภาค ก.",
      path: "/admin/print_sedirector_app_score_a",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_a")
    },

    {
      name: "รายงานผล ภาค ข.",
      path: "/admin/print_sedirector_app_score_b",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_b")
    },

    {
      name: "รายงานผล ภาค ข.",
      path: "/admin/print_sedirector_app_score_b_dir",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_b_dir")
    },

    

    {
      name: "รายงานผล ภาค ค.",
      path: "/admin/print_sedirector_app_score_c",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_c")
    },
    {
      name:
        "ประกาศผู้ผ่านการคัดเลือกตำแหน่ง  ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
      path: "/admin/print_sedirector_app_score_notice",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_notice")
    },

    {
      name: "รายงานตัวเลือกสถานศึกษา",
      path: "/admin/print_sedirector_app_score_choose",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_choose")
    },

    {
      name: "รายงานตัว ณ สถานศึกษา",
      path: "/admin/print_sedirector_app_score_report",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_report")
    },

    /*  */

    {
      name: "รายชื่อสมัครคัดเลือก ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
      path: "/admin/print_director_app",
      component: () => import("@/views/dashboard/pages/doc/print_director_app")
    },
    {
      name: "รายงานผล ภาค ก. รอง",
      path: "/admin/print_director_app_score_a",
      component: () =>
        import("@/views/dashboard/pages/doc/print_director_app_score_a")
    },

    {
      name: "รายงานผล ภาค ก. ผอ.",
      path: "/admin/print_director_app_score_a_dir",
      component: () =>
        import("@/views/dashboard/pages/doc/print_director_app_score_a_dir")
    },

    

    {
      name: "รายงานผล ภาค ข.",
      path: "/admin/print_director_app_score_b",
      component: () =>
        import("@/views/dashboard/pages/doc/print_director_app_score_b")
    },

    {
      name: "รายงานผล ภาค ค.",
      path: "/admin/print_director_app_score_c",
      component: () =>
        import("@/views/dashboard/pages/doc/print_director_app_score_c")
    },
    {
      name:
        "ประกาศผู้ผ่านการคัดเลือกตำแหน่ง  ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
      path: "/admin/print_director_app_score_notice",
      component: () =>
        import("@/views/dashboard/pages/doc/print_director_app_score_notice")
    },

    {
      name:
        "ประกาศผู้ผ่านการคัดเลือกตำแหน่ง  ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
      path: "/admin/print_director_app_score_notice_dir",
      component: () =>
        import("@/views/dashboard/pages/doc/print_director_app_score_notice_dir")
    },

    

    {
      name: "รายงานตัวเลือกสถานศึกษา",
      path: "/admin/print_director_app_score_choose",
      component: () =>
        import("@/views/dashboard/pages/doc/print_director_app_score_choose")
    },

    {
      name: "รายงานตัว ณ สถานศึกษา",
      path: "/admin/print_director_app_score_report",
      component: () =>
        import("@/views/dashboard/pages/doc/print_director_app_score_report")
    },
    /*  */

    {
      name:
        "พิมพ์ใบสมัครเข้ารับการคัดเลือกเพื่อบรรจุและแต่งตั้งให้ดำรงตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) สถานศึกษา",
      path: "/user/print_app_sedirector_rig/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_app_sedirector_rig")
    },

    {
      name:
        "พิมพ์ใบสมัครเข้ารับการคัดเลือกเพื่อบรรจุและแต่งตั้งให้ดำรงตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) สถานศึกษา",
      path: "/user/print_app_director_rig/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_app_director_rig")
    },

    {
      name: "พิมพ์ใบสมัคร",
      path: "/print_temporaryemployees/:temEmIDReGis",
      component: () =>
        import("@/views/dashboard/pages/doc/print_temporaryemployees")
    },

    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย ตัวอย่าง",
      path: "/user/print_info_preview/",
      component: () => import("@/views/dashboard/pages/doc/print_info_preview")
    },

    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย สายบริหาร ตัวอย่าง",
      path: "/user/print_info_manage_preview/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_info_manage_preview")
    },

    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย สายบริหาร พัฒนา ตัวอย่าง",
      path: "/user/print_info_manage_d_preview/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_info_manage_d_preview")
    },

    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย กรณีพิเศษ ตัวอย่าง",
      path: "/user/print_info_previewSp/:id_ref",
      component: () =>
        import("@/views/dashboard/pages/doc/print_info_previewSp")
    },

    {
      name: "พิมพ์แบบแสดงความรู้ความสามารถ 7.2",
      path: "/user/print_14_form/:id_ref",
      component: () => import("@/views/dashboard/pages/doc/print_14_form")
    },

    {
      name: "พิมพ์แบบแสดงความรู้ความสามารถ 7.1",
      path: "/user/print_14_71_form/:id_ref",
      component: () => import("@/views/dashboard/pages/doc/print_14_71_form")
    },

    {
      name: "พิมพ์แบบแสดงความรู้ความสามารถ 1.4",
      path: "/user/print_doc_kpi/:id_ref",
      component: () => import("@/views/dashboard/pages/doc/print_doc_kpi")
    },

    {
      name: "ทดสอบ Vue PDF App",
      path: "/user/vue_pdf_app/:id_ref",
      component: () => import("@/vue_pdf_app")
    },

    {
      name: "ทดสอบ HTML",
      path: "/user/print_test_html/",
      component: () => import("@/views/dashboard/pages/doc/print_test_html")
    },

    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย",
      path: "/user/print_info_sp/:id_ref",
      component: () => import("@/views/dashboard/pages/doc/print_info_sp")
    },
    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย สายงานบริหารสถานศึกษา",
      path: "/user/print_info_manage/",
      component: () => import("@/views/dashboard/pages/doc/print_info_manage")
    },
    {
      name: "พิมพ์แบบแสดงความประสงค์ขอย้าย สายงานบริหารสถานศึกษา",
      path: "/user/print_info_manage_d/:manage_id_ref",
      component: () => import("@/views/dashboard/pages/doc/print_info_manage_d")
    },

    {
      name: "พิมพ์แบบรายงานเงือนไขสาขาวิชา",
      path: "/college/print_condition/",
      component: () => import("@/views/dashboard/pages/doc/print_condition")
    },

    {
      name: "พิมพ์แบบรายงานสาขาวิชาสำหรับตำแหน่งว่าง",
      path: "/college/print_college_manpower_report/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_college_manpower_report")
    },

    {
      name: "พิมพ์แบบรายงานสาขาวิชาสำหรับตำแหน่งว่าง แบบ ศธ",
      path: "/college/print_college_manpower_report_std/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_college_manpower_report_std")
    },

    {
      name: "พิมพ์รายชื่อบุคลากรใบอนุญาตประกอบวิชาชีพ",
      path: "/college/print_personnel_license/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_personnel_license")
    },

    {
      name: "ฟอร์มนำเข้าข้อมูลบุคคล",
      path: "/college/personnel_form_import/",
      component: () =>
        import("@/views/dashboard/pages/doc/personnel_form_import")
    },
    {
      name: "พิมพ์ใบรายชื่อบุคลากร",
      path: "/college/print_report_personnel_etc/:college_code",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_personnel_etc")
    },

    {
      name: "รายงานข้อมูลอัตรากำลังสถานศึกษาประจำปี",
      path: "/college/print_rate_workforce_cal/:rate_work_g_year/:college_code",
      component: () =>
        import("@/views/dashboard/pages/doc/print_rate_workforce_cal")
    },
    {
      name: "รายงานข้อมูลอัตรากำลังสถานศึกษาประจำปี 2",
      path:
        "/college/print_rate_workforce_cal2/:rate_work_g_year/:college_code",
      component: () =>
        import("@/views/dashboard/pages/doc/print_rate_workforce_cal2")
    },

    {
      name: "พิมพ์ บัญชีรายละเอียดการพิจารณาย้าย",
      path: "/admin/print_report_movement_filter/:id_oa:times_s:year_s:tech",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement_filter")
    },

    {
      name: "พิมพ์ ข้อมูลย้าย 38 ค.(2)",
      path: "/admin/print_transference_personnel_38/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_transference_personnel_38")
    },

    {
      name: "พิมพ์ ข้อมูลรายชื่อบุคคล",
      path: "/admin/print_personnel_etc_type/:personnel_etc_m_type",
      component: () =>
        import("@/views/dashboard/pages/doc/print_personnel_etc_type")
    },

    {
      name: "พิมพ์ ข้อมูลวุฒิการศึกษา",
      path: "/admin/print_personnel_education/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_personnel_education")
    },

    {
      name: "พิมพ์ สรุปจำนวนคนแยกตามสาขาวิชา",
      path: "/admin/print_personnel_etcSummaryBranch/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_personnel_etcSummaryBranch")
    },

    {
      name: "พิมพ์ รายชื่อกรรมการประเมิน",
      path: "/admin/print_assistantcommittee/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_assistantcommittee")
    },

    {
      name: "พิมพ์ รายชื่อกรรมการประเมิน",
      path: "/admin/print_assistantcommittee2/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_assistantcommittee2")
    },

    {
      name: "พิมพ์ รายชื่อผู้ทรงคุณวุฒิ",
      path: "/admin/print_assistantcommittee4/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_assistantcommittee4")
    },
    {
      name: "พิมพ์ รายชื่อผู้ทรงคุณวุฒิ",
      path: "/admin/print_assistantcommittee_vip/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_assistantcommittee_vip")
    },

    {
      name: "พิมพ์ ข้อมูลรายชื่อบุคคล",
      path: "/admin/print_personnel_type/:personnel_m_type",
      component: () =>
        import("@/views/dashboard/pages/doc/print_personnel_type")
    },

    {
      name: "พิมพ์ ข้อมูลรายชื่อบุคคล",
      path: "/admin/print_personnel_retrire_year/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_personnel_retrire_year")
    },

    {
      name: "พิมพ์รายงานผลการประเมิน",
      path: "/admin/print_AssismentReport/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_AssismentReport")
    },

    {
      name: "พิมพ์อัตราว่างจาก จ.18",
      path: "/admin/print_jor18_null/",
      component: () => import("@/views/dashboard/pages/doc/print_jor18_null")
    },

    {
      name: "พิมพ์อัตราว่างจาก จ.18 ตำแหน่งครู (สำรวจ)",
      path: "/admin/print_jor18_teacherNull/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_jor18_teacherNull")
    },


    {
      name: "พิมพ์อัตราว่างจาก จ.18 ตำแหน่งครู (สำรวจ ย้าย)",
      path: "/admin/print_jor18_teacherMove/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_jor18_teacherMove")
    },


    



    {
      name: "พิมพ์อัตราว่างจาก จ.18 ตำแหน่งครู (วิทยาลัยรายงาน)",
      path: "/admin/print_jor18_teacherReport/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_jor18_teacherReport")
    },

    {
      name: "พิมพ์อัตราว่างจาก จ.18 ตำแหน่งครู (วิทยาลัยรายงาน) แบบที่ 2",
      path: "/admin/print_jor18_teacherReport_2/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_jor18_teacherReport_2")
    },

    {
      name: "พิมพ์อัตราว่างจาก จ.18 ตำแหน่งครู (วิทยาลัยรายงาน) แบบที่ 3",
      path: "/admin/print_jor18_teacherReport_3/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_jor18_teacherReport_3")
    },

    {
      name: "พิมพ์รายงานผลการประเมิน 2",
      path: "/admin/print_AssismentReportCP/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_AssismentReportCP")
    },
    {
      name: "พิมพ์รายงานผลการประเมิน 3",
      path: "/admin/print_AssismentReportCPDay/:times",
      component: () =>
        import("@/views/dashboard/pages/doc/print_AssismentReportCPDay")
    },
    {
      name: "พิมพ์รายงานผลการประเมิน 4",
      path: "/admin/print_AssismentReportCPDay2/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_AssismentReportCPDay2")
    },

    {
      name: "พิมพ์ บัญชีรายละเอียดการพิจารณาย้าย แบบที่ 2 ",
      path: "/admin/print_report_movement_filter_2/:id_oa:times_s:year_s:tech",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement_filter_2")
    },
    {
      name: "พิมพ์ บัญชีรายละเอียดการพิจารณาย้าย แบบที่ 3 ",
      path: "/admin/print_report_movement_filter_3/:id_oa:times_s:year_s:tech",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement_filter_3")
    },

    {
      name: "พิมพ์ บัญชีรายละเอียดการพิจารณาย้าย อ.ก.ค.ศ.",
      path: "/admin/print_report_movement_filter_o/:id_oa:times_s:year_s:tech",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement_filter_o")
    },
    {
      name: "พิมพ์คำสั่ง แต่งตั้งรักษาการในตำแหน่ง",
      path: "/admin/print_report_movement/:id_oa:times_s:year_s:tech",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement")
    },
    {
      name: "พิมพ์รายงาน การเขียนย้ายออนไลน์ประกอบการพิจารณา แบบที่ 1",
      path: "/admin/print_report_movement_online/:times_s/:year_s/:teach",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement_online")
    },
    {
      name: "พิมพ์รายงาน การเขียนย้ายออนไลน์ประกอบการพิจารณา แบบที่ 2",
      path: "/admin/print_report_movement_online_2/:times_s/:year_s/:teach",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement_online_2")
    },

    {
      name: "พิมพ์แบบบันทึกคะแนน กรรมการกลั่นกรอง ตัวชี้วัดที่ 7",
      path: "/admin/print_report_movement_formscore7/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement_formscore7")
    },

    {
      name: "พิมพ์รายงาน การเขียนย้ายออนไลน์ประกอบการพิจารณา แบบที่ 3",
      path: "/admin/print_report_movement_online_3/:times_s/:year_s/:teach",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement_online_3")
    },
    {
      name:
        "พิมพ์รายงาน การเขียนย้ายออนไลน์ประกอบการพิจารณา(ค้างพิจารณา) แบบที่ 4",
      path:
        "/admin/print_report_movement_online_4/:id_oa/:times_s/:year_s/:teach",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_movement_online_4")
    },
    {
      name: "พิมพ์รายงาน ผู้เสนอย้ายไม่บันทึกข้อมูล",
      path: "/admin/print_report_personnel_can/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_personnel_can")
    },
    {
      name: "พิมพ์รายงาน สรุปตำแหน่งว่าง",
      path: "/admin/print_report_manpower",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_manpower")
    },
    {
      name: "พิมพ์รายงาน สถานศึกษาไม่ดำเนินการบันทึกเงือนไขสาขาวิชา",
      path: "/admin/print_report_college_condition/:times_s/:year_s",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_college_condition")
    },
    {
      name: "พิมพ์รายงาน ข้อมูลผู้เสนอย้าย และเงินเดือน",
      path:
        "/admin/print_report_movement_filter_salary/:id_oa/:times_s/:year_s/:teach",
      component: () =>
        import(
          "@/views/dashboard/pages/doc/print_report_movement_filter_salary"
        )
    },
    {
      name: "พิมพ์รายงานข้อมูลอัตรากำลัง",
      path: "/admin/print_report_rate_work_g/:year_s",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_rate_work_g")
    },
    {
      name: "พิมพ์รายงานข้อมูลอัตรากำลัง ครูผู้สอน",
      path: "/admin/print_report_rate_work_g_teach/:year_s",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_rate_work_g_teach")
    },
    {
      name: "พิมพ์รายงานข้อมูลอัตรากำลัง ผู้บริหาร",
      path: "/admin/print_report_rate_work_g_dr/:year_s",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_rate_work_g_dr")
    },
    {
      name: "พิมพ์รายงานข้อมูลอัตรากำลังสายสนับสนุนการสอน",
      path: "/admin/print_report_rate_work_g_sup/:year_s",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_rate_work_g_sup")
    },
    {
      name: "พิมพ์รายงานข้อมูลอัตรากำลังลูกจ้างทั่วไป/สายการสอน",
      path: "/admin/print_report_rate_work_g_pte/:year_s",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_rate_work_g_pte")
    },
    {
      name: "พิมพ์รายงานข้อมูลเงือนไขสายการสอน",
      path: "/admin/print_report_condition_all/:times_s/:year_s",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_condition_all")
    },
    {
      name: "พิมพ์รายงานข้อมูลผู้ขอย้ายถูกระงับย้าย",
      path: "/admin/print_report_transference_suspend/:times_s/:year_s",
      component: () =>
        import("@/views/dashboard/pages/doc/print_report_transference_suspend")
    },
    {
      name: "พิมพ์รายงานข้อมูลผู้ขอย้าย สายบริหาร",
      path: "/admin/print_report_movement_manage_online/:times_s/:year_s",
      component: () =>
        import(
          "@/views/dashboard/pages/doc/print_report_movement_manage_online"
        )
    },
    {
      name: "พิมพ์รายงานข้อมูลผู้ขอย้าย สายบริหาร มุมมองสถานศึกษา",
      path: "/admin/print_report_movement_manage_online2/:times_s/:year_s",
      component: () =>
        import(
          "@/views/dashboard/pages/doc/print_report_movement_manage_online2"
        )
    },
    {
      name: "พิมพ์แบบบันทึกผล องค์ประกอบ ตัวชี้วัดและคะแนน",
      path: "/admin/print_reportAssesmentManage/:manage_id_ref",
      component: () =>
        import("@/views/dashboard/pages/doc/print_reportAssesmentManage")
    },

    {
      name:
        "พิมพ์แบบบันทึกผล องค์ประกอบ ตัวชี้วัดและคะแนน รวมทั้งหมด ของผู้อำนวยการ",
      path:
        "/admin/print_reportAssesmentManageAllDr/:period_times/:period_years/:dr",
      component: () =>
        import("@/views/dashboard/pages/doc/print_reportAssesmentManageAllDr")
    },
    {
      name:
        "พิมพ์แบบบันทึกผล องค์ประกอบ ตัวชี้วัดและคะแนน รวมทั้งหมด ของ ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
      path:
        "/admin/print_reportAssesmentManageAllSeDr/:period_times/:period_years/:sedr",
      component: () =>
        import("@/views/dashboard/pages/doc/print_reportAssesmentManageAllSeDr")
    },
    {
      name:
        "พิมพ์แบบบันทึกผล องค์ประกอบ ตัวชี้วัดและคะแนน รวมทั้งหมด ของผู้อำนวยการ เพื่อการพัฒนา",
      path:
        "/admin/print_reportAssesmentManageAllDrDev/:period_times/:period_years/:drdev",
      component: () =>
        import(
          "@/views/dashboard/pages/doc/print_reportAssesmentManageAllDrDev"
        )
    },

    {
      name: "ฟอร์มนำเข้าข้อมูลบุคคล ถึงแก่กรรม ออกจากราชการ เกษียณ",
      path: "/admin/personneloutof_form_import/",
      component: () =>
        import("@/views/dashboard/pages/doc/personneloutof_form_import")
    },

    {
      name: "ฟอร์มนำเข้าสาขาวิชา ศธ.02",
      path: "/admin/rate_work_course_std_import/",
      component: () =>
        import("@/views/dashboard/pages/doc/rate_work_course_std_import")
    },

    {
      name: "ฟอร์มนำเข้าอัตรากำลังรับย้าย",
      path: "/admin/man_power_import/",
      component: () => import("@/views/dashboard/pages/doc/man_power_import")
    },
    {
      name: "ฟอร์มนำเข้าอัตรากำลังขาด/เกินเกณฑ์ พิจารณาย้าย",
      path: "/admin/man_powersummary_import/",
      component: () =>
        import("@/views/dashboard/pages/doc/man_powersummary_import")
    },

    {
      name: "ฟอร์มนำเข้าจำนวนนักเรียน ศธ.02",
      path: "/admin/rate_work_college_import/",
      component: () =>
        import("@/views/dashboard/pages/doc/rate_work_college_import")
    },

    {
      name: "ฟอร์มนำเข้าข้อมูลบุคคล",
      path: "/college/personnel_etc_import/:college_code",
      component: () =>
        import("@/views/dashboard/pages/doc/personnel_etc_import")
    },

    {
      name: "ฟอร์มเสนอรายชื่อกรรมการประเมินครูผู้ช่วย",
      path: "/college/print_assistantcommittee_present/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_assistantcommittee_present")
    },

    {
      name: "ฟอร์มนำเข้ารายชื่อครูเรียกรายงานตัว",
      path: "/admin/manage_assistant_teacher_import/",
      component: () =>
        import("@/views/dashboard/pages/doc/manage_assistant_teacher_import")
    },
    {
      name: "ฟอร์มนำเข้าเลขที่ตำแหน่ง สำหรับรายงานตัวครูผู้ช่วย",
      path: "/admin/manage_college_manpower_import/",
      component: () =>
        import("@/views/dashboard/pages/doc/manage_college_manpower_import")
    },

    {
      name: "ฟอร์มนำเข้าคะแนน ผู้สมัคร ตำแห่นง ผู้อำนวยการ",
      path: "/admin/sedirector_app_import/",
      component: () =>
        import("@/views/dashboard/pages/doc/sedirector_app_import")
    },

    

    {
      name:
        "ฟอร์มนำเข้าเลขที่ตำแหน่งว่าง สำหรับรายงานตัว ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
      path: "/admin/director_college_manpower_import/",
      component: () =>
        import("@/views/dashboard/pages/doc/director_college_manpower_import")
    },

    {
      name: "รายงานสรุปการรายงานตัวครูผู้ช่วย",
      path:
        "/admin/print_manage_assistant_teacher_report/:periodAssTeachTimes/:periodAssTeachYear",
      component: () =>
        import(
          "@/views/dashboard/pages/doc/print_manage_assistant_teacher_report"
        )
    },

    {
      name: "รายงานสรุปการรายงานตัวครูผู้ช่วย ทั้งหมด",
      path:
        "/admin/print_manage_assistant_teacher_report_all/",
      component: () =>
        import(
          "@/views/dashboard/pages/doc/print_manage_assistant_teacher_report_all"
        )
    },


    

    {
      name: "KPI Score",
      path: "/vecprovince/print_kpi_teacher_vecp/:province_ID",
      component: () =>
        import("@/views/dashboard/pages/doc/print_kpi_teacher_vecp")
    },
    {
      name: "KPI Score Total",
      path: "/vecprovince/print_kpi_teacher_vecp_all/:province_ID",
      component: () =>
        import("@/views/dashboard/pages/doc/print_kpi_teacher_vecp_all")
    },
    {
      name: "Notice Committee",
      path: "/vecprovince/print_committee_vp/:province_ID",
      component: () => import("@/views/dashboard/pages/doc/print_committee_vp")
    },

    {
      name: "แบบสรุปผลคะแนนภาค ก.",
      path: "/college/print_sedirector_app_score_a_vp/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_a_vp")
    },
    {
      name: "แบบสรุปผลคะแนนภาค ข.",
      path: "/vecprovince/print_sedirector_app_score_b_vp/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_b_vp")
    },

    {
      name: "แบบสรุปผลคะแนนภาค ข.",
      path: "/vecprovince/print_sedirector_app_score_b_vp_dir/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_sedirector_app_score_b_vp_dir")
    },

    {
      name: "แบบสรุปผลคะแนนภาค ข.",
      path: "/supervisions/print_director_app_score_b_vp_dir/",
      component: () =>
        import("@/views/dashboard/pages/doc/print_director_app_score_b_vp_dir")
    },


    

    

    {
      name: "รายงานสถานศึกษาดำเนินการ",
      path: "/admin/rate_work_gConfirm/:period_years",
      component: () => import("@/views/dashboard/pages/doc/rate_work_gConfirm")
    },

    {
      name: "รายงานสถานศึกษาอยู่ระหว่างดำเนินการ",
      path: "/admin/rate_work_gSave/:period_years",
      component: () => import("@/views/dashboard/pages/doc/rate_work_gSave")
    },

    {
      name: "รายงานสถานศึกษาไม่ได้ดำเนินการ",
      path: "/admin/rate_work_gNull/:period_years",
      component: () => import("@/views/dashboard/pages/doc/rate_work_gNull")
    },

    {
      name: "พิมพ์แบบฟอร์มรายชื่อวิทยาลัยรายงานอัตรากำลังไม่ตรง ศธ.02",
      path: "/admin/college_updateedit_import",
      component: () =>
        import("@/views/dashboard/pages/doc/college_updateedit_import")
    },

    {
      name: "พิมพ์รายงาน สอจ.",
      path:
        "/admin/print_commiteevecreport/:period_vecprovince_times/:period_vecprovince_year",
      component: () =>
        import("@/views/dashboard/pages/doc/print_commiteevecreport")
    },
    {
      name: "พิมพ์คำสั่งแต่งตั้งคณะกรรมรับเอกสาร ส่วนกลาง ทั่วประเทศ.",
      path:
        "/admin/print_commiteevecproOrder/:period_vecprovince_times/:period_vecprovince_year",
      component: () =>
        import("@/views/dashboard/pages/doc/print_commiteevecproOrder")
    },
    {
      name: "พิมพ์แบบประเมิน กลั่นกรอง 20 คะแนน",
      path: "/admin/print_assess1420/:period_times/:period_years",
      component: () => import("@/views/dashboard/pages/doc/print_assess1420")
    },

    {
      name: "พิมพ์แบบประเมิน กลั่นกรอง 20 คะแนน คณะกรรมการ",
      path: "/admin/print_assess1420detail/:period_times/:period_years",
      component: () =>
        import("@/views/dashboard/pages/doc/print_assess1420detail")
    },

    {
      path: "/college",
      component: () => import("@/views/dashboard/college"),
      children: [
        {
          name: "เมนูรายการ",
          path: "",
          component: () => import("@/views/dashboard/pages/college/home_menu")
        },
        {
          name: "รายงาน",
          path: "/college/Dashboard",
          component: () => import("@/views/dashboard/pages/college/Dashboard")
        },
        {
          name: "รายงานตัวครูย้าย",
          path: "/college/conditons_transfer_success",
          component: () =>
            import("@/views/dashboard/pages/college/conditons_transfer_success")
        },

        {
          name: "รายละเอียดสถานศึกษา",
          path: "/college/collegeinfo",
          component: () => import("@/views/dashboard/pages/college/collegeinfo")
        },

        {
          name: "เมนูรายการ",
          path: "/college/home_menu",
          component: () => import("@/views/dashboard/pages/college/home_menu")
        },
        {
          name: "ข้อมูลเกี่ยวกับบุคคล",
          path: "/college/personnel",
          component: () => import("@/views/dashboard/pages/college/personnel")
        },
        {
          name: "ข้อมูลคุณวุฒิการศึกษา",
          path: "/college/personnel_education",
          component: () =>
            import("@/views/dashboard/pages/college/personnel_education")
        },
        {
          name: "ข้อมูลประสบการณ์",
          path: "/college/personnel_experience",
          component: () =>
            import("@/views/dashboard/pages/college/personnel_experience")
        },
        {
          name: "ข้อมูลประวัติการรับราชการ",
          path: "/college/personnel_work_history",
          component: () =>
            import("@/views/dashboard/pages/college/personnel_work_history")
        },
        {
          name: "ข้อมูลผลงาน รางวัล",
          path: "/college/personnel_award",
          component: () =>
            import("@/views/dashboard/pages/college/personnel_award")
        },
        {
          name: "ข้อมูลโทษ วินัย",
          path: "/college/personnel_discipline",
          component: () =>
            import("@/views/dashboard/pages/college/personnel_discipline")
        },

        {
          name: "ข้อมูลบุคคลใช้งานระบบ",
          path: "/college/personnel",
          component: () => import("@/views/dashboard/pages/college/personnel")
        },
        {
          name: "ข้อมูลข้าราชการครูและบุคลการทางการศึกษา",
          path: "/college/personnel_tem",
          component: () =>
            import("@/views/dashboard/pages/college/personnel_temporary")
        },
        {
          name: "ข้อมูลใบอนุญาตประกอบวิชาชีพ",
          path: "/college/personnel_temporarylicence",
          component: () =>
            import("@/views/dashboard/pages/college/personnel_temporarylicence")
        },

        {
          name: "เงือนไขการย้าย",
          path: "/college/conditions_branch",
          component: () =>
            import("@/views/dashboard/pages/college/conditions_branch")
        },

        {
          name: "ประวัติข้อมูลเงือนไขการย้าย",
          path: "/college/history_conditions",
          component: () =>
            import("@/views/dashboard/pages/college/history_conditions")
        },

        {
          name: "กำหนดสาขาวิชาสำหรับเลขที่ตำแหน่งว่าง",
          path: "/college/man_power_j",
          component: () => import("@/views/dashboard/pages/college/man_power_j")
        },

        {
          name: "การย้าย 38 ค.(2)",
          path: "/college/transference_personnel_38",
          component: () =>
            import("@/views/dashboard/pages/college/transference_personnel_38")
        },

        {
          name: "การย้ายสายการสอนและสายสนับสนุน",
          path: "/college/transference_personnel",
          component: () =>
            import("@/views/dashboard/pages/college/transference_personnel")
        },
        {
          name: "การย้ายสายบริหาร",
          path: "/college/transference_manage",
          component: () =>
            import("@/views/dashboard/pages/college/transference_manage")
        },
        {
          name: "การย้ายสายบริหาร สถานศึกษาอื่นประสงค์ย้ายเข้า",
          path: "/college/transference_manage_location",
          component: () =>
            import(
              "@/views/dashboard/pages/college/transference_manage_location"
            )
        },

        {
          name: "ข้อมูลทั่วไป",
          path: "/college/rate_workforce_g",
          component: () =>
            import("@/views/dashboard/pages/college/rate_workforce_g")
        },
        {
          name: "รายงานข้อมูลสถานศึกษา (อัตรากำลัง)",
          path: "/college/rate_workforce",
          component: () =>
            import("@/views/dashboard/pages/college/rate_workforce")
        },
        {
          name: "รายงานข้อมูลสถานศึกษา (อัตรากำลัง) สรุป",
          path: "/college/rate_workforce_pro",
          component: () =>
            import("@/views/dashboard/pages/college/rate_workforce_pro")
        },
        {
          name: "รายงานอัตรากำลังสถานศึกษา",
          path: "/college/rate_workforce_report",
          component: () =>
            import("@/views/dashboard/pages/college/rate_workforce_report")
        },

        {
          name: "ข้อมูลหมวดวิชา",
          path: "/college/rate_workforce_course/:rate_work_course_id",
          component: () =>
            import("@/views/dashboard/pages/college/rate_workforce_course")
        },
        {
          name: "ประมวลผลอัตรากำลัง",
          path: "/college/rate_workforce_cal",
          component: () =>
            import("@/views/dashboard/pages/college/rate_workforce_cal")
        },
        {
          name: "ครูผู้ช่วย",
          path: "/college/assistant_teacher",
          component: () =>
            import("@/views/dashboard/pages/college/assistant_teacher")
        },
        {
          name: "รับรายงานตัว ตำแหน่ง ครูผู้ช่วย",
          path: "/college/manage_assistant_teacher",
          component: () =>
            import("@/views/dashboard/pages/college/manage_assistant_teacher")
        },

        {
          name: "ประเมินภาค ก ตำแหน่ง  ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
          path: "/college/sedirector_app",
          component: () =>
            import("@/views/dashboard/pages/college/sedirector_app")
        },
        {
          name: "รับรายงานตัว ตำแหน่ง  ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.) ",
          path: "/college/sedirector_app_report",
          component: () =>
            import("@/views/dashboard/pages/college/sedirector_app_report")
        },

        {
          name: "ข้อมูลผู้ทรงคุณวุฒิอื่นจากภายนอกสถานศึกษา",
          path: "/college/assistantcommitteequalified",
          component: () =>
            import(
              "@/views/dashboard/pages/college/assistantcommitteequalified"
            )
        },

        {
          name: "กำหนดรายชื่อกรรมการต่อครูผู้ช่วย",
          path: "/college/assistantcommittee",
          component: () =>
            import("@/views/dashboard/pages/college/assistantcommittee")
        },

        {
          name:
            "ข้อมูล ข้าราชการ ลูกจ้างประจำ พนักงานราชการ ครูอัตราจ้างหรือลูกจ้างชั่วคราว",
          path: "/college/personnel_etc",
          component: () =>
            import("@/views/dashboard/pages/college/personnel_etc")
        },

        {
          name: "ผู้ประสงค์ขอย้ายเข้า 38 ค.(2)",
          path: "/college/transference_personnel_movein_38",
          component: () =>
            import(
              "@/views/dashboard/pages/college/transference_personnel_movein_38"
            )
        },

        {
          name: "ผู้ประสงค์ขอย้ายเข้า",
          path: "/college/transference_personnel_movein",
          component: () =>
            import(
              "@/views/dashboard/pages/college/transference_personnel_movein"
            )
        },

        {
          name: "คัดเลือก/แข่งขัน บุคลากร",
          path: "/college/temporarycollegeemreg",
          component: () =>
            import("@/views/dashboard/pages/college/temporarycollegeemreg")
        },

        {
          name: "ข้อมูลการสมัคร คัดเลือก/แข่งขัน บุคลากร",
          path: "/college/temporaryemployees",
          component: () =>
            import("@/views/dashboard/pages/college/temporaryemployees")
        }
      ]
    },

    {
      path: "/iven",
      component: () => import("@/views/dashboard/iven"),
      children: [
        {
          name: "รายงาน",
          path: "",
          component: () => import("@/views/dashboard/pages/iven/Dashboard")
        },
        {
          name: "รายงาน",
          path: "/iven/Dashboard",
          component: () => import("@/views/dashboard/pages/iven/Dashboard")
        },
        {
          name: "รายละเอียดสถานศึกษา",
          path: "/iven/collegeinfo",
          component: () => import("@/views/dashboard/pages/iven/collegeinfo")
        },

        {
          name: "เมนูรายการ",
          path: "/iven/home_menu",
          component: () => import("@/views/dashboard/pages/iven/home_menu")
        },
        {
          name: "ข้อมูลเกี่ยวกับบุคคล",
          path: "/iven/personnel",
          component: () => import("@/views/dashboard/pages/iven/personnel")
        },
        {
          name: "ข้อมูลคุณวุฒิการศึกษา",
          path: "/iven/personnel_education",
          component: () =>
            import("@/views/dashboard/pages/iven/personnel_education")
        },
        {
          name: "ข้อมูลประสบการณ์",
          path: "/iven/personnel_experience",
          component: () =>
            import("@/views/dashboard/pages/iven/personnel_experience")
        },
        {
          name: "ข้อมูลประวัติการรับราชการ",
          path: "/iven/personnel_work_history",
          component: () =>
            import("@/views/dashboard/pages/iven/personnel_work_history")
        },
        {
          name: "ข้อมูลผลงาน รางวัล",
          path: "/iven/personnel_award",
          component: () =>
            import("@/views/dashboard/pages/iven/personnel_award")
        },
        {
          name: "ข้อมูลโทษ วินัย",
          path: "/iven/personnel_discipline",
          component: () =>
            import("@/views/dashboard/pages/iven/personnel_discipline")
        },

        {
          name: "ข้อมูลบุคคลใช้งานระบบ",
          path: "/iven/personnel",
          component: () => import("@/views/dashboard/pages/iven/personnel")
        },
        {
          name: "ข้อมูลข้าราชการครูและบุคลการทางการศึกษา",
          path: "/iven/personnel_tem",
          component: () =>
            import("@/views/dashboard/pages/iven/personnel_temporary")
        },
        {
          name: "เงือนไขการย้าย",
          path: "/iven/conditions_branch",
          component: () =>
            import("@/views/dashboard/pages/iven/conditions_branch")
        },

        {
          name: "ประวัติข้อมูลเงือนไขการย้าย",
          path: "/iven/history_conditions",
          component: () =>
            import("@/views/dashboard/pages/iven/history_conditions")
        },
        {
          name: "การย้ายสายการสอนและสายสนับสนุน",
          path: "/iven/transference_personnel",
          component: () =>
            import("@/views/dashboard/pages/iven/transference_personnel")
        },
        {
          name: "การย้ายสายบริหาร",
          path: "/iven/transference_manage",
          component: () =>
            import("@/views/dashboard/pages/iven/transference_manage")
        },
        {
          name: "การย้ายสายบริหาร สถานศึกษาอื่นประสงค์ย้ายเข้า",
          path: "/iven/transference_manage_location",
          component: () =>
            import("@/views/dashboard/pages/iven/transference_manage_location")
        },

        {
          name: "ข้อมูลทั่วไป",
          path: "/iven/rate_workforce_g",
          component: () =>
            import("@/views/dashboard/pages/iven/rate_workforce_g")
        },
        {
          name: "รายงานข้อมูลสถานศึกษา (อัตรากำลัง)",
          path: "/iven/rate_workforce",
          component: () => import("@/views/dashboard/pages/iven/rate_workforce")
        },
        {
          name: "รายงานข้อมูลสถานศึกษา (อัตรากำลัง) สรุป",
          path: "/iven/rate_workforce_pro",
          component: () =>
            import("@/views/dashboard/pages/iven/rate_workforce_pro")
        },
        {
          name: "รายงานอัตรากำลังสถานศึกษา",
          path: "/iven/rate_workforce_report",
          component: () =>
            import("@/views/dashboard/pages/iven/rate_workforce_report")
        },

        {
          name: "ข้อมูลหมวดวิชา",
          path: "/iven/rate_workforce_course/:rate_work_course_id",
          component: () =>
            import("@/views/dashboard/pages/iven/rate_workforce_course")
        },
        {
          name: "ประมวลผลอัตรากำลัง",
          path: "/iven/rate_workforce_cal",
          component: () =>
            import("@/views/dashboard/pages/iven/rate_workforce_cal")
        },
        {
          name: "ครูผู้ช่วย",
          path: "/iven/assistant_teacher",
          component: () =>
            import("@/views/dashboard/pages/iven/assistant_teacher")
        },
        {
          name:
            "ข้อมูล ลูกจ้างประจำ พนักงานราชการ ครูอัตราจ้างหรือลูกจ้างชั่วคราว",
          path: "/iven/personnel_etc",
          component: () => import("@/views/dashboard/pages/iven/personnel_etc")
        },
        {
          name: "คณะกรรมการตรวจสอบผู้ประสงค์ขอย้าย",
          path: "/iven/commiteevecpro",
          component: () => import("@/views/dashboard/pages/iven/commiteevecpro")
        },
        {
          name: "ข้อมูลเบื้องต้น สถาบันการอาชีวศึกษา",
          path: "/iven/iveninfo",
          component: () => import("@/views/dashboard/pages/iven/iveninfo")
        },

        {
          name: "ผู้สมัครสอบ ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/iven/sedirector_app/",
          component: () => import("@/views/dashboard/pages/iven/sedirector_app")
        },

        {
          name: "ข้อมูลบุคลากร",
          path: "/iven/personnel_iven/",
          component: () => import("@/views/dashboard/pages/iven/personnel_iven")
        },

        {
          name: "ประเมิน ผู้สมัครคัดเลือก ตำแหน่ง ผู้อำนวยการวิทยาลัย",
          path: "/iven/director_app_b/",
          component: () => import("@/views/dashboard/pages/iven/director_app_b")
        },
        
      ]
    },

    {
      path: "/supervisions",
      component: () => import("@/views/dashboard/supervisions"),
      children: [
        {
          name: "รายงาน",
          path: "",
          component: () =>
            import("@/views/dashboard/pages/supervisions/Dashboard")
        },
        {
          name: "รายงาน",
          path: "/supervisions/Dashboard",
          component: () =>
            import("@/views/dashboard/pages/supervisions/Dashboard")
        },
        {
          name: "รายละเอียดสถานศึกษา",
          path: "/supervisions/collegeinfo",
          component: () =>
            import("@/views/dashboard/pages/supervisions/collegeinfo")
        },

        {
          name: "เมนูรายการ",
          path: "/supervisions/home_menu",
          component: () =>
            import("@/views/dashboard/pages/supervisions/home_menu")
        },
        {
          name: "ข้อมูลเกี่ยวกับบุคคล",
          path: "/supervisions/personnel",
          component: () =>
            import("@/views/dashboard/pages/supervisions/personnel")
        },

        {
          name: "ข้อมูลเกี่ยวกับศึกษานิเทศก์",
          path: "/supervisions/personnel_supervision",
          component: () =>
            import("@/views/dashboard/pages/supervisions/personnel_supervision")
        },

        {
          name: "ข้อมูลคุณวุฒิการศึกษา",
          path: "/supervisions/personnel_education",
          component: () =>
            import("@/views/dashboard/pages/supervisions/personnel_education")
        },
        {
          name: "ข้อมูลประสบการณ์",
          path: "/supervisions/personnel_experience",
          component: () =>
            import("@/views/dashboard/pages/supervisions/personnel_experience")
        },
        {
          name: "ข้อมูลประวัติการรับราชการ",
          path: "/supervisions/personnel_work_history",
          component: () =>
            import(
              "@/views/dashboard/pages/supervisions/personnel_work_history"
            )
        },
        {
          name: "ข้อมูลผลงาน รางวัล",
          path: "/supervisions/personnel_award",
          component: () =>
            import("@/views/dashboard/pages/supervisions/personnel_award")
        },
        {
          name: "ข้อมูลโทษ วินัย",
          path: "/supervisions/personnel_discipline",
          component: () =>
            import("@/views/dashboard/pages/supervisions/personnel_discipline")
        },

        {
          name: "ข้อมูลบุคคลใช้งานระบบ",
          path: "/supervisions/personnel",
          component: () =>
            import("@/views/dashboard/pages/supervisions/personnel")
        },
        {
          name: "ข้อมูลข้าราชการครูและบุคลการทางการศึกษา",
          path: "/supervisions/personnel_tem",
          component: () =>
            import("@/views/dashboard/pages/supervisions/personnel_temporary")
        },
        {
          name: "เงือนไขการย้าย",
          path: "/supervisions/conditions_branch",
          component: () =>
            import("@/views/dashboard/pages/supervisions/conditions_branch")
        },

        {
          name: "ประวัติข้อมูลเงือนไขการย้าย",
          path: "/supervisions/history_conditions",
          component: () =>
            import("@/views/dashboard/pages/supervisions/history_conditions")
        },
        {
          name: "การย้ายสายการสอนและสายสนับสนุน",
          path: "/supervisions/transference_personnel",
          component: () =>
            import(
              "@/views/dashboard/pages/supervisions/transference_personnel"
            )
        },
        {
          name: "การย้ายสายบริหาร",
          path: "/supervisions/transference_manage",
          component: () =>
            import("@/views/dashboard/pages/supervisions/transference_manage")
        },
        {
          name: "การย้ายสายบริหาร สถานศึกษาอื่นประสงค์ย้ายเข้า",
          path: "/supervisions/transference_manage_location",
          component: () =>
            import(
              "@/views/dashboard/pages/supervisions/transference_manage_location"
            )
        },

        {
          name:
            "สอจ. รายงานผลการประเมิน ภาค ข. ผู้สมัครคัดเลือก ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/supervisions/director_app_b/",
          component: () =>
            import("@/views/dashboard/pages/supervisions/director_app_b")
        },

        {
          name: "ข้อมูลทั่วไป",
          path: "/supervisions/rate_workforce_g",
          component: () =>
            import("@/views/dashboard/pages/supervisions/rate_workforce_g")
        },
        {
          name: "รายงานข้อมูลสถานศึกษา (อัตรากำลัง)",
          path: "/supervisions/rate_workforce",
          component: () =>
            import("@/views/dashboard/pages/supervisions/rate_workforce")
        },
        {
          name: "รายงานข้อมูลสถานศึกษา (อัตรากำลัง) สรุป",
          path: "/supervisions/rate_workforce_pro",
          component: () =>
            import("@/views/dashboard/pages/supervisions/rate_workforce_pro")
        },
        {
          name: "รายงานอัตรากำลังสถานศึกษา",
          path: "/supervisions/rate_workforce_report",
          component: () =>
            import("@/views/dashboard/pages/supervisions/rate_workforce_report")
        },

        {
          name: "ข้อมูลหมวดวิชา",
          path: "/supervisions/rate_workforce_course/:rate_work_course_id",
          component: () =>
            import("@/views/dashboard/pages/supervisions/rate_workforce_course")
        },
        {
          name: "ประมวลผลอัตรากำลัง",
          path: "/supervisions/rate_workforce_cal",
          component: () =>
            import("@/views/dashboard/pages/supervisions/rate_workforce_cal")
        },
        {
          name: "ครูผู้ช่วย",
          path: "/supervisions/assistant_teacher",
          component: () =>
            import("@/views/dashboard/pages/supervisions/assistant_teacher")
        },
        {
          name:
            "ข้อมูล ลูกจ้างประจำ พนักงานราชการ ครูอัตราจ้างหรือลูกจ้างชั่วคราว",
          path: "/supervisions/personnel_etc",
          component: () =>
            import("@/views/dashboard/pages/supervisions/personnel_etc")
        },
        {
          name: "คณะกรรมการตรวจสอบผู้ประสงค์ขอย้าย",
          path: "/supervisions/commiteevecpro",
          component: () =>
            import("@/views/dashboard/pages/supervisions/commiteevecpro")
        },
        {
          name: "ข้อมูลเบื้องต้นสำนักงานอาชีวศึกษาจังหวัด",
          path: "/supervisions/info",
          component: () => import("@/views/dashboard/pages/supervisions/info")
        },

        {
          name: "ผู้สมัครสอบ ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/supervisions/sedirector_app/",
          component: () =>
            import("@/views/dashboard/pages/supervisions/sedirector_app")
        }
      ]
    },
    {
      path: "/vecprovince",
      component: () => import("@/views/dashboard/vecprovince"),
      children: [
        {
          name: "รายงาน",
          path: "",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/Dashboard")
        },
        {
          name: "รายงาน",
          path: "/vecprovince/Dashboard",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/Dashboard")
        },
        {
          name: "รายละเอียดสถานศึกษา",
          path: "/vecprovince/collegeinfo",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/collegeinfo")
        },

        {
          name: "เมนูรายการ",
          path: "/vecprovince/home_menu",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/home_menu")
        },
        {
          name: "ข้อมูลเกี่ยวกับบุคคล",
          path: "/vecprovince/personnel",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/personnel")
        },
        {
          name: "ข้อมูลคุณวุฒิการศึกษา",
          path: "/vecprovince/personnel_education",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/personnel_education")
        },
        {
          name: "ข้อมูลประสบการณ์",
          path: "/vecprovince/personnel_experience",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/personnel_experience")
        },
        {
          name: "ข้อมูลประวัติการรับราชการ",
          path: "/vecprovince/personnel_work_history",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/personnel_work_history")
        },
        {
          name: "ข้อมูลผลงาน รางวัล",
          path: "/vecprovince/personnel_award",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/personnel_award")
        },
        {
          name: "ข้อมูลโทษ วินัย",
          path: "/vecprovince/personnel_discipline",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/personnel_discipline")
        },

        {
          name: "ข้อมูลบุคคลใช้งานระบบ",
          path: "/vecprovince/personnel",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/personnel")
        },
        {
          name: "ข้อมูลข้าราชการครูและบุคลการทางการศึกษา",
          path: "/vecprovince/personnel_tem",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/personnel_temporary")
        },
        {
          name: "เงือนไขการย้าย",
          path: "/vecprovince/conditions_branch",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/conditions_branch")
        },

        {
          name: "ประวัติข้อมูลเงือนไขการย้าย",
          path: "/vecprovince/history_conditions",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/history_conditions")
        },
        {
          name: "การย้ายสายการสอนและสายสนับสนุน",
          path: "/vecprovince/transference_personnel",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/transference_personnel")
        },
        {
          name: "การย้ายสายบริหาร",
          path: "/vecprovince/transference_manage",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/transference_manage")
        },
        {
          name: "การย้ายสายบริหาร สถานศึกษาอื่นประสงค์ย้ายเข้า",
          path: "/vecprovince/transference_manage_location",
          component: () =>
            import(
              "@/views/dashboard/pages/vecprovince/transference_manage_location"
            )
        },

        {
          name: "ข้อมูลทั่วไป",
          path: "/vecprovince/rate_workforce_g",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/rate_workforce_g")
        },
        {
          name: "รายงานข้อมูลสถานศึกษา (อัตรากำลัง)",
          path: "/vecprovince/rate_workforce",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/rate_workforce")
        },
        {
          name: "รายงานข้อมูลสถานศึกษา (อัตรากำลัง) สรุป",
          path: "/vecprovince/rate_workforce_pro",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/rate_workforce_pro")
        },
        {
          name: "รายงานอัตรากำลังสถานศึกษา",
          path: "/vecprovince/rate_workforce_report",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/rate_workforce_report")
        },

        {
          name: "ข้อมูลหมวดวิชา",
          path: "/vecprovince/rate_workforce_course/:rate_work_course_id",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/rate_workforce_course")
        },
        {
          name: "ประมวลผลอัตรากำลัง",
          path: "/vecprovince/rate_workforce_cal",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/rate_workforce_cal")
        },
        {
          name: "ครูผู้ช่วย",
          path: "/vecprovince/assistant_teacher",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/assistant_teacher")
        },
        {
          name:
            "ข้อมูล ลูกจ้างประจำ พนักงานราชการ ครูอัตราจ้างหรือลูกจ้างชั่วคราว",
          path: "/vecprovince/personnel_etc",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/personnel_etc")
        },
        {
          name: "คณะกรรมการตรวจสอบผู้ประสงค์ขอย้าย",
          path: "/vecprovince/commiteevecpro",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/commiteevecpro")
        },
        {
          name: "ข้อมูลเบื้องต้นสำนักงานอาชีวศึกษาจังหวัด",
          path: "/vecprovince/provincialinfo",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/provincialinfo")
        },

        {
          name: "ผู้สมัครสอบ ตำแหน่ง รองผู้อำนวยการวิทยาลัย",
          path: "/vecprovince/sedirector_app/",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/sedirector_app")
        },

        {
          name:
            "สอจ. รายงานผลการประเมิน ภาค ก. ผู้สมัครคัดเลือก ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/vecprovince/sedirector_app_a/",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/sedirector_app_a")
        },

        {
          name:
            "สอจ. รายงานผลการประเมิน ภาค ข. ผู้สมัครคัดเลือก ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/vecprovince/sedirector_app_b/",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/sedirector_app_b")
        },

      

        

        {
          name:
            "สอจ. รายงานผลการประเมิน ภาค ข. ผู้สมัครคัดเลือก ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/vecprovince/director_app_b/",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/director_app_b")
        },

        /*  */

        {
          name: "ผู้สมัครสอบ ตำแหน่ง ผู้อำนวยการวิทยาลัย",
          path: "/vecprovince/director_app/",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/director_app")
        }
        /* 
        {
          name: "สอจ. รายงานผลการประเมิน ภาค ก. ผู้สมัครคัดเลือก ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/vecprovince/director_app_a/",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/director_app_a")
        },

        {
          name: "สอจ. รายงานผลการประเมิน ภาค ข. ผู้สมัครคัดเลือก ตำแหน่ง ผู้บริหารสถานศึกษา (ผอ. / รอง ผอ.)",
          path: "/vecprovince/director_app_b/",
          component: () =>
            import("@/views/dashboard/pages/vecprovince/director_app_b")
        }, */
      ]
    }
  ]
});
