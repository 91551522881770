<template>
  <div>
    <v-bottom-navigation color="info" horizontal dark>
      <v-btn to="/college/personnel_tem" class="mr-5">
        <span>ข้อมูลบุคคล </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/college/personnel_temporarylicence" class="mr-5">
        <span>ข้อมูลใบอนุญาต</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

      <!--   <v-btn to="/college/manage_assistant_teacher" class="mr-5">
        <span>ข้อมูลวิทยฐานะ</span>
        <v-icon>mdi-numeric-3-box</v-icon>
      </v-btn>

      <v-btn to="/college/manage_assistant_teacher" class="mr-5">
        <span>ข้อมูลเครื่องราช</span>
        <v-icon>mdi-numeric-4-box</v-icon>
      </v-btn> -->
    </v-bottom-navigation>

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้าราชการครูและบุคลากรทางการศึกษา (เกี่ยวข้องกับระบบการย้าย)"
        class="px-5 py-3"
      >
        <v-row>
          <v-col cols="12" md="12">
            <div>
              <div class="text-right pa-2">
                <span class="font-weight-bold red--text"
                  >*
                  รายการร้องขอข้อมูลบุคคลจากส่วนกลางกรณีย้ายเข้ามาแล้วแต่ไม่ปรากฎชื่อ
                </span>
              </div>
              <div class="text-right pa-2">
                <v-btn color="primary" @click.native="personnel_temporaryAdd()">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                  <h3>ขอเพิ่มบุคลากร (ข้าราชการ)</h3>
                </v-btn>
                <v-btn
                  color="info"
                  :to="
                    '/college/print_personnel_license/?cid=' + user.college_code
                  "
                  target="_blank"
                  ><v-icon>mdi-printer</v-icon> ส่งออกข้อมูล</v-btn
                >
              </div>
            </div>
            <v-data-table
              :headers="headersReq"
              class="elevation-1"
              :items="collegepersonnelreqs"
              :loading="loading"
            >
              <template v-slot:item.brith_day="{ item }">
                {{
                  item.brith_day +
                    "/" +
                    item.brith_month +
                    "/" +
                    item.brith_year
                }}
              </template>
              <template v-slot:item.appoin_day="{ item }">
                {{
                  item.appoin_day +
                    "/" +
                    item.appoin_month +
                    "/" +
                    item.appoin_year
                }}
              </template>

              <template v-slot:item.collegePersonnelAppOrderDate="{ item }">
                {{
                  item.collegePersonnelAppOrderDate
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-chip
                  dark
                  color="warning"
                  v-if="item.collegeRegStatus === 'req'"
                  @click="CancelReq(item.personalIDcardReq)"
                >
                  <v-icon>mdi-close-circle-outline</v-icon>
                  ขอยกเลิกรับย้าย
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row no-gutters class="mb-2">
          <v-col cols="12" class="pa-1">
            <span class="font-weight-bold">รายการแจ้งปรับปรุงข้อมูล</span>
            <v-data-table
              :headers="headersNotify"
              :items="personnel_temporarysNoticesQu"
              class="elevation-1"
            >
              <template v-slot:item.personnel_temporary_notifyStatus="{ item }">
                <v-chip
                  color="red"
                  dark
                  v-if="item.personnel_temporary_notifyStatus === 'send'"
                  >รอดำเนินการ</v-chip
                >
                <v-chip
                  color="primary"
                  dark
                  v-else-if="item.personnel_temporary_notifyStatus === 'update'"
                  >เสร็จสิ้น</v-chip
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  @click="
                    personnel_temporary_notifyDel(
                      item.personnel_temporary_notifyID
                    )
                  "
                  v-if="item.personnel_temporary_notifyStatus === 'send'"
                  color="warning"
                  rounded
                >
                  <v-icon>mdi-close</v-icon> ยกเลิก</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-data-table
          :headers="showHeaders"
          :items="personnel_temporarys"
          class="elevation-1"
          :loading="loading"
          :search="search"
          disable-pagination
          group-by="position_name"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                v-on:keyup.enter="OnEnter()"
                dense
                filled
                class="mb-2"
              />

              <v-select
                v-model="selectedHeaders"
                :items="headers"
                label="เลือกคอลัมน์ที่ต้องการแสดง"
                multiple
                outlined
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 6">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 6" class="grey--text caption"
                    >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
                  >
                </template>
              </v-select>
            </div>
          </template>
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="22">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].position_name }}
            </th>
          </template>

          <template v-slot:item.viewId="{ item }">
            <v-btn
              :href="'#/user/resume/?id_card=' + item.id_card"
              target="_blank"
              rounded
              color="info"
              fab
              small
            >
              <v-icon>mdi-printer</v-icon></v-btn
            >
          </template>

          <template v-slot:item.branch_name_th="{ item }">
            <div>{{ item.pt_id_branch }} : {{ item.branch_name_th }}</div>
          </template>

          <template v-slot:item.date_rang_level="{ item }">
            {{
              item.date_rang_level
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.date_app_now="{ item }">
            {{
              item.date_app_now
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.date_app_positonnow="{ item }">
            {{
              item.date_app_positonnow
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.brith_day="{ item }">
            {{
              item.brith_day + "/" + item.brith_month + "/" + item.brith_year
            }}
          </template>
          <template v-slot:item.appoin_day="{ item }">
            {{
              item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year
            }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="warning"
              @click.stop="personnel_temporaryEdit(item.id_card)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:item.actionNotices="{ item }">
            <v-icon
              color="red"
              @click.stop="personnel_temporary_notifyEdit(item.id_card)"
            >
              mdi-account-edit
            </v-icon>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>

      <!-- V-model editpersonnel_temporarydialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editpersonnel_temporarydialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ปรับปรุงข้อมูลบุคลากร"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_temporaryform" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" class="font-weight-bold"
                    >ใบอนุญาตประกอบวิชาชีพครู</v-col
                  >

                  <v-col cols="12" md="3" class="pa-1">
                    <v-text-field
                      outlined
                      label="เลขที่ใบอนุญาต"
                      v-model="editpersonnel_temporary.pt_licence"
                      required
                      :rules="[v => !!v || '']"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" class="pa-1">
                    <v-menu
                      ref="menu6"
                      v-model="menu6"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.pt_ondate"
                          label="วันออกบัตร"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_ondate"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu6 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu6.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="3" class="pa-1">
                    <v-menu
                      ref="menu7"
                      v-model="menu7"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.pt_enddate"
                          label="วันหมดอายุ"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_enddate"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu7 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu7.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="text-center"
                    v-if="editpersonnel_temporary.pt_licence_file"
                  >
                    <v-btn
                      v-if="editpersonnel_temporary.pt_licence_file"
                      fab
                      outlined
                      small
                      color="info"
                      @click="viewpdflicenseteach()"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    >

                    <v-btn
                      v-if="editpersonnel_temporary.pt_licence_file"
                      fab
                      outlined
                      small
                      color="red"
                      class="ml-2"
                      @click="deletelicenseteach()"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="!editpersonnel_temporary.pt_licence_file"
                  >
                    <v-file-input
                      v-model="license_file"
                      accept=".pdf"
                      name="license_file"
                      color="deep-purple accent-4"
                      counter
                      label="ใบอนุญาตประกอบวิชาชีพครู .pdf"
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :rules="rules"
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>

                  <v-col cols="12" class="font-weight-bold"
                    >ใบอนุญาตประกอบวิชาชีพผู้บริหารสถานศึกษา</v-col
                  >

                  <v-col cols="12" md="3" class="pa-1">
                    <v-text-field
                      outlined
                      label="ใบอนุญาตประกอบวิชาชีพผู้บริหารสถานศึกษา"
                      v-model="editpersonnel_temporary.pt_licencedr"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" class="pa-1">
                    <v-menu
                      ref="menu9"
                      v-model="menu9"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.pt_ondatedr"
                          label="วันที่ออกบัตร"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_ondatedr"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu9 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu9.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="3" class="pa-1">
                    <v-menu
                      ref="menu8"
                      v-model="menu8"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.pt_enddatedr"
                          label="วันหมดอายุ"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_enddatedr"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu8 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu8.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="3"
                    v-if="editpersonnel_temporary.pt_licencedr_file"
                    class="text-center"
                  >
                    <v-btn
                      v-if="editpersonnel_temporary.pt_licencedr_file"
                      fab
                      outlined
                      small
                      color="info"
                      @click="viewpdflicensedr()"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    >
                    <v-btn
                      v-if="editpersonnel_temporary.pt_licencedr_file"
                      fab
                      outlined
                      small
                      color="red"
                      class="ml-2"
                      @click="deletelicensedr()"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="3"
                    v-if="!editpersonnel_temporary.pt_licencedr_file"
                  >
                    <v-file-input
                      v-model="license_filedr"
                      accept=".pdf"
                      name="license_filedr"
                      color="deep-purple accent-4"
                      counter
                      label="ใบอนุญาตประกอบวิชาชีพผู้บริหารสถานศึกษา .pdf"
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :rules="rules"
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>

                  <v-col cols="12" v-if="editpersonnel_temporary.pt_licencedr">
                    <v-radio-group
                      v-model="editpersonnel_temporary.pt_licencedr_type"
                      row
                      :rules="[v => !!v || '']"
                    >
                      <template v-slot:label>
                        <div class="font-weight-bold">
                          <v-icon size="30">mdi-card-account-details</v-icon>
                        </div>
                      </template>
                      <v-radio value="a">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text"
                              >สำเร็จการศึกษา ด้านบริหารการศึกษา</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="b">
                        <template v-slot:label>
                          <div>
                            <strong class="info--text"
                              >การเทียบประสบการณ์ในการบริหารสถานศึกษามาแล้วไม่น้อยกว่า
                              10 ปี</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12">
                    <hr style="border: 1px solid gray;" />
                  </v-col>

                  <v-col cols="12" class="font-weight-bold"
                    >เกี่ยวกับวิทยฐานะปัจจุบัน</v-col
                  >
                  <v-col cols="12" md="3" class="pa-1">
                    <v-autocomplete
                      v-model="editpersonnel_temporary.rang_name"
                      outlined
                      label="วิทยฐานะปัจจุบัน"
                      :items="rang_names"
                      item-text="text"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-autocomplete
                      v-model="editpersonnel_temporary.rang_level"
                      outlined
                      label="ระดับ วิทยฐานะ"
                      :items="rang_levels"
                      item-text="text"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" class="pa-1">
                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.date_rang_level"
                          label="วันที่สั่งให้มีวิทยฐานะปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_rang_level"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu4 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu4.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="3" class="pa-1">
                    <v-text-field
                      type="number"
                      v-model="editpersonnel_temporary.salary_s"
                      outlined
                      label="เงินเดือนปัจจุบัน"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <hr style="border: 1px solid gray;" />
                  </v-col>

                  <v-col cols="12" md="12" class="font-weight-bold">
                    การปฏิบัติงาน ณ สถานศึกษาบัจจุบัน
                  </v-col>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-text-field
                      outlined
                      label="คำสั่งที่ ปฏิบัติหน้าที่ ณ สถานศึกษาบัจจุบัน ระบุเพียง : 783/2563"
                      v-model="editpersonnel_temporary.order_app_now"
                      required
                      :rules="[v => !!v || '']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.date_app_now"
                          label="วันเดือนเริ่มปฏิบัติงาน ณ สถานศึกษาปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_app_now"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu3 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu3.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid gray;" />
                  </v-col>

                  <v-col cols="12" md="6" class="pa-1">
                    <h4>
                      การปฏิบัติงานในตำแหน่งปัจจุบัน (ครู / รองผู้อำนวยการ /
                      ผู้อำนวยการ)
                    </h4>
                    <v-menu
                      ref="menu5"
                      v-model="menu5"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.date_app_positonnow"
                          label="วันเดือนเริ่มปฏิบัติงาน ใน ตำแหน่งปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_app_positonnow"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu5 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu5.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    class="pa-1"
                    v-if="editpersonnel_temporary.user_status === 'teacher'"
                  >
                    <h4>สาขาวิชาที่สอน/เกี่ยวข้อง/ถนัด</h4>
                    <v-autocomplete
                      outlined
                      label="สาขาวิชาที่สอน/เกี่ยวข้อง/ถนัด"
                      v-model="editpersonnel_temporary.personnel_tem_branch"
                      :items="rate_work_course_stds"
                      item-text="branch_name_th"
                      item-value="id_course_branch"
                      required
                      :rules="[v => !!v || '']"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12" class="pa-1">
                    <h4>
                      การบรรจุ
                    </h4>
                  </v-col>

                  <v-col cols="12" md="6" class="pa-1">
                    <v-select
                      v-model="editpersonnel_temporary.pt_ed_AppType"
                      outlined
                      label="บรรจุรับเงินเดือนในอันดับ ครูผู้ช่วย"
                      :items="pt_ed_AppTypes"
                      item-text="text"
                      item-value="value"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="6" class="pa-1">
                    <v-autocomplete
                      v-model="editpersonnel_temporary.pt_id_branch"
                      outlined
                      label="สาขาวิชาบรรจุ"
                      :items="branch"
                      item-text="name_branch"
                      item-value="id_branch"
                    >
                      <template v-slot:item="{ item }">
                        <div>
                          <div>
                            {{ item.id_branch }} : {{ item.name_branch }}
                          </div>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid gray;" />
                  </v-col>

                  <v-col cols="12" md="12" class="font-weight-bold">
                    การสำเร็จการศึกษา ปริญญาโท สายตรง (ข้าราชการครู)
                  </v-col>

                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field
                      v-model="editpersonnel_temporary.pt_ed_UpMaster"
                      outlined
                      label="ชื่อวุฒิการศึกษา ปริญญาโท : คอม.วิศวกรรมระบบไฟฟ้ากำลัง"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="pa-1">
                    <v-file-input
                      v-model="pt_ed_UpMasterFile"
                      accept=".pdf"
                      name="pt_ed_UpMasterFile"
                      color="deep-purple accent-4"
                      counter
                      label="ปริญญาโท/หนังสือรับรอง/Transcript .pdf"
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :rules="rules"
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-menu
                      ref="menu10"
                      v-model="menu10"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="
                            editpersonnel_temporary.pt_ed_UpMasterDateTime
                          "
                          label="วันเดือนปีที่สำเร็จการศึกษาระดับ ปริญญาโท สายตรง"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_ed_UpMasterDateTime"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu10 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu10.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="12" class="font-weight-bold">
                    การสำเร็จการศึกษา ปริญญาเอก สายตรง (ข้าราชการครู)
                  </v-col>

                  <v-col cols="12" md="4" class="pa-1">
                    <v-text-field
                      v-model="editpersonnel_temporary.pt_ed_UpDoctor"
                      outlined
                      label="ชื่อวุฒิการศึกษา ปริญญาเอก : วศ.ด.วิศวกรรมไฟฟ้า"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="pa-1">
                    <v-file-input
                      v-model="pt_ed_UpDoctorFile"
                      accept=".pdf"
                      name="pt_ed_UpDoctorFile"
                      color="deep-purple accent-4"
                      counter
                      label="ปริญญาเอก/หนังสือรับรอง/Transcript .pdf"
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :rules="rules"
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-menu
                      ref="menu11"
                      v-model="menu11"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="
                            editpersonnel_temporary.pt_ed_UpDoctorDatetime
                          "
                          label="วันเดือนปีที่สำเร็จการศึกษาระดับ ปริญญาโท สายตรง"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_ed_UpDoctorDatetime"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu11 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu11.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                fab
                outlined
                @click.stop="editpersonnel_temporarydialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                outlined
                color="success"
                @click.stop="editpersonnel_temporarySubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!--addpersonnel_temporarydialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addpersonnel_temporarydialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-account-multiple"
              title="ขอเพิ่มข้อมูลข้าราชการครูและบุคลากรทางการศึกษา"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-form ref="addpersonnel_temporaryform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        prepend-icon="mdi-account-search"
                        label="รหัสบัตรประชาชน"
                        v-model="addpersonnel_temporary.personalIDcardReq"
                        required
                        :rules="[v => !!v || '']"
                        @input="id_cardSearch()"
                      ></v-text-field>

                      <v-autocomplete
                        :items="j18idPositions"
                        item-text="id_position"
                        item-value="id_position"
                        outlined
                        prepend-icon="mdi-account-search"
                        label="เลขที่ตำแหน่ง ณ สังกัดใหม่"
                        v-model="addpersonnel_temporary.collegeNewIdPosition"
                        required
                        :rules="[v => !!v || '']"
                        @change="id_cardSearch()"
                      >
                        <template v-slot:item="{ item }">
                          <div>
                            <div class="font-weight-bold">
                              {{ item.id_position }} : สำหรับ
                              {{ item.positions }}
                            </div>
                            <div>คนครอง : {{ item.id_card }}</div>
                          </div>
                        </template>
                      </v-autocomplete>

                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-icon="mdi-calendar"
                            outlined
                            v-model="addpersonnel_temporary.collegePersonnelApp"
                            label="วันเดือนปีที่ย้ายมาปฏิบัติงาน ณ สถานศึกษาปัจจุบัน"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addpersonnel_temporary.collegePersonnelApp"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>

                      <v-text-field
                        outlined
                        prepend-icon="mdi-basket-fill"
                        label="คำสั่งให้ปฏิบัติงาน ที่ : "
                        v-model="
                          addpersonnel_temporary.collegePersonnelAppOrder
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>

                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            prepend-icon="mdi-calendar"
                            outlined
                            v-model="
                              addpersonnel_temporary.collegePersonnelAppOrderDate
                            "
                            label="วันที่คำสั่ง"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            addpersonnel_temporary.collegePersonnelAppOrderDate
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>

                    <v-flex md6>
                      <v-card class="mx-auto">
                        <v-list two-line>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-account
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>{{
                                personnel_temporaryschecks.frist_names
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                >ปัจจุบัน :
                                {{
                                  personnel_temporaryschecks.college_name
                                }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>

                          <v-divider inset></v-divider>

                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-clipboard
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>ตำแหน่ง </v-list-item-title>
                              <v-list-item-subtitle
                                >{{
                                  personnel_temporaryschecks.position_name
                                }}
                                - {{ personnel_temporaryschecks.rang_name }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item>
                            <v-list-item-action></v-list-item-action>

                            <v-list-item-content>
                              <v-list-item-title
                                >เลขที่ตำแหน่ง</v-list-item-title
                              >
                              <v-list-item-subtitle>{{
                                personnel_temporaryschecks.id_position
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-divider inset></v-divider>

                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon color="indigo">
                                mdi-map-marker
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>วันที่บรรจุ</v-list-item-title>
                              <v-list-item-subtitle>{{
                                personnel_temporaryschecks.date_app
                              }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="addpersonnel_temporarydialog = false"
                rounded
                ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
              >
              <v-btn
                v-if="
                  personnel_temporaryschecks.id_position &&
                    addpersonnel_temporary.collegePersonnelApp
                "
                large
                color="success"
                @click.stop="addpersonnel_temporarySubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึกคำขอ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model personnel_temporaryNoticesdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="personnel_temporaryNoticesdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-account-edit"
              title="แจ้ง ส่วนกลาง ปรับปรุงข้อมูลบุคลากร"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="personnel_temporaryNoticesdialogform"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12 class="text-center font-weight-bold">
                      {{ personnel_temporarysNotices.id_card }}
                      {{ personnel_temporarysNotices.frist_names }}
                    </v-flex>

                    <v-flex md12>
                      <v-text-field
                        outlined
                        label="คำร้องขอแก้ไข : รายละเอียดแก้ไข"
                        v-model="
                          addpersonnel_temporary_notify.personnel_temporary_notifyDetail
                        "
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                @click.stop="personnel_temporaryNoticesdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                color="warning"
                @click.stop="personnel_temporaryNoticesdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdflicenseteachdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdflicenseteachdialog" max-width="70%">
          <v-card class="" elevation="2">
            <embed
              :src="
                '/HRvecLicenseTeach/' + editpersonnel_temporary.pt_licence_file
              "
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdflicensedrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdflicensedrdialog" max-width="70%">
          <v-card class="" elevation="2">
            <embed
              :src="
                '/HRvecLicenseDirector/' +
                  editpersonnel_temporary.pt_licencedr_file
              "
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addpersonnel_temporarydialog: false,
      editpersonnel_temporarydialog: false,
      deletepersonnel_temporarydialog: false,
      personnel_temporaryNoticesdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      personnel_temporarys: [],
      addpersonnel_temporary: {},
      editpersonnel_temporary: {},
      search: "",
      pagination: {},
      headersReq: [
        { text: "สังกัดเดิม", align: "left", value: "OldCollege" },
        { text: "เลขที่ตำแหน่งเดิม", align: "left", value: "id_position" },
        /* { text: "รหัสบัตรประชาชน", align: "left", value: "id_card" }, */
        { text: "ชื่อ-นามสกุล", align: "left", value: "frist_names" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        {
          text: "เลขที่ตำแหน่งใหม่",
          align: "center",
          value: "collegeNewIdPosition"
        },
        { text: "สาขาวิชา", align: "center", value: "branch_name_th" },
        { text: "วันเดือนปีเกิด", align: "center", value: "brith_day" },
        { text: "วันเดือนปีบรรจุ", align: "center", value: "appoin_day" },
        {
          text: "คำสั่งที่",
          align: "center",
          value: "collegePersonnelAppOrder"
        },
        {
          text: "วันที่ปฏิบัติหน้าที่ ณ สถานศึกษาปัจจุบัน",
          align: "center",
          value: "collegePersonnelAppOrderDate"
        },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
      ],

      headersNotify: [
        /*  { text: "วิทยาลัย", align: "left", value: "personnel_temporary_notifyCollegeCode" }, */
        { text: "ชื่อนามสกุล", align: "left", value: "frist_names" },
        {
          text: "รายละเอียด",
          align: "left",
          value: "personnel_temporary_notifyDetail"
        },
        {
          text: "สถานะ",
          align: "left",
          value: "personnel_temporary_notifyStatus"
        },
        {
          text: "วันที่ร้องขอ",
          align: "left",
          value: "personnel_temporary_notifyDateTIme"
        },
        {
          text: "ดำเนินการ",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        }
      ],

      headers: [],
      selectedHeaders: [],
      headersMap: [
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "แจ้งปรับปรุงข้อมูล",
          align: "center",
          value: "actionNotices",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ดูประวัติ",
          align: "center",
          value: "viewId",
          icon: "mdi-file-document-edit"
        },
       /*  {
          text: "รหัสบัตรประชาชน",
          align: "left",
          value: "id_card",
          class: "my-header-style"
        }, */
        { text: "ชื่อ-นามสกุล", align: "left", value: "frist_names" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        { text: "วิทยฐานะ", align: "center", value: "rang_name" },
        { text: "วันที่วิทยฐานะ", align: "center", value: "date_rang_level" },
        { text: "ระดับ", align: "center", value: "rang_level" },
        { text: "สาขาวิชาบรรจุ", align: "center", value: "name_branch" },
        { text: "สาขาวิชาสอน", align: "center", value: "branch_name_th" },

        { text: "วันเดือนปีเกิด", align: "center", value: "brith_day" },
        { text: "วันเดือนปีบรรจุ", align: "center", value: "appoin_day" },
        { text: "คำสั่งที่", align: "center", value: "order_app_now" },
        {
          text: "วันที่ปฏิบัติหน้าที่ ณ สถานศึกษาปัจจุบัน",
          align: "center",
          value: "date_app_now"
        },
        {
          text: "วันที่ปฏิบัติหน้าที่ ในตำแหน่ง",
          align: "center",
          value: "date_app_positonnow"
        },
        { text: "เกษียณ", align: "center", value: "retrire_year" }
      ],
      rang_names: [
        { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
        { text: "ครู", value: "ครู" },
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],
      rang_levels: [
        { text: "ครูผู้ช่วย", value: "ครูผู้ช่วย" },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      man_powers: [],
      collgegs: [],
      personnel_temporarystatus: [],
      regions: [],
      region_ena: true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menu9: false,
      menu10: false,
      menu11: false,
      data_syslog: {},
      rate_work_course_stds: [],
      personnel_temporaryschecks: [],
      collegepersonnelreqData: {},
      user: [],
      collegepersonnelreqs: [],
      collegepersonnelreqQr: [],
      collegepersonnelreqDel: {},
      j18idPositions: [],
      personnel_temporarysNotices: [],
      addpersonnel_temporary_notify: {},
      delpersonnel_temporary_notify: {},

      personnel_temporarysNoticesQu: [],
      editdelpersonnel_temporary_notify: [],
      data_syslog: {},
      linealerts: {},
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB"
      ],
      license_file: null,
      license_filedr: null,
      pdflicenseteachdialog: false,
      pdflicensedrdialog: false,
      pt_ed_AppTypes: [
        { text: "ปริญญาตรี หลักสูตร 4 ปี", value: "1" },
        { text: "ปริญญาตรี หลักสูตร 5 ปี", value: "2" },
        {
          text:
            "ประกาศนียบัตรบัณฑิต หลักสูตรไม่น้อยกว่า 1 ปี ต่อจาก ป.ตรี 4 ปี",
          value: "3"
        },
        { text: "ปริญญาตรี หลักสูตร 6 ปี", value: "4" }
      ],
      pt_ed_UpDoctorFile: null,
      pt_ed_UpMasterFile: null,
      branch: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.sweetAlertLoading();
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    let resultUser = await this.$http.post("user.php", {
      ApiKey: this.ApiKey,
      user_ID: userSession.user_ID
    });
    this.user = resultUser.data;

    await this.personnel_temporaryQueryAll();
    await this.collegepersonnelreqQueryAll();

    let result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;

    await this.personnel_temporary_notifyQuery();

    this.rate_work_course_stdsQueryAll();
    await this.j18Search();
    await this.branchQuery();
    Swal.close();
  },

  methods: {
    async branchQuery() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branch = result.data;
    },

    async viewpdflicenseteach() {
      this.pdflicenseteachdialog = true;
    },
    async viewpdflicensedr() {
      this.pdflicensedrdialog = true;
    },

    async deletelicensedr() {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "ลบไฟล์ใบอนุญาตประกอบวิชาชีพผู้บริหารเดิม",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecLicenseDirector/" +
              this.editpersonnel_temporary.pt_licencedr_file
          });

          result = await this.$http.post("personnel_temporary.update.php", {
            ApiKey: this.ApiKey,
            pt_licencedr_file: "",
            id_card: this.editpersonnel_temporary.id_card
          });

          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_temporaryQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async deletelicenseteach() {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "ลบไฟล์ใบอนุญาตประกอบวิชาชีพครู เดิม",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecLicenseTeach/" +
              this.editpersonnel_temporary.pt_licence_file
          });

          let resultup = await this.$http.post(
            "personnel_temporary.update.php",
            {
              ApiKey: this.ApiKey,
              pt_licence_file: "",
              id_card: this.editpersonnel_temporary.id_card
            }
          );

          if (resultup.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_temporaryQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async personnel_temporary_notifyDel(personnel_temporary_notifyID) {
      let result = await this.$http.post("personnel_temporary_notify.php", {
        ApiKey: this.ApiKey,
        personnel_temporary_notifyID: personnel_temporary_notifyID
      });
      this.editdelpersonnel_temporary_notify = result.data;
      let personnelApp = this.editdelpersonnel_temporary_notify.frist_names;
      Swal.fire({
        title: "ยกเลิกรายการ",
        text: personnelApp,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.delpersonnel_temporary_notify.ApiKey = this.ApiKey;
          this.delpersonnel_temporary_notify.personnel_temporary_notifyID = this.editdelpersonnel_temporary_notify.personnel_temporary_notifyID;

          let result = await this.$http.post(
            "personnel_temporary_notify.delete.php",
            this.delpersonnel_temporary_notify
          );
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "personnel_temporary_notify";
          this.data_syslog.table_log = "personnel_temporary_notify.delete";
          this.data_syslog.detail_log = this.delpersonnel_temporary_notify.personnel_temporary_notifyID;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);

          if (result.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.personnel_temporary_notifyQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async personnel_temporaryNoticesdialogSubmit() {
      if (this.$refs.personnel_temporaryNoticesdialogform.validate()) {
        let personnelApp = this.personnel_temporarysNotices.frist_names;
        Swal.fire({
          title: "ขอแจ้งปรับปรุงข้อมูลบุคคล",
          text: personnelApp,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก"
        }).then(async result => {
          if (result.isConfirmed) {
            this.addpersonnel_temporary_notify.ApiKey = this.ApiKey;
            this.addpersonnel_temporary_notify.personnel_temporary_notifyCollegeCode = this.personnel_temporarysNotices.college_code;
            this.addpersonnel_temporary_notify.personnel_temporary_notify_IDcard = this.personnel_temporarysNotices.id_card;
            this.addpersonnel_temporary_notify.personnel_temporary_notifyStatus =
              "send";
            this.addpersonnel_temporary_notify.personnel_temporary_notifyDateTIme = this.date_today_log;
            let result = await this.$http.post(
              "personnel_temporary_notify.insert.php",
              this.addpersonnel_temporary_notify
            );

            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "insert";
            this.data_syslog.page_log = "personnel_temporary_notify";
            this.data_syslog.table_log = "personnel_temporary_notify.insert";
            this.data_syslog.detail_log = this.addpersonnel_temporary_notify.personnel_temporary_notify_IDcard;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);

            if (result.data.status == true) {
              Swal.fire({
                title: "ดำเนินการข้อมูลเรียบร้อย",
                icon: "success",
                showConfirmButton: false,
                timer: 1500
              });

              this.linealerts.ApiKey = this.ApiKey;
              this.linealerts.college_name = this.user.college_name;
              this.linealerts.details =
                "งานบุคคล : แจ้งแก้ไข " +
                this.addpersonnel_temporary_notify
                  .personnel_temporary_notify_IDcard;
              this.linealerts.datetime = this.date_today;
              let result_line = await this.$http.post(
                "linenotify_hr.php",
                this.linealerts
              );

              await this.personnel_temporary_notifyQuery();
            } else {
              Swal.fire({
                icon: "warning",
                title: "ผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
            }
          }
        });
        this.personnel_temporaryNoticesdialog = false;
      }
    },
    async personnel_temporary_notifyQuery() {
      let result = await this.$http.post("personnel_temporary_notify.php", {
        ApiKey: this.ApiKey,
        personnel_temporary_notifyCollegeCode: this.user.user_code
      });
      this.personnel_temporarysNoticesQu = result.data;
    },

    async personnel_temporary_notifyEdit(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.personnel_temporarysNotices = result.data;
      this.personnel_temporaryNoticesdialog = true;
    },

    async CancelReq(personalIDcardReq) {
      let result = await this.$http.post("collegepersonnelreq.php", {
        ApiKey: this.ApiKey,
        personalIDcardReq: personalIDcardReq
      });
      this.collegepersonnelreqQr = result.data;
      let personnelApp = this.collegepersonnelreqQr.frist_names;
      Swal.fire({
        title: "อนุมัติย้ายข้อมูลบุคคล",
        text: personnelApp,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.collegepersonnelreqDel.ApiKey = this.ApiKey;
          this.collegepersonnelreqDel.personalIDcardReq = this.collegepersonnelreqQr.id_card;
          let resultDel_Preq = await this.$http.post(
            "collegepersonnelreq.delete.php",
            this.collegepersonnelreqDel
          );
          if (resultDel_Preq.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
            this.data_syslog.ApiKey = this.ApiKey;
            this.data_syslog.user_account = userSession.user_name;
            this.data_syslog.event_log = "delete";
            this.data_syslog.page_log = "collegepersonnelreq";
            this.data_syslog.table_log = "collegepersonnelreq.delete";
            this.data_syslog.detail_log = this.collegepersonnelreqDel.id_card;
            this.data_syslog.date_times = this.date_today_log;
            await this.$http.post("data_syslog.insert.php", this.data_syslog);

            await this.personnel_temporaryQueryAll();
            await this.collegepersonnelreqQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async addpersonnel_temporarySubmit() {
      if (this.$refs.addpersonnel_temporaryform.validate()) {
        this.addpersonnel_temporary.ApiKey = this.ApiKey;
        this.addpersonnel_temporary.collegeNewReq = this.user.user_code;
        this.addpersonnel_temporary.collegeOleCode = this.personnel_temporaryschecks.college_code;
        this.addpersonnel_temporary.collegeRegDatetime = this.date_today_log;
        this.addpersonnel_temporary.collegeRegStatus = "req";
        let result = await this.$http.post(
          "collegepersonnelreq.insert.php",
          this.addpersonnel_temporary
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.collegepersonnelreqQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "insert";
          this.data_syslog.page_log = "collegepersonnelreq";
          this.data_syslog.table_log = "collegepersonnelreq.insert";
          this.data_syslog.detail_log = this.addpersonnel_temporary.id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.addpersonnel_temporarydialog = false;
      }
    },

    async id_cardSearch() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: this.addpersonnel_temporary.personalIDcardReq
      });
      this.personnel_temporaryschecks = result.data;
    },

    async j18Search() {
      let result = await this.$http.post("jor18.php", {
        ApiKey: this.ApiKey,
        j18college_code: this.user.user_code
      });
      this.j18idPositions = result.data;
    },

    async personnel_temporaryAdd() {
      this.addpersonnel_temporary = {};
      this.addpersonnel_temporarydialog = true;
    },

    async rate_work_course_stdsQueryAll() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey
      });
      this.rate_work_course_stds = result.data;
    },

    async OnEnter() {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        frist_name: this.search
      });
      this.personnel_temporarys = result.data;
    },

    async man_powerQuery() {
      let man_power_result;
      man_power_result = await this.$http.post("man_power.php", {
        ApiKey: this.ApiKey,
        college_code: this.addpersonnel_temporary.college_code
      });
      this.man_powers = man_power_result.data;
    },

    async personnel_temporaryQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          user_name: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },

    async collegepersonnelreqQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("collegepersonnelreq.php", {
          ApiKey: this.ApiKey,
          collegeNewReq: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.collegepersonnelreqs = result.data;
    },

    async personnel_temporaryEdit(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.editpersonnel_temporary.p_word = "";
      this.editpersonnel_temporarydialog = true;
    },

    async editpersonnel_temporarySubmit() {
      if (this.$refs.editpersonnel_temporaryform.validate()) {
        this.editpersonnel_temporary.ApiKey = this.ApiKey;
        let uploaded = null;
        if (this.license_file != null) {
          let formData = new FormData();
          let filename =
            this.editpersonnel_temporary.id_card +
            "" +
            this.time_stamp +
            "" +
            "teach.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.license_file);
          formData.append("filename", "../HRvecLicenseTeach/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.editpersonnel_temporary.pt_licence_file = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }

        if (this.license_filedr != null) {
          let formData = new FormData();
          let filename =
            this.editpersonnel_temporary.id_card +
            "" +
            this.time_stamp +
            "" +
            "dr.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.license_filedr);
          formData.append("filename", "../HRvecLicenseDirector/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.editpersonnel_temporary.pt_licencedr_file = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }

        if (this.pt_ed_UpMasterFile != null) {
          let formData = new FormData();
          let filename =
            this.editpersonnel_temporary.id_card +
            "" +
            this.time_stamp +
            "" +
            "teach.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.pt_ed_UpMasterFile);
          formData.append("filename", "../HRvecLicenseTeach/" + filename);
          let resultTeach = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.editpersonnel_temporary.pt_ed_UpMasterFile = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }

        if (this.pt_ed_UpDoctorFile != null) {
          let formData = new FormData();
          let filename =
            this.editpersonnel_temporary.id_card +
            "" +
            this.time_stamp +
            "" +
            "dr.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.pt_ed_UpDoctorFile);
          formData.append("filename", "../HRvecLicenseDirector/" + filename);
          let resultDir = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.editpersonnel_temporary.pt_ed_UpDoctorFile = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }

        if (this.editpersonnel_temporary.p_word == "")
          delete this.editpersonnel_temporary.p_word;

        let result = await this.$http.post(
          "personnel_temporary.update.admin.php",
          this.editpersonnel_temporary
        );

        if (result.data.status == true) {
          this.personnel_temporary = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "personnel";
          this.data_syslog.table_log = "personnel_temporary";
          this.data_syslog.detail_log = this.editpersonnel_temporary.id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editpersonnel_temporarydialog = false;
      }
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    p_word() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let password = dd + "/" + mm + "/" + yyyy;
      return password;
    },
    brith_day() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let dd = parseInt(String(today.getDate()).padStart(2, "0"));
      return dd;
    },
    brith_month() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let mm = parseInt(String(today.getMonth() + 1).padStart(2, "0"));
      return mm;
    },
    brith_year() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let yyyy = today.getFullYear() + 543;
      return yyyy;
    },

    appoin_day() {
      let today = new Date(
        this.addpersonnel_temporary.date_app_now ||
          this.editpersonnel_temporary.date_app_now
      );
      let dd = parseInt(String(today.getDate()).padStart(2, "0"));
      return dd;
    },
    appoin_month() {
      let today = new Date(
        this.addpersonnel_temporary.date_app_now ||
          this.editpersonnel_temporary.date_app_now
      );
      let mm = parseInt(String(today.getMonth() + 1).padStart(2, "0")); //January is 0!
      return mm;
    },
    appoin_year() {
      let today = new Date(
        this.addpersonnel_temporary.date_app_now ||
          this.editpersonnel_temporary.date_app_now
      );
      let yyyy = today.getFullYear() + 543;
      return yyyy;
    },
    date_today_cal() {
      let today = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },

    retrire_year() {
      let mm = this.brith_month;
      let yy_retire = this.brith_year;
      let result;
      if (mm > 9) {
        result = yy_retire + 61;
      } else {
        result = yy_retire + 60;
      }
      return result;
    },
    cal_date_age() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(
        this.addpersonnel_temporary.birthday ||
          this.editpersonnel_temporary.birthday
      );

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },

    cal_date_age_gov() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(
        this.addpersonnel_temporary.date_app_now ||
          this.editpersonnel_temporary.date_app_now
      );

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  }
};
</script>
<style scoped>
.my-header-style {
  background: #f8bbd0;
  color: white;
}
</style>
