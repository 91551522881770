<template>
  <div>
    <base-material-card
      icon="mdi-clipboard-text"
      title="การเสนอคณะกรรมการประเมิน ตำแหน่งครูผู้ช่วย"
      class="px-5 py-3"
    >
      <!-- College committee CC -->

      <v-card outlined>
        <h3 class="pa-2 red--text">
          <v-icon color="red">mdi-alert</v-icon> ยกเลิก
          เสนอรายชื่อกรรมการประเมิน
        </h3>
        <v-data-table
          :headers="headerscommitteecc"
          :items="committeCC"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:item.action_s="{ item }">
            <div>
              <v-icon
                color="red"
                @click.stop="manage_assistant_teacherDelete(item.mt_id)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
          <template v-slot:item.mt_admissions_status="{ item }">
            <v-chip
              dark
              color="red"
              v-if="item.mt_admissions_status === 'missing'"
            >
              <v-icon>mdi-information</v-icon>ไม่มารายงานตัว
            </v-chip>
            <v-chip
              dark
              color="primary"
              v-if="item.mt_admissions_status === 'receipt'"
            >
              <v-icon>mdi-information</v-icon>มารายงานตัวปกติ
            </v-chip>
          </template>

          <template v-slot:item.mt_date_app_now="{ item }">
            <div>
              {{
                item.mt_date_app_now
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.mt_committe_status="{ item }">
            <div v-if="item.mt_committe_status === 'CC'">
              <v-chip @click="updateCommittee(item.mt_id)" color="red" dark>
                <v-icon>mdi-information-outline</v-icon>
                แจ้งรายงานข้อมูลผิดพลาด</v-chip
              >
            </div>
            <div v-else-if="item.mt_committe_status === 'CF'">
              <v-chip color="success" dark>
                <v-icon>mdi-information-outline</v-icon>
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_committe_file="{ item }">
            <div v-if="item.mt_committe_file">
              <v-btn
                @click="viewpdfmt_committe_file(item.mt_committe_file)"
                color="info"
                fab
                small
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-icon color="red">mdi-alert</v-icon>
            </div>
          </template>

          <template v-slot:item.mt_committe_datetime="{ item }">
            <div v-if="item.mt_committe_datetime">
              {{
                item.mt_committe_datetime
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY : HH:mm:ss a")
              }}
            </div>
            <div v-else>
              <v-icon color="red">mdi-alert</v-icon>
            </div>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </v-card>

      <!-- College pertem_assistantFileCC -->

      <v-card>
        <h3 class="pa-2 red--text">
          <v-icon color="red">mdi-alert</v-icon> ยกเลิก
          แนบคำสั่งแต่งตั้งกรรมการประเมิน
        </h3>

        <v-data-table
          :headers="headerspertem_assistantFileCC"
          :items="manage_assistant_teacherscc"
          class="elevation-1"
          :loading="loading"
        >
          <template v-slot:item.mt_date_app_now="{ item }">
            <div>
              {{
                item.mt_date_app_now
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.pertem_assistantFileCC="{ item }">
            <div v-if="item.pertem_assistantFileCC === 'CC'">
              <v-chip @click="updateAssisstant(item.mt_id)" color="red" dark>
                <v-icon>mdi-information-outline</v-icon>
                แจ้งขอยกเลิกไฟล์</v-chip
              >
            </div>
            <div v-else-if="item.pertem_assistantFileCC === 'CF'">
              <v-chip color="success" dark>
                <v-icon>mdi-information-outline</v-icon>
              </v-chip>
            </div>
          </template>

          <template v-slot:item.pertem_assistantFile="{ item }">
            <div v-if="item.pertem_assistantFile">
              <v-btn
                @click="viewfilePDF(item.pertem_assistantFile)"
                color="info"
                fab
                small
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
            <div v-else>
              <v-icon color="red">mdi-alert</v-icon>
            </div>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </v-card>

      <!--  -->
      <v-card class="mb-4 pa-2">
        <v-row no-gutters>
          <v-col cols="12" md="12" class="pa-1">
            <div class="text-right">
              <v-btn
                :href="
                  '#/admin/print_assistantcommittee/?mt_appoin_day=' +
                    mt_appoin_day +
                    '&mt_appoin_month=' +
                    mt_appoin_month +
                    '&mt_appoin_year=' +
                    mt_appoin_year
                "
                target="_blank"
                rounded
                color="info"
              >
                <v-icon>mdi-printer</v-icon> พิมพ์รายชื่อกรรมการประเมิน (1.1)
                รวม</v-btn
              >

              <v-btn
                :href="
                  '#/admin/print_assistantcommittee_vip/?mt_appoin_day=' +
                    mt_appoin_day +
                    '&mt_appoin_month=' +
                    mt_appoin_month +
                    '&mt_appoin_year=' +
                    mt_appoin_year
                "
                target="_blank"
                rounded
                color="info"
              >
                <v-icon>mdi-printer</v-icon> พิมพ์รายชื่อผู้่ทรงคุณวุฒิ (2.1)
                รวม</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-center">จำนวนทั้งสิ้น</th>
              <th class="text-center">เสนอกรรมการ</th>
              <th class="text-center">แนบไฟล์เสนอ</th>
              <th class="text-center">ขอยกเลิกรายการ</th>
              <th class="text-center">ยังไม่ได้ดำเนินการ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center">
                <v-btn
                  @click="manage_assistant_teacherQueryTimeYear()"
                  fab
                  color="info"
                  >{{ dashboardShow.count_teach_alls }}
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn
                  @click="
                    manage_assistant_teacherShow((mt_committe_status = 'CF'))
                  "
                  fab
                  color="warning"
                  >{{ dashboardShow.count_teach_cfs }}
                </v-btn>
              </td>

              <td class="text-center">
                <v-btn
                  @click="
                    manage_assistant_teacherShow((mt_committe_status = 'CK'))
                  "
                  fab
                  color="success"
                  >{{ dashboardShow.mt_committe_files }}
                </v-btn>
              </td>

              <td class="text-center">
                <v-btn
                  @click="
                    manage_assistant_teacherShow((mt_committe_status = 'CC'))
                  "
                  fab
                  color="red"
                  >{{ dashboardShow.count_teach_ccs }}
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn
                  @click="
                    manage_assistant_teacherShow((mt_committe_status = 'null'))
                  "
                  fab
                  color="warning"
                  >{{ dashboardShow.count_teach_nulls }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>

      <v-data-table
        :headers="showHeaders"
        :items="manage_assistant_teachers"
        class="elevation-1"
        :loading="loading"
        :search="search"
      >
        <template v-slot:top>
          <div>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง หรือ กรอกรหัสบัตรประชาชน และ กด Enter"
              filled
              @keyup="manage_assistant_teacherid"
            />
          </div>
          <div>
            <v-select
              v-model="selectedHeaders"
              :items="headers"
              label="เลือกคอลัมน์ที่ต้องการแสดง"
              multiple
              outlined
              return-object
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index < 6">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 6" class="grey--text caption"
                  >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
                >
              </template>
            </v-select>
          </div>
        </template>
        <template v-slot:item="{ item, index }">
          <tr>
            <td class="text-center">
              {{ index + 1 }}
            </td>
            <td class="text-center">
              {{ item.college_code }}
            </td>
            <td class="text-center">
              {{ item.college_name }}
            </td>
            <td class="text-center">
                    {{ item.province_name }}
                  </td>
            <td class="text-center">
              {{ item.region_name }}
            </td>

            <td class="text-center">
              {{ item.mt_id_card }}
            </td>
            <td class="text-center">
              {{ item.mt_title_s }}
            </td>
            <td class="text-center">
              {{ item.mt_frist_name }}
            </td>
            <td class="text-center">
              {{ item.mt_last_name }}
            </td>
            <td class="text-center">
              <v-btn
                v-if="item.mt_committe_ipaapprove === 'pass'"
                fab
                small
                color="success"
                @click.stop="updatemanage_assistant_teacher(item.mt_id)"
                ><v-icon>mdi-update</v-icon></v-btn
              >
              <v-btn
                v-else-if="item.mt_committe_ipaapprove === 'dontpass'"
                fab
                small
                color="red"
                @click.stop="updatemanage_assistant_teacher(item.mt_id)"
                ><v-icon>mdi-update</v-icon></v-btn
              >

              <v-btn
                v-else
                fab
                small
                color="warning"
                @click.stop="updatemanage_assistant_teacher(item.mt_id)"
                ><v-icon>mdi-update</v-icon></v-btn
              >
            </td>

            <td class="text-center">
              {{ item.mt_committe_ipaapprove_detail }}
            </td>
            <td class="text-center">
              <div v-if="item.mt_committe_status === 'CC'">
                <v-chip @click="updateCommittee(item.mt_id)" color="red" dark>
                  <v-icon>mdi-information-outline</v-icon>
                  แจ้งรายงานข้อมูลผิดพลาด</v-chip
                >
              </div>
              <div v-else-if="item.mt_committe_status === 'CF'">
                <v-icon color="success"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
              </div>
              <div v-else>
                <v-icon color="red">mdi-alert</v-icon>
              </div>
            </td>

            <td class="text-center">
              {{ item.mt_appoin }}
            </td>
            <td class="text-center">
              <div v-if="item.mt_committe_ipaapprove === 'pass'">
                <v-icon size="25" color="success">mdi-check-circle</v-icon>
              </div>
              <div v-if="item.mt_committe_file">
                <v-btn
                  @click="viewpdfmt_committe_file(item.mt_committe_file)"
                  color="info"
                  fab
                  small
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-icon color="red">mdi-alert</v-icon>
              </div>
            </td>

            <td class="text-center">
              <div v-if="item.pertem_assistantFile_ipacheck === 'pass'">
                <v-icon size="25" color="success">mdi-check-circle</v-icon>
              </div>

              <div v-if="item.pertem_assistantFile">
                <v-btn
                  @click="viewfilePDF(item.pertem_assistantFile)"
                  color="info"
                  fab
                  small
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <v-icon color="red">mdi-alert</v-icon>
              </div>
            </td>

            <td class="text-center">
              <div v-if="item.mt_committe_datetime">
                {{
                  item.mt_committe_datetime
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY : HH:mm:ss a")
                }}
              </div>
              <div v-else>
                <v-icon color="red">mdi-alert</v-icon>
              </div>
            </td>
          </tr>
        </template>

        <v-alert slot="no-results" :value="true" color="error" icon="warning">
          Your search for "{{ search }}" found no results.
        </v-alert>
      </v-data-table>

      <!-- Query Normal -->
    </base-material-card>

    <!-- V-model pdfmt_committe_filedialog -->
    <v-layout row justify-center>
      <v-dialog v-model="pdfmt_committe_filedialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRcommitteeAssistantTeach/' + pdf_files"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- V-model pertem_assistantFiledialog -->
    <v-layout row justify-center>
      <v-dialog v-model="pertem_assistantFiledialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRvecfiles/' + pdf_files"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>

    <v-dialog
      v-model="editmanage_assistant_teacherdialog"
      persistent
      max-width="60%"
    >
      <v-card class="mx-auto pa-6">
        <base-material-card
          color="success"
          icon="mdi-clipboard-text"
          title="ตรวจสอบรายการ"
          class="px-5 py-3 text_google"
        ></base-material-card>
        <v-card-text>
          <v-form ref="editmanage_assistant_teacherform" lazy-validation>
            <v-container grid-list-md>
              <div class="my-4">
                <h3>
                  สถานศึกษา :
                  {{ editmanage_assistant_teacher.college_name }}
                </h3>
                รหัสบัตรประชาชน :
                {{ editmanage_assistant_teacher.mt_id_card }} <br />
                ชื่อ-นามสกุล :
                {{ editmanage_assistant_teacher.frist_names }}
                <br />
                วันที่บรรจุ :
                {{ editmanage_assistant_teacher.mt_date_app_now }} <br />
                คำสั่งที่บรรจุ :
                {{ editmanage_assistant_teacher.mt_order_app }}
              </div>

              <v-card outlined class="pa-1">
                <v-row>
                  <v-col cols="12" md="6" class="text-center">
                    <span class="font-weight-bold"
                      >ไฟล์เสนอรายชื่อกรรมการประเมินฯ :</span
                    >
                    <div v-if="editmanage_assistant_teacher.mt_committe_file">
                      <v-btn
                        @click="
                          viewpdfmt_committe_file(
                            editmanage_assistant_teacher.mt_committe_file
                          )
                        "
                        color="info"
                        fab
                        small
                      >
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-icon color="red">mdi-alert</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-radio-group
                      v-model="
                        editmanage_assistant_teacher.mt_committe_ipaapprove
                      "
                      row
                    >
                      <template v-slot:label>
                        <div><strong>สถานะการตรวจสอบ</strong></div>
                      </template>
                      <v-radio value="pass">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">ถูกต้อง/ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="dontpass">
                        <template v-slot:label>
                          <div>
                            <strong class="error--text"
                              >ไม่ถูกต้อง/ไม่ผ่าน</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    v-if="
                      editmanage_assistant_teacher.mt_committe_ipaapprove ===
                        'dontpass'
                    "
                  >
                    <v-textarea
                      outlined
                      label="เหตุผล"
                      v-model="
                        editmanage_assistant_teacher.mt_committe_ipaapprove_detail
                      "
                      required
                      :rules="[v => !!v || '']"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>

              <v-card outlined class="pa-1">
                <v-row>
                  <v-col cols="12" md="6" class="text-center">
                    <span class="font-weight-bold">ไฟล์คำสั่งแต่งตั้ง :</span>
                    <div
                      v-if="editmanage_assistant_teacher.pertem_assistantFile"
                    >
                      <v-btn
                        @click="
                          viewpdfpertem_assistantFile(
                            editmanage_assistant_teacher.pertem_assistantFile
                          )
                        "
                        color="info"
                        fab
                        small
                      >
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-icon color="red">mdi-alert</v-icon>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-radio-group
                      v-model="
                        editmanage_assistant_teacher.pertem_assistantFile_ipacheck
                      "
                      row
                    >
                      <template v-slot:label>
                        <div><strong>สถานะการตรวจสอบ</strong></div>
                      </template>
                      <v-radio value="pass">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">ถูกต้อง/ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="dontpass">
                        <template v-slot:label>
                          <div>
                            <strong class="error--text"
                              >ไม่ถูกต้อง/ไม่ผ่าน</strong
                            >
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    v-if="
                      editmanage_assistant_teacher.pertem_assistantFile_ipacheck ===
                        'dontpass'
                    "
                  >
                    <v-textarea
                      outlined
                      label="เหตุผล"
                      v-model="
                        editmanage_assistant_teacher.pertem_assistantFile_ipacheck_detail
                      "
                      required
                      :rules="[v => !!v || '']"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
            <small>* จำเป็น</small>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            large
            @click.stop="editmanage_assistant_teacherdialog = false"
            rounded
            outlined
          >
            <v-icon dark>mdi-close</v-icon> ปิด
          </v-btn>
          <v-btn
            large
            color="success"
            @click.stop="editmanage_assistant_teacherSubmit()"
            rounded
          >
            <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- V-model deletemanage_assistant_teacherdialog -->
    <v-layout>
      <v-dialog
        v-model="deletemanage_assistant_teacherdialog"
        persistent
        max-width="60%"
      >
        <v-card class="mx-auto pa-5">
          <base-material-card
            color="error"
            icon="mdi-delete"
            title="ลบรายการข้อมูลครูผู้ช่วย รายงานตัว"
            class="px-5 py-3"
          >
          </base-material-card>

          <v-card-text class="text_google">
            <v-card>
              <v-card-text>
                <v-form
                  ref="deletemanage_assistant_teacherform"
                  lazy-validation
                >
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        ยืนยันการลบข้อมูล <br />
                        {{
                          editmanage_assistant_teacher.mt_id_card +
                            " : " +
                            editmanage_assistant_teacher.mt_title_s +
                            editmanage_assistant_teacher.mt_frist_name +
                            " : " +
                            editmanage_assistant_teacher.mt_last_name
                        }}
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              outlined
              color="grey"
              @click.stop="deletemanage_assistant_teacherdialog = false"
              ><v-icon dark>mdi-close</v-icon> ปิด
            </v-btn>
            <v-btn
              large
              color="red darken-3"
              @click.stop="deletemanage_assistant_teacherSubmit()"
              dark
            >
              <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <!-- V-model pdffileviewDialog -->
    <v-layout row justify-center>
      <v-dialog v-model="pdffileviewDialog" max-width="80%">
        <v-card class="" elevation="2">
          <embed
            :src="'/HRvecfiles/' + pdf_files"
            width="100%"
            height="700px"
          />
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "HRvecManageAssistantTeacherCommittee",
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      search: "",
      college_select: "",
      singleSelect: false,
      deletemanage_assistant_teacherdialog: false,
      pertem_assistantFiledialog: false,
      pdffileviewDialog: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "ลำดับ", align: "left", value: "index" },
        { text: "รหัสสถานศึกษา", align: "left", value: "college_code" },
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ภาค", align: "left", value: "region_name" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "mt_id_card" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "สกุล", align: "left", value: "mt_last_name" },        
        { text: "ดำเนินการ", align: "center", value: "actions" },
        {
          text: "เหตุผลการยกเลิก",
          align: "center",
          value: "mt_committe_ipaapprove_detail"
        },
        {
          text: "สถานะกรรมการ",
          align: "center",
          value: "mt_committe_status"
        },
        {
          text: "วันที่บรรจุ",
          align: "center",
          value: "mt_appoin"
        },

        {
          text: "เสนอกรรมการ",
          align: "center",
          value: "mt_committe_file"
        },

        {
          text: "คำสั่งแต่งตั้ง",
          align: "center",
          value: "pertem_assistantFile"
        },
        {
          text: "วันที่ทำรายการ",
          align: "center",
          value: "mt_committe_datetime"
        }
      ],
      headerscommitteecc: [
        { text: "รหัสสถานศึกษา", align: "left", value: "mt_college_code" },
        { text: "สถานศึกษา", align: "left", value: "agency" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "mt_id_card" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "สกุล", align: "left", value: "mt_last_name" },
        {
          text: "แจ้ง",
          align: "center",
          value: "mt_committe_status"
        },
        {
          text: "วันที่บรรจุ",
          align: "center",
          value: "mt_date_app_now"
        },
        {
          text: "หมายเหตุ",
          align: "center",
          value: "mt_collegeCC_datail"
        },

        {
          text: "เอกสารแนบ",
          align: "center",
          value: "mt_committe_file"
        },
        {
          text: "วันที่ทำรายการ",
          align: "center",
          value: "mt_committe_datetime"
        }
      ],

      headerspertem_assistantFileCC: [
        { text: "รหัสสถานศึกษา", align: "left", value: "mt_college_code" },
        { text: "สถานศึกษา", align: "left", value: "agency" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "mt_id_card" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "สกุล", align: "left", value: "mt_last_name" },

        {
          text: "วันที่บรรจุ",
          align: "center",
          value: "mt_date_app_now"
        },
        {
          text: "สถานะ",
          align: "center",
          value: "pertem_assistantFileCC"
        },

        {
          text: "เนื่องจาก",
          align: "center",
          value: "pertem_assistantFileCC_detail"
        },

        {
          text: "เอกสารแนบ",
          align: "center",
          value: "pertem_assistantFile"
        }
      ],

      group_mt_years: [],
      group_mt_times: [],
      manage_assistant_teacherscc: [],
      manage_assistant_teachers: [],
      dateTimeAppSelect: "",
      dateYearAppSelect: "",
      dashboardShow: [],
      editmanage_assistant_teacher: [],
      pdf_files: [],
      pdfmt_committe_filedialog: false,
      editPersonnel: {},
      editmanage_assistant_teacherdialog: false,
      data_syslog: {},
      committeCC: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.checkParamQuery();

    await this.committeCCQueryAll();
    await this.pertem_assis_teachASCCQueryAll();
  },
  methods: {
    async viewfilePDF(file) {
      this.pdf_files = file;
      this.pdffileviewDialog = true;
    },

    async deletemanage_assistant_teacherSubmit() {
      if (this.$refs.deletemanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "manage_assistant_teacher.delete.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "manage_assistant_teacher";
          this.data_syslog.table_log = "manage_assistant_teacher";
          this.data_syslog.detail_log =
            this.editmanage_assistant_teacher.id_position +
            " : " +
            this.editmanage_assistant_teacher.college_code +
            " : " +
            this.editmanage_assistant_teacher.case_vacancy;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.pertem_assis_teachASCCQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletemanage_assistant_teacherdialog = false;
      }
    },

    async manage_assistant_teacherDelete(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.deletemanage_assistant_teacherdialog = true;
    },

    async checkParamQuery() {
      let appoin_day = this.mt_appoin_day;
      let appoin_month = this.mt_appoin_month;
      let appoin_year = this.mt_appoin_year;
      if (appoin_year > 0 && appoin_month > 0 && appoin_day) {
        await this.manage_assistant_teacherQueryTimeYear();
      } else {
      }
    },

    async editmanage_assistant_teacherSubmit() {
      this.editmanage_assistant_teacher.ApiKey = this.ApiKey;

      if (
        this.editmanage_assistant_teacher.mt_committe_ipaapprove == "dontpass"
      ) {
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRcommitteeAssistantTeach/" +
            this.editmanage_assistant_teacher.mt_committe_file
        });

        this.editmanage_assistant_teacher.mt_committe_file = "";
        this.editmanage_assistant_teacher.mt_committe_status = "";
      } else {
        this.editmanage_assistant_teacher.mt_committe_ipaapprove_detail = "";
      }

      if (
        this.editmanage_assistant_teacher.pertem_assistantFile_ipacheck ==
        "dontpass"
      ) {
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" +
            this.editmanage_assistant_teacher.pertem_assistantFile
        });

        this.editmanage_assistant_teacher.pertem_assistantFile = "";
        this.editmanage_assistant_teacher.pertem_assistantFileCC = "";
        this.editmanage_assistant_teacher.pertem_assistantFileCC_detail = "";
      } else {
        this.editmanage_assistant_teacher.pertem_assistantFile_ipacheck_detail =
          "";
      }

      let result = await this.$http.post(
        "manage_assistant_teacher.update.php",
        this.editmanage_assistant_teacher
      );
      if (result.data.status == true) {
        this.manage_assistant_teacher = result.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        await this.checkParamQuery();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.editmanage_assistant_teacherdialog = false;
    },
    async updatemanage_assistant_teacher(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.editmanage_assistant_teacherdialog = true;
    },
    async viewpdfmt_committe_file(mt_committe_file) {
      this.pdf_files = mt_committe_file;

      this.pdfmt_committe_filedialog = true;
    },

    async viewpdfpertem_assistantFile(pertem_assistantFile) {
      this.pdf_files = pertem_assistantFile;

      this.pertem_assistantFiledialog = true;
    },

    async pertem_assis_teachASCCQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          pertem_assistantFileCC: "CC"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teacherscc = result.data;
    },

    async committeCCQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_committe_status: "CC"
        })
        .finally(() => (this.loading = false));
      this.committeCC = result.data;
    },

    async updateAssisstant(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      let textAlert =
        this.editmanage_assistant_teacher.mt_title_s +
        this.editmanage_assistant_teacher.mt_frist_name +
        " " +
        this.editmanage_assistant_teacher.mt_last_name;
      Swal.fire({
        title:
          "ต้องการอนุมัติการขอยกเลิก แนบไฟล์คำสั่งแต่งตั้งคณะกรรมการประเมิน ?",
        text: textAlert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.editmanage_assistant_teacher.ApiKey = this.ApiKey;

          result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.editmanage_assistant_teacher.pertem_assistantFile
          });

          this.editmanage_assistant_teacher.pertem_assistantFile = "";
          this.editmanage_assistant_teacher.pertem_assistantFileCC = "";

          let result = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.editmanage_assistant_teacher
          );

          if (result.data.status == true) {
            this.manage_assistant_teacher = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });

            await this.pertem_assis_teachASCCQueryAll();
            await this.manage_assistant_teacherQueryTimeYear();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async updateCommittee(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      let textAlert =
        this.editmanage_assistant_teacher.mt_title_s +
        this.editmanage_assistant_teacher.mt_frist_name +
        " " +
        this.editmanage_assistant_teacher.mt_last_name;
      Swal.fire({
        title: "ต้องการอนุมัติการขอยกเลิกรายการ ?",
        text: textAlert,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.editmanage_assistant_teacher.ApiKey = this.ApiKey;

          result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRcommitteeAssistantTeach/" +
              this.editmanage_assistant_teacher.mt_committe_file
          });

          this.editmanage_assistant_teacher.mt_collegeCC = "";
          this.editmanage_assistant_teacher.mt_committe_status = "";
          this.editmanage_assistant_teacher.mt_committe_file = "";
          this.editmanage_assistant_teacher.mt_committe_datetime = "";
          this.editmanage_assistant_teacher.mt_committe_ipaapprove = "";
          this.editmanage_assistant_teacher.mt_committe_ipaapprove_detail = "";
          let result = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.editmanage_assistant_teacher
          );

          if (result.data.status == true) {
            this.manage_assistant_teacher = result.data;
            Swal.fire({
              icon: "success",
              title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });

            await this.committeCCQueryAll();
            await this.manage_assistant_teacherQueryTimeYear();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async manage_assistant_teacherShow(mt_committe_status) {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_appoin_day: this.mt_appoin_day,
          mt_appoin_month: this.mt_appoin_month,
          mt_appoin_year: this.mt_appoin_year,
          mt_admissions_status: "receipt",
          mt_committe_status: mt_committe_status,
          showSystem: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async manage_assistant_teacherid(e) {
      if (e.keyCode == 13) {
        this.loading = true;
        let result = await this.$http
          .post("manage_assistant_teacher.php", {
            ApiKey: this.ApiKey,
            mt_id_card: this.search,
            keyenter: "ok"
          })
          .finally(() => (this.loading = false));
        this.manage_assistant_teachers = result.data;
      }
    },

    async manage_assistant_teacherQueryTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_appoin_day: this.mt_appoin_day,
          mt_appoin_month: this.mt_appoin_month,
          mt_appoin_year: this.mt_appoin_year,
          mt_admissions_status: "receipt",
          showSystem: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
      let data = this.manage_assistant_teachers;
      let count_teach_alls = 0;
      let count_teach_ccs = 0;
      let count_teach_nulls = 0;
      let count_teach_cfs = 0;
      let mt_committe_files = 0;
      data.forEach(async value => {
        if (value.mt_admissions_status == "receipt") {
          count_teach_alls += 1;
        }

        if (value.mt_committe_status == "CF") {
          count_teach_cfs += 1;
        } else if (value.mt_committe_status == "CC") {
          count_teach_ccs += 1;
        } else {
          count_teach_nulls += 1;
        }

        if (value.mt_committe_file) {
          mt_committe_files += 1;
        }
      });
      this.dashboardShow.count_teach_alls = count_teach_alls;
      this.dashboardShow.count_teach_ccs = count_teach_ccs;
      this.dashboardShow.count_teach_nulls = count_teach_nulls;
      this.dashboardShow.count_teach_cfs = count_teach_cfs;
      this.dashboardShow.mt_committe_files = mt_committe_files;
    },
    async timesAssisstant() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        group_mt_times: "Ok"
      });
      this.group_mt_times = result.data;
    },
    async yearAssisstant() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        group_mt_years: "Ok"
      });
      this.group_mt_years = result.data;
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  computed: {
    mt_appoin_day() {
      return this.$route.query.appoin_day;
    },
    mt_appoin_month() {
      return this.$route.query.appoin_month;
    },
    mt_appoin_year() {
      return this.$route.query.appoin_year;
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  }
};
</script>

<style lang="scss" scoped></style>
