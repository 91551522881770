<template class="text_google">
  <v-app>
    <v-system-bar
      class="noprint"
      color="grey darken-2"
      dark
      height="40"
      app
      lights-out
    >
      <v-toolbar-items class="ml-5">
        <v-btn
          @click.stop="exportToExcel('exportContent', 'ManPower_Import_Form')"
          class="title elevation-0"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to CSV
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>
    <div id="exportContent" style="mso-page-orientation: landscape;">
      <div class="page">
        <div class="head text-center">
          ข้อมูลครูผู้ช่วย
        </div>
     
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headersMap"
          :items="manage_assistant_teachers"
          :search="search"  
          class="elevation-1"
          disable-pagination
        >



          <template v-slot:item.mt_status="{ item }">
            <v-chip dark color="red" v-if="item.mt_status === 'disclaim'">
              <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
            </v-chip>
            <v-chip dark color="green" v-else>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_transfer_status="{ item }">
            <v-chip dark color="red" v-if="item.mt_transfer_status === '1'">
              <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
            </v-chip>
            <v-chip dark color="green" v-else>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-chip>
          </template>
          

          <template v-slot:item.mt_examtype="{ item }">
            <v-chip dark color="purple" v-if="item.mt_examtype === 'select'">
              คัดเลือก
            </v-chip>
            <v-chip dark color="indigo" v-else>
              ทั่วไป
            </v-chip>
          </template>

          <template v-slot:item.mt_examgroup="{ item }">
            <div class="text-center">
              <v-chip
                dark
                color="purple"
                v-if="item.mt_examgroup === 'general'"
              >
                ทั่วไป
              </v-chip>
              <v-chip dark color="indigo" v-else>
                จชต.
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'transfer_position'"
              >
                ครูโอนย้าย
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'local_dev'"
              >
                ครูพัฒนาท้องถิ่น
              </v-chip>
            </div>
          </template>

        
       

          <template v-slot:item.pt_ondate="{ item }">
            <div>
              {{
                item.pt_ondate
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.pt_enddate="{ item }">
            <div>
              {{
                item.pt_enddate
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>

      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    manage_assistant_teachers: [],
    headersMap: [
    
        { text: "รอบที่", align: "left", value: "mt_times" },
        { text: "ปี", align: "left", value: "mt_years" },
        { text: "รหัสบัตร", align: "left", value: "mt_id_card" },
        { text: "สาขาวิชา", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "ลำดับที่", align: "left", value: "mt_sequence" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "นามสกุล", align: "left", value: "mt_last_name" },

        { text: "สถานะรายงาน", align: "center", value: "mt_status_report" },

        { text: "ตรวจสอบ", align: "center", value: "action_check" },

        { text: "สถานะใบประกอบ", align: "center", value: "mt_license_status" },
        { text: "ใบประกอบ", align: "center", value: "pt_licence_file" },
        { text: "เลขที่", align: "left", value: "pt_licence" },
        { text: "วันออก", align: "center", value: "pt_ondate" },
        { text: "วันหมด", align: "center", value: "pt_enddate" },
        { text: "Transcript", align: "center", value: "mt_transcript" },
        { text: "IDcard", align: "center", value: "mt_idcard_copy" },
        { text: "การเปลี่ยนชื่อ", align: "center", value: "mt_change_name" },

        { text: "วันเกิด", align: "left", value: "mt_brith_day" },
        { text: "เดือนเกิด", align: "left", value: "mt_brith_month" },
        { text: "ปีเกิด", align: "left", value: "mt_brith_year" },
        { text: "โทร", align: "left", value: "mt_tel_p" },
        { text: "วุฒิการศึกษา", align: "left", value: "mt_ed_abb" },
        { text: "เอกวิชา", align: "left", value: "mt_ed_name" },
        { text: "วันที่บรรจุ", align: "left", value: "mt_appoin_day" },
        { text: "เดือนบรรจุ", align: "left", value: "mt_appoin_month" },
        { text: "ปีบรรจุ", align: "left", value: "mt_appoin_year" },
        { text: "คำสั่ง", align: "left", value: "mt_order_app" }
      ],
      periodassteachs:[],
  }),

  async mounted() {
    /*  await this.exportToExcel('exportContent', 'ManPower_Import_Form')
     await this.close_window() */
     await this.periodassteachQuery();

    await this.manage_assistant_teacherQueryAll();
  },

  methods: {
    async periodassteachQuery() {
      let result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnablePro: "1"
      });
      this.periodassteachs = result.data;
    },

    async manage_assistant_teacherQueryAll() {
    this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          report_selete: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async close_window() {
      close();
    },
    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    mt_times() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },

    mt_years() {
      let result = window.location.href;
      let split_s = result.split("/");

      return split_s[7];
    }
  },

  props: {
    source: String
  }
};
</script>
<style>
@import url(
  https://fonts.googleapis.com/css?family=Prompt:400,
  300|Roboto:400,
  300&subset=latin,
  thai
);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.textAlignVer {
  display: block;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  width: 20px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 40cm;
  min-height: 21cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 0.5cm;
  padding-right: 0.5cm;
  padding-bottom: 0.5cm;
  padding-left: 0.5cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
      height: 247mm;
    }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    border: initial;
    border-radius: initial;
    /* width: initial; */
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding: initial;
    page-break-after: always;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
        font-size: 18px;
        font-weight: bold;
      }
      .regular12 {
        font-size: 12px;
      }
      .blod12 {
        font-size: 12px;
        font-weight: bold;
      }
      .blackRegula8 {
        font-size: 8px;
      } */
  .noprint {
    display: none;
  }
}
</style>
