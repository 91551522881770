<template>
  <div>
    <v-bottom-navigation color="info" horizontal dark>
      <v-btn to="/college/personnel_tem" class="mr-5">
        <span>ข้อมูลบุคคล </span>
        <v-icon>mdi-numeric-1-box</v-icon>
      </v-btn>

      <v-btn to="/college/personnel_temporarylicence" class="mr-5">
        <span>ข้อมูลใบอนุญาต</span>
        <v-icon>mdi-numeric-2-box</v-icon>
      </v-btn>

      <!--   <v-btn to="/college/manage_assistant_teacher" class="mr-5">
          <span>ข้อมูลวิทยฐานะ</span>
          <v-icon>mdi-numeric-3-box</v-icon>
        </v-btn>
  
        <v-btn to="/college/manage_assistant_teacher" class="mr-5">
          <span>ข้อมูลเครื่องราช</span>
          <v-icon>mdi-numeric-4-box</v-icon>
        </v-btn> -->
    </v-bottom-navigation>

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้าราชการครูและบุคลากรทางการศึกษา เกี่ยวกับใบอนุญาตประกอบวิชาชีพ"
        class="px-5 py-3"
      >
        <v-card class="pa-1">
          <v-row no-gutters>
            <v-col cols="12" class="text-right">
              <v-btn color="info" :to="'/college/print_personnel_license/?cid=' + user.college_code" target="_blank"><v-icon>mdi-printer</v-icon> ส่งออกข้อมูล</v-btn>
            </v-col>
            <v-col cols="12">
              <v-card>
                <div id="chart">
                  <apexchart
                    type="bar"
                    height="350"
                    :options="chartOptions_b"
                    :series="series_b"
                  ></apexchart>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          v-on:keyup.enter="OnEnter()"
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          :headers="showHeaders"
          :items="personnel_temporarys"
          class="elevation-1"
          :loading="loading"
          :search="search"
          disable-pagination
          group-by="position_name"
          :item-class="row_classes"
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="22">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].position_name }}
            </th>
          </template>

          <template v-slot:item.pt_licence="{ item }">
            <v-btn
              v-if="item.pt_licence_file"
              fab
              outlined
              small
              color="info"
            @click="viewpdflicenseteach(item.id_card)"
              ><v-icon>mdi-printer</v-icon></v-btn
            >
            {{ item.pt_licence }}
          </template>

          <template v-slot:item.pt_ondate="{ item }">
            {{
              item.pt_ondate
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.pt_enddate="{ item }">
            {{
              item.pt_enddate
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.Agelicence="{ item }">
            <div v-if="!item.Agelicence">
              <v-chip color="warning" dark>
                <v-icon>mdi-information-outline</v-icon>
                <span class="font-weight-bold"> </span>
              </v-chip>
            </div>

            <div v-else-if="item.Agelicence <= 60">
              <v-chip color="red" dark>
                <span class="font-weight-bold">
                  {{ item.Agelicence || "" }}
                </span>
              </v-chip>
            </div>
            <div v-else-if="item.Agelicence <= 180">
              <v-chip color="warning" dark>
                <span class="font-weight-bold">
                  {{ item.Agelicence || "" }}
                </span>
              </v-chip>
            </div>

            <div v-else>
              <span class="font-weight-bold green--text">
                {{ item.Agelicence || "" }}
              </span>
            </div>
          </template>

          <template v-slot:item.pt_licencedr="{ item }">
            <v-btn
              v-if="item.pt_licencedr_file"
              fab
              outlined
              small
              color="info"
              @click="viewpdflicensedr(item.id_card)"
              ><v-icon>mdi-printer</v-icon></v-btn
            >
            {{ item.pt_licencedr }}
          </template>

          <template v-slot:item.pt_ondatedr="{ item }">
            {{
              item.pt_ondatedr
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.pt_enddatedr="{ item }">
            {{
              item.pt_enddatedr
                | moment("add", "543 years")
                | moment("D MMMM YYYY")
            }}
          </template>

          <template v-slot:item.Agelicencedr="{ item }">
            <div v-if="!item.Agelicencedr">
              <v-chip color="warning" dark>
                <v-icon>mdi-information-outline</v-icon>
                <span class="font-weight-bold"> </span>
              </v-chip>
            </div>

            <div v-else-if="item.Agelicencedr <= 60">
              <v-chip color="red" dark>
                <span class="font-weight-bold">
                  {{ item.Agelicencedr || "" }}
                </span>
              </v-chip>
            </div>
            <div v-else-if="item.Agelicencedr <= 180">
              <v-chip color="warning" dark>
                <span class="font-weight-bold">
                  {{ item.Agelicencedr || "" }}
                </span>
              </v-chip>
            </div>

            <div v-else>
              <span class="font-weight-bold green--text">
                {{ item.Agelicencedr || "" }}
              </span>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="warning"
              @click.stop="personnel_temporaryEdit(item.id_card)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:item.actionNotices="{ item }">
            <v-icon
              color="red"
              @click.stop="personnel_temporary_notifyEdit(item.id_card)"
            >
              mdi-account-edit
            </v-icon>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>



         <!-- V-model pdflicenseteachdialog -->
         <v-layout row justify-center>
        <v-dialog v-model="pdflicenseteachdialog" max-width="60%">
          <v-card class="" elevation="2">  
              <embed :src="'/HRvecLicenseTeach/' + editpersonnel_temporary.pt_licence_file" width="100%" height="700px">    
          </v-card>
        </v-dialog>
      </v-layout>

           <!-- V-model pdflicensedrdialog -->
           <v-layout row justify-center>
        <v-dialog v-model="pdflicensedrdialog" max-width="60%">
          <v-card class="" elevation="2">  
              <embed :src="'/HRvecLicenseDirector/' + editpersonnel_temporary.pt_licencedr_file" width="100%" height="700px">    
          </v-card>
        </v-dialog>
      </v-layout>



      <!-- V-model editpersonnel_temporarydialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editpersonnel_temporarydialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ปรับปรุงข้อมูลบุคลากร"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_temporaryform" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" class="font-weight-bold"
                    >ใบอนุญาตประกอบวิชาชีพครู</v-col
                  >

                  <v-col cols="12" md="3" class="pa-1">
                    <v-text-field
                      outlined
                      label="เลขที่ใบอนุญาตประกอบวิชาชีพครู"
                      v-model="editpersonnel_temporary.pt_licence"
                      required
                      :rules="[v => !!v || '']"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" class="pa-1">
                    <v-menu
                      ref="menu6"
                      v-model="menu6"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.pt_ondate"
                          label="วันออกบัตร"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_ondate"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu6 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu6.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="3" class="pa-1">
                    <v-menu
                      ref="menu7"
                      v-model="menu7"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.pt_enddate"
                          label="วันที่บัตรหมดอายุ"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_enddate"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu7 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu7.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="pa-1"
                    v-if="editpersonnel_temporary.pt_licence_file"
                  >
                    <v-btn
                      v-if="editpersonnel_temporary.pt_licence_file"
                      fab
                      outlined
                      small
                      color="info"
                      :href="
                        '/HRvecLicenseTeach/' +
                          editpersonnel_temporary.pt_licence_file
                      "
                      target="_blank"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    >

                    <v-btn
                      v-if="editpersonnel_temporary.pt_licence_file"
                      fab
                      outlined
                      small
                      color="red"
                      class="ml-2"
                      @click="deletelicenseteach()"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="pa-1"
                    v-if="!editpersonnel_temporary.pt_licence_file"
                  >
                    <v-file-input
                      v-model="license_file"
                      accept=".pdf"                   
                      name="license_file"
                      color="deep-purple accent-4"
                      counter
                      label="ใบอนุญาตประกอบวิชาชีพครู .pdf"
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :rules="rules"
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>

                  <v-col cols="12">
                    <hr style="border: 1px solid gray;"
                  /></v-col>

                  <v-col cols="12" class="font-weight-bold"
                    >ใบอนุญาตประกอบวิชาชีพผู้บริหาร</v-col
                  >

                  <v-col cols="12" md="3" class="pa-1">
                    <v-text-field
                      outlined
                      label="เลขที่ใบอนุญาตประกอบวิชาชีพครู"
                      v-model="editpersonnel_temporary.pt_licencedr"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="3" class="pa-1">
                    <v-menu
                      ref="menu8"
                      v-model="menu8"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.pt_ondatedr"
                          label="วันออกบัตร"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_ondatedr"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu8 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu8.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="3" class="pa-1">
                    <v-menu
                      ref="menu9"
                      v-model="menu9"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.pt_enddatedr"
                          label="วันที่บัตรหมดอายุ"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.pt_enddatedr"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu9 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu9.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                    class="pa-1"
                    v-if="editpersonnel_temporary.pt_licencedr_file"
                  >
                    <v-btn
                      v-if="editpersonnel_temporary.pt_licencedr_file"
                      fab
                      outlined
                      small
                      color="info"
                      :href="
                        '/HRvecLicenseDirector/' +
                          editpersonnel_temporary.pt_licencedr_file
                      "
                      target="_blank"
                      ><v-icon>mdi-printer</v-icon></v-btn
                    >

                    <v-btn
                      v-if="editpersonnel_temporary.pt_licencedr_file"
                      fab
                      outlined
                      small
                      color="red"
                      class="ml-2"
                      @click="deletelicensedr()"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="pa-1"
                    v-if="!editpersonnel_temporary.pt_licencedr_file"
                  >
                    <v-file-input
                      v-model="license_filedr"
                      accept=".pdf"
                      name="license_filedr"
                      color="deep-purple accent-4"
                      counter
                      label="ใบอนุญาตประกอบวิชาชีพครู .pdf"
                      placeholder="Select your files"
                      prepend-icon="mdi-paperclip"
                      outlined
                      :rules="rules"
                      :show-size="1000"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>

                  <v-col cols="12"> 
                    <v-radio-group v-model="editpersonnel_temporary.pt_licencedr_type" row>
                        <template v-slot:label>
                          <div class="font-weight-bold">
                           <v-icon size="30">mdi-card-account-details</v-icon> เลือกการได้มาซึ่ง<strong>ใบประกอบกวิชาชีพผู้บริหารสถานศึกษา</strong>
                          </div>
                        </template>
                        <v-radio value="a">
                          <template v-slot:label>
                            <div>                              
                              <strong class="success--text">สำเร็จการศึกษา ด้านบริหารการศึกษา</strong>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="b">
                          <template v-slot:label>
                            <div>
                              
                              <strong class="info--text">การเทียบประสบการณ์ในการบริหารสถานศึกษามาแล้วไม่น้อยกว่า 10 ปี</strong>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>

                  </v-col>

                  <v-col cols="12">
                    <hr style="border: 1px solid gray;"
                  /></v-col>

                  <v-col cols="12" class="font-weight-bold"
                    >เกี่ยวกับวิทยฐานะปัจจุบัน</v-col
                  >
                  <v-col cols="12" md="4" class="pa-1">
                    <v-autocomplete
                      v-model="editpersonnel_temporary.rang_name"
                      outlined
                      label="วิทยฐานะปัจจุบัน"
                      :items="rang_names"
                      item-text="text"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-autocomplete
                      v-model="editpersonnel_temporary.rang_level"
                      outlined
                      label="ระดับ วิทยฐานะ"
                      :items="rang_levels"
                      item-text="text"
                      item-value="value"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="pa-1">
                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.date_rang_level"
                          label="วันที่สั่งให้มีวิทยฐานะปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_rang_level"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu4 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu4.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12">
                    <hr style="border: 1px solid gray;"
                  /></v-col>

                  <v-col cols="12" md="12" class="font-weight-bold">
                    การปฏิบัติงาน ณ สถานศึกษาบัจจุบัน
                  </v-col>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-text-field
                      outlined
                      label="คำสั่งที่ ปฏิบัติหน้าที่ ณ สถานศึกษาบัจจุบัน ระบุเพียง : 783/2563"
                      v-model="editpersonnel_temporary.order_app_now"
                      required
                      :rules="[v => !!v || '']"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="pa-1">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.date_app_now"
                          label="วันเดือนเริ่มปฏิบัติงาน ณ สถานศึกษาปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_app_now"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu3 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu3.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="12">
                    <hr style="border: 1px solid gray;"
                  /></v-col>

                  <v-col cols="12" md="12" class="font-weight-bold">
                    การปฏิบัติงานในตำแหน่งปัจจุบัน (ครู / รองผู้อำนวยการ /
                    ผู้อำนวยการ)
                  </v-col>

                  <v-col cols="12" md="12" class="pa-1">
                    <v-menu
                      ref="menu5"
                      v-model="menu5"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="editpersonnel_temporary.date_app_positonnow"
                          label="วันเดือนเริ่มปฏิบัติงาน ใน ตำแหน่งปัจจุบัน"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editpersonnel_temporary.date_app_positonnow"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu5 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu5.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    class="pa-1"
                    v-if="editpersonnel_temporary.user_status === 'teacher'"
                  >
                    <h3>สาขาวิชาที่สอน/เกี่ยวข้อง/ถนัด</h3>
                    <v-autocomplete
                      outlined
                      label="สาขาวิชาที่สอน/เกี่ยวข้อง/ถนัด"
                      v-model="editpersonnel_temporary.personnel_tem_branch"
                      :items="rate_work_course_stds"
                      item-text="branch_name_th"
                      item-value="id_course_branch"
                      required
                      :rules="[v => !!v || '']"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editpersonnel_temporarydialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editpersonnel_temporarySubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  components: { FlipCountdown },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      addpersonnel_temporarydialog: false,
      editpersonnel_temporarydialog: false,
      deletepersonnel_temporarydialog: false,
      personnel_temporaryNoticesdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      personnel_temporarys: [],
      addpersonnel_temporary: {},
      editpersonnel_temporary: {},
      search: "",
      pagination: {},

      headers: [],
      selectedHeaders: [],
      headersMap: [
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        /* {
          text: "รหัสบัตรประชาชน",
          align: "left",
          value: "id_card",
          class: "my-header-style"
        }, */
        { text: "ชื่อ-นามสกุล", align: "left", value: "frist_names" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        { text: "สาขาวิชา", align: "center", value: "branch_name_th" },
        { text: "บัตรหมดอายุ/วัน (ครู)", align: "center", value: "Agelicence" },

        { text: "ใบอนุญาต(ครู)", align: "center", value: "pt_licence" },
        { text: "วันออกบัตร(ครู)", align: "center", value: "pt_ondate" },
        { text: "บัตรหมดอายุ(ครู)", align: "center", value: "pt_enddate" },
        {
          text: "บัตรหมดอายุ/วัน (ผู้บริหาร)",
          align: "center",
          value: "Agelicencedr"
        },

        { text: "ใบอนุญาต(ผู้บริหาร)", align: "center", value: "pt_licencedr" },
        {
          text: "วันออกบัตร(ผู้บริหาร)",
          align: "center",
          value: "pt_ondatedr"
        },
        {
          text: "บัตรหมดอายุ(ผู้บริหาร)",
          align: "center",
          value: "pt_enddatedr"
        }
      ],
      rang_names: [
        
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],
      rang_levels: [
        
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      man_powers: [],
      collgegs: [],
      personnel_temporarystatus: [],
      regions: [],
      region_ena: true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      menu8: false,
      menu9: false,
      data_syslog: {},
      rate_work_course_stds: [],
      personnel_temporaryschecks: [],
      collegepersonnelreqData: {},
      user: [],
      collegepersonnelreqs: [],
      collegepersonnelreqQr: [],
      collegepersonnelreqDel: {},
      j18idPositions: [],
      personnel_temporarysNotices: [],
      addpersonnel_temporary_notify: {},
      delpersonnel_temporary_notify: {},

      personnel_temporarysNoticesQu: [],
      editdelpersonnel_temporary_notify: [],
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      chartOptions_b: {
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: [
            "มากกว่า 180",
            "180 - 120",
            "119 - 60",
            "59-1",
            "บัตรหมดอายุ"
          ]
        },
        grid: {
          borderColor: "#6D6D6D"
        },
        yaxis: {
          title: {
            text: "จำนวนคน"
          }
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " คน";
            }
          }
        }
      },
      series_b: [
        {
          name: "จำนวน",
          data: []
        }
      ],
      countf: [],
      count180: [],
      count119: [],
      count59: [],
      countEnd: [],
      license_file:null,
      license_filedr:null,
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB"
      ],
      pdflicenseteachdialog:false,
      pdflicensedrdialog:false,
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.sweetAlertLoading();
    await this.personnel_temporaryQueryAll();
    let result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });

    this.colleges = result.data;

    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    let resultUser = await this.$http.post("user.php", {
      ApiKey: this.ApiKey,
      user_ID: userSession.user_ID
    });
    this.user = resultUser.data;
    this.rate_work_course_stdsQueryAll();
    Swal.close();
  },

  methods: {
    async viewpdflicenseteach(id_card){
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.pdflicenseteachdialog = true
    },
    async viewpdflicensedr(id_card){
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.pdflicensedrdialog = true
    },

    async deletelicensedr() {
      let text = this.editpersonnel_temporary.frist_names
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "ลบไฟล์ใบอนุญาตประกอบวิชาชีพผู้บริหารเดิมของ : " + text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecLicenseDirector/" +
              this.editpersonnel_temporary.pt_licencedr_file
          });

          if (this.editpersonnel_temporary.p_word == "")
          delete this.editpersonnel_temporary.p_word;

          result = await this.$http.post("personnel_temporary.update.php", {
            ApiKey: this.ApiKey,
            pt_licencedr_file: "",
            id_card: this.editpersonnel_temporary.id_card
          });

          if (result.data.status == true) {   
            await this.personnel_temporaryEdit(this.editpersonnel_temporary.id_card)

            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_temporaryQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async deletelicenseteach() {
      let text = this.editpersonnel_temporary.frist_names
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "ลบไฟล์ใบอนุญาตประกอบวิชาชีพผู้บริหารเดิมของ : " + text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecLicenseDirector/" +
              this.editpersonnel_temporary.pt_licence_file
          });

          if (this.editpersonnel_temporary.p_word == "")
          delete this.editpersonnel_temporary.p_word;

          result = await this.$http.post("personnel_temporary.update.php", {
            ApiKey: this.ApiKey,
            pt_licence_file: "",
            id_card: this.editpersonnel_temporary.id_card
          });

          if (result.data.status == true) {
            await this.personnel_temporaryEdit(this.editpersonnel_temporary.id_card)
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnel_temporaryQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async rate_work_course_stdsQueryAll() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey
      });
      this.rate_work_course_stds = result.data;
    },

    async personnel_temporaryQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          user_name: userSession.user_name
        })
        .then(result => {
          let countfc = 0;
          let count180c = 0;
          let count119c = 0;
          let count59c = 0;
          let countEndc = 0;
          result.data.forEach(element => {
            if (element.Agelicence > 180) {
              countfc = countfc + 1;
            } else if (element.Agelicence <= 180 && element.Agelicence >= 120) {
              count180c = count180c + 1;
            } else if (element.Agelicence <= 119 && element.Agelicence >= 60) {
              count119c = count119c + 1;
            } else if (element.Agelicence <= 59 && element.Agelicence >= 1) {
              count59c = count59c + 1;
            } else {
              countEndc = countEndc + 1;
            }
          });
          this.countf = countfc;
          this.count180 = count180c;
          this.count119 = count119c;
          this.count59 = count59c;
          this.countEnd = countEndc;
          this.series_b = [
            {
              data: [
                this.countf,
                this.count180,
                this.count119,
                this.count59,
                this.countEnd
              ]
            }
          ];

          this.personnel_temporarys = result.data;
        })
        .finally(() => (this.loading = false));
    },

    async personnel_temporaryEdit(id_card) {
      let result = await this.$http.post("personnel_temporary.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpersonnel_temporary = result.data;
      this.editpersonnel_temporary.p_word = "";
      this.editpersonnel_temporarydialog = true;
      this.license_file = null;
      this.license_filedr = null;
    },

    async editpersonnel_temporarySubmit() {
      await this.sweetAlertLoading()
      let result = "";
      let uploaded = null;

      if (this.$refs.editpersonnel_temporaryform.validate()) {
        this.editpersonnel_temporary.ApiKey = this.ApiKey;
        if (this.editpersonnel_temporary.p_word == "")
          delete this.editpersonnel_temporary.p_word;

          if (this.license_file != null) {
          let formData = new FormData();
          let filename =
          this.editpersonnel_temporary.id_card +
            "" +
            this.time_stamp +
            "" +
            "teach.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.license_file);
          formData.append("filename", "../HRvecLicenseTeach/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });         
            this.editpersonnel_temporary.pt_licence_file = filename;      
          uploaded = true;
        } else {
          uploaded = false;
        }
    
          if (this.license_filedr != null) {
          let formData = new FormData();
          let filename =
          this.editpersonnel_temporary.id_card +
            "" +
            this.time_stamp +
            "" +
            "dr.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.license_filedr);
          formData.append("filename", "../HRvecLicenseDirector/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });         
            this.editpersonnel_temporary.pt_licencedr_file = filename;      
          uploaded = true;
        } else {
          uploaded = false;
        }



         result = await this.$http.post(
          "personnel_temporary.update.admin.php",
          this.editpersonnel_temporary
        );
        if (result.data.status == true) {      
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnel_temporaryQueryAll();

          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "personnel_tem";
          this.data_syslog.table_log = "personnel_temporary";
          this.data_syslog.detail_log = this.editpersonnel_temporary.id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
       /*  this.editpersonnel_temporarydialog = false; */
      }
      Swal.close();

    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    countDown(dataEnd) {
      var countDownDate = new Date(dataEnd).getTime();
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      if (days) {
        return days;
      }
    },
    row_classes(item) {
      if (!item.pt_enddate) {
        return "yellow";
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },

    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
    time_stamp() {
      const d = new Date();
      let time = Math.floor(d.getTime() / 1000);
      return time;
    },
  }
};
</script>
<style scoped>
.my-header-style {
  background: #f8bbd0;
  color: white;
}
.filpcountDown {
  width: 50px;
}
</style>
