<template>
  <div>
    <Assistant_teacher_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รายงานสรุปผลคะแนนการประเมิน ตำแหน่งครูผู้ช่วย ครั้งที่ 1 - 4"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2" outlined>
          <v-row no-gutters>
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete
                outlined
                label="ค้นหา วิทยาลัย :"
                :items="colleges"
                item-text="college_name"
                item-value="college_code"
                v-model="college_select"
                @change="assistant_teacher_reportCollegeQueryAll()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1">
              <v-autocomplete
                outlined
                label="วันเดือนปีที่บรรจุ"
                :items="groupDateTimeApps"
                item-text="dateApps"
                item-value="dateApps"
                @change="personnel_temporaryDateApp()"
                v-model="dateTimeAppSelect"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="pa-1 text-right">
              <v-btn
                fab
                outlined
                class="mr-2"
                color="info"
                :href="'#/admin/print_AssismentReportCP/'"
                target="_blank"
                ><v-icon>mdi-printer</v-icon></v-btn
              >
              <!--  <v-btn
                color="info"
                :href="
                  '#/admin/print_AssismentReportCPDay/' + dateTimeAppSelect
                "
                target="_blank"
                ><v-icon>mdi-printer</v-icon> พิมพ์รายงานตามวันบรรจุ</v-btn
              > -->
              <v-btn
                fab
                outlined
                :href="
                  '#/admin/print_AssismentReportCPDay2/?mt_appoin_day=' +
                    mt_appoin_day +
                    '&mt_appoin_month=' +
                    mt_appoin_month +
                    '&mt_appoin_year=' +
                    mt_appoin_year
                "
                target="_blank"
                color="info"
                class="mr-2"
                v-if="check_param_query > 1"
                ><v-icon>mdi-printer-search</v-icon></v-btn
              >
              <v-btn outlined fab color="info" @click="checkappoint()">
                <v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card>

        <div class="text-center ma-2" v-if="check_CC.counts > 0">
          <v-btn @click="ccSearch()" outlined rounded color="error">
            <v-icon>mdi-alert</v-icon>
            <h1>{{ check_CC.counts }}</h1>
          </v-btn>
          ยกเลิกไฟล์สรุปวันลา
        </div>
        <v-data-table
          :headers="headers"
          :items="assistant_teacher_reports"
          class="elevation-1"
          :loading="loading"
          :search="search"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                filled
                class="mb-2"
              />
            </div>

            <div>
              <v-radio-group row>
                <v-radio
                  label="ชื่อ"
                  value="radio-1"
                  @click.native="search_name()"
                ></v-radio>
                <v-radio
                  label="นามสกุล"
                  value="radio-2"
                  @click.native="search_lastname()"
                ></v-radio>
                <v-radio
                  label="รหัสบัตรประชาชน"
                  value="radio-3"
                  @click.native="search_idcard()"
                ></v-radio>
              </v-radio-group>
            </div>
            <div></div>
          </template>

          <template v-slot:item.pt_licence="{ item }">
            <div v-if="item.mt_license_status === 'have'">
              <v-icon color="info" size="30"
                >mdi-card-account-details-star</v-icon
              >
            </div>

            <div v-if="item.mt_license_status === 'donot'">
              <v-icon color="warning" size="30">mdi-card-bulleted-off</v-icon>

              <div v-if="item.pt_licence_tem">
                <div v-if="String(item.pt_licence_file_tem).length < 10">
                  <v-chip color="error" outlined>
                    <v-icon>mdi-alert</v-icon> ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
                <div v-else>
                  <v-chip
                    @click="viewpdflicenseteach(item.pt_licence_file_tem)"
                    outlined
                    color="info"
                  >
                    <v-icon>mdi-magnify</v-icon>
                    {{ item.pt_licence_tem }}</v-chip
                  >
                </div>
                หมดอายุวันที่ <br />
                {{
                  item.pt_enddate_tem
                    | moment("add", "543 year")
                    | moment("D MMMM YYYY")
                }}
                <div
                  v-if="item.datediffendtem <= 0"
                  class="red--text font-weight-bold"
                >
                  <v-icon color="red" size="30">mdi-alert</v-icon>
                  <br />
                  หมดก่อน {{ item.datediffendtem }} วัน
                </div>

                <v-btn
                  icon
                  @click="updateLicense(item.mt_id)"
                  color="grey"
                  dark
                >
                  <v-icon>mdi-magnify-close</v-icon>
                </v-btn>
              </div>
            </div>

            <div v-if="item.pt_licence_file_ipa === 'pass'">
              <v-icon color="success">mdi-check-circle</v-icon>
            </div>

            <div
              v-if="item.pt_licence_file_ipa === 'dontpass'"
              class="red--text"
            >
              {{ item.pt_licence_file_ipacomment }}
            </div>

            <div v-if="item.pt_licence">
              <div v-if="String(item.pt_licence_file).length < 10">
                <v-chip color="error" outlined>
                  <v-icon>mdi-alert</v-icon> ไม่ได้แนบไฟล์
                </v-chip>
              </div>
              <div v-else>
                <v-chip
                  @click="viewpdflicenseteach(item.pt_licence_file)"
                  outlined
                  color="info"
                >
                  <v-icon>mdi-magnify</v-icon>
                  {{ item.pt_licence }}</v-chip
                >
              </div>
              หมดอายุวันที่ <br />
              {{
                item.pt_enddate
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
              <div
                v-if="item.datediffend <= 0"
                class="red--text font-weight-bold"
              >
                <v-icon color="red" size="30">mdi-alert</v-icon> <br />
                หมดก่อน {{ item.datediffend }} วัน
              </div>

              <br />
              <v-btn icon @click="updateLicense(item.mt_id)" color="grey" dark>
                <v-icon>mdi-magnify-close</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.mt_time_1_score="{ item }">
            <v-chip
              v-if="item.mt_time_1_score > 0"
              color="info"
              @click="viewFileScore((param = item.mt_time_1_file))"
            >
              <span class="font-weight-bold yellow--text">{{
                item.mt_time_1_score
              }}</span>
            </v-chip>
          </template>

          <template v-slot:item.mt_time_2_score="{ item }">
            <v-chip
              v-if="item.mt_time_2_score > 0"
              color="info"
              @click="viewFileScore((param = item.mt_time_2_file))"
            >
              <span class="font-weight-bold yellow--text">{{
                item.mt_time_2_score
              }}</span>
            </v-chip>
          </template>

          <template v-slot:item.mt_time_3_score="{ item }">
            <v-chip
              v-if="item.mt_time_3_score > 0"
              color="info"
              @click="viewFileScore((param = item.mt_time_3_file))"
            >
              <span class="font-weight-bold yellow--text">{{
                item.mt_time_3_score
              }}</span>
            </v-chip>
          </template>

          <template v-slot:item.mt_time_4_score="{ item }">
            <v-chip
              v-if="item.mt_time_4_score > 0"
              color="info"
              @click="viewFileScore((param = item.mt_time_4_file))"
            >
              <span class="font-weight-bold yellow--text">{{
                item.mt_time_4_score
              }}</span>
            </v-chip>
          </template>

          <template v-slot:item.assistant_teacher_re_status="{ item }">
            <div v-if="item.assistant_teacher_re_status === 'CC'">
              <v-icon size="30" color="red"> mdi-alert</v-icon>
            </div>
            <div
              v-else-if="
                String(item.assistant_teacher_re_leaveguarantee).length > 4
              "
            >
              <v-icon size="30" color="success">mdi-check-circle</v-icon>
            </div>
          </template>

          <template v-slot:item.assistant_teacher_re_leaveguarantee="{ item }">
            <div
              v-if="String(item.assistant_teacher_re_leaveguarantee).length > 4"
            >
              <v-btn
                @click="
                  viewFileleavegurantee(
                    item.assistant_teacher_re_leaveguarantee
                  )
                "
                fab
                small
                color="info"
              >
                <v-icon size="30">mdi-printer</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.assistant_teacher_re_status_detail="{ item }">
            <div
              v-if="item.assistant_teacher_re_status === 'CC'"
              class="red--text"
            >
              {{ item.assistant_teacher_re_status_detail }}
            </div>
          </template>

          <template v-slot:item.evaluate_s="{ item }">
            <v-chip
              color="green"
              dark
              v-if="item.report_id_card === item.id_card"
            >
              <h2>
                <v-icon>mdi-checkbox-marked-circle</v-icon> ส่งผลการประเมิน
              </h2>
            </v-chip>
            <v-chip
              v-else-if="
                item.last_time_ass === '1' ||
                  item.last_time_ass === '2' ||
                  item.last_time_ass === '3' ||
                  item.last_time_ass === '4'
              "
              color="info"
              @click.stop="evaluate_assistant(item.mt_id_card)"
              dark
            >
              <h2>{{ item.last_time_ass }}</h2>
            </v-chip>
            <v-chip
              v-else
              dark
              color="red"
              @click.stop="evaluate_assistant(item.mt_id_card)"
            >
              <h2>
                <v-icon class="pa-1">mdi-cursor-pointer</v-icon>
                ไม่ได้เริ่มประเมิน
              </h2>
            </v-chip>
          </template>

          <template v-slot:item.prints="{ item }">
            <v-chip
              color="info"
              dark
              v-if="item.report_id_card === item.id_card"
            >
              <h2><v-icon>mdi-printer</v-icon> พิมพ์รายงาน</h2>
            </v-chip>
            <v-chip
              v-else-if="
                item.last_time_ass === '1' ||
                  item.last_time_ass === '2' ||
                  item.last_time_ass === '3' ||
                  item.last_time_ass === '4'
              "
              color="info"
              dark
            >
              <h2>อยู่ระหว่างการประเมิน</h2>
            </v-chip>
            <v-chip v-else color="red" dark>
              <h2>ไม่ได้เริ่มประเมิน</h2>
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="assistant_teacher_reportEdit(item.id_rc)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:item.cancels="{ item }">
            <v-icon
              color="red"
              @click.stop="evaluate_assistant(item.mt_id_card)"
            >
              mdi-cancel
            </v-icon>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>

      <!-- V-model evaluate_assistantdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="evaluate_assistantdialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="บันทึกคะแนนการประเมิน รายบุคคล"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="evaluate_assistantform" lazy-validation>
                <v-container grid-list-md>
                  <v-card>
                    <template slot="progress">
                      <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                      ></v-progress-linear>
                    </template>

                    <v-card-title
                      ><v-icon large color="info">mdi-account-check</v-icon>
                      <h2>ข้อมูลครูผู้ช่วย ผู้รับการประเมิน</h2></v-card-title
                    >
                    <v-card-text>
                      <div class="my-4">
                        <h3>
                          สถานศึกษา :
                          {{ assistant_teacher_report_id_card.user_firstname }}
                        </h3>
                        รหัสบัตรประชาชน :
                        {{
                          assistant_teacher_report_id_card.assistant_teacher_re_id_card
                        }}
                        <br />
                        ชื่อ-นามสกุล :
                        {{ assistant_teacher_report_id_card.frist_names }}
                        <br />
                        วันที่บรรจุ :
                        {{
                          assistant_teacher_report_id_card.mt_date_app_now
                            | moment("add", "543 year")
                            | moment("D MMMM YYYY")
                        }}
                        <br />
                        คำสั่งที่บรรจุ :
                        {{ assistant_teacher_report_id_card.mt_order_app }}
                      </div>
                      <div class="my-4"></div>
                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>

                    <v-card-title class="text-center">
                      <h3>ส่งผลการประเมิน</h3>
                    </v-card-title>
                    <v-row>
                      <v-col cols="12">
                        <v-data-table
                          :headers="headers_aassistant_teacher"
                          :items="assistant_teachers"
                          class="elevation-1"
                          :loading="loading"
                        >
                          |<!--  moment("add","543 years") | moment("D MMMM YYYY") -->

                          <template
                            v-slot:item.assistant_teacher_file="{ item }"
                          >
                            <v-btn
                              v-if="item.assistant_teacher_file"
                              large
                              icon
                              color="info"
                              @click="viewfilePDF(item.assistant_teacher_file)"
                            >
                              <v-icon>mdi-printer</v-icon>
                            </v-btn>
                          </template>

                          <template
                            v-slot:item.assistant_teacher_datetime="{
                              item
                            }"
                          >
                            {{
                              item.assistant_teacher_datetime
                                | moment("add", "543 years")
                                | moment("D MMMM YYYY")
                            }}
                          </template>

                          <template
                            v-slot:item.assistant_teacher_status="{ item }"
                          >
                            <v-chip
                              v-if="item.assistant_teacher_status === 'confirm'"
                              color="green"
                              dark
                            >
                              <h2>ส่งผลการประเมินเรียบร้อย</h2>
                            </v-chip>
                            <v-chip v-else color="warning" dark>
                              <h2>อยู่ระหว่างการประเมินผล</h2>
                            </v-chip>
                          </template>

                          <template
                            v-slot:item.assistant_teacher_comment="{ item }"
                          >
                            <v-icon
                              v-if="item.assistant_teacher_status === 'confirm'"
                              color="green"
                              large
                            >
                              mdi-cube-send
                            </v-icon>
                            <v-icon
                              v-else
                              large
                              color="yellow"
                              @click.stop="
                                assistant_teacherEdit(item.assistant_teacher_id)
                              "
                            >
                              mdi-pencil
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-col cols="12" md="12" class="text-center">
                          <v-chip
                            dark
                            color="red"
                            v-if="
                              times_s === 'ครบการประเมิน' &&
                                result_assistant_teacher_reports.assistant_teacher_re_id_card !==
                                  assistant_teacher_report_id_card.assistant_teacher_re_id_card
                            "
                          >
                            <h2>
                              <v-icon>mdi-alert</v-icon>
                              เมื่อกดรายงานผลการประเมินและยืนยันผลการประเมิน
                              สถานศึกษาจะไม่สามารถดำเนินการแก้ไขข้อมูลได้แล้ว
                              กรุณาตรวจสอบให้ถูกต้องก่อนกดปุ่มยืนยัน
                            </h2>
                          </v-chip>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <v-btn
                            @click="cancel_assistant_teacher_report()"
                            color="red"
                            outlined
                            rounded
                            v-if="
                              assistant_teacher_report_id_card.assistant_teacher_re_status ===
                                'CC'
                            "
                          >
                            <v-icon>mdi-alert</v-icon>
                            &nbsp; ยกเลิกรายการ
                          </v-btn>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                          <v-btn
                            outlined
                            @click.stop="evaluate_assistantdialog = false"
                            rounded
                          >
                            <v-icon dark>mdi-close</v-icon>ปิด
                          </v-btn>

                          <v-btn
                            v-if="
                              times_s === 'ครบการประเมิน' &&
                                result_assistant_teacher_reports.assistant_teacher_re_id_card !==
                                  assistant_teacher_report_id_card.assistant_teacher_re_id_card
                            "
                            large
                            color="green"
                            @click.stop="evaluate_assistantSubmit()"
                            rounded
                          >
                            <v-icon dark>mdi-content-save</v-icon
                            >&nbsp;รายงานผลการประเมิน
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="assistant_teacherdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="green"
              icon="mdi-clipboard-text"
              title="บันทึกคะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ assistant_teacher_report_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{
                        assistant_teacher_report_id_card.assistant_teacher_re_id_card
                      }}
                      <br />
                      ชื่อ-นามสกุล :
                      {{ assistant_teacher_report_id_card.frist_names }} <br />
                      วันที่บรรจุ :
                      {{ assistant_teacher_report_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ assistant_teacher_report_id_card.order_app_now }}
                      <br />
                      <br />

                      <h3>ครั้งที่ประเมิน : {{ times_s }}</h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12 class="text-center">
                      <h3>คะแนนประเมิน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="addassistant_teacher.assistant_teacher_score"
                        type="number"
                        label="คะแนน"
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 100) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              addassistant_teacher.assistant_teacher_datetime
                            "
                            label="วันที่ประเมิน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            addassistant_teacher.assistant_teacher_datetime
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu2.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text"
                  >* ปีที่ 1 (ไม่ต่ำกว่าร้อยละ 60 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
                <br />
                <strong class="red--text"
                  >* ปีที่ 2 (ไม่ต่ำกว่าร้อยละ 70 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="assistant_teacherdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherEditdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherEditdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="แก้ไขคะแนนประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherEditform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ assistant_teacher_report_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{
                        assistant_teacher_report_id_card.assistant_teacher_re_id_card
                      }}
                      <br />
                      ชื่อ-นามสกุล :
                      {{ assistant_teacher_report_id_card.frist_names }} <br />
                      วันที่บรรจุ :
                      {{ assistant_teacher_report_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ assistant_teacher_report_id_card.order_app_now }}
                      <br />
                      <br />

                      <h3>
                        ครั้งที่ประเมิน :
                        {{ assistant_teachers_edit.assistant_teacher_times }}
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                  <v-layout wrap>
                    <v-flex md12 class="text-center">
                      <h3>คะแนนประเมิน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="
                          assistant_teachers_edit.assistant_teacher_score
                        "
                        type="number"
                        label="คะแนน"
                        required
                        :rules="[
                          v => !!v || '',
                          v => (!!v && v <= 100) || 'กรอกคะแนนไม่ถูกต้อง'
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="
                              assistant_teachers_edit.assistant_teacher_datetime
                            "
                            label="วันที่ประเมิน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="
                            assistant_teachers_edit.assistant_teacher_datetime
                          "
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu1 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu1.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text"
                  >* ปีที่ 1 (ไม่ต่ำกว่าร้อยละ 60 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
                <br />
                <strong class="red--text"
                  >* ปีที่ 2 (ไม่ต่ำกว่าร้อยละ 70 จึงจะถือว่า
                  ผ่านการประเมิน)</strong
                >
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="assistant_teacherEditdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="assistant_teacherEditdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model assistant_teacherConfirmdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacherConfirmdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยืนยันผลการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="assistant_teacherConfirmdialogform" lazy-validation>
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ assistant_teacher_report_id_card.college_name }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{
                        assistant_teacher_report_id_card.assistant_teacher_re_id_card
                      }}
                      <br />
                      ชื่อ-นามสกุล :
                      {{ assistant_teacher_report_id_card.frist_names }} <br />
                      วันที่บรรจุ :
                      {{ assistant_teacher_report_id_card.date_app }} <br />
                      คำสั่งที่บรรจุ :
                      {{ assistant_teacher_report_id_card.order_app_now }}
                      <br />
                      <h3>
                        คะแนนเฉลี่ย :
                        {{ assistant_teacher_report_id_card.avg_score }}
                      </h3>
                      <br />

                      <h3>
                        หากกดปุ่มยืนยันแล้วจะไม่สามารถดำเนินการแก้ไขได้
                        กรุณาตรวจสอบให้ถูกต้องก่อนกดปุ่มยืนยัน
                      </h3>
                    </div>
                    <div class="my-4"></div>
                  </v-card-text>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="assistant_teacherConfirmdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="assistant_teacherConfirmdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;ยืนยันผลการประเมิน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model cancel_assistant_teacher_reportdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="cancel_assistant_teacher_reportdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการประเมินผล"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="cancel_assistant_teacher_reportdialogform"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-card-text>
                    <div class="my-4">
                      <h3>
                        สถานศึกษา :
                        {{ assistant_teacher_report_id_card.user_firstname }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{
                        assistant_teacher_report_id_card.assistant_teacher_re_id_card
                      }}
                      <br />
                      ชื่อ-นามสกุล :
                      {{ assistant_teacher_report_id_card.frist_names }}
                      <br />
                      วันที่บรรจุ :
                      {{
                        assistant_teacher_report_id_card.mt_date_app_now
                          | moment("add", "543 year")
                          | moment("D MMMM YYYY")
                      }}
                      <br />
                      คำสั่งที่บรรจุ :
                      {{ assistant_teacher_report_id_card.mt_order_app }}
                    </div>
                    <div class="my-4">
                      <v-text-field
                        v-model="
                          cancel_assistant_teachers.assistant_teacher_ipacomment
                        "
                        label="เหตุผลในการยกเลิก"
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </div>
                  </v-card-text>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="cancel_assistant_teacher_reportdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="cancel_assistant_teacher_reportdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon
                >&nbsp;ยืนยันการยกเลิกผลการประเมิน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pertem_assistantFileCCdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="pertem_assistantFileCCdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ยกเลิกแนบคำสั่งแต่งตั้งกรรมการประเมิน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="pertem_assistantFileCCform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div class="my-4">
                        <h3>
                          สถานศึกษา :
                          {{ editassistant_teacher_report.college_name }}
                        </h3>
                        รหัสบัตรประชาชน :
                        {{ editassistant_teacher_report.id_card }} <br />
                        ชื่อ-นามสกุล :
                        {{ editassistant_teacher_report.frist_names }} <br />
                        วันที่บรรจุ :
                        {{ editassistant_teacher_report.date_app }} <br />
                        คำสั่งที่บรรจุ :
                        {{ editassistant_teacher_report.order_app_now }}
                      </div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green"
                @click.stop="pertem_assistantFileCCSubmit()"
                rounded
              >
                <v-icon dark>mdi-checkbox-marked-circle</v-icon
                >&nbsp;ยืนยันยกเลิก
              </v-btn>
              <v-btn
                outlined
                color="warning"
                @click.stop="pertem_assistantFileCCdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editassistant_teacher_reportdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editassistant_teacher_reportdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ปรับปรุงข้อมูลบุคลากร"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editassistant_teacher_reportform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <h3>ข้อมูลการเริ่มปฏิบัติหน้าที่ในสถานศึกษาปัจจุบัน</h3>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="คำสั่งที่ ระบุเพียง : 783/2563"
                        v-model="editassistant_teacher_report.order_app_now"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-menu
                        ref="menu3"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editassistant_teacher_report.date_app_now"
                            label="วันเดือนเริ่มปฏิบัติงาน"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            locale="th"
                            required
                            :rules="[v => !!v || '']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editassistant_teacher_report.date_app_now"
                          no-title
                          scrollable
                          locale="th"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu3 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.menu3.save(date)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
                <strong class="red--text">* จำเป็น</strong>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="warning"
                large
                @click.stop="editassistant_teacher_reportdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="editassistant_teacher_reportSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <v-layout row justify-center>
        <v-dialog v-model="pdflicenseteachdialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="
                'https://hrvec.ovec.go.th/HRvecLicenseTeach/' + pt_licence_files
              "
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <v-layout row justify-center>
        <v-dialog v-model="scoredialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'https://hrvec.ovec.go.th/HRvecfiles/' + assFileScore"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <v-layout row justify-center>
        <v-dialog
          v-model="assistant_teacher_re_leaveguaranteedialog"
          max-width="80%"
        >
          <v-card class="" elevation="2">
            <embed
              :src="
                'https://hrvec.ovec.go.th/HRvecAssistant_report/' +
                  assistant_teacher_re_leaveguarantee
              "
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- ยกเลิกไฟล์แนบใบอนุญาตประกอบวิชาชีพครู -->

      <v-dialog v-model="cancel_Licensefiledialog" persistent max-width="60%">
        <v-card class="mx-auto pa-6">
          <base-material-card
            color="warning"
            icon="mdi-clipboard-text"
            title="การตรวจสอบข้อมูล ใบอนุญาตประกอบวิชาชีพครู/ใบอนุญาตปฏิบัติการสอน"
            class="px-5 py-3 text_google"
          ></base-material-card>
          <v-card-text>
            <v-form ref="cancel_Licensefiledialogform" lazy-validation>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    <div class="text-center">
                      <v-btn
                        v-if="
                          String(editmanage_assistant_teacher.pt_licence_file)
                            .length > 6
                        "
                        color="info"
                        icon
                        @click="
                          viewpdflicenseteach(
                            editmanage_assistant_teacher.pt_licence_file
                          )
                        "
                        ><v-icon size="60">mdi-printer</v-icon></v-btn
                      >
                    </div>

                    <div class="text-center">
                      <v-btn
                        v-if="
                          String(
                            editmanage_assistant_teacher.pt_licence_file_tem
                          ).length > 6
                        "
                        color="info"
                        icon
                        @click="
                          viewpdflicenseteach(
                            editmanage_assistant_teacher.pt_licence_file_tem
                          )
                        "
                        ><v-icon size="60">mdi-printer</v-icon></v-btn
                      >
                    </div>

                    <br />
                    ชื่อ-นามสกุล :
                    <span class="font-weight-bold"
                      >{{ editmanage_assistant_teacher.frist_names }}
                    </span>
                    <br />
                    ID-card :
                    {{ editmanage_assistant_teacher.mt_id_card }}
                  </v-flex>

                  <v-flex
                    md6
                    v-if="
                      editmanage_assistant_teacher.mt_license_status === 'have'
                    "
                  >
                    <v-radio-group
                      v-model="editmanage_assistant_teacher.pt_licence_file_ipa"
                      row
                      :rules="[v => !!v || '']"
                    >
                      <template v-slot:label>
                        <div><strong>การตรวจสอบข้อมูล</strong></div>
                      </template>
                      <v-radio value="pass">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="dontpass">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">ไม่ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex
                    md6
                    v-if="
                      editmanage_assistant_teacher.pt_licence_file_ipa ===
                        'dontpass' &&
                        editmanage_assistant_teacher.mt_license_status ===
                          'have'
                    "
                  >
                    <v-text-field
                      label="เหตุผล"
                      v-model="
                        editmanage_assistant_teacher.pt_licence_file_ipacomment
                      "
                      required
                      :rules="[v => !!v || '']"
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex
                    md6
                    v-if="
                      editmanage_assistant_teacher.mt_license_status === 'donot'
                    "
                  >
                    <v-radio-group
                      v-model="
                        editmanage_assistant_teacher.pt_licence_file_ipa_tem
                      "
                      row
                      :rules="[v => !!v || '']"
                    >
                      <template v-slot:label>
                        <div>
                          การตรวจสอบข้อมูล<strong>
                            ใบอนุญาตปฏิบัติการสอน</strong
                          >
                        </div>
                      </template>
                      <v-radio value="pass">
                        <template v-slot:label>
                          <div>
                            <strong class="success--text">ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="dontpass">
                        <template v-slot:label>
                          <div>
                            <strong class="primary--text">ไม่ผ่าน</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-flex>

                  <v-flex
                    md6
                    v-if="
                      editmanage_assistant_teacher.pt_licence_file_ipa_tem ===
                        'dontpass' &&
                        editmanage_assistant_teacher.mt_license_status ===
                          'donot'
                    "
                  >
                    <v-text-field
                      label="เหตุผล"
                      v-model="
                        editmanage_assistant_teacher.pt_licence_file_ipacomment_tem
                      "
                      required
                      :rules="[v => !!v || '']"
                      outlined
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <strong class="red--text">* จำเป็น</strong>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              outlined
              @click.stop="cancel_Licensefiledialog = false"
              rounded
            >
              <v-icon dark>mdi-close</v-icon>
            </v-btn>

            <v-btn
              outlined
              color="green"
              @click.stop="cancel_LicensefiledialogSubmit()"
              rounded
            >
              <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Assistant_teacher_bar from "../../components/admin/assistant_teacher_bar.vue";
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      scoredialog: false,
      assFileScore: "",
      addassistant_teacher_reportdialog: false,
      editassistant_teacher_reportdialog: false,
      deleteassistant_teacher_reportdialog: false,
      evaluate_assistantdialog: false,
      assistant_teacherdialog: false,
      assistant_teacherEditdialog: false,
      assistant_teacherConfirmdialog: false,
      cancel_assistant_teacher_reportdialog: false,
      pertem_assistantFileCCdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      assistant_teacher_reports: [],
      addassistant_teacher_report: {},
      editassistant_teacher_report: {},
      search: "",
      pagination: {},
      headers: [
        { text: "รหัสสถานศึกษา", align: "left", value: "mt_college_code" },
        { text: "สถานศึกษา", align: "left", value: "user_firstname" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "mt_id_card" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "frist_names" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "mt_id_position" },
        { text: "ใบประกอบวิชาชีพ", align: "center", value: "pt_licence" },

        { text: "ครั้งที่ 1", align: "center", value: "mt_time_1_score" },
        { text: "ครั้งที่ 2", align: "center", value: "mt_time_2_score" },
        { text: "ครั้งที่ 3", align: "center", value: "mt_time_3_score" },
        { text: "ครั้งที่ 4", align: "center", value: "mt_time_4_score" },
        {
          text: "คะแนน",
          align: "center",
          value: "assistant_teacher_re_avg_score"
        },

        {
          text: "วันลา",
          align: "center",
          value: "assistant_teacher_re_leaveday"
        },

        {
          text: "ไฟล์รับรองวันลา",
          align: "center",
          value: "assistant_teacher_re_leaveguarantee"
        },

        {
          text: "สถานะ",
          align: "center",
          value: "assistant_teacher_re_status"
        },
        {
          text: "หมายเหตุ",
          align: "center",
          value: "assistant_teacher_re_status_detail"
        },

        { text: "วันเดือนปีบรรจุ", align: "center", value: "mt_date_app_now" },
        { text: "คำสั่งที่", align: "center", value: "mt_order_app" },
        {
          text: "วันที่รายงาน",
          align: "center",
          value: "assistant_teacher_re_datetime"
        },
        {
          text: "ยกเลิกการประเมิน",
          align: "center",
          value: "cancels",
          icon: "mdi-cancel"
        }
      ],
      headers_aassistant_teacher: [
        { text: "ครั้งที่", align: "center", value: "assistant_teacher_times" },
        {
          text: "สถานศึกษา",
          align: "left",
          value: "user_firstname"
        },
        {
          text: "รหัสบัตรประชาชน",
          align: "left",
          value: "assistant_teacher_id_card"
        },
        {
          text: "ชื่อ-นามสกุล",
          align: "left",
          value: "frist_names"
        },
        {
          text: "วันที่ประเมิน",
          align: "center",
          value: "assistant_teacher_datetime"
        },
        { text: "คะแนน", align: "center", value: "assistant_teacher_score" },
        { text: "สถานะ", align: "center", value: "assistant_teacher_status" },
        { text: "ไฟล์แนบ", align: "center", value: "assistant_teacher_file" },
        {
          text: "รายงาน",
          align: "center",
          value: "assistant_teacher_comment"
        },

        {
          text: "สอศ.ตรวจสอบ",
          align: "center",
          value: "assistant_teacher_ipacheck"
        }
      ],
      rang_names: [
        { text: "ชำนาญการ", value: "ชำนาญการ" },
        { text: "ชำนาญการพิเศษ", value: "ชำนาญการพิเศษ" },
        { text: "เชี่ยวชาญ", value: "เชี่ยวชาญ" },
        { text: "เชี่ยวชาญพิเศษ", value: "เชี่ยวชาญพิเศษ" }
      ],
      rang_levels: ["-", 1, 2, 3, 4, 5],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      select_per: "",
      select_personel: [
        { text: "ประเมินครั้งที่ 1 ", value: "1" },
        { text: "ประเมินครั้งที่ 2 ", value: "2" },
        { text: "ประเมินครั้งที่ 3 ", value: "3" },
        { text: "ประเมินครั้งที่ 4 ", value: "4" }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      userstatus: [],
      man_powers: [],
      collgegs: [],
      assistant_teacher_reportstatus: [],
      regions: [],
      region_ena: true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      assistant_teacher_report_id_card: [],
      assistant_teachers: [],
      addassistant_teacher: {},
      assistant_teachers_edit: {},
      assistant_teachers_confrim: {},
      assistant_teacher_report_s: {},
      result_assistant_teacher_reports: {},
      cancel_assistant_teacher_reports: {},
      cancel_assistant_teachers: {},
      data_syslog: {},
      colleges: [],
      college_select: "",
      Updateassistant_teacher_report: {},
      groupDateTimeApps: [],
      dateTimeAppSelect: null,
      pdflicenseteachdialog: false,
      pt_licence_files: "",
      check_param_query: [],
      check_CC: [],
      assistant_teacher_re_leaveguarantee: "",
      assistant_teacher_re_leaveguaranteedialog: false,
      editmanage_assistant_teacher: [],
      cancel_Licensefiledialog: false
    };
  },
  async mounted() {
    await this.groupDateTimeAppQuery();
    await this.checkappoint();
    let result;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });
    this.colleges = result.data;
    let user_status_result;
    user_status_result = await this.$http.post(
      "crud_user_status.php?crud=read",
      {
        ApiKey: this.ApiKey
      }
    );
    this.userstatus = user_status_result.data;
  },
  methods: {
    async updateLicense(mt_id) {
      this.editmanage_assistant_teacher = [];
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.cancel_Licensefiledialog = true;
    },

    async viewFileleavegurantee(assistant_teacher_re_leaveguarantee) {
      this.assistant_teacher_re_leaveguarantee = assistant_teacher_re_leaveguarantee;
      this.assistant_teacher_re_leaveguaranteedialog = true;
    },
    async ccSearch() {
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_re_status: "CC"
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
    },
    async viewFileScore(param) {
      this.assFileScore = param;
      this.scoredialog = true;
    },
    async viewpdflicenseteach(pt_licence_file) {
      this.pt_licence_files = pt_licence_file;
      let pt_licence_fileCk = String(pt_licence_file);
      if (pt_licence_fileCk.length > 4) {
        this.pdflicenseteachdialog = true;
      } else {
        Swal.fire({
          icon: "warning",
          title: "ไม่ได้แนบไฟล์",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async checkappoint() {
      this.loading = true;
      let mt_appoin_day = this.mt_appoin_day || 0;
      let mt_appoin_month = this.mt_appoin_month || 0;
      let mt_appoin_year = this.mt_appoin_year || 0;
      let dates = new Date(
        mt_appoin_year + "-" + mt_appoin_month + "-" + mt_appoin_day
      );
      let getTime = dates.getTime();
      this.check_param_query = getTime;
      if (getTime > 1) {
        let result = await this.$http
          .post("manage_assistant_teacher.php", {
            ApiKey: this.ApiKey,
            mt_appoin_day: mt_appoin_day,
            mt_appoin_month: mt_appoin_month,
            mt_appoin_year: mt_appoin_year,
            fastQ: "ok"
          })
          .finally(() => (this.loading = false));
        this.assistant_teacher_reports = result.data;
        let data = result.data;
        let counts = 0;
        data.forEach(value => {
          if (value.assistant_teacher_re_status == "CC") {
            counts += 1;
          }
        });
        this.check_CC.counts = counts;
      } else {
        let result = await this.$http
          .post("assistant_teacher_report.php", {
            ApiKey: this.ApiKey
          })
          .finally(() => (this.loading = false));
        this.assistant_teacher_reports = result.data;
      }
    },

    async clearSearch() {
      this.assistant_teacher_reports = [];
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },
    async search_idcard() {
      await this.sweetAlertLoading();
      let result = await this.$http
        .post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_re_id_card: this.search
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
      Swal.close();
    },
    async search_name() {
      await this.sweetAlertLoading();
      let result = await this.$http
        .post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          mt_frist_name: this.search
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
      Swal.close();
    },
    async search_lastname() {
      await this.sweetAlertLoading();
      let result = await this.$http
        .post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          mt_last_name: this.search
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
      Swal.close();
    },
    async personnel_temporaryDateApp() {
      await this.sweetAlertLoading();
      let dateTimeApp = this.dateTimeAppSelect.split("-");
      let mt_appoin_day = dateTimeApp[0];
      let mt_appoin_month = dateTimeApp[1];
      let mt_appoin_year = dateTimeApp[2];
      let result = await this.$http
        .post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          mt_appoin_day: mt_appoin_day,
          mt_appoin_month: mt_appoin_month,
          mt_appoin_year: mt_appoin_year
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
      Swal.close();
    },
    async groupDateTimeAppQuery() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        calendar_admin: "Ok"
      });
      this.groupDateTimeApps = result.data;
    },
    async pertem_assistantFileCCSubmit() {
      this.Updateassistant_teacher_report.ApiKey = this.ApiKey;
      this.Updateassistant_teacher_report.id_card = this.editassistant_teacher_report.id_card;
      this.Updateassistant_teacher_report.pertem_assistantFileCC = "";
      let resultPic = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename:
          "../HRvecfiles/" +
          this.editassistant_teacher_report.pertem_assistantFile
      });
      this.Updateassistant_teacher_report.assistant_teacher_report_pic = "";
      let result = await this.$http.post(
        "assistant_teacher_report.update.php",
        this.Updateassistant_teacher_report
      );
      if (result.data.status == true && resultPic.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.assistant_teacher_reportASCCQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.pertem_assistantFileCCdialog = false;
    },
    async pertem_assistantFileCC(mt_id_card) {
      let result = await this.$http.post("assistant_teacher_report.php", {
        ApiKey: this.ApiKey,
        id_card: mt_id_card
      });
      this.editassistant_teacher_report = result.data;
      this.pertem_assistantFileCCdialog = true;
    },
    async cancel_assistant_teacher_report() {
      this.cancel_assistant_teacher_reportdialog = true;
    },
    async evaluate_assistant(mt_id_card) {
      let result = await this.$http.post("assistant_teacher_report.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_re_id_card: mt_id_card
      });
      this.assistant_teacher_report_id_card = result.data;

      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id_card: mt_id_card
      });
      this.assistant_teachers = result_assistant.data;

      this.evaluate_assistantdialog = true;
    },

    async assistant_teacher_selectsearch() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          position_name: "ครูผู้ช่วย",
          last_time_check: this.select_per
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
    },
    async assistant_teacher_success() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          position_name: "ครูผู้ช่วย",
          evalution_succ: "success"
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
    },
    async assistant_teacher_reportASCCQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          position_name: "ครูผู้ช่วย",
          pertem_assistantFileCC: "CC"
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
    },
    async assistant_teacher_not_evalution() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          position_name: "ครูผู้ช่วย",
          not_evalution: "check"
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
    },
    async assistant_teacher_add() {
      this.addassistant_teacher = {};
      this.assistant_teacherdialog = true;
    },
    async assistant_teacherEdit(assistant_teacher_id) {
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id: assistant_teacher_id
      });
      this.assistant_teachers_edit = result_assistant.data;
      this.assistant_teacherEditdialog = true;
    },

    async assistant_teacher_reportCollegeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_re_college_code: this.college_select
        })
        .finally(() => (this.loading = false));
      this.assistant_teacher_reports = result.data;
    },
    async assistant_teacherdialogSubmit() {
      if (this.$refs.assistant_teacherdialogform.validate()) {
        this.addassistant_teacher.ApiKey = this.ApiKey;
        this.addassistant_teacher.assistant_teacher_college_code = this.assistant_teacher_report_id_card.college_code;
        this.addassistant_teacher.assistant_teacher_id_card = this.assistant_teacher_report_id_card.assistant_teacher_re_id_card;
        this.addassistant_teacher.assistant_teacher_times = this.times_s;

        let result = await this.$http.post(
          "assistant_teacher.insert.php",
          this.addassistant_teacher
        );
        if (result.data.status == true) {
          let result = await this.$http.post("assistant_teacher_report.php", {
            ApiKey: this.ApiKey,
            id_card: this.assistant_teacher_report_id_card
              .assistant_teacher_re_id_card
          });
          this.assistant_teacher_report_id_card = result.data;
          let result_assistant = await this.$http.post(
            "assistant_teacher.php",
            {
              ApiKey: this.ApiKey,
              assistant_teacher_id_card: this.assistant_teacher_report_id_card
                .id_card
            }
          );
          this.assistant_teachers = result_assistant.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "assistant_teacher";
          this.data_syslog.table_log = "assistant_teacher";
          this.data_syslog.detail_log = this.addassistant_teacher.assistant_teacher_id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacherdialog = false;
      }
    },
    async assistant_teacherEditdialogSubmit() {
      if (this.$refs.assistant_teacherEditform.validate()) {
        this.assistant_teachers_edit.ApiKey = this.ApiKey;

        let result = await this.$http.post(
          "assistant_teacher.update.php",
          this.assistant_teachers_edit
        );
        if (result.data.status == true) {
          let result = await this.$http.post("assistant_teacher_report.php", {
            ApiKey: this.ApiKey,
            id_card: this.assistant_teacher_report_id_card
              .assistant_teacher_re_id_card
          });
          this.assistant_teacher_report_id_card = result.data;
          let result_assistant = await this.$http.post(
            "assistant_teacher.php",
            {
              ApiKey: this.ApiKey,
              assistant_teacher_id_card: this.assistant_teacher_report_id_card
                .id_card
            }
          );
          this.assistant_teachers = result_assistant.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "assistant_teacher";
          this.data_syslog.table_log = "assistant_teacher";
          this.data_syslog.detail_log = this.assistant_teachers_edit.assistant_teacher_id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.assistant_teacherEditdialog = false;
      }
    },
    async evaluate_assistantSubmit() {
      this.assistant_teacherConfirmdialog = true;
    },
    async assistant_teacherConfirmdialogSubmit() {
      this.assistant_teachers_confrim.ApiKey = this.ApiKey;
      this.assistant_teachers_confrim.assistant_teacher_id_card = this.assistant_teacher_report_id_card.assistant_teacher_re_id_card;
      this.assistant_teachers_confrim.assistant_teacher_status = "confirm";
      this.assistant_teacher_report_s.ApiKey = this.ApiKey;
      this.assistant_teacher_report_s.assistant_teacher_re_college_code = this.assistant_teacher_report_id_card.college_code;
      this.assistant_teacher_report_s.assistant_teacher_re_id_card = this.assistant_teacher_report_id_card.assistant_teacher_re_id_card;
      this.assistant_teacher_report_s.assistant_teacher_re_datetime = this.date_today;
      this.assistant_teacher_report_s.assistant_teacher_re_avg_score = this.assistant_teacher_report_id_card.avg_score;

      let result = await this.$http.post(
        "assistant_teacher.update.confirm.php",
        this.assistant_teachers_confrim
      );
      let result_assistant_teacher_report = await this.$http.post(
        "assistant_teacher_report.insert.php",
        this.assistant_teacher_report_s
      );
      if (
        result.data.status == true &&
        result_assistant_teacher_report.data.status == true
      ) {
        let result = await this.$http.post("assistant_teacher_report.php", {
          ApiKey: this.ApiKey,
          id_card: this.assistant_teacher_report_id_card
            .assistant_teacher_re_id_card
        });
        this.assistant_teacher_report_id_card = result.data;
        let result_assistant = await this.$http.post("assistant_teacher.php", {
          ApiKey: this.ApiKey,
          assistant_teacher_id_card: this.assistant_teacher_report_id_card
            .id_card
        });
        let result_assistant_teacher_report = await this.$http.post(
          "assistant_teacher_report.php",
          {
            ApiKey: this.ApiKey,
            assistant_teacher_re_id_card: this.assistant_teacher_report_id_card
              .id_card
          }
        );
        this.result_assistant_teacher_reports =
          result_assistant_teacher_report.data;
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "insert";
        this.data_syslog.page_log = "assistant_teacher_report";
        this.data_syslog.table_log = "assistant_teacher_report";
        this.data_syslog.detail_log = this.assistant_teacher_report_s.assistant_teacher_re_id_card;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
        this.assistant_teachers = result_assistant.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.assistant_teacherConfirmdialog = false;
    },
    async assistant_teacher_reportEdit(id_rc) {
      let result = await this.$http.post("assistant_teacher_report.php", {
        ApiKey: this.ApiKey,
        id_rc: id_rc
      });
      this.editassistant_teacher_report = result.data;
      this.editassistant_teacher_report.p_word = "";
      this.editassistant_teacher_reportdialog = true;
    },
    async editassistant_teacher_reportSubmit() {
      if (this.$refs.editassistant_teacher_reportform.validate()) {
        this.editassistant_teacher_report.ApiKey = this.ApiKey;
        if (this.editassistant_teacher_report.p_word == "")
          delete this.editassistant_teacher_report.p_word;
        this.editassistant_teacher_report.brith_day = this.brith_day;
        this.editassistant_teacher_report.brith_month = this.brith_month;
        this.editassistant_teacher_report.brith_year = this.brith_year;
        this.editassistant_teacher_report.mt_appoin_day = this.mt_appoin_day;
        this.editassistant_teacher_report.mt_appoin_month = this.mt_appoin_month;
        this.editassistant_teacher_report.mt_appoin_year = this.mt_appoin_year;
        this.editassistant_teacher_report.retrire_year = this.retrire_year;
        let result = await this.$http.post(
          "assistant_teacher_report.update.admin.php",
          this.editassistant_teacher_report
        );
        if (result.data.status == true) {
          this.assistant_teacher_report = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "assistant_teacher";
          this.data_syslog.table_log = "assistant_teacher_report";
          this.data_syslog.detail_log = this.editassistant_teacher_report.id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.assistant_teacher_reportQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editassistant_teacher_reportdialog = false;
      }
    },
    async cancel_assistant_teacher_reportdialogSubmit() {
      await this.sweetAlertLoading();
      this.cancel_assistant_teacher_reports.ApiKey = this.ApiKey;
      this.cancel_assistant_teacher_reports.assistant_teacher_re_id_card = this.assistant_teacher_report_id_card.assistant_teacher_re_id_card;
      this.cancel_assistant_teachers.ApiKey = this.ApiKey;
      this.cancel_assistant_teachers.assistant_teacher_id_card = this.assistant_teacher_report_id_card.assistant_teacher_re_id_card;
      this.cancel_assistant_teachers.assistant_teacher_status = "";
      this.cancel_assistant_teachers.assistant_teacher_ipacheck = "NotPass";

      let result = await this.$http.post(
        "assistant_teacher.update.confirm.php",
        this.cancel_assistant_teachers
      );
      let result_assistant_teacher_report = await this.$http.post(
        "assistant_teacher_report.delete.php",
        this.cancel_assistant_teacher_reports
      );
      if (
        result.data.status == true ||
        result_assistant_teacher_report.data.status == true
      ) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "Cancel";
        this.data_syslog.page_log = "assistant_teacher";
        this.data_syslog.table_log = "assistant_teacher_report";
        this.data_syslog.detail_log = this.cancel_assistant_teachers.assistant_teacher_id_card;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
        Swal.close();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.evaluate_assistantdialog = false;
      this.cancel_assistant_teacher_reportdialog = false;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    times_s() {
      let times = this.assistant_teacher_report_id_card.last_time;
      let result;
      if (times == "1") {
        result = 2;
      } else if (times == "2") {
        result = 3;
      } else if (times == "3") {
        result = 4;
      } else if (times == "4") {
        result = "ครบการประเมิน";
      } else {
        result = 1;
      }
      return result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    color() {
      return "indigo darken-4";
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    },
    mt_appoin_day() {
      return this.$route.query.appoin_day;
    },
    mt_appoin_month() {
      return this.$route.query.appoin_month;
    },
    mt_appoin_year() {
      return this.$route.query.appoin_year;
    }
  },
  components: { Assistant_teacher_bar }
};
</script>
