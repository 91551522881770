<template>
  <div>
    <v-container id="user-profile" fluid tag="section">
      <v-row justify="center">
        <v-col cols="12" md="12">
          <!-- แจ้งสอบผู้อำนวยการ -->
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="pa-2"
              v-if="
                period_directors.period_sedirector_enable === '1' &&
                  (users.user_status === 'se_director' ||
                    users.user_status === 'teacher' ||
                    users.user_status === 'S' ||
                    users.user_status === 'C')
              "
            >
              <v-row no-gutters>
                <v-col cols="7">
                  <v-alert
                    outlined
                    prominent
                    icon="mdi-bell-ring"
                    type="error"
                    border="left"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <h2 class="primary--text">
                          <v-icon color="primary">mdi-account</v-icon>
                          เปิดระบบการรับสมัครคัดเลือก ตำแหน่ง
                          ผู้อำนวยการวิทยาลัย
                        </h2>
                        <h2>สามารถดำเนินการ</h2>
                        <h3>
                          ก่อนระบบปิดในวันที่
                          {{
                            period_directors.period_sedirector_stop
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </h3>
                      </v-col>
                      <v-col class="shrink">
                        <div
                          v-if="
                            users.user_status === 'se_director' ||
                              users.user_status === 'teacher' ||
                              users.user_status === 'S' ||
                              users.user_status === 'C'
                          "
                        >
                          <v-col
                            cols="12"
                            md="12"
                            class="text-center"
                            v-if="
                              period_directors.period_sedirector_enable === '1'
                            "
                          >
                            <v-btn
                              color="primary"
                              x-large
                              rounded
                              to="/director_app"
                            >
                              <v-icon>mdi-gesture-double-tap</v-icon>
                              สมัคร</v-btn
                            >
                          </v-col>
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col cols="5">
                  <div class="text-center">
                    <h3>
                      ปิดระบบ <u>รับสมัคร</u> :
                      {{
                        period_directors.period_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                      <br />
                      เหลือเวลาอีก
                    </h3>
                    <flip-countdown
                      :deadline="
                        period_directors.period_sedirector_stop + ' 16:30:00'
                      "
                      :labels="labels"
                    ></flip-countdown>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 16 : 2"
                      :class="{ 'on-hover': hover }"
                      v-if="director_apps.sedirector_app_pvc_approve === 'pass'"
                    >
                      <v-alert type="info" outlined class="pa-2">
                        <h2>ผ่านการตรวจสอบคุณสมบัติเบื้องต้นจาก สอจ.</h2>
                        <div class="text-center">
                          <h1>
                            <v-icon size="40" color="success"
                              >mdi-check-circle</v-icon
                            >
                          </h1>

                          <v-btn
                            color="success"
                            outlined
                            rounded
                            to="/director_app"
                          >
                            <v-icon>mdi-cursor-default-click</v-icon>
                            ตรวจสอบ</v-btn
                          >
                        </div>
                      </v-alert>
                    </v-card>
                  </v-hover>

                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 16 : 2"
                      :class="{ 'on-hover': hover }"
                      v-if="director_apps.sedirector_app_ipacheck === 'pass'"
                    >
                      <v-alert type="info" outlined class="pa-2">
                        <h2>การตรวจสอบเอกสารจาก ส่วนกลาง กจ.2</h2>
                        <div class="text-center">
                          <h1>
                            <v-icon size="40" color="success"
                              >mdi-check-circle</v-icon
                            >
                            {{ director_apps.sedirector_app_ipacheckcomment }}
                          </h1>

                          <v-btn
                            color="success"
                            outlined
                            rounded
                            to="/director_app"
                          >
                            <v-icon>mdi-cursor-default-click</v-icon>
                            ตรวจสอบ</v-btn
                          >
                        </div>
                      </v-alert>
                    </v-card>
                  </v-hover>

                  <v-hover v-slot="{ hover }">
                    <v-card
                      :elevation="hover ? 16 : 2"
                      :class="{ 'on-hover': hover }"
                      v-if="director_apps.sedirector_app_ipacheck === 'notpass'"
                    >
                      <v-alert type="error" outlined class="pa-2">
                        แจ้งเตือนการสมัคร
                        <div class="text-center">
                          <h1>
                            <v-icon size="40" color="red">mdi-alert</v-icon>
                            {{ director_apps.sedirector_app_ipacheckcomment }}
                          </h1>

                          <v-btn
                            color="warning"
                            outlined
                            rounded
                            to="/director_app"
                          >
                            <v-icon>mdi-pencil</v-icon>
                            ตรวจสอบ/แก้ไข</v-btn
                          >
                        </div>
                      </v-alert>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>

          <!-- แจ้งสอบรองผู้อำนวยการ -->
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="pa-2"
              v-if="
                period_sedirectors.period_sedirector_enable === '1' &&
                  (users.user_status === 'teacher' || users.user_status === 'S')
              "
            >
              <v-row>
                <v-col cols="7">
                  <v-alert
                    outlined
                    prominent
                    icon="mdi-bell-ring"
                    type="error"
                    border="left"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <h2 class="primary--text">
                          <v-icon color="primary">mdi-account</v-icon>
                          เปิดระบบแนบไฟล์ การรับสมัคร ตำแหน่ง
                          รองผู้อำนวยการวิทยาลัย
                        </h2>
                        <h2>สามารถดำเนินการ</h2>
                        <h3>
                          ก่อนระบบปิดในวันที่
                          {{
                            period_sedirectors.period_sedirector_stop
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </h3>
                      </v-col>
                      <v-col class="shrink">
                        <div
                          v-if="
                            users.user_status === 'teacher' ||
                              users.user_status === 'S'
                          "
                        >
                          <v-col
                            cols="12"
                            md="12"
                            class="text-center"
                            v-if="
                              period_sedirectors.period_sedirector_enable ===
                                '1'
                            "
                          >
                            <v-btn
                              color="primary"
                              x-large
                              rounded
                              to="/sedirector_app"
                            >
                              <v-icon>mdi-gesture-double-tap</v-icon>
                              สมัคร</v-btn
                            >
                          </v-col>
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col cols="5">
                  <div class="text-center">
                    <h3>
                      ปิดระบบ <u>รับสมัคร</u> :
                      {{
                        period_sedirectors.period_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                      <br />
                      เหลือเวลาอีก
                    </h3>
                    <flip-countdown
                      :deadline="
                        period_sedirectors.period_sedirector_stop + ' 16:30:00'
                      "
                      :labels="labels"
                    ></flip-countdown>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>

          <!-- แจ้งเตือนย้ายครู -->
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="pa-2"
              v-if="periods.period_enable === '1'"
            >
              <v-row>
                <v-col cols="7">
                  <v-alert
                    outlined
                    prominent
                    icon="mdi-bell-ring"
                    type="error"
                    border="left"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <h2 class="primary--text">
                          <v-icon color="primary">mdi-account</v-icon>
                          ข้าราชการครู
                        </h2>
                        <h2>สามารถดำเนินการ <u>ยื่นคำร้องขอย้าย</u></h2>
                        <h3>
                          ก่อนระบบปิดในวันที่
                          {{
                            periods.period_stop
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </h3>
                      </v-col>
                      <v-col class="shrink">
                        <div v-if="users.user_status === 'teacher'">
                          <v-col
                            cols="12"
                            md="12"
                            class="text-center"
                            v-if="
                              periods.period_enable === '1' &&
                                users.user_status === 'teacher' &&
                                periods.period_type === 'teacher'
                            "
                          >
                            <div v-if="users.date_app_now === ''"></div>
                            <div v-else>
                              <v-form
                                v-if="
                                  users.type_personnel === 'local_dev' &&
                                    get_gov_Age_year < 4
                                "
                              >
                              </v-form>
                              <v-form v-else>
                                <v-btn
                                  rounded
                                  prominent
                                  color="primary"
                                  border="bottom"
                                  dark
                                  to="/transference_personnel"
                                  @click="timeCheck()"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                  <h2>ยื่นคำร้องขอย้าย</h2></v-btn
                                >
                                <v-btn text color="red" @click="VideoDialog()">
                                  <v-icon large color="red">mdi-video</v-icon>
                                </v-btn>
                              </v-form>
                            </div>
                          </v-col>
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col cols="5">
                  <div class="text-center">
                    <h3>
                      ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                      {{
                        periods.period_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                      <br />
                      เหลือเวลาอีก
                    </h3>
                    <flip-countdown
                      :deadline="periods.period_stop + ' 16:30:00'"
                      :labels="labels"
                    ></flip-countdown>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>

          <!-- แจ้งเตือนย้าย 38 ค.(2) -->
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="pa-2"
              v-if="
                periods_38.period_enable === '1' &&
                  (users.user_status == '38' ||
                    users.user_status == '3801' ||
                    users.user_status == '3802' ||
                    users.user_status == '3803')
              "
            >
              <v-row>
                <v-col cols="7">
                  <v-alert
                    outlined
                    prominent
                    icon="mdi-bell-ring"
                    type="error"
                    border="left"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <h2 class="primary--text">
                          <v-icon color="primary">mdi-account</v-icon>
                          บุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2)
                        </h2>
                        <h2>สามารถดำเนินการ <u>ยื่นคำร้องขอย้าย</u></h2>
                        <h3>
                          ก่อนระบบปิดในวันที่
                          {{
                            periods_38.period_stop
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </h3>
                      </v-col>
                      <v-col class="shrink">
                        <div
                          v-if="
                            users.user_status == '38' ||
                              users.user_status == '3801' ||
                              users.user_status == '3802' ||
                              users.user_status == '3803'
                          "
                        >
                          <v-col
                            cols="12"
                            md="12"
                            class="text-center"
                            v-if="
                              periods_38.period_enable === '1' &&
                                (users.user_status == '38' ||
                                  users.user_status == '3801' ||
                                  users.user_status == '3802' ||
                                  users.user_status == '3803') &&
                                periods_38.period_type === '38'
                            "
                          >
                            <div v-if="users.date_app_now === ''"></div>
                            <div v-else>
                              <v-form>
                                <v-btn
                                  rounded
                                  prominent
                                  color="primary"
                                  border="bottom"
                                  dark
                                  to="/transference_personnel_38"
                                  @click="timeCheck()"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                  <h2>ยื่นคำร้องขอย้าย</h2></v-btn
                                >
                                <!--   <v-btn text color="red" @click="VideoDialog()">
                                  <v-icon large color="red">mdi-video</v-icon>
                                </v-btn> -->
                              </v-form>
                            </div>
                          </v-col>
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col cols="5">
                  <div class="text-center">
                    <h3>
                      ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                      {{
                        periods_38.period_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                      <br />
                      เหลือเวลาอีก
                    </h3>
                    <flip-countdown
                      :deadline="periods_38.period_stop + ' 16:30:00'"
                      :labels="labels"
                    ></flip-countdown>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>

          <!-- แจ้งเตือนย้าย ผอ -->
          <v-hover v-slot="{ hover }" v-if="users.user_status === 'director'">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="pa-2"
              v-if="periodsmanage_di.period_enable === '1'"
            >
              <v-row>
                <v-col cols="7">
                  <v-alert
                    outlined
                    prominent
                    icon="mdi-bell-ring"
                    type="error"
                    border="left"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <h2 class="primary--text">
                          <v-icon color="primary">mdi-account</v-icon>
                          ผู้อำนวยการวิทยาลัย
                        </h2>
                        <h2>
                          สามารถดำเนินการ <u>ยื่นคำร้องขอย้าย </u>

                          <u v-if="periodsmanage_di.period_normal_case === '1'"
                            >[ กรณีปกติ ]
                          </u>
                          <u
                            v-if="periodsmanage_di.period_quality_case === '1'"
                            class="info--text"
                          >
                            [ เพื่อพัฒนาคุณภาพการศึกษา ]</u
                          >
                        </h2>
                        <h3>
                          ก่อนระบบปิดในวันที่
                          {{
                            periodsmanage_di.period_stop
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </h3>
                        <div>
                          <v-btn
                            v-if="periodsmanage_di.period_quality_case === '1'"
                            rounded
                            color="info"
                            dark
                            class="ma-1"
                            to="/transference_manage_d"
                          >
                            <v-icon>mdi-pencil</v-icon>
                            การย้ายสายงานบริหารสถานศึกษา
                            <span class="red--text pa-3 font-weight-bold">
                              (เพื่อพัฒนาคุณภาพการศึกษา)</span
                            >
                          </v-btn>

                          <v-btn
                            v-if="periodsmanage_di.period_normal_case === '1'"
                            rounded
                            color="primary"
                            dark
                            to="/transference_manage"
                            class="ma-1"
                          >
                            <h3>
                              <v-icon>mdi-pencil</v-icon>
                              การย้ายสายงานบริหารสถานศึกษา (กรณีปกติ)
                            </h3>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col cols="5">
                  <div class="text-center">
                    <h3>
                      ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                      {{
                        periodsmanage_di.period_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                      <br />
                      เหลือเวลาอีก
                    </h3>
                    <flip-countdown
                      :deadline="periodsmanage_di.period_stop + ' 16:30:00'"
                      :labels="labels"
                    ></flip-countdown>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>

          <!-- แจ้งเตือนย้าย รอง -->
          <v-hover
            v-slot="{ hover }"
            v-if="users.user_status === 'se_director'"
          >
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="pa-2"
              v-if="periodsmanage_sedi.period_enable === '1'"
            >
              <v-row>
                <v-col cols="7">
                  <v-alert
                    outlined
                    prominent
                    icon="mdi-bell-ring"
                    type="error"
                    border="left"
                  >
                    <v-row align="center">
                      <v-col class="grow">
                        <h2 class="primary--text">
                          <v-icon color="primary">mdi-account</v-icon>
                          รองผู้อำนวยการวิทยาลัย
                        </h2>
                        <h2>สามารถดำเนินการ <u>ยื่นคำร้องขอย้าย</u></h2>
                        <h3>
                          ก่อนระบบปิดในวันที่
                          {{
                            periodsmanage_sedi.period_stop
                              | moment("add", "543 years")
                              | moment("D MMMM YYYY")
                          }}
                        </h3>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-col>
                <v-col cols="5">
                  <div class="text-center">
                    <h3>
                      ปิดระบบ <u>ยื่นคำร้องขอย้าย</u> :
                      {{
                        periodsmanage_sedi.period_stop
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                      <br />
                      เหลือเวลาอีก
                    </h3>
                    <flip-countdown
                      :deadline="periodsmanage_sedi.period_stop + ' 16:30:00'"
                      :labels="labels"
                    ></flip-countdown>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" md="12"> </v-col>
        <v-col cols="12" md="4">
          <base-material-card
            class="v-card-profile"
            avatar="/HRvecfiles/logo.png"
          >
            <v-card-text class="text-center">
              <div align="center">
                <img
                  v-if="users.personnel_temporary_pic"
                  width="300"
                  style="border-radius: 8px"
                  :src="'/HRvecfiles/' + users.personnel_temporary_pic"
                />
                <img
                  v-else
                  width="200"
                  src="/HRvecfiles/blank-human-image.png"
                  style="border-radius: 30px"
                />
              </div>
              <div class="text-center">
                <v-btn
                  @click.native="personnelPicDelete()"
                  color="red darken-3"
                  v-if="users.personnel_temporary_pic"
                  dark
                  icon
                >
                  <v-icon>mdi-delete-circle-outline</v-icon>
                </v-btn>
              </div>
              <h3 class="font-weight-light mb-1 grey--text">
                {{ users.id_card }}
              </h3>
              <h3 class="font-weight-light mb-1 grey--text">
                {{ users.title_s }}{{ users.frist_name }} {{ users.last_name }}
              </h3>
              <h4 class="font-weight-light mb-3 black--text">
                {{ users.college_name }}
              </h4>
              <p class="font-weight-light grey--text"></p>

              <div class="text-center">
                <v-btn
                  elevation="2"
                  x-large
                  rounded
                  color="warning"
                  class="mr-0"
                  @click.native="personnelPicUpdate()"
                >
                  <v-icon>mdi-pencil</v-icon> แก้ไขรูปโปรไฟล์</v-btn
                >
              </div>
              <div>
                <v-btn
                  elevation="2"
                  x-large
                  rounded
                  color="warning"
                  class="mr-0"
                  @click.native="passwordUpdate()"
                >
                  <v-icon>mdi-pencil</v-icon>
                  แก้ไขรหัสผ่านเข้าสู่ระบบ</v-btn
                >
              </div>
            </v-card-text>
          </base-material-card>
          <v-card color="#FFF3E0" v-if="users.user_status === 'teacher'">
            <v-row>
              <v-col cols="12" md="12">
                <div class="text-center">
                  <h3>ขอย้ายกรณีพิเศษ</h3>
                  <span></span>
                  <div class="text-center">
                    <v-btn
                      rounded
                      color="warning"
                      large
                      to="/transference_personnel_sp"
                    >
                      <v-icon class="pa-2">mdi-pencil</v-icon> ย้ายกรณีพิเศษ
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <v-card color="#FFF3E0" v-if="users.user_status === 'teacher'">
            <v-row>
              <v-col cols="12" md="12">
                <div class="text-left pa-5">
                  <h3 class="red--text">
                    ข้อมูลในระบบของท่าน
                    หากยังไม่เป็นปัจจุบันให้รอการปรับปรุงจากส่วนกลาง ระบบนี้
                    ทางกลุ่มงานบุคคล 2 สำนักอำนวยการ มีภาระกิจในระบบเพียง 4
                    ภาระกิจ ประกอบด้วย
                  </h3>
                  <h4>1.ระบบย้ายข้าราชการครู</h4>
                  <h4>2.ระบบย้ายสายบริหาร</h4>
                  <h4>3.ระบบอัตรากำลัง</h4>
                  <h4>4.ระบบประเมินครูผู้ช่วย</h4>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <base-material-card>
            <template v-slot:heading>
              <div class="font-weight-light">
                <h2 class="h1 font-weight-light">
                  <v-icon large left>mdi-account</v-icon> ข้อมูลส่วนบุคคล
                </h2>
              </div>
            </template>
            <v-card class="elevation-6" width="100%">
              <v-form>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <div v-if="users.status_appove === 'wait'">
                        <v-alert
                          text
                          dense
                          color="teal"
                          icon="mdi-clock-fast"
                          border="left"
                        >
                          <h3 class="red--text">
                            ข้อมูลของท่านอยู่ระหว่างการตรวจสอบ
                            ในระหว่างการย้ายรอบที่ผ่านมา
                          </h3>
                        </v-alert>
                      </div>

                      <div v-else-if="users.user_status === 'teacher'">
                        <v-alert
                          outlined
                          type="success"
                          v-if="
                            periods.period_enable === '1' &&
                              periods.period_type === 'teacher'
                          "
                        >
                          <v-col cols="12">
                            <v-alert
                              outlined
                              type="info"
                              prominent
                              border="left"
                            >
                              <h2 class="text-center">
                                การยืนย้ายสายการสอน
                              </h2>

                              <h3 class="text-center">
                                ครั้งที่
                                {{ periods.period_times }} ระหว่างวันที่
                                {{
                                  periods.period_start
                                    | moment("add", "543 years")
                                    | moment("D MMMM YYYY")
                                }}
                                -
                                {{
                                  periods.period_stop
                                    | moment("add", "543 years")
                                    | moment("D MMMM YYYY")
                                }}
                              </h3>
                            </v-alert>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                            class="text-center"
                            v-if="
                              periods.period_enable === '1' &&
                                users.user_status === 'teacher' &&
                                periods.period_type === 'teacher'
                            "
                          >
                            <div v-if="users.date_app_now === ''">
                              <v-alert prominent type="error">
                                <v-row>
                                  <v-col class="grow">
                                    <h3>
                                      ให้งานบุคลากร สถานศึกษาของท่าน
                                      ดำเนินการเกี่ยวกับวันที่ปฏิบัติงาน ณ
                                      สถานศึกษาปัจจุบันให้เรียบร้อย
                                    </h3>
                                  </v-col>
                                </v-row>
                              </v-alert>
                            </div>
                            <div v-else>
                              <v-form
                                v-if="
                                  users.type_personnel === 'local_dev' &&
                                    get_gov_Age_year < 4
                                "
                              >
                                <v-alert prominent type="error">
                                  <v-row>
                                    <v-col class="grow">
                                      <h3>
                                        ท่านสามาถดำเนินการเขียนย้ายได้กรณีที่ปฏิบัติงาน
                                        ณ สถานศึกษาปัจจุบันเป็นเวลาไม่น้อยกว่า 4
                                        ปี
                                      </h3>
                                    </v-col>
                                  </v-row>
                                </v-alert>
                              </v-form>
                              <v-form v-else>
                                <v-btn
                                  rounded
                                  prominent
                                  color="primary"
                                  border="bottom"
                                  x-large
                                  dark
                                  to="/transference_personnel"
                                  @click="timeCheck()"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                  <h2>ยื่นคำร้องขอย้าย</h2></v-btn
                                >
                              </v-form>
                            </div>
                          </v-col>
                        </v-alert>
                      </div>

                      <div v-else-if="users.user_status === 'director'">
                        <v-alert
                          outlined
                          type="success"
                          v-if="periodsmanage_di.period_enable === '1'"
                        >
                          <v-btn
                            v-if="periodsmanage_di.period_quality_case === '1'"
                            rounded
                            color="info"
                            block
                            dark
                            class="ma-1"
                            to="/transference_manage_d"
                          >
                            <v-icon>mdi-pencil</v-icon>
                            การย้ายสายงานบริหารสถานศึกษา
                            <span class="red--text pa-3 font-weight-bold">
                              (เพื่อพัฒนาคุณภาพการศึกษา)</span
                            >
                          </v-btn>

                          <v-btn
                            v-if="periodsmanage_di.period_normal_case === '1'"
                            rounded
                            color="primary"
                            block
                            dark
                            to="/transference_manage"
                            class="ma-1"
                          >
                            <h3>
                              <v-icon>mdi-pencil</v-icon>
                              การย้ายสายงานบริหารสถานศึกษา (กรณีปกติ)
                            </h3>
                          </v-btn>
                        </v-alert>
                      </div>

                      <div v-else-if="users.user_status === 'se_director'">
                        <v-alert
                          outlined
                          type="success"
                          v-if="periodsmanage_sedi.period_enable === '1'"
                        >
                          <v-btn
                            rounded
                            color="primary"
                            block
                            dark
                            to="/transference_manage"
                            class="ma-1"
                          >
                            <h3>
                              <v-icon>mdi-pencil</v-icon>
                              การย้ายสายงานบริหารสถานศึกษา (กรณีปกติ)
                            </h3>
                          </v-btn>
                        </v-alert>
                      </div>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-alert type="info" outlined>
                        <div class="text-center">
                          <v-icon color="indigo">
                            mdi-domain
                          </v-icon>
                          สังกัด {{ users.agencys }}
                        </div>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-list two-line outlined>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-domain
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>เลขที่ตำแหน่ง</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ users.id_position }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title>ตำแหน่ง</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ users.position_name }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-school
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>คุณวุฒิ</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.ed_abb
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title>สาขาวิชาเอก</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.ed_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-settings
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>วิทยฐานะ</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.rang_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title>ระดับ</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.rang_level
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-clipboard-account
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              >ใบประกอบวิชาชีพครู</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{ users.pt_licence }}
                              <v-btn
                                v-if="users.pt_licence_file"
                                fab
                                outlined
                                small
                                color="info"
                                @click="viewpdflicenseteach(users.pt_licence_file)"
                                ><v-icon>mdi-printer</v-icon></v-btn
                              >

                              <v-btn
                                v-if="users.pt_licence_file"
                                fab
                                outlined
                                small
                                color="red"
                                class="ml-2"
                                @click="deletelicenseteach()"
                                ><v-icon>mdi-delete</v-icon></v-btn
                              >
                              <v-btn
                                v-else
                                fab
                                outlined
                                small
                                color="warning"
                                class="ml-2"
                                @click="personnelUpdate()"
                                ><v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <v-chip
                                color="warning"
                                dark
                                v-if="!users.pt_licence_file"
                                class="font-weight-bold"
                                >ไม่ได้แนบไฟล์ใบประกอบวิชาชีพ</v-chip
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-clipboard-account
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>วันออกบัตร</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.pt_ondate
                                | moment("add", "543 years")
                                | moment("D MMMM YYYY")
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title>วันหมดอายุ</v-list-item-title>
                            <v-list-item-subtitle
                              >{{
                                users.pt_enddate
                                  | moment("add", "543 years")
                                  | moment("D MMMM YYYY")
                              }}
                              <br />
                              <div v-if="!users.Agelicence">
                                <v-chip color="warning" dark>
                                  <v-icon>mdi-information-outline</v-icon>
                                  <span class="font-weight-bold"> </span>
                                </v-chip>
                              </div>

                              <div v-else-if="users.Agelicence <= 60">
                                <v-chip color="red" dark>
                                  <span class="font-weight-bold">
                                    ในอีก : {{ users.Agelicence || "" }} วัน
                                  </span>
                                </v-chip>
                              </div>
                              <div v-else-if="users.Agelicence <= 180">
                                <v-chip color="warning" dark>
                                  <span class="font-weight-bold">
                                    วัน ในอีก : {{ users.Agelicence || "" }}
                                  </span>
                                </v-chip>
                              </div>

                              <div v-else>
                                <span class="font-weight-bold green--text">
                                  ในอีก : {{ users.Agelicence || "" }} วัน
                                </span>
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-clipboard-account
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              >ใบประกอบวิชาชีพผู้บริหารสถานศึกษา</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{ users.pt_licencedr }}
                              <v-btn
                                v-if="users.pt_licencedr_file"
                                fab
                                outlined
                                small
                                color="info"
                                @click="viewpdflicensedr()"
                                ><v-icon>mdi-printer</v-icon></v-btn
                              >

                              <v-btn
                                v-if="users.pt_licencedr_file"
                                fab
                                outlined
                                small
                                color="red"
                                class="ml-2"
                                @click="deletelicensedr()"
                                ><v-icon>mdi-delete</v-icon></v-btn
                              >
                              <v-btn
                                v-else
                                fab
                                outlined
                                small
                                color="warning"
                                class="ml-2"
                                @click="personnelUpdate()"
                                ><v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <v-chip
                                color="warning"
                                dark
                                v-if="!users.pt_licencedr_file"
                                class="font-weight-bold"
                                >ไม่ได้แนบไฟล์ใบประกอบวิชาชีพ</v-chip
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-clipboard-account
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              >การได้มาซึ่งใบประกอบวิชาชีพผู้บริหารสถานศึกษา</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              <v-chip
                                outlined
                                color="success"
                                v-if="users.pt_licencedr_type === 'a'"
                              >
                                <v-icon>mdi-information</v-icon> สำเร็จการศึกษา
                                ด้านบริหารการศึกษา</v-chip
                              >
                              <v-chip
                                outlined
                                color="info"
                                v-else-if="users.pt_licencedr_type === 'b'"
                              >
                                <v-icon>mdi-information</v-icon>
                                การเทียบประสบการณ์ในการบริหารสถานศึกษามาแล้วไม่น้อยกว่า
                                10 ปี</v-chip
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-clipboard-account
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>วันออกบัตร</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.pt_ondatedr
                                | moment("add", "543 years")
                                | moment("D MMMM YYYY")
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title>วันหมดอายุ</v-list-item-title>
                            <v-list-item-subtitle
                              >{{
                                users.pt_enddatedr
                                  | moment("add", "543 years")
                                  | moment("D MMMM YYYY")
                              }}
                              <br />
                              <div v-if="!users.Agelicencedr">
                                <v-chip color="warning" dark>
                                  <v-icon>mdi-information-outline</v-icon>
                                  <span class="font-weight-bold"> </span>
                                </v-chip>
                              </div>

                              <div v-else-if="users.Agelicencedr <= 60">
                                <v-chip color="red" dark>
                                  <span class="font-weight-bold">
                                    ในอีก : {{ users.Agelicencedr || "" }} วัน
                                  </span>
                                </v-chip>
                              </div>
                              <div v-else-if="users.Agelicencedr <= 180">
                                <v-chip color="warning" dark>
                                  <span class="font-weight-bold">
                                    วัน ในอีก : {{ users.Agelicencedr || "" }}
                                  </span>
                                </v-chip>
                              </div>

                              <div v-else>
                                <span class="font-weight-bold green--text">
                                  ในอีก : {{ users.Agelicencedr || "" }} วัน
                                </span>
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-list two-line outlined>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-account-box
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              >รหัสบัตรประชาชน</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              users.id_card
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action></v-list-item-action>

                          <v-list-item-content>
                            <v-list-item-title
                              >วันเดือนปี เกิด</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{ users.brith_day }}/{{ users.brith_month }}/{{
                                users.brith_year
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>

                          <v-list-item-content>
                            <v-list-item-title
                              >วันเดือนปี บรรจุ</v-list-item-title
                            >
                            <v-list-item-subtitle>
                              {{ users.appoin_day }}/{{ users.appoin_month }}/{{
                                users.appoin_year
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-clipboard-account
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>เกษียณ</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.retrire_year
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title
                              >เบอร์โทรติดต่อ</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              users.tel_p
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider inset></v-divider>

                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="indigo">
                              mdi-clipboard-account
                            </v-icon>
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title>สถานภาพสมรส</v-list-item-title>
                            <v-list-item-subtitle>{{
                              marital_status
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title>E-mail</v-list-item-title>
                            <v-list-item-subtitle>{{
                              users.e_mail
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <div class="text-center">
                          <v-btn
                            elevation="2"
                            rounded
                            color="warning"
                            class="mr-0"
                            @click.native="personnelUpdate()"
                          >
                            <v-icon>mdi-pencil</v-icon>
                            ปรับปรุงข้อมูล</v-btn
                          >

                          <v-btn
                            :href="'#/user/resume/?id_card=' + users.id_card"
                            target="_blank"
                            rounded
                            color="info"
                          >
                            <v-icon>mdi-printer</v-icon> ประวัติ</v-btn
                          >
                        </div>
                      </v-list>
                    </v-col>

                    <v-col cols="12" md="12">
                      <dir v-if="users.status_appove === 'wait'"></dir>
                      <div v-else>
                        <h2 class="text-center red--text">
                          * ข้อมูลส่วนบุคลคลให้ท่านตรวจสอบ
                          และแก้ไขข้อมูลเฉพาะบางส่วนที่เกี่ยวข้องกับการดำเนินการในระบบ
                        </h2>
                        <h2 class="text-center red--text">
                          ให้เรียบร้อยก่อนการดำเนินการในส่วนที่เกี่ยวข้องกับท่าน
                        </h2>
                        <h3 class="red--text">
                          * ส่วนข้อมูลที่ผิดพลาด เช่นชื่อ-นามสกุลผิด
                          แจ้งงานบุคลากรของท่านประสานส่วนกลางผ่านช่องทาง line
                        </h3>
                        <h3 class="red--text">
                          * ส่วนข้อมูลอื่นๆ เช่น ตำแหน่ง วิทยฐานะ ระดับ
                          ที่ไม่เป็นปัจจุบันให้รอการปรับปรุงข้อมูลจากส่วนกลาง
                        </h3>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-card>
            <h3 class="pa-1 font-weihgt-bold">
              <v-icon>mdi-clipboard-text-clock</v-icon> ประวัติการยื่นย้าย
            </h3>
            <v-data-table
              v-if="users.user_status === 'teacher'"
              color="success"
              :loading="loading"
              :headers="headers_teach"
              :items="transference_personnels"
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ item.tid_ref }}</td>
                  <td class="text-center">{{ item.id_card }}</td>
                  <td class="text-center">{{ item.frist_name }}</td>
                  <td class="text-center">{{ item.last_name }}</td>
                  <td class="text-center">{{ item.time_ss }}</td>
                  <td class="text-center">{{ item.year_ss }}</td>
                  <td class="text-center">{{ item.age_app_time }}</td>
                  <td class="text-center">{{ item.date_time }}</td>

                  <td class="text-center">
                    <div v-if="item.tp_steps === '11'">
                      <v-btn
                        large
                        color="info"
                        text
                        :href="'#/user/print_info/' + item.tid_ref"
                        target="_blank"
                        ><v-icon large>mdi-printer</v-icon></v-btn
                      >
                    </div>

                    <div v-else>
                      <v-icon color="red darken-2">mdi-alert</v-icon>
                      กรุณาทำให้ครบขั้นตอน
                    </div>
                  </td>

                  <td class="text-center">
                    <div v-if="item.tp_steps === '11'">
                      <v-btn
                        large
                        color="info"
                        text
                        :href="'#/user/print_doc_kpi/' + item.tid_ref"
                        target="_blank"
                        ><v-icon large>mdi-printer</v-icon></v-btn
                      >
                    </div>
                    <div v-else>
                      <v-icon color="red darken-2">mdi-alert</v-icon>
                      กรุณาทำให้ครบขั้นตอน
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-if="item.tp_steps === '11'">
                      <v-btn
                        large
                        color="info"
                        text
                        :href="'#/user/print_14_71_form/' + item.tid_ref"
                        target="_blank"
                        ><v-icon large>mdi-printer</v-icon></v-btn
                      >
                    </div>
                    <div v-else>
                      <v-icon color="red darken-2">mdi-alert</v-icon>
                      กรุณาทำให้ครบขั้นตอน
                    </div>
                  </td>
                  <td class="text-center">
                    <div v-if="item.tp_steps === '11'">
                      <v-btn
                        large
                        color="info"
                        text
                        :href="'#/user/print_14_form/' + item.tid_ref"
                        target="_blank"
                        ><v-icon large>mdi-printer</v-icon></v-btn
                      >
                    </div>

                    <div v-else>
                      <v-icon color="red darken-2">mdi-alert</v-icon>
                      กรุณาทำให้ครบขั้นตอน
                    </div>
                  </td>

                  <!--  <td class="text-center">
                    <v-btn
                      large
                      color="info"
                      text
                      :href="'#/user/print_doc_kpi/' + item.tid_ref"
                      target="_blank"
                      ><v-icon large>mdi-printer</v-icon></v-btn
                    >
                  </td> -->

                  <!--  <td class="text-center">
                    <v-chip
                      v-if="item.comment_dr_c === 'approp'"
                      color="green"
                      dark
                      >เห็นควร</v-chip
                    >
                    <v-chip
                      v-else-if="item.comment_dr_c === 'inapprop'"
                      color="red"
                      dark
                      >ไม่เห็นควร</v-chip
                    >
                    <v-icon large v-else color="info"
                      >mdi-comment-processing</v-icon
                    >
                  </td> -->
                </tr>
              </template>
            </v-data-table>
            <v-data-table
              v-else-if="
                users.user_status === 'director' ||
                  users.user_status === 'se_director'
              "
              color="success"
              :loading="loading"
              :headers="headers_manage"
              :items="transference_personnels"
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ item.manage_id_ref }}</td>
                  <td class="text-center">{{ item.id_card }}</td>
                  <td class="text-center">{{ item.frist_name }}</td>
                  <td class="text-center">{{ item.last_name }}</td>
                  <td class="text-center">{{ item.manage_time_s }}</td>
                  <td class="text-center">{{ item.manage_year_s }}</td>
                  <td class="text-center">{{ item.manage_age_app_time }}</td>
                  <td class="text-center">{{ item.manage_date_time }}</td>
                  <td class="text-center">
                    <v-chip v-if="item.manage_case_move === 'quality'"
                      >เพื่อพัฒนา</v-chip
                    >
                    <v-chip v-else-if="item.manage_case_move === 'normal'"
                      >ปกติ</v-chip
                    >
                    <v-chip v-else-if="item.manage_case_move === 'special'"
                      >กรณีพิเศษ</v-chip
                    >
                  </td>

                  <td class="text-center">
                    <v-btn
                      v-if="item.manage_case_move === 'quality'"
                      large
                      color="info"
                      text
                      :href="
                        '#/user/print_info_manage_d/?manage_id_ref=' +
                          item.manage_id_ref
                      "
                      target="_blank"
                      ><v-icon large>mdi-printer</v-icon></v-btn
                    >
                    <v-btn
                      v-else-if="item.manage_case_move === 'normal'"
                      large
                      color="info"
                      text
                      :href="
                        '#/user/print_info_manage/?manage_id_ref=' +
                          item.manage_id_ref
                      "
                      target="_blank"
                      ><v-icon large>mdi-printer</v-icon></v-btn
                    >
                    <v-btn
                      v-else-if="item.manage_case_move === 'special'"
                      large
                      color="info"
                      text
                      :href="
                        '#/user/print_info_manage/?manage_id_ref=' +
                          item.manage_id_ref
                      "
                      target="_blank"
                      ><v-icon large>mdi-printer</v-icon></v-btn
                    >
                  </td>

                  <td class="text-center">
                    <span v-if="item.manage_status_save === 'Save'"
                      ><v-chip color="green" dark>เสนอขอย้าย</v-chip>
                    </span>
                    <span v-else>
                      <v-chip color="warning">บันทึกชั่วคราว</v-chip>
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>

            <v-data-table
              v-if="
                users.user_status === '38' ||
                  users.user_status === '3801' ||
                  users.user_status === '3802' ||
                  users.user_status === '3803'
              "
              color="success"
              :loading="loading"
              :headers="headers_38"
              :items="transference_personnels"
            >
              <template v-slot:item="{ item, index }">
                <tr>
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ item.tp_id_ref }}</td>
                  <td class="text-center">{{ item.id_card }}</td>
                  <td class="text-center">{{ item.frist_name }}</td>
                  <td class="text-center">{{ item.last_name }}</td>
                  <td class="text-center">{{ item.tp_time_s }}</td>
                  <td class="text-center">{{ item.tp_year_s }}</td>
                  <td class="text-center">{{ item.tp_age_app_time }}</td>
                  <td class="text-center">{{ item.tp_date_time }}</td>

                  <td class="text-center">
                    <div v-if="item.tp_steps === '12'">
                      <v-btn
                        large
                        color="info"
                        text
                        :href="
                          '#/user/print_info_38/?tp_id_ref=' + item.tp_id_ref
                        "
                        target="_blank"
                        ><v-icon large>mdi-printer</v-icon></v-btn
                      >
                    </div>

                    <div v-else>
                      <v-icon color="red darken-2">mdi-alert</v-icon>
                      กรุณาทำให้ครบขั้นตอน
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          md="12"
          v-if="users.user_status === 'teacher' || users.user_status === 'S'"
        >
          <v-card>
            <h3 class="pa-1 font-weihgt-bold">
              <v-icon>mdi-clipboard-text-clock</v-icon> ประวัติการสมัครคัดเลือก
              ตำแหน่ง รองผู้อำนวยการวิทยาลัย
            </h3>
            <v-data-table
              color="success"
              :loading="loading"
              :headers="headersSecDirecApp"
              :items="sedirector_app_idcards"
            >
              <template v-slot:item.sedirector_app_status="{ item }">
                <v-chip
                  color="success"
                  v-if="item.sedirector_app_status === '8'"
                >
                  บันทึก
                </v-chip>
                <v-chip color="warning" v-else>
                  {{ item.sedirector_app_status }} : ยังไม่ได้บันทึก
                </v-chip>
              </template>

              <template v-slot:item.sedirector_app_position="{ item }">
                <v-chip
                  color="success"
                  v-if="item.sedirector_app_position === 'teach'"
                >
                  ครูผู้สอน</v-chip
                >
                <v-chip
                  color="success"
                  v-else-if="item.sedirector_app_position === 'head'"
                >
                  หัวหน้างาน</v-chip
                >
                <v-chip
                  color="success"
                  v-else-if="item.sedirector_app_position === 'director'"
                >
                  รักษาการรองผู้อำนวยการ</v-chip
                >
                <v-chip color="warning" v-else>
                  ยังไม่ได้บันทึก
                </v-chip>
              </template>

              <template v-slot:item.sedirector_app_discipline="{ item }">
                <v-chip
                  color="warning"
                  v-if="item.sedirector_app_discipline === 'ever'"
                >
                  เคยถูกลงโทษทางวินัย
                </v-chip>
                <v-chip color="success" v-else>
                  ไม่เคยถูกลงโทษทางวินัย
                </v-chip>
              </template>

              <template v-slot:item.sedirector_app_time="{ item }">
                {{ item.sedirector_app_time + "/" + item.sedirector_app_year }}
              </template>

              <template v-slot:item.sedirector_app_file="{ item }">
                <div
                  v-if="
                    item.sedirector_app_file && item.sedirector_app_status > 7
                  "
                >
                  <div>
                    <v-icon
                      color="red"
                      large
                      v-if="item.sedirector_app_file_cc === 'cc'"
                      >mdi-information</v-icon
                    >
                    <v-btn large text rounded color="green"
                      ><v-icon large>mdi-printer</v-icon></v-btn
                    >
                    <v-icon
                      v-if="
                        item.sedirector_app_pvc_approve === 'pass' ||
                          item.sedirector_app_pvc_approve === 'fail' ||
                          item.sedirector_app_pvc_approve === 'CC'
                      "
                      >mdi-checkbox-marked</v-icon
                    >
                    <v-icon v-else large color="red">mdi-delete-circle</v-icon>
                  </div>
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </template>

              <template v-slot:item.sedirector_app_file_A="{ item }">
                <div
                  v-if="
                    item.sedirector_app_file_A && item.sedirector_app_status > 7
                  "
                >
                  <v-icon
                    color="red"
                    large
                    v-if="item.sedirector_app_file_A_CC === 'cc'"
                    >mdi-information</v-icon
                  >
                  <v-btn text rounded color="green" large
                    ><v-icon large>mdi-printer</v-icon></v-btn
                  >
                  <v-icon
                    v-if="
                      item.sedirector_app_pvc_approve === 'pass' ||
                        item.sedirector_app_pvc_approve === 'fail' ||
                        item.sedirector_app_pvc_approve === 'CC'
                    "
                    >mdi-checkbox-marked</v-icon
                  >
                  <v-icon v-else color="red" large>mdi-delete-circle</v-icon>
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </template>

              <template v-slot:item.sedirector_app_file_B="{ item }">
                <div
                  v-if="
                    item.sedirector_app_file_B && item.sedirector_app_status > 7
                  "
                >
                  <v-icon
                    color="red"
                    large
                    v-if="item.sedirector_app_file_B_CC === 'cc'"
                    >mdi-information</v-icon
                  >
                  <v-btn text rounded color="green" large
                    ><v-icon large>mdi-printer</v-icon></v-btn
                  >
                  <v-icon
                    v-if="
                      item.sedirector_app_pvc_approve === 'pass' ||
                        item.sedirector_app_pvc_approve === 'fail' ||
                        item.sedirector_app_pvc_approve === 'CC'
                    "
                    >mdi-checkbox-marked</v-icon
                  >
                  <v-icon v-else color="red" large>mdi-delete-circle</v-icon>
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </template>

              <template v-slot:item.sedirector_app_datetime="{ item }">
                <div v-if="item.sedirector_app_status <= 7">
                  <v-icon color="warning" size="30">mdi-alert</v-icon>
                  ทำรายการไม่สำเร็จ
                </div>
                <div v-else>
                  {{
                    item.sedirector_app_datetime
                      | moment("add", "543 years")
                      | moment("D MMMM YYYY : h:mm:ss a")
                  }}
                </div>
              </template>

              <template v-slot:item.sedirector_app_pvc_approve="{ item }">
                <div v-if="item.sedirector_app_status > 7">
                  <div v-if="item.sedirector_app_pvc_approve === 'pass'">
                    <v-chip rounded text color="green" dark
                      ><v-icon>mdi-checkbox-marked-circle</v-icon>ผ่าน</v-chip
                    >
                    <div class="pa-2">
                      <v-btn rounded color="warning" small>
                        <v-icon>mdi-close</v-icon> ขอยกเลิกรายงาน</v-btn
                      >
                    </div>
                  </div>

                  <div v-else-if="item.sedirector_app_pvc_approve === 'fail'">
                    <v-chip rounded text color="red" dark
                      ><v-icon>mdi-close-circle-outline</v-icon>ไม่ผ่าน</v-chip
                    >
                  </div>

                  <div v-else-if="item.sedirector_app_pvc_approve === 'CC'">
                    <v-chip rounded text color="red" dark
                      ><v-icon>mdi-close-circle-outline</v-icon>ขอยกเลิก</v-chip
                    >
                  </div>

                  <div v-else>
                    <v-btn rounded icon color="grey" large
                      ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
                    >
                  </div>
                </div>
                <div v-else>
                  <v-icon color="warning">mdi-alert</v-icon> ยังไม่ได้บันทึก
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="12" md="12" v-if="checksedirector_app_idrefs === 'true'">
          <div id="sedirectors">
            <sedirectorAppTimeline />
          </div>
        </v-col>
      </v-row>

      <!-- V-model userdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="addpersonneldialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6" elevation="2">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลเบื้องต้น"
              class="px-5 py-3"
              elevation="2"
            >
              <div align="right">
                {{ users.id_card }} {{ users.title_s }}{{ users.frist_name }}
                {{ users.last_name }}
              </div>
            </base-material-card>
            <v-card-text>
              <v-form ref="personnelform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md12>
                      <div class="font-weight-bold">
                        ข้อมูลคู่สมรส :
                      </div>
                    </v-flex>
                    <v-flex md2>
                      <v-select
                        outlined
                        label="สถานภาพสมรส"
                        :items="user_marital_status"
                        item-text="title"
                        item-value="value"
                        v-model="users.marital_status"
                      ></v-select>
                    </v-flex>
                    <v-flex
                      md3
                      v-if="
                        users.marital_status == 'married' ||
                          users.marital_status == 'separate'
                      "
                    >
                      <v-text-field
                        outlined
                        label="รหัสบัตรประชาชนคู่สมรส :"
                        v-model="users.id_card_m"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      md2
                      v-if="
                        users.marital_status == 'married' ||
                          users.marital_status == 'separate'
                      "
                    >
                      <v-text-field
                        outlined
                        label="คำนำหน้าชื่อ :"
                        v-model="users.title_sm"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      md3
                      v-if="
                        users.marital_status == 'married' ||
                          users.marital_status == 'separate'
                      "
                    >
                      <v-text-field
                        outlined
                        label="ชื่อ :"
                        v-model="users.frist_namem"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      md2
                      v-if="
                        users.marital_status == 'married' ||
                          users.marital_status == 'separate'
                      "
                    >
                      <v-text-field
                        outlined
                        label="นามสกุล :"
                        v-model="users.last_namem"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      md4
                      v-if="
                        users.marital_status == 'married' ||
                          users.marital_status == 'separate'
                      "
                    >
                      <v-text-field
                        outlined
                        label="อาชีพ :"
                        v-model="users.occupation_sm"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      md4
                      v-if="
                        users.marital_status == 'married' ||
                          users.marital_status == 'separate'
                      "
                    >
                      <v-text-field
                        outlined
                        label="สถานที่ทำงาน :"
                        v-model="users.work_placem"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      md4
                      v-if="
                        users.marital_status == 'married' ||
                          users.marital_status == 'separate'
                      "
                    >
                      <v-autocomplete
                        :items="provices_sh"
                        item-text="province_name"
                        item-value="province_ID"
                        label="ภูมิลำเนาของคู่สมรส จังหวัด : "
                        required
                        outlined
                        :rules="[v => !!v || '']"
                        v-model="users.province_IDm"
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex xs12>
                      <hr color="primary" class="mb-5" />
                      <div class="font-weight-bold">
                        ที่อยู่ปัจจุบันของท่าน :
                      </div>
                    </v-flex>
                    <v-flex md2>
                      <v-text-field
                        outlined
                        label="บ้านเลขที่ : "
                        v-model="users.pt_homeid"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md2>
                      <v-text-field
                        outlined
                        label="หมู่ที่ : "
                        v-model="users.pt_moo"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ถนน : "
                        v-model="users.pt_road"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="provices_sh"
                        item-text="province_name"
                        item-value="province_ID"
                        label="ภูมิลำเนาของข้าพเจ้า จังหวัด : "
                        required
                        outlined
                        :rules="[v => !!v || '']"
                        v-model="users.domicile_pt"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md3>
                      <v-autocomplete
                        :items="prefectures"
                        item-text="prefecture_name"
                        item-value="prefecture_ID"
                        label="ภูมิลำเนาของข้าพเจ้า อำเภอ : "
                        required
                        outlined
                        :rules="[v => !!v || '']"
                        v-model="users.pt_perfecture"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md3>
                      <v-autocomplete
                        :items="district"
                        item-text="district_name"
                        item-value="district_ID"
                        label="ภูมิลำเนาของข้าพเจ้า ตำบล : "
                        required
                        outlined
                        :rules="[v => !!v || '']"
                        v-model="users.pt_distirict"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md3>
                      <v-text-field
                        outlined
                        label="เบอร์โทรติดต่อ ของท่าน : "
                        v-model="users.tel_p"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md3>
                      <v-text-field
                        outlined
                        label="E-mail ของท่าน :"
                        v-model="users.e_mail"
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12>
                      <hr color="primary" class="mb-5" />
                      <div class="font-weight-bold">
                        ใบอนุญาตประกอบวิชาชีพครู :
                      </div>
                    </v-flex>

                    <v-flex md12>
                      <div class="text-center font14">
                        <v-radio-group
                          class="ml-15"
                          v-model="manage_assistant_teachers.mt_license_status"
                          row
                        >
                          <template v-slot:label>
                            <div class="font14">
                              สถานะ ใบอนุญาตประกอบวิชาชีพครู
                              <strong> ระบุ</strong>
                            </div>
                          </template>
                          <v-radio value="have">
                            <template v-slot:label>
                              <div class="font14">
                                <strong class="success--text"
                                  >มีใบประกอบวิชาชีพครู</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="donot">
                            <template v-slot:label>
                              <div class="font14">
                                <strong class="warning--text"
                                  >ไม่มีใบประกอบวิชาชีพ</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </v-flex>

                    <v-flex
                      md3
                      v-if="
                        manage_assistant_teachers.mt_license_status === 'have'
                      "
                    >
                      <v-text-field
                        outlined
                        label="ใบอนุญาตประกอบวิชาชีพครู"
                        v-model="users.pt_licence"
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      md3
                      v-if="
                        manage_assistant_teachers.mt_license_status === 'have'
                      "
                    >
                      <v-text-field
                        type="date"
                        outlined
                        label="วันที่ออกบัตร"
                        v-model="users.pt_ondate"
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      md3
                      v-if="
                        manage_assistant_teachers.mt_license_status === 'have'
                      "
                    >
                      <v-text-field
                        type="date"
                        outlined
                        label="วันที่บัตรหมดอายุ"
                        v-model="users.pt_enddate"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md3 v-if="users.pt_licence_file">
                      <v-btn
                        v-if="users.pt_licence_file"
                        fab
                        outlined
                        small
                        color="info"
                        @click="viewpdflicenseteach(users.pt_licence_file)"
                        ><v-icon>mdi-printer</v-icon></v-btn
                      >

                      <v-btn
                        v-if="users.pt_licence_file"
                        fab
                        outlined
                        small
                        color="red"
                        class="ml-2"
                        @click="deletelicenseteach()"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                      ลบใบอนุญาตเดิม
                    </v-flex>
                    <v-flex
                      md3
                      v-if="
                        !users.pt_licence_file &&
                          manage_assistant_teachers.mt_license_status === 'have'
                      "
                    >
                      <v-file-input
                        v-model="license_file"
                        accept=".pdf"
                        name="license_file"
                        color="deep-purple accent-4"
                        counter
                        label="ใบอนุญาตประกอบวิชาชีพครู .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :rules="rules"
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>

                    <v-flex
                      md3
                      v-if="
                        manage_assistant_teachers.mt_license_status === 'donot'
                      "
                    >
                      <v-text-field
                        outlined
                        label="ใบอนุญาตปฏิบัติการสอน"
                        v-model="manage_assistant_teachers.pt_licence_tem"
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      md3
                      v-if="
                        manage_assistant_teachers.mt_license_status === 'donot'
                      "
                    >
                      <v-text-field
                        type="date"
                        outlined
                        label="วันที่ออกบัตร"
                        v-model="manage_assistant_teachers.pt_ondate_tem"
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      md3
                      v-if="
                        manage_assistant_teachers.mt_license_status === 'donot'
                      "
                    >
                      <v-text-field
                        type="date"
                        outlined
                        label="วันที่บัตรหมดอายุ"
                        v-model="manage_assistant_teachers.pt_enddate_tem"
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      md3
                      v-if="manage_assistant_teachers.pt_licence_file_tem"
                    >
                      <v-btn
                        v-if="manage_assistant_teachers.pt_licence_file_tem"
                        fab
                        outlined
                        small
                        color="info"
                        @click="viewpdflicenseteach(manage_assistant_teachers.pt_licence_file_tem)"
                        ><v-icon>mdi-printer</v-icon></v-btn
                      >

                      <v-btn
                        v-if="manage_assistant_teachers.pt_licence_file_tem"
                        fab
                        outlined
                        small
                        color="red"
                        class="ml-2"
                        @click="deletelicenseteachtem()"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                      ลบใบอนุญาตเดิม
                    </v-flex>
                    <v-flex
                      md3
                      v-if="
                        !manage_assistant_teachers.pt_licence_file_tem &&
                          manage_assistant_teachers.mt_license_status ===
                            'donot'
                      "
                    >
                      <v-file-input
                        v-model="pt_licence_file_tem"
                        accept=".pdf"
                        name="pt_licence_file_tem"
                        color="deep-purple accent-4"
                        counter
                        label="ใบอนุญาตปฏิบัติการสอน .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :rules="rules"
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>

                    <v-flex xs12>
                      <hr color="primary" class="mb-5" />
                      <div class="font-weight-bold">
                        ใบอนุญาตประกอบวิชาชีพผู้บริหารสถานศึกษา :
                      </div>
                    </v-flex>

                    <v-flex md3>
                      <v-text-field
                        outlined
                        label="ใบอนุญาตประกอบวิชาชีพผู้บริหารสถานศึกษา"
                        v-model="users.pt_licencedr"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md3>
                      <v-text-field
                        type="date"
                        outlined
                        label="วันที่ออกบัตร"
                        v-model="users.pt_ondatedr"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md3>
                      <v-text-field
                        type="date"
                        outlined
                        label="วันที่บัตรหมดอายุ"
                        v-model="users.pt_enddatedr"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md3 v-if="users.pt_licencedr_file">
                      <v-btn
                        v-if="users.pt_licencedr_file"
                        fab
                        outlined
                        small
                        color="info"
                        @click="viewpdflicensedr()"
                        ><v-icon>mdi-printer</v-icon></v-btn
                      >
                      <v-btn
                        v-if="users.pt_licencedr_file"
                        fab
                        outlined
                        small
                        color="red"
                        class="ml-2"
                        @click="deletelicensedr()"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                      ลบใบอนุญาตเดิม
                    </v-flex>
                    <v-flex md3 v-if="!users.pt_licencedr_file">
                      <v-file-input
                        v-model="license_filedr"
                        accept=".pdf"
                        name="license_filedr"
                        color="deep-purple accent-4"
                        counter
                        label="ใบอนุญาตประกอบวิชาชีพผู้บริหารสถานศึกษา .pdf"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :rules="rules"
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>

                    <v-flex md12 v-if="users.pt_licencedr">
                      <v-radio-group
                        v-model="users.pt_licencedr_type"
                        row
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:label>
                          <div class="font-weight-bold">
                            <v-icon size="30">mdi-card-account-details</v-icon>
                            เลือกการได้มาซึ่ง<strong
                              >ใบประกอบกวิชาชีพผู้บริหารสถานศึกษา</strong
                            >
                          </div>
                        </template>
                        <v-radio value="a">
                          <template v-slot:label>
                            <div>
                              <strong class="success--text"
                                >สำเร็จการศึกษา ด้านบริหารการศึกษา</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="b">
                          <template v-slot:label>
                            <div>
                              <strong class="info--text"
                                >การเทียบประสบการณ์ในการบริหารสถานศึกษามาแล้วไม่น้อยกว่า
                                10 ปี</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>

                    <v-flex xs12>
                      <span class="red--text">
                        * การลบใบอนุญาตประกอบวิชาชีพเดิม
                        เพื่อทำการแนบไฟล์ใหม่</span
                      >
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="addpersonneldialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ปิด
              </v-btn>
              <v-btn
                large
                color="success"
                @click.stop="personnelSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model passworddialog -->
      <v-layout row justify-center>
        <v-dialog v-model="passwoorddialog" persistent max-width="50%">
          <v-card class="mx-auto pa-6" elevation="2">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขรหัสผ่าน"
              class="px-5 py-3"
              elevation="2"
            >
              <div align="right">
                {{ users.id_card }} {{ users.title_s }}{{ users.frist_name }}
                {{ users.last_name }}
              </div>
            </base-material-card>
            <v-card-text>
              <v-form ref="passwordform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="Password"
                        v-model="users.p_word"
                        type="password"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="Confirm Password"
                        v-model="users.user_confirmpassword"
                        type="password"
                        :rules="[v => v == users.p_word]"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="passwoorddialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="success"
                @click.stop="passwordSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdflicenseteachdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdflicenseteachdialog" max-width="60%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecLicenseTeach/' + licensefiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdflicensedrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdflicensedrdialog" max-width="60%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecLicenseDirector/' + users.pt_licencedr_file"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Update Pic -->
      <v-layout>
        <v-dialog
          v-model="personnel_temporary_pic_dialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-6" elevation="2">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขรูปโปรไฟล์"
              class="px-5 py-3"
              elevation="2"
            >
            </base-material-card>
            <v-card-text>
              <v-form ref="form5" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                      เลือกไฟล์ภาพของท่าน : ชนิด JPEG / JPG

                      <v-file-input
                        v-model="imageFiles"
                        accept="image/png, image/jpeg"
                        name="imageFiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .png/jpeg"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="personnel_temporary_pic_dialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="success"
                @click.stop="editpersonnel_temporaryinfoSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon> &nbsp;บันทึก</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Delete Pic -->
        <v-dialog v-model="deletefiledialog" persistent max-width="50%">
          <v-card>
            <v-card-title class="red darken-3 white--text elevation-2">
              <v-icon medium dark>fa-school</v-icon>&nbsp;ลบข้อมูลไฟล์รูป
            </v-card-title>
            <v-card-text>
              <v-form ref="deletestandardtopicform2" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 class="text-xs-center red--text">
                      ยืนยันการลบไฟล์รูป
                      {{ this.users.personnel_temporary_pic }}
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click.native="deletefiledialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                color="red darken-3"
                @click.native="deletefileSubmit2()"
                rounded
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model confirm -->
      <v-layout>
        <v-dialog v-model="VideoDialogYoutube" persistent max-width="80%">
          <v-card>
            <div class="text-center">
              <v-btn text large rounded @click="VideoDialogYoutubeStop()"
                ><v-icon dark>mdi-close</v-icon></v-btn
              >
              <iframe
                width="100%"
                height="600px"
                src="https://www.youtube.com/embed/mI2Fzw4etE4"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
import sedirectorAppTimeline from "../../components/user/sedirectorAppTimeline";

export default {
  components: { FlipCountdown, sedirectorAppTimeline },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      period_enable: "1",
      addpersonneldialog: false,
      personnel_temporary_pic_dialog: false,
      VideoDialogYoutube: false,
      deletefiledialog: false,
      passwoorddialog: false,
      transference_personnels: [],
      transference_personnels_38: [],
      valid: true,
      user: {},
      updateuser: {},
      personnel: {},
      periods: [],
      periods_38: [],
      periodsmanage_sedi: [],
      periodsmanage_di: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      provices_sh: [],
      user_marital_status: [
        { title: "โสด", value: "single" },
        { title: "สมรส", value: "married" },
        { title: "หม้าย", value: "widow" },
        { title: "หย่า", value: "divorce" },
        { title: "แยกกันอยู่", value: "separate" }
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      headers_teach: [
        { text: "#", align: "center", value: "index" },
        { text: "อ้างอิง", align: "center", value: "tid_ref" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "id_card" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "ครั้งที่", align: "center", value: "time_ss" },
        { text: "ปีที่", align: "center", value: "year_ss" },
        { text: "อายุงาน ณ ปัจจุบัน", align: "center", value: "age_app_time" },
        { text: "วันที่ทำรายการ", align: "center", value: "date_time" },
        { text: "พิมพ์ แบบคำร้อง", align: "center", value: "prints1" },
        {
          text: "พิมพ์ เอกสารชุดที่ 1",
          align: "center",
          value: "print_doc_kpi"
        },
        {
          text: "พิมพ์ เอกสาร(7.1)",
          align: "center",
          value: "print_14_71_form"
        },
        { text: "พิมพ์ เอกสาร(7.2)", align: "center", value: "print_14_form" }

        /*  { text: "พิมพ์ เอกสารชุดที่ 1", align: "center", value: "prints2" } */

        /*  { text: "ความคิดเห็นผู้อำนวยการ", align: "center", value: "actions" } */
      ],

      headers_38: [
        { text: "#", align: "center", value: "index" },
        { text: "อ้างอิง", align: "center", value: "tid_ref" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "id_card" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "ครั้งที่", align: "center", value: "time_ss" },
        { text: "ปีที่", align: "center", value: "year_ss" },
        { text: "อายุงาน ณ ปัจจุบัน", align: "center", value: "age_app_time" },
        { text: "วันที่ทำรายการ", align: "center", value: "date_time" },
        { text: "พิมพ์ แบบคำร้อง", align: "center", value: "prints1" }
      ],

      headers_manage: [
        { text: "#", align: "center", value: "index" },
        { text: "อ้างอิง", align: "center", value: "manage_id_ref" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "manage_id_card" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "ครั้งที่", align: "center", value: "manage_time_s" },
        { text: "ปีที่", align: "center", value: "manage_year_s" },
        {
          text: "อายุงาน ณ ปัจจุบัน",
          align: "center",
          value: "manage_age_app_time"
        },
        { text: "วันที่ทำรายการ", align: "center", value: "manage_date_time" },
        { text: "กรณี", align: "center", value: "manage_case_move" },
        { text: "พิมพ์", align: "center", value: "prints" },
        { text: "สถานะ", align: "center", value: "manage_status_save" }
        /*  { text: "ความคิดเห็นผู้อำนวยการ", align: "center", value: "actions" } */
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      imageFiles: null,
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      period_sedirectors: [],
      license_file: null,
      license_filedr: null,
      pt_licence_file_tem: null,
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB"
      ],
      pdflicenseteachdialog: false,
      pdflicensedrdialog: false,
      users: [],
      period_sedirectorsprocess: [],
      sedirector_apps: [],
      checksedirector_app_idrefs: [],

      headersSecDirecApp: [
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "สถานะ", align: "center", value: "sedirector_app_status" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "user_firstname" },
        { text: "เลขที่ตำแหน่งเดิม", align: "left", value: "id_position" },
        { text: "ครั้งที่/ปี", align: "center", value: "sedirector_app_time" },
        {
          text: "อายุงาน ณ ปัจจุบัน",
          align: "center",
          value: "sedirector_app_agework"
        },
        {
          text: "ตำแหน่งที่ใช้สมัคร",
          align: "center",
          value: "sedirector_app_position"
        },
        /*   { text: "อายุงาน", align: "center", value: "sedirector_age_position" },
        {
          text: "อายุงานในตำแหน่ง",
          align: "center",
          value: "sedirector_age_position_work5"
        }, */
        { text: "วินัย", align: "center", value: "sedirector_app_discipline" },

        {
          text: "ใบสมัครและเอกสารประกอบฯ",
          align: "center",
          value: "sedirector_app_file"
        },
        {
          text: "ภาค ก.",
          align: "center",
          value: "sedirector_app_file_A"
        },
        {
          text: "ภาค ข.",
          align: "center",
          value: "sedirector_app_file_B"
        },
        {
          text: "วันที่ทำรายการ",
          align: "center",
          value: "sedirector_app_datetime"
        }
      ],
      sedirector_app_idcards: [],
      period_directors: [],
      period_directorsprocess: [],
      director_apps: [],
      prefectures: [],
      district: [],
      manage_assistant_teachers: [],
      licensefiles:'',
    };
  },
  async mounted() {
    await this.personnelQuery();
    let result_provice;
    result_provice = await this.$http.post("province_sh.php", {
      ApiKey: this.ApiKey
    });
    this.provices_sh = result_provice.data;

    await this.date_app_nowCheck();
    await this.periodQuery(); /* กำหนดการย้ายครู */
    await this.periodManagedirectorQuery(); /* กำหนดการย้าย ผอ.*/
    await this.periodManageSedirectorQuery(); /* กำนหดการย้าย รอง */
    await this.timeCheck(); /* ย้ายครู */
    await this.period_sedirectorQuery(); /* สมัครสอบรอง */
    await this.period_sedirectorProcess(); /* ติดตามผลการสมัครสอบรอง */
    await this.transference_personnelQueryAll();
    await this.sedirector_app_idcardQuery();

    await this.period_directorQuery();
    await this.period_directorProcess();

    await this.period38Query();
    await this.manage_assistant_teacherQuery();
  },

  methods: {
    async manage_assistant_teacherQuery() {
      if (this.users.position_name == "ครูผู้ช่วย") {
        let result = await this.$http.post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_id_card: this.users.id_card,
          flash: "ok"
        });
        this.manage_assistant_teachers = result.data;
      }
    },
    async sedirector_app_idcardQuery() {
      let result = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idcard: this.users.id_card
      });
      this.sedirector_app_idcards = result.data;
    },

    async personnelQuery() {
      this.loading = true;
      let useression = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: useression.id_card
        })
        .finally(() => (this.loading = false));
      this.users = result.data;
    },

    async reload() {
      location.reload();
    },
    async viewpdflicenseteach(licensefile) {
      this.licensefiles=''
      this.licensefiles = licensefile
      this.pdflicenseteachdialog = true;
    },
    async viewpdflicensedr() {
      this.pdflicensedrdialog = true;
    },

    async date_app_nowCheck() {
      let datecheck = String(this.users.date_app_now);
      if (datecheck.length < 5) {
        Swal.fire({
          icon: "error",
          title:
            "ให้ งานบุคลากร ดำเนินการปรับปรุงวันที่ปฏิบัติงานของท่านให้เรียบร้อย"
        });
      }
    },

    async VideoDialogYoutubeStop() {
      var videos = document.querySelectorAll("iframe, video");
      Array.prototype.forEach.call(videos, function(video) {
        if (video.tagName.toLowerCase() === "video") {
          video.pause();
        } else {
          var src = video.src;
          video.src = src;
        }
      });
      this.VideoDialogYoutube = false;
    },
    async VideoDialog() {
      this.VideoDialogYoutube = true;
    },

    async periodManagedirectorQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_id: "101"
      });
      this.periodsmanage_di = result_period.data;
    },

    async periodManageSedirectorQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_id: "201"
      });
      this.periodsmanage_sedi = result_period.data;
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_id: "301"
      });
      this.periods = result_period.data;
    },

    async period38Query() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_id: "3801"
      });
      this.periods_38 = result_period.data;
    },

    async period_sedirectorQuery() {
      let result_period;
      result_period = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_enable: "1",
        period_sedirector_id: "201"
      });
      this.period_sedirectors = result_period.data;
    },

    async period_directorQuery() {
      let result_period;
      result_period = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_enable: "1",
        period_sedirector_id: "301"
      });
      this.period_directors = result_period.data;
    },

    async period_directorProcess() {
      let result_period;
      result_period = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_process: "1",
        period_sedirector_id: "301"
      });
      this.period_directorsprocess = result_period.data;

      let result = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_year: this.period_directorsprocess
          .period_sedirector_year,
        sedirector_app_time: this.period_directorsprocess
          .period_sedirector_times,
        sedirector_app_idcard: this.users.id_card,
        sedirector_app_ptype: 3
      });
      this.director_apps = result.data;
      let checkdata = String(this.director_apps.sedirector_app_idref);
      if (checkdata.length > 10) {
        this.checksedirector_app_idrefs = "true";
      } else {
        this.checksedirector_app_idrefs = "false";
      }
    },

    async period_sedirectorProcess() {
      let result_period;
      result_period = await this.$http.post("period_sedirector.php", {
        ApiKey: this.ApiKey,
        period_sedirector_process: "1",
        period_sedirector_id: "201"
      });
      this.period_sedirectorsprocess = result_period.data;

      let result = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_year: this.period_sedirectorsprocess
          .period_sedirector_year,
        sedirector_app_time: this.period_sedirectorsprocess
          .period_sedirector_times,
        sedirector_app_idcard: this.users.id_card
      });
      this.sedirector_apps = result.data;
      let checkdata = String(this.sedirector_apps.sedirector_app_idref);
      if (checkdata.length > 10) {
        this.checksedirector_app_idrefs = "true";
      } else {
        this.checksedirector_app_idrefs = "false";
      }
    },

    async timeCheck() {
      let dateNow = new Date();
      let dateStop = new Date(this.periods.period_stop + " 16:30:00");
      this.dateNows = dateNow.getTime();
      this.dateStops = dateStop.getTime();
      if (this.dateNows > this.dateStops) {
        await this.periodTeacherUpdate();
      } else {
      }
    },
    async periodTeacherUpdate() {
      this.periods.ApiKey = this.ApiKey;
      this.periods.period_enable = "0";
      await this.$http.post("period.update.php", this.periods);
    },

    async personnelUpdate() {
      this.addpersonneldialog = true;
    },

    async personnelPicUpdate() {
      this.personnel_temporary_pic_dialog = true;
    },

    async passwordUpdate() {
      this.users.p_word = "";
      this.passwoorddialog = true;
    },

    async personnelPicDelete() {
      this.deletefiledialog = true;
    },

    async transference_personnelQueryAll() {
      let result;
      if (this.users.user_status === "teacher") {
        result = await this.$http.post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          id_card: this.users.id_card
        });
        this.transference_personnels = result.data;
      } else if (
        this.users.user_status === "director" ||
        this.users.user_status === "se_director"
      ) {
        result = await this.$http.post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_id_card: this.users.id_card
        });
        this.transference_personnels = result.data;
      } else if (this.users.user_status === "S") {
      } else if (
        this.users.user_status === "38" ||
        this.users.user_status === "3801" ||
        this.users.user_status === "3802" ||
        this.users.user_status === "38030"
      ) {
        let result38 = await this.$http.post("transference_personnel_38.php", {
          ApiKey: this.ApiKey,
          tp_id_card: this.users.id_card
        });
        this.transference_personnels = result38.data;
      }
    },

    async personnelSubmit() {
      if (this.$refs.personnelform.validate()) {
        this.users.ApiKey = this.ApiKey;
        let uploaded = null;
        if (this.license_file != null) {
          let formData = new FormData();
          let filename =
            this.users.id_card + "" + this.time_stamp + "" + "teach.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.license_file);
          formData.append("filename", "../HRvecLicenseTeach/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.users.pt_licence_file = filename;

          uploaded = true;
        } else {
          uploaded = false;
        }

        if (this.license_filedr != null) {
          let formData = new FormData();
          let filename =
            this.users.id_card + "" + this.time_stamp + "" + "dr.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.license_filedr);
          formData.append("filename", "../HRvecLicenseDirector/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          this.users.pt_licencedr_file = filename;
          uploaded = true;
        } else {
          uploaded = false;
        }

        let result = await this.$http.post(
          "personnel_temporary.update.php",
          this.users
        );
        let result_m = await this.$http.post(
          "personnel_marriage.insert.php",
          this.users
        );

        if (
          this.users.position_name == "ครูผู้ช่วย" &&
          String(this.manage_assistant_teachers.mt_id_card).length > 6
        ) {
          this.manage_assistant_teachers.ApiKey = this.ApiKey;
          if (this.pt_licence_file_tem != null) {
            let formData = new FormData();
            let filename =
              this.users.id_card + "" + this.time_stamp + "" + "teach_tem.pdf";
            formData.append("ApiKey", this.ApiKey);
            formData.append("file", this.pt_licence_file_tem);
            formData.append("filename", "../HRvecLicenseTeach/" + filename);
            result = await this.$http.post("uploadfile.php", formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            });
            this.manage_assistant_teachers.pt_licence_file_tem = filename;
          }

          this.manage_assistant_teachers.pertem_assistantFile = this.users.pertem_assistantFile;

          if (this.manage_assistant_teachers.mt_license_status == "have") {
            this.manage_assistant_teachers.pt_licence = this.users.pt_licence;
            (this.manage_assistant_teachers.pt_ondate = this.users.pt_ondate),
              (this.manage_assistant_teachers.pt_enddate = this.users.pt_enddate),
              (this.manage_assistant_teachers.pt_licence_file = this.users.pt_licence_file);
          } else {
            this.manage_assistant_teachers.pt_licence = "";
            (this.manage_assistant_teachers.pt_ondate = ""),
              (this.manage_assistant_teachers.pt_enddate = ""),
              (this.manage_assistant_teachers.pt_licence_file = "");
          }

          let resultUp = await this.$http.post(
            "manage_assistant_teacher.update.idcard.php",
            this.manage_assistant_teachers
          );

          console.log(resultUp.data);
        }

        if (result.data.status == true || result_m.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnelQuery();
        }
      }
    },
    async deletelicensedr() {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "ลบไฟล์ใบอนุญาตประกอบวิชาชีพผู้บริหารเดิม",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename: "../HRvecLicenseDirector/" + this.users.pt_licencedr_file
          });

          result = await this.$http.post("personnel_temporary.update.php", {
            ApiKey: this.ApiKey,
            pt_licencedr_file: "",
            id_card: this.users.id_card
          });

          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnelQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async deletelicenseteach() {
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "ลบไฟล์ใบอนุญาตประกอบวิชาชีพครู เดิม",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename: "../HRvecLicenseTeach/" + this.users.pt_licence_file
          });

          let resultup = await this.$http.post(
            "personnel_temporary.update.php",
            {
              ApiKey: this.ApiKey,
              pt_licence_file: "",
              id_card: this.users.id_card
            }
          );

          if (resultup.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.personnelQuery();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async deletelicenseteachtem(){
      Swal.fire({
        title: "ยืนยันการลบ",
        text: "ลบไฟล์ใบอนุญาตปฏิบัติการสอน",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let result = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename: "../HRvecLicenseTeach/" + this.manage_assistant_teachers.pt_licence_file_tem
          });

          let resultup = await this.$http.post(
            "manage_assistant_teacher.update.idcard.php",
            {
              ApiKey: this.ApiKey,
              pt_licence_file_tem: "",
              mt_id_card: this.manage_assistant_teachers.mt_id_card
            }
          );

          if (resultup.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            await this.manage_assistant_teacherQuery();

          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async passwordSubmit() {
      if (this.$refs.passwordform.validate()) {
        this.users.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "personnel_temporary.update.password.php",
          this.users
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnelQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.passwoorddialog = false;
    },

    async editpersonnel_temporaryinfoSubmit(upload) {
      let result = "";
      let uploaded = null;
      if (this.$refs.form5.validate()) {
        if (this.imageFiles != "") {
          let formData = new FormData();
          let filename =
            this.users.id_card + "." + this.time_stamp + "." + "users.jpg";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.imageFiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.users.personnel_temporary_pic = "";
            this.users.personnel_temporary_pic = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
      } else {
        upload = false;
      }

      this.users.ApiKey = this.ApiKey;
      result = await this.$http.post(
        "personnel_temporary.update.php",
        this.users
      );
      if (result.data.status == true || uploaded == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        if (!uploaded && upload) {
          Swal.fire({
            icon: "warning",
            title: "แนบไฟล์ไม่ถูกต้อง",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.personnel_temporary_pic_dialog = false;
    },
    async deletefileSubmit2() {
      let result = await this.$http.post("deletefile.php", {
        ApiKey: this.ApiKey,
        filename: "../HRvecfiles/" + this.users.personnel_temporary_pic
      });

      if (result.data.status) {
        result = await this.$http.post("personnel_temporary.update.php", {
          ApiKey: this.ApiKey,
          personnel_temporary_pic: "",
          id_card: this.users.id_card
        });

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.personnelQuery();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
      this.deletefiledialog = false;
    },

    async prefectureQueryAll(province_ID) {
      let result = await this.$http.post("prefecture.php", {
        province_ID: province_ID
      });
      this.prefectures = result.data;
    },

    async districtQueryAll(prefecture_ID) {
      let result = await this.$http.post("districts.php", {
        prefecture_ID: prefecture_ID
      });
      this.district = result.data;
    }
  },
  computed: {
    marital_status() {
      let marital_status = this.users.marital_status;
      let marital_result;
      if (marital_status == "single") {
        marital_result = "โสด";
      } else if (marital_status == "married") {
        marital_result = "สมรส";
      } else if (marital_status == "widow") {
        marital_result = "หม้าย";
      } else if (marital_status == "divorce") {
        marital_result = "หย่า";
      } else if (marital_status == "separate") {
        marital_result = "แยกกันอยู่";
      } else {
        marital_result = "ไม่ได้กำหนด";
      }
      return marital_result;
    },
    time_stamp() {
      const d = new Date();
      let time = Math.floor(d.getTime() / 1000);
      return time;
    },
    user_status_type() {
      let user_status = this.users.user_status;
      let result;
      if (user_status == "teacher") {
        result = "teacher";
      } else if (user_status == "director") {
        result = "manage";
      } else if (user_status == "se_director") {
        result = "manage";
      } else if (user_status == "S") {
        result = "supervisions";
      }

      return result;
    },
    dmy_app_cal() {
      let month = this.users.appoin_month;
      let day = this.users.appoin_day;
      let year = this.users.appoin_year - 543;
      let result = month + "/" + day + "/" + year;
      return result;
    },
    month_now() {
      const d = new Date();
      let month = d.getMonth() + 1;
      return month;
    },
    year_s() {
      const d = new Date();
      let year = d.getFullYear() + 543;
      return year;
    },
    time_s() {
      let time_m;
      if (this.month_now > 2) {
        time_m = 2;
      } else {
        time_m = 1;
      }
      return time_m;
    },

    date_month_cal() {
      let time_s = this.time_s;
      if (time_s == 1) {
        let dd = "31";
        let mm = "3";
        let yyyy = parseInt(this.year_s) - 543;
        let date = yyyy + "-" + mm + "-" + dd;
        return date;
      } else if (time_s == 2) {
        let dd = "30";
        let mm = "9";
        let yyyy = parseInt(this.year_s) - 543;
        let date = yyyy + "-" + mm + "-" + dd;
        return date;
      }
    },

    get_gov_Age_year() {
      let today = new Date(this.date_month_cal);
      let DOB = new Date(this.users.date_app_now);

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years;
      return age;
    },

    provinceChange() {
      return this.users.domicile_pt;
    },
    prefectureChange() {
      return this.users.pt_perfecture;
    }
  },

  watch: {
    async provinceChange() {
      this.prefectureQueryAll(this.users.domicile_pt);
    },
    async prefectureChange() {
      this.districtQueryAll(this.users.pt_perfecture);
    }
  }
};
</script>
<style>
v-img {
  border-radius: 8px;
}
</style>
