<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-calendar"
        title="การการประเมินและสถานะ"
        class="px-5 py-3"
      >
        <v-card>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :search="search"
                :items="manage_assistant_teachers"
                :loading="loading"
              >
                <template v-slot:top>
                  <div>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="ค้นหา"
                      single-line
                      hide-details
                      filled
                    ></v-text-field>
                  </div>
                </template>

                
                <template v-slot:item.mt_date_app_now="{ item }">
                  <div>
                    {{ item.mt_date_app_now | moment("add","543 year") | moment("D MMMM YYYY") }}
                  </div>
                </template>
                       
                <template v-slot:item.month6="{ item }">
                  <div>
                    <div class="font-weight-bold">
                         {{ item.month6 | moment("D MMMM YYYY") }}
                    </div>
                   
                    <h3 v-if="parseInt(item.countAssess1)>=60" class="success--text">
                        {{ item.countAssess1 }}
                    </h3>
                    <h3 v-else-if="parseInt(item.countAssess1)<60" class="error--text">
                        {{ item.countAssess1 }}
                    </h3>

                    <div class="success--text" v-if="item.countAssess1ipa==='Pass'">
                        <v-icon color="success" size="30">mdi-check-circle</v-icon>
                    </div>

                  </div>
                </template>

                <template v-slot:item.month12="{ item }">
                    <div>
                    <div class="font-weight-bold">
                         {{ item.month12 | moment("D MMMM YYYY") }}
                    </div>
                   
                    <h3 v-if="parseInt(item.countAssess2)>=60" class="success--text">
                        {{ item.countAssess2 }}
                    </h3>
                    <h3 v-else-if="parseInt(item.countAssess2)<60" class="error--text">
                        {{ item.countAssess2 }}
                    </h3>

                    <div class="success--text" v-if="item.countAssess2ipa==='Pass'">
                        <v-icon color="success" size="30">mdi-check-circle</v-icon>
                    </div>

                  </div>
                </template>

                <template v-slot:item.month18="{ item }">
                    <div>
                    <div class="font-weight-bold">
                         {{ item.month18 | moment("D MMMM YYYY") }}
                    </div>
                   
                    <h3 v-if="parseInt(item.countAssess3)>=70" class="success--text">
                        {{ item.countAssess3 }}
                    </h3>
                    <h3 v-else-if="parseInt(item.countAssess3)<70" class="error--text">
                        {{ item.countAssess3 }}
                    </h3>

                    <div class="success--text" v-if="item.countAssess3ipa==='Pass'">
                        <v-icon color="success" size="30">mdi-check-circle</v-icon>
                    </div>

                  </div>
                </template>

                <template v-slot:item.month24="{ item }">
                    <div>
                    <div class="font-weight-bold">
                         {{ item.month24 | moment("D MMMM YYYY") }}
                    </div>
                   
                    <h3 v-if="parseInt(item.countAssess4)>=70" class="success--text">
                        {{ item.countAssess4 }}
                    </h3>
                    <h3 v-else-if="parseInt(item.countAssess4)<70" class="error--text">
                        {{ item.countAssess4 }}
                    </h3>

                    <div class="success--text" v-if="item.countAssess4ipa==='Pass'">
                        <v-icon color="success" size="30">mdi-check-circle</v-icon>
                    </div>

                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </base-material-card>   
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HRvecPersonnelTemporaryAssistantPeriod",
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      search: "",
      headers: [
        /* { text: "รหัสบัตรประชาชน", align: "center", value: "mt_id_card" }, */
        { text: "คำนำหน้าชื่อ", align: "center", value: "mt_title_s" },
        { text: "ชื่อ", align: "left", value: "mt_frist_name" },
        { text: "นามสกุล", align: "left", value: "mt_last_name" },
        { text: "วันที่บรรจุ", align: "center", value: "mt_date_app_now" },    
  
        { text: "ครั้งที่ 1", align: "center", value: "month6" },
        { text: "ครั้งที่ 2", align: "center", value: "month12" },
        { text: "ครั้งที่ 3", align: "center", value: "month18" },
        { text: "ครั้งที่ 4", align: "center", value: "month24" }
      ],
      manage_assistant_teachers: [],
      type_personnelApp: [
        { text: "รอบคัดเลือก", value: "select" },
        { text: "รอบแข่งข้น", value: "recruit" },
        { text: "รอบคืนถิ่น", value: "local_dev" },
        { text: "รอบโอน", value: "transfer_position" }
      ],
      updateTypePersonnels: [],
      updateTypePersonneldialog: false,
      countData: [],
      colleges_user: []
    };
  },
  async mounted() {
    await this.colleges_userQuery();
    await this.manage_assistant_teacherQuery();
  },
  methods: {
    async colleges_userQuery() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("admin.php", {
          ApiKey: this.ApiKey,
          user_name: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.colleges_user = result.data;
    },

    async manage_assistant_teacherQuery() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_college_code: this.colleges_user.user_code,
          calendar_admin: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;  
    }
  }
};
</script>

<style lang="scss" scoped></style>
