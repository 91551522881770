<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลการย้าย สายการสอนและสายสนับสนุน"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                v-model="times_select"
                :items="time_ss"
                item-value="time_ss"
                :value="1"
                label="ครั้งที่ :"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                v-model="years_select"
                :items="year_ss"
                item-value="year_ss"
                :value="2565"
                label="ปีที่ :"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn color="info" large block @click="searchTimeYear()">
                <v-icon>mdi-account-search</v-icon> ค้นหา</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <div class="red--text">
          *เมื่อสถานศึกษารายงานข้อมูลและแนบไฟล์เสร็จสิ้นจากไม่สามารถดำเนินการแก้ไขข้อมูลใดๆ
          ได้แล้วกรุณาตรวจสอบข้อมูลให้ถูกต้องการบันทึก
        </div>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="transference_personnels"
          :search="search"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">{{ item.tid_ref }}</td>
              <td class="text-center">{{ item.id_card }}</td>
              <td class="text-center">{{ item.frist_name }}</td>
              <td class="text-center">{{ item.last_name }}</td>
              <td class="text-center">{{ item.time_ss }}</td>
              <td class="text-center">{{ item.year_ss }}</td>
              <td class="text-center">{{ item.age_app_time }}</td>
              <td class="text-center">{{ item.date_time }}</td>
              <td class="text-center">
                <v-chip v-if="item.comment_dr_c === 'approp'" color="green" dark
                  >เห็นควร</v-chip
                >
                <v-chip
                  v-else-if="item.comment_dr_c === 'inapprop'"
                  color="red"
                  dark
                  >ไม่เห็นควร</v-chip
                >
                <v-chip
                  v-else
                  color="info"
                  @click="comment_idPosition(item.tid_ref)"
                >
                  <v-icon>mdi-cursor-pointer</v-icon>คลิกเพื่อแนบไฟล์
                </v-chip>

                <v-chip
                  v-if="item.tp_comment_dr_stb === 'approp'"
                  color="green"
                  dark
                  >เห็นควร</v-chip
                >
                <v-chip
                  v-else-if="item.tp_comment_dr_stb === 'inapprop'"
                  color="red"
                  dark
                  @click="comment_idPosition(item.tid_ref)"
                  >ไม่เห็นควร</v-chip
                >
              </td>
              <td class="text-center">
                <div v-if="item.documentsLinkfile">
                  <v-btn
                    @click="viewdocumentsLinkfile(item.documentsLinkfile)"
                    text
                    rounded
                    color="green"
                    large
                    ><v-icon>mdi-printer</v-icon></v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    <v-icon>mdi-information</v-icon> ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </td>
              <!--  <td class="text-center">
                <div v-if="item.doccon_1">
                                  <v-btn
                    text
                    rounded
                    color="green"
                    large
                    :href="'/HRvecfiles/' + item.doccon_1"
                    target="_blank"
                    ><v-icon>mdi-printer</v-icon></v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    <v-icon>mdi-information</v-icon>   ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </td> -->
              <!-- <td class="text-center">
                <div v-if="item.doccon_14">
                  <v-btn
                    text
                    rounded
                    color="green"
                    large
                    :href="'/HRvecfiles/' + item.doccon_14"
                    target="_blank"
                    ><v-icon>mdi-printer</v-icon></v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    <v-icon>mdi-information</v-icon> ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </td> -->
              <td class="text-center">
                <div v-if="item.documentsLinkfile">
                  <v-chip color="red" dark v-if="item.ccDocumentFile === 'cc'"
                    >รอการยกเลิก</v-chip
                  >
                  <v-btn
                    v-else
                    color="warning"
                    @click="cancelPDFSubmit(item.tid_ref)"
                  >
                    <v-icon color="red" class="pr-1">mdi-close</v-icon>
                    ยกเลิกแนบไฟล์</v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    <v-icon>mdi-information</v-icon> ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </td>
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model commentDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="commentDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เพิ่มความคิดเห็นผู้อำนวยการ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecommentform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                        {{ transference_personnels_id_ref.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        <br />
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        <br />
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_s }} ปี :
                        {{ transference_personnels_id_ref.year_s }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="number"
                        outlined
                        label=" 1.ปัจจุบันสถานศึกษามีครูผู้สอนในสาขาวิชาเดียวกับผู้ขอย้าย จำนวน :"
                        v-model="transference_personnels_id_ref.num_tech_tran_a"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        type="number"
                        outlined
                        label="2.หากผู้ขอย้ายได้ย้ายไปปฏิบัติหน้าที่แห่งใหม่มีครูผู้สอนในสาขาวิชาเดียวกับผู้ขอย้าย จำนวน :"
                        v-model="transference_personnels_id_ref.num_tech_tran_b"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-alert
                        class="mx-auto text-center pa-2 ma-2"
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        <h3>
                          หากผู้ขอย้ายได้ย้ายไปปฏิบัติหน้าที่แห่งใหม่
                          สถานศึกษามีครูผู้สอนในสาขาเดียวกับผู้ขอย้ายเพียงพอหรือไม่
                        </h3>

                        <h3>
                          <v-radio-group
                            v-model="transference_personnels_id_ref.com_enough"
                            row
                            required
                            :rules="[v => !!v || '']"
                            align="center"
                          >
                            <v-radio value="enough">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text">เพียงพอ</strong>
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="not_enough">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text"
                                    >ไม่เพียงพอ</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </h3>
                      </v-alert>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-alert
                        class="mx-auto text-center pa-2 ma-2"
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        <h3
                          v-if="
                            transference_personnels_id_ref.collegeVei !== '0'
                          "
                        >
                          ความคิดเห็น ผู้อำนวยการสถาบัน กรณีสังกัดสถาบัน
                        </h3>

                        <h2
                          v-if="
                            transference_personnels_id_ref.collegeVei !== '0'
                          "
                        >
                          <v-radio-group
                            v-model="
                              transference_personnels_id_ref.tp_comment_dr_stb
                            "
                            row
                            required
                            :rules="[v => !!v || '']"
                            align="center"
                          >
                            <v-radio value="approp">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text"
                                    >เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="inapprop">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text"
                                    >ไม่เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <span class="red--text"
                              >*กรณีไม่ได้สังกัด สถาบันฯ ให้เลือกเห็นควรให้ย้าย
                              หรือเป็นช่องว่าง</span
                            >
                          </v-radio-group>

                          <v-textarea
                            v-if="
                              transference_personnels_id_ref.tp_comment_dr_stb ===
                                'inapprop'
                            "
                            outlined
                            label=" ไม่เห็นควรให้ย้ายเนื่องจาก:"
                            v-model="
                              transference_personnels_id_ref.tp_reason_dr_stb
                            "
                          ></v-textarea>
                        </h2>

                        <h3>ความคิดเห็น ผู้อำนวยการ</h3>

                        <h2>
                          <v-radio-group
                            v-model="
                              transference_personnels_id_ref.comment_dr_c
                            "
                            row
                            required
                            :rules="[v => !!v || '']"
                            align="center"
                          >
                            <v-radio value="approp">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text"
                                    >เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="inapprop">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text"
                                    >ไม่เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                          <v-textarea
                            v-if="
                              transference_personnels_id_ref.comment_dr_c ===
                                'inapprop'
                            "
                            outlined
                            label=" ไม่เห็นควรให้ย้ายเนื่องจาก:"
                            v-model="
                              transference_personnels_id_ref.detail_comment
                            "
                          ></v-textarea>
                        </h2>
                      </v-alert>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-if="
                          transference_personnels_id_ref.comment_dr_c ===
                            'approp'
                        "
                        :items="branch_s"
                        item-text="name_branch"
                        item-value="id_branch"
                        outlined
                        label="สาขาวิชารับย้าย :"
                        v-model="transference_personnels_id_ref.id_branch"
                        @change="branch_sub_d_select()"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        v-if="
                          transference_personnels_id_ref.comment_dr_c ===
                            'approp'
                        "
                        :items="branch_sub_ds_select"
                        item-text="name_sub_branch"
                        item-value="id_branch_sub"
                        outlined
                        label="วุฒิการศึกษา :"
                        v-model="
                          transference_personnels_id_ref.tran_id_branch_sub
                        "
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="documentsLinkfiles"
                        accept=".pdf"
                        name="documentsLinkfiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์แบบเสนอย้ายและหลักฐานอื่นๆ .pdf"
                        placeholder="แบบคำร้องขอย้าย"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                    <!--    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="documentsLinkfiles2"
                        accept=".pdf"
                        name="documentsLinkfiles2"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ เอกสารชุดที่ 1 .pdf"
                        placeholder="เอกสารชุดที่ 1 ยกเว้นตัวชี้วัดที่ 1.4"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
 -->
                    <!-- 
                    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="documentsLinkfiles3"
                        accept=".pdf"
                        name="documentsLinkfiles3"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ เอกสารชุดที่ 1 (1-6).pdf"
                        placeholder="เอกสารชุดที่ 1 (1-6)"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col> -->
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text large @click.stop="commentDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatecommentSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model transference_personnelsCollegePDFCCdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="transference_personnelsCollegePDFCCdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ยกเลิกแนบไฟล์ เนื่องจากไฟล์ไม่สมบูรณ์หรือแนบผิด"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecommentform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnelsCollegePDFCC.tid_ref }}
                        {{ transference_personnelsCollegePDFCC.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnelsCollegePDFCC.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnelsCollegePDFCC.title_s
                        }}{{ transference_personnelsCollegePDFCC.frist_name }}
                        {{ transference_personnelsCollegePDFCC.last_name }}
                        <br />
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnelsCollegePDFCC.college_code }}
                        {{ transference_personnelsCollegePDFCC.college_name }}
                        <br />
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnelsCollegePDFCC.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnelsCollegePDFCC.time_s }} ปี :
                        {{ transference_personnelsCollegePDFCC.year_s }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                fab
                text
                large
                @click.stop="transference_personnelsCollegePDFCCdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="cancelPDFCCSubmitConfirm()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon
                >&nbsp;ยืนยันขอยกเลิกแนบไฟล์
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfdocumentsLinkfileDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfdocumentsLinkfileDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      transference_personnelsCollegePDFCCdialog: false,
      times_select: "1",
      documentsLinkfiles: null,
      documentsLinkfiles2: null,
      documentsLinkfiles3: null,
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      time_ss: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_personnels: [],
      edittransference_personnel: {},
      search: "",
      pagination: {},
      headers: [
        { text: "#", align: "center", value: "index" },
        { text: "อ้างอิง", align: "center", value: "tid_ref" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "id_card" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "ครั้งที่", align: "center", value: "time_ss" },
        { text: "ปีที่", align: "center", value: "year_ss" },
        { text: "อายุงาน ณ ปัจจุบัน", align: "center", value: "age_app_time" },
        { text: "วันที่ทำรายการ", align: "center", value: "date_time" },
        {
          text: "ความคิดเห็น ผอ/ผอ สถาบัน ",
          align: "center",
          value: "actions"
        },
        {
          text: "แบบคำร้องขอย้าย",
          align: "center",
          value: "documentsLinkfile"
        },
        /*         { text: "เอกสาร 1", align: "center", value: "doccon_1" }, */
        /*  { text: "เอกสารชุดที่ 1", align: "center", value: "doccon_1" }, */
        { text: "ขอยกเลิกรายการ", align: "center", value: "cancelPDF" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      transference_personnelstatus: [],
      userstatus: {},
      commentDrdialog: false,
      transference_personnels_id_ref: [],
      regions: [],
      period_college_file: [],
      period_enable: "1",
      updatecomment: {},
      branch_s: [],
      branch_sub_ds_select: [],
      branchs_id_sub: [],
      period_college_file: [],
      period_college_enable_file: "1",
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      transference_personnelsCollegePDFCC: [],
      data_syslog: {},
      linealerts: {},
      pdf_files: [],
      pdfdocumentsLinkfileDialog: false
    };
  },
  async mounted() {
    await this.periodQuery();
    await this.branchQuery();
    //await this.timeCheck();
  },
  methods: {
    async timeCheck() {
      let dateNow = new Date();
      let dateStop = new Date(
        this.period_college_file.period_college_stop + " 16:30:00"
      );
      let dateNows = dateNow.getTime();
      let dateStops = dateStop.getTime();
      if (this.period_college_file.period_college_enable_file == "1") {
        this.interval = setInterval(async () => {
          if (dateNows > dateStops) {
            await this.periodUpdate();
            await this.periodQuery();
          }
        }, 3000);
      }
    },

    async periodUpdate() {
      this.period_college_file.ApiKey = this.ApiKey;
      this.period_college_file.period_college_enable = "0";
      this.period_college_file.period_college_enable_file = "0";
      let result = await this.$http.post(
        "period_college.update.php",
        this.period_college_file
      );
    },

    async branchQuery() {
      let result_branch = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branch_s = result_branch.data;
    },
    async periodQuery() {
      let result_period_college_file = await this.$http.post(
        "period_college.php",
        {
          ApiKey: this.ApiKey,
          period_college_enable_file: "1",
          period_college_id: "30101"
        }
      );
      this.period_college_file = result_period_college_file.data;

      if (this.period_college_file.period_college_enable_file != "1") {
        Swal.fire({
          icon: "error",
          title: "ปิดระบบ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      } else {
        await this.transference_personnelQueryAll();
      }
    },
    async viewdocumentsLinkfile(documentsLinkfile) {
      this.pdf_files = documentsLinkfile;
      this.pdfdocumentsLinkfileDialog = true;
    },

    async cancelPDFCCSubmitConfirm() {
      this.transference_personnelsCollegePDFCC.ApiKey = this.ApiKey;
      this.transference_personnelsCollegePDFCC.ccDocumentFile = "cc";
      let result = await this.$http.post(
        "transference_personnel.update.cccc.php",
        this.transference_personnelsCollegePDFCC
      );
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      this.linealerts.ApiKey = this.ApiKey;
      this.linealerts.college_name = userSession.user_firstname;
      this.linealerts.details =
        "ย้ายครู : ขอยกเลิกแนบไฟล์" +
        this.transference_personnelsCollegePDFCC.title_s +
        this.transference_personnelsCollegePDFCC.frist_name +
        " " +
        this.transference_personnelsCollegePDFCC.last_name;
      this.linealerts.datetime = this.date_today;
      if (result.data.status == true) {
        let result_line = await this.$http.post(
          "linenotify_hr.php",
          this.linealerts
        );

        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.transference_personnelQueryAll();
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "cancel";
        this.data_syslog.page_log = "transference_personnel";
        this.data_syslog.table_log = "transference_personnel";
        this.data_syslog.detail_log = this.transference_personnelsCollegePDFCC.id_ref;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.transference_personnelsCollegePDFCCdialog = false;
    },
    async cancelPDFSubmit(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.transference_personnelsCollegePDFCC = result_con.data;
      this.transference_personnelsCollegePDFCCdialog = true;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async branch_sub_d_select() {
      this.loading = true;
      let result = await this.$http
        .post("branch_sub_d.php", {
          ApiKey: this.ApiKey,
          id_main_branch: this.transference_personnels_id_ref.id_branch
        })
        .finally(() => (this.loading = false));
      this.branch_sub_ds_select = result.data;
    },

    async searchTimeYear() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.times_select,
          year_s: this.years_select,
          user_name: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
    },

    async comment_idPosition(tid_ref) {
      if (this.period_college_file.period_college_enable_file == "1") {
        let result_con = await this.$http.post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          id_ref: tid_ref
        });
        this.transference_personnels_id_ref = result_con.data;
        this.documentsLinkfiles = null;
        this.documentsLinkfiles2 = null;
        this.commentDrdialog = true;
      } else {
        Swal.fire({
          icon: "warning",
          title: "ขณะนี้ได้ปิดระบบรับรายงานข้อมูล",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },

    async transference_personnelQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          user_name: userSession.user_name,
          time_s: this.period_college_file.period_college_times,
          year_s: this.period_college_file.period_college_year,
          type_move: "nm"
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
      let data = this.transference_personnels;
      let counts = 0;
      data.forEach(value => {
        counts += 1;
      });
      if (counts < 1) {
        Swal.fire({
          icon: "error",
          title: "ไม่ปรากฎผู้เสนอย้ายออก",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      }
    },

    async updatecommentSubmit() {
      if (this.$refs.updatecommentform.validate()) {
        await this.sweetAlertLoading();
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.id_tfp = this.transference_personnels_id_ref.id_tfp;
        this.updatecomment.comment_dr_c = this.transference_personnels_id_ref.comment_dr_c;
        this.updatecomment.id_branch = this.transference_personnels_id_ref.id_branch;
        this.updatecomment.detail_comment = this.transference_personnels_id_ref.detail_comment;
        this.updatecomment.num_tech_tran_a = this.transference_personnels_id_ref.num_tech_tran_a;
        this.updatecomment.num_tech_tran_b = this.transference_personnels_id_ref.num_tech_tran_b;
        this.updatecomment.com_enough = this.transference_personnels_id_ref.com_enough;
        this.updatecomment.tran_id_branch_sub = this.transference_personnels_id_ref.tran_id_branch_sub;
        this.updatecomment.tp_comment_dr_stb = this.transference_personnels_id_ref.tp_comment_dr_stb;
        this.updatecomment.tp_reason_dr_stb = this.transference_personnels_id_ref.tp_reason_dr_stb;
        let result = "";
        let uploaded = null;

        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.transference_personnels_id_ref.tid_ref +
            "" +
            this.time_stamp +
            "" +
            "doc.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (result.data.status == true) {
            this.updatecomment.documentsLinkfile = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        /*  if (this.documentsLinkfiles2 != "") {
          let formData = new FormData();
          let filename =
            this.transference_personnels_id_ref.tid_ref +
            "" +
            this.time_stamp +
            "" +
            "cp1.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles2);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (result.data.status == true) {
            this.updatecomment.doccon_1 = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        } */

        if (this.documentsLinkfiles3 != "") {
          let formData = new FormData();
          let filename =
            this.transference_personnels_id_ref.tid_ref +
            "" +
            this.time_stamp +
            "" +
            "cp1.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles3);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (result.data.status == true) {
            this.updatecomment.doccon_1 = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        result = await this.$http.post(
          "transference_personnel.update.php",
          this.updatecomment
        );

        if (result.data.status == true || uploaded) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "transference_personnel";
          this.data_syslog.table_log = "transference_personnel";
          this.data_syslog.detail_log = this.transference_personnels_id_ref.tid_ref;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.commentDrdialog = false;
      }
      Swal.close();
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
