<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="รับรายงานตัว ย้ายครู กรณีปกติ"
        class="px-5 py-3"
      >
        <v-card class="pa-2">
          <v-data-table
            color="success"
            :headers="showHeaders"
            :items="conditons_transfer_successs"
            :search="search"
            :loading="loading"
            :item-class="row_classes"
            disable-pagination
          >
            <template v-slot:item.cts_report="{ item }">
              <div v-if="item.cts_college_cc === 'CC'">
                <v-chip color="warning" dark>
                  <v-icon>mdi-information</v-icon>
                  อยู่ระหว่างการขอยกเลิก</v-chip
                >
              </div>
              <div v-else>
                <div color="warning" dark v-if="item.cts_report === 'nb'">
                  <v-btn
                    @click="cancelTeach(item.id_cts)"
                    rounded
                    color="warning"
                  >
                    <v-icon>mdi-close</v-icon> ขอยกเลิกรายการ</v-btn
                  >
                </div>
                <div color="warning" dark v-else-if="item.cts_report === 'dnb'">
                  <v-btn
                    @click="cancelTeach(item.id_cts)"
                    rounded
                    color="warning"
                  >
                    <v-icon>mdi-close</v-icon> ขอยกเลิกรายการ</v-btn
                  >
                </div>
                <div color="red" v-else dark>
                  <v-btn
                    @click="receiptTeach(item.id_cts)"
                    rounded
                    color="primary"
                  >
                    <v-icon>mdi-account-star</v-icon> รับรายงานตัว</v-btn
                  >
                </div>
              </div>
            </template>

            <template v-slot:item.cts_datetime="{ item }">
              <div v-if="item.cts_college_cc === 'CC'">
                <v-chip color="warning" dark>
                  <v-icon>mdi-information</v-icon>
                  อยู่ระหว่างการขอยกเลิก</v-chip
                >
              </div>
              <div v-else>
                <v-chip color="primary" dark v-if="item.cts_report === 'nb'">{{
                  item.cts_datetime
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}</v-chip>
                <v-chip
                  color="warning"
                  dark
                  v-else-if="item.cts_report === 'dnb'"
                  >ไม่มารายงานตัว</v-chip
                >
                <v-chip color="red" v-else dark>ไม่ได้ดำเนินการ</v-chip>
              </div>
            </template>

            <template v-slot:item.cts_files="{ item }">
              <div v-if="item.cts_college_cc === 'CC'">
                <v-chip color="warning" dark>
                  <v-icon>mdi-information</v-icon>
                  อยู่ระหว่างการขอยกเลิก</v-chip
                >
              </div>
              <div v-else>
                <div v-if="item.cts_report === 'nb'">
                  <v-btn
                    text
                    rounded
                    color="green"
                    large
                    :href="'/HRvecfiles/' + item.cts_files"
                    target="_blank"
                    ><v-icon>mdi-printer</v-icon></v-btn
                  >
                </div>
                <div v-else-if="item.cts_report === 'dnb'">ไม่มารายงานตัว</div>
                <div v-else dark>ไม่ได้ดำเนินการ</div>
              </div>
            </template>

            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
              >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
            >
          </v-data-table>
        </v-card>
      </base-material-card>

      <!-- V-model reportMoveTeachDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="reportMoveTeachDialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="primary"
              icon="mdi-clipboard-text"
              title="รับรายงานตัว ย้ายครู กรณีปกติ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatemvtform" lazy-validation>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="12">
                    <h3 class="text-center">
                      {{ conditons_transfer_successs_teach.ctsid_card }} :
                      {{ conditons_transfer_successs_teach.full_name }}
                    </h3>
                  </v-col>

                  <v-col cols="12" md="6" class="text-center">
                    <div>
                      <v-radio-group
                        v-model="conditons_transfer_successs_teach.cts_report"
                        row
                        required
                        :rules="[v => !!v || '']"
                      >
                        <v-radio value="nb">
                          <template v-slot:label>
                            <div>
                              <strong class="primary--text">มารายงานตัว</strong>
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="dnb">
                          <template v-slot:label>
                            <div>
                              <strong class="warning--text"
                                >ไม่มารายงานตัว</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-menu
                      v-if="
                        conditons_transfer_successs_teach.cts_report === 'nb'
                      "
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :rules="[v => !!v || '']"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="
                            conditons_transfer_successs_teach.cts_datetime
                          "
                          label="วันที่มารายงานตัว"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          locale="th"
                          outlined
                          required
                          :rules="[v => !!v || '']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="conditons_transfer_successs_teach.cts_datetime"
                        no-title
                        scrollable
                        locale="th"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-file-input
                      v-model="documentsLinkfiles"
                      accept=".pdf"
                      name="documentsLinkfiles"
                      color="deep-purple accent-4"
                      counter
                      label="ไฟล์หนังสือราชการแจ้งการรายงานตัว.pdf"
                      placeholder="หนังสือรายการ"
                      outlined
                      :show-size="1000"
                      :rules="rules"
                    >
                      <template v-slot:selection="{ index, text }">
                        <v-chip
                          v-if="index < 2"
                          color="deep-purple accent-4"
                          dark
                          label
                          small
                        >
                          {{ text }}
                        </v-chip>

                        <span
                          v-else-if="index === 2"
                          class="text-overline grey--text text--darken-3 mx-2"
                        >
                          +{{ files.length - 2 }} File(s)
                        </span>
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                large
                @click.stop="reportMoveTeachDialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
              <v-btn
                large
                color="green"
                @click="updateReportMoveTeachSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      conditons_transfer_successs: [],
      search: "",
      pagination: {},
      singleSelect: false,
      reportMoveTeachDialog: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "ดำเนินการ", align: "left", value: "cts_report" },
        { text: "วันที่รายงานตัว", align: "left", value: "cts_datetime" },
        { text: "File", align: "left", value: "cts_files" },
        { text: "ครั้งที่", align: "left", value: "ctstime_s" },
        { text: "ปีที่", align: "center", value: "ctsyear_s" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "ctsid_card" },
        { text: "คำนำหน้าชื่อ", align: "center", value: "title_s" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "ตำแหน่ง", align: "center", value: "ctsname_position" },
        { text: "สังกัดใหม่", align: "center", value: "college_name_new" },
        { text: "เลขที่ตำแหน่งใหม่", align: "center", value: "ctsid_position" },
        { text: "รหัสสาขา", align: "center", value: "ctsid_branch" },
        { text: "เงื่อนไข", align: "center", value: "branch_name" },
        {
          text: "เลขที่ตำแหน่งเดิม",
          align: "left",
          value: "ctsid_postion_old"
        },
        { text: "สังกัดเดิม", align: "center", value: "college_name_old" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      period_college: [],
      conditons_transfer_successs_teach: [],
      documentsLinkfiles: null,
      rules: [
        value => !value || value.size < 1048576 || "ไฟล์ไม่เกิน 1 MB / 1024 KB",
        value => !!value || ""
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.periodQuery();
    if (this.period_college.period_college_enable != "1") {
      this.$router.push("/college");
    } else {
      await this.conditons_transfer_successQueryAll();
      //await this.timeCheck();
    }
  },
  methods: {
    async timeCheck() {
      let dateNow = new Date();
      let dateStop = new Date(this.period_college.period_college_stop + " 16:30:00");
      let dateNows = dateNow.getTime();
      let dateStops = dateStop.getTime();
      
      
      if (dateNows > dateStops) {
     
        await this.periodTeacherUpdate();
      } else {
       
      }
    },
    async periodTeacherUpdate() {
      this.period_college.ApiKey = this.ApiKey;
      this.period_college.period_college_enable = "0";
      this.period_college.period_college_enable_file = "0";
      await this.$http.post("period_college.update.php", this.period_college);
    },

    async cancelTeach(id_cts) {
      let result = await this.$http.post("conditons_transfer_success.php", {
        ApiKey: this.ApiKey,
        id_cts: id_cts
      });
      this.conditons_transfer_successs_teach = result.data;
      let charText = this.conditons_transfer_successs_teach.full_name;
      Swal.fire({
        title: "ขอยกเลิกรายการ เนื่องจากรายงานผิดพลาด",
        text: charText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.conditons_transfer_successs_teach.ApiKey = this.ApiKey;        
          this.conditons_transfer_successs_teach.cts_college_cc = "CC";

          let resultup = await this.$http.post(
            "conditons_transfer_success.update.php",
            this.conditons_transfer_successs_teach
          );
          if (resultup.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.conditons_transfer_successQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async updateReportMoveTeachSubmit() {
      let result = "";
      let uploaded = null;
      this.conditons_transfer_successs_teach.ApiKey = this.ApiKey;
      if (this.$refs.updatemvtform.validate()) {
        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.conditons_transfer_successs_teach.id_cts +
            this.conditons_transfer_successs_teach.ctsid_ref +
            "reportmvteach.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.conditons_transfer_successs_teach.cts_files = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        let result = await this.$http.post(
          "conditons_transfer_success.update.php",
          this.conditons_transfer_successs_teach
        );
        if (result.data.status || uploaded) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          await this.conditons_transfer_successQueryAll();
        } else {
          if (!uploaded) {
            Swal.fire({
              icon: "warning",
              title: "ไฟล์แนบไม่ถูกต้อง",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        this.reportMoveTeachDialog = false;
      }
    },
    async receiptTeach(id_cts) {
      let result = await this.$http.post("conditons_transfer_success.php", {
        ApiKey: this.ApiKey,
        id_cts: id_cts
      });
      this.conditons_transfer_successs_teach = result.data;
      this.reportMoveTeachDialog = true;
      
    },
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "reportmove_teacher"
      });
      this.period_college = result_period.data;

      ;
    },
    async conditons_transfer_successQueryAll() {
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;

      this.loading = true;
      let result = await this.$http
        .post("conditons_transfer_success.php", {
          ApiKey: this.ApiKey,
          ctstime_s: this.period_college.period_college_times,
          ctsyear_s: this.period_college.period_college_year,
          ctscollege_code: userSession.user_name
        })
        .finally(() => (this.loading = false));
      this.conditons_transfer_successs = result.data;
      let data = this.conditons_transfer_successs;
      let sum = 0;
      data.forEach(value => {
        sum = sum + 1;
      });
      let charttext = "จำนวนครูย้ายเข้า : " + sum + " คน ";
      Swal.fire({
        title: "สถานะการย้าย",
        text: charttext,
        confirmButtonColor: "#3085d6",
        showConfirmButton: true
      });
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    },
    row_classes(item) {
      if (item.ctsstatus_select == "transfer") {
        return "grey";
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "green darken-4";
    },
    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
