<template>
  <div>
    <AssisstantBar />

    <v-container fluid>
      <base-material-card
        icon="mdi-account-star"
        title="รับรายงานตัวครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="8" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="4" class="text-right">
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_assistant_teacherTotal()"
              >
                <v-icon>mdi-clipboard-text</v-icon>สรุปจำนวนเรียกรายงานตัว
              </v-btn>
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_assistant_teacherQueryAll()"
              >
                <v-icon>mdi-clipboard-text</v-icon>แสดงข้อมูลทั้งหมด
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <div class="pa-3 red--text font-weight-bold"  >
          <v-chip color="warning"><v-icon>mdi-information</v-icon> ครูโอนย้าย</v-chip> กรณี ครูโอนย้าย ให้รับรายงานตัวเมื่อมี หนังสือ/การแจ้งจาก ส่วนกลาง
        </div>

        <h2  v-if="periodassteachs.periodAssTeachEnable==='1'" class="success--text ml-5">
          รับรายงานตัว กรณีปกติ 
        </h2>
        <v-data-table
        v-if="periodassteachs.periodAssTeachEnable==='1'"
          color="success"
          :loading="loading"
          :headers="headers"
          :items="manage_assistant_teachers"
          :search="search"
          group-by="mt_id_branch"
          class="elevation-1"
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="22">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].mt_id_branch + " : " + items[0].name_branch }}
            </th>
          </template>

          <template v-slot:item.mt_admissions_status="{ item }">
            <v-chip
              dark
              color="red"
              v-if="item.mt_admissions_status === 'missing'"
            >
              <v-icon>mdi-information</v-icon>ไม่มารายงานตัว
            </v-chip>
            <v-chip
              dark
              color="primary"
              v-if="item.mt_admissions_status === 'receipt'"
            >
              <v-icon>mdi-information</v-icon>มารายงานตัวปกติ
            </v-chip>
          </template>

          <template v-slot:item.mt_type_personnel="{ item }">
            <div class="text-center">
              <v-chip
                dark
                color="waring"
                v-if="item.mt_type_personnel === 'local_dev'"
              >
                <v-icon>mdi-information</v-icon>ครูพัฒนาท้องถิ่น
              </v-chip>
              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'transfer_position'"
              >
                <v-icon>mdi-information</v-icon>ครูโอนย้าย
              </v-chip>
              <v-chip dark color="green" v-else>
                <v-icon>mdi-information</v-icon>รับรายงานตัว ปกติ
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_admissions_file="{ item }">
            <v-chip
              dark
              color="info"
              v-if="item.mt_admissions_file"
              :href="'/HRvecfiles/' + item.mt_admissions_file"
              target="_blank"
            >
              <v-icon>mdi-printer</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_status="{ item }">
            <v-chip dark color="red" v-if="item.mt_status === 'disclaim'">
              <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
            </v-chip>
            <v-chip dark color="green" v-else>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_examtype="{ item }">
            <span dark color="purple" v-if="item.mt_examtype === 'select'">
              คัดเลือก
            </span>
            <span dark color="indigo" v-else>
              แข่งขัน
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <div v-if="item.mt_transfer_status==='0'">
    <div v-if="item.mt_collegeCC === 'CC'">
              <v-chip color="red" dark>
                <v-icon>mdi-information-outline</v-icon>
                แจ้งรายงานข้อมูลผิดพลาด</v-chip
              >
            </div>
            <div v-else>
              <v-icon
                large
                v-if="item.mt_admissions_status === 'send'"
                color="red"
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                mdi-email
              </v-icon>
              <v-icon
                large
                v-else-if="item.mt_admissions_status === 'missing'"
                color="red"
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                mdi-account-off
              </v-icon>
              <v-icon
                large
                v-else-if="item.mt_admissions_status === 'receipt'"
                color="primary"
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                mdi-account-check
              </v-icon>

              <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
            </div>
            </div>
            <div v-else>
                เปิดรับรายงานตัวเมื่อส่วนกลาง อนุมัติ
            </div>
        
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>



        <!-- ครูโอนย้าย -->
        <h2 class="warning--text ml-5" v-if="manage_assistant_teacherstanscoutn>=1">
          รับรายงานตัว กรณีโอนย้าย 
        </h2>
        <v-data-table
        v-if="manage_assistant_teacherstanscoutn>=1"
          color="success"
          :loading="loading"
          :headers="headers"
          :items="manage_assistant_teacherstan"
          :search="search"
          group-by="mt_id_branch"
          class="elevation-1"
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="22">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{ items[0].mt_id_branch + " : " + items[0].name_branch }}
            </th>
          </template>

          <template v-slot:item.mt_admissions_status="{ item }">
            <v-chip
              dark
              color="red"
              v-if="item.mt_admissions_status === 'missing'"
            >
              <v-icon>mdi-information</v-icon>ไม่มารายงานตัว
            </v-chip>
            <v-chip
              dark
              color="primary"
              v-if="item.mt_admissions_status === 'receipt'"
            >
              <v-icon>mdi-information</v-icon>มารายงานตัวปกติ
            </v-chip>
          </template>

          <template v-slot:item.mt_type_personnel="{ item }">
            <div class="text-center">
              <v-chip
                dark
                color="waring"
                v-if="item.mt_type_personnel === 'local_dev'"
              >
                <v-icon>mdi-information</v-icon>ครูพัฒนาท้องถิ่น
              </v-chip>
              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'transfer_position'"
              >
                <v-icon>mdi-information</v-icon>ครูโอนย้าย
              </v-chip>
              <v-chip dark color="green" v-else>
                <v-icon>mdi-information</v-icon>รับรายงานตัว ปกติ
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_admissions_file="{ item }">
            <v-chip
              dark
              color="info"
              v-if="item.mt_admissions_file"
              :href="'/HRvecfiles/' + item.mt_admissions_file"
              target="_blank"
            >
              <v-icon>mdi-printer</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_status="{ item }">
            <v-chip dark color="red" v-if="item.mt_status === 'disclaim'">
              <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
            </v-chip>
            <v-chip dark color="green" v-else>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_examtype="{ item }">
            <span dark color="purple" v-if="item.mt_examtype === 'select'">
              คัดเลือก
            </span>
            <span dark color="indigo" v-else>
              แข่งขัน
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <div v-if="item.mt_transfer_status==='0'">
    <div v-if="item.mt_collegeCC === 'CC'">
              <v-chip color="red" dark>
                <v-icon>mdi-information-outline</v-icon>
                แจ้งรายงานข้อมูลผิดพลาด</v-chip
              >
            </div>
            <div v-else>
              <v-icon
                large
                v-if="item.mt_admissions_status === 'send'"
                color="red"
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                mdi-email
              </v-icon>
              <v-icon
                large
                v-else-if="item.mt_admissions_status === 'missing'"
                color="red"
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                mdi-account-off
              </v-icon>
              <v-icon
                large
                v-else-if="item.mt_admissions_status === 'receipt'"
                color="primary"
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                mdi-account-check
              </v-icon>

              <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
            </div>
            </div>
            <div v-else>
                เปิดรับรายงานตัวเมื่อส่วนกลาง อนุมัติ
            </div>
        
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>


      </base-material-card>

      <!-- V-model editmanage_assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editmanage_assistant_teacherdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-email-open"
              :title="
                'รายรายงานตัว ครูผู้ช่วยรอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editmanage_assistant_teacherform" lazy-validation>
                <v-container grid-list-md>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_id_card
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >เลขบัตรประชาชน</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_title_s +
                            editmanage_assistant_teacher.mt_frist_name +
                            " " +
                            editmanage_assistant_teacher.mt_last_name
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >ชื่อ-นามสกุล</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-school
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_id_branch
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >สาขาวิชา :
                          {{
                            editmanage_assistant_teacher.name_branch
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_tel_p
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Tel.</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon>mdi-message-text</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item
                      v-if="
                        editmanage_assistant_teacher.mt_admissions_status ===
                          'missing' ||
                          editmanage_assistant_teacher.mt_admissions_status ===
                            'receipt'
                      "
                    >
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          <h2
                            class="red--text"
                            v-if="
                              editmanage_assistant_teacher.mt_admissions_status ===
                                'missing'
                            "
                          >
                            ไม่มารายงานตัว
                          </h2>
                          <h2
                            class="green--text"
                            v-if="
                              editmanage_assistant_teacher.mt_admissions_status ===
                                'receipt'
                            "
                          >
                            มารายงานตัว
                          </h2>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >สถานะการรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip
                            :href="
                              '/HRvecfiles/' +
                                editmanage_assistant_teacher.mt_admissions_file
                            "
                            target="_blank"
                            color="info"
                            class="pa-2"
                          >
                            <v-icon class="mr-5">mdi-printer</v-icon>
                            <h2>หนังสือนำส่ง</h2>
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >หนังสือแจ้งรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon>mdi-message-text</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item
                      v-if="
                        editmanage_assistant_teacher.mt_admissions_status ===
                          'missing' ||
                          editmanage_assistant_teacher.mt_admissions_status ===
                            'receipt'
                      "
                    >
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          <h2 class="red--text">
                            ขอยกเลิกการรายงานข้อมูลเนื่องจากรายงานผิดพลาด
                            <br />
                            <v-btn
                              @click="manage_assistant_teacherCC()"
                              outlined
                              color="red"
                              >ขอยกเลิกการรายงาน</v-btn
                            >
                          </h2>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >สถานะการรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <div
                    v-if="
                      editmanage_assistant_teacher.mt_type_personnel ===
                        'transfer_position'
                    "
                  >
                    <v-alert type="warning">
                      <div>
                        <u class="font-weight-bold red--text">ครูโอนย้าย</u>
                        วิทยาลัยรอให้ต้นสังกัดครูโอนย้าย อนุมัติให้โอนย้าย
                        โดยสามารถติดต่อประสานงานกับครูผู้โอนย้าย
                        เพื่อขอทราบกำหนดการมารายงานตัว
                      </div>
                    </v-alert>
                  </div>
                  <v-layout
                    wrap
                    v-if="
                      editmanage_assistant_teacher.mt_admissions_status ===
                        'send'
                    "
                  >
                    <v-flex md4>
                      <v-autocomplete
                        v-model="
                          editmanage_assistant_teacher.mt_admissions_statusSelect
                        "
                        :items="mt_admissions_status_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="สถานะการรับรายงานตัว"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>

                    <v-flex md8>
                      <v-file-input
                        v-model="mt_admissions_file"
                        accept=".pdf"
                        name="mt_admissions_file"
                        color="deep-purple accent-4"
                        counter
                        label="หนังสือนำส่ง .pdf"
                        placeholder="หนังสือแจ้งรับรายงานตัว"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-flex>

                    <v-flex md6>
                      <v-autocomplete
                        v-model="editmanage_assistant_teacher.mt_subject"
                        :items="subject_types"
                        item-text="subject_typeName"
                        item-value="subject_typeID"
                        outlined
                        label="ประเภทวิชา"
                        @change="rate_work_course_stdQuery()"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.subject_typeID }} :
                              {{ item.subject_typeName }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md6>
                      <v-autocomplete
                        v-model="editmanage_assistant_teacher.mt_branch"
                        :items="rate_work_course_stds"
                        item-text="branch_name_th"
                        item-value="id_course_branch"
                        outlined
                        label="สาขาวิชา"
                        @change="rate_work_course_stdSubQuery()"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.id_course_branch }} :
                              {{ item.branch_name_th }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex md6>
                      <v-autocomplete
                        v-model="editmanage_assistant_teacher.mt_branch_sub"
                        outlined
                        label="สาขางาน"
                        :items="rate_work_course_stdsSub"
                        item-text="branch_sub_name_th"
                        item-value="id_course_branch_sub"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.id_course_branch_sub }} :
                              {{ item.branch_sub_name_th }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                outlined
                @click.stop="editmanage_assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editmanage_assistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editmanage_assistant_teacherCCdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editmanage_assistant_teacherCCdialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-email-open"
              :title="
                'รายรายงานตัว ครูผู้ช่วยรอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="editmanage_assistant_teacherCCdialogform"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_id_card
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >เลขบัตรประชาชน</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-account
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_title_s +
                            editmanage_assistant_teacher.mt_frist_name +
                            " " +
                            editmanage_assistant_teacher.mt_last_name
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >ชื่อ-นามสกุล</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon color="indigo">
                          mdi-school
                        </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_id_branch
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >สาขาวิชา :
                          {{
                            editmanage_assistant_teacher.name_branch
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          editmanage_assistant_teacher.mt_tel_p
                        }}</v-list-item-title>
                        <v-list-item-subtitle>Tel.</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon>mdi-message-text</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item
                      v-if="
                        editmanage_assistant_teacher.mt_admissions_status ===
                          'missing' ||
                          editmanage_assistant_teacher.mt_admissions_status ===
                            'receipt'
                      "
                    >
                      <v-list-item-action></v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          <h2
                            class="red--text"
                            v-if="
                              editmanage_assistant_teacher.mt_admissions_status ===
                                'missing'
                            "
                          >
                            ไม่มารายงานตัว
                          </h2>
                          <h2
                            class="green--text"
                            v-if="
                              editmanage_assistant_teacher.mt_admissions_status ===
                                'receipt'
                            "
                          >
                            มารายงานตัว
                          </h2>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >สถานะการรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip
                            :href="
                              '/HRvecfiles/' +
                                editmanage_assistant_teacher.mt_admissions_file
                            "
                            target="_blank"
                            color="info"
                            class="pa-2"
                          >
                            <v-icon class="mr-5">mdi-printer</v-icon>
                            <h2>หนังสือนำส่ง</h2>
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >หนังสือแจ้งรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon>mdi-message-text</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item
                      v-if="
                        editmanage_assistant_teacher.mt_admissions_status ===
                          'missing' ||
                          editmanage_assistant_teacher.mt_admissions_status ===
                            'receipt'
                      "
                    >
                      <v-list-item-action></v-list-item-action>

                      <!-- <v-list-item-content>
                        <v-list-item-title>
                          <h2 class="red--text">
                            ขอยกเลิกการรายงานข้อมูลเนื่องจากรายงานผิดพลาด
                            
                            <v-btn @click="manage_assistant_teacherCC()" outlined color="red"
                              >ขอยกเลิกการรายงาน</v-btn
                            >
                          </h2>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          >สถานะการรับรายงานตัว</v-list-item-subtitle
                        >
                      </v-list-item-content> -->
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                large
                @click.stop="editmanage_assistant_teacherCCdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="red"
                @click.stop="editmanage_assistant_teacherCCdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-clipboard-alert</v-icon>&nbsp;แจ้งยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherCountDialog -->
      <v-layout>
        <v-dialog
          v-model="manage_assistant_teacherCountDialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="ข้อมูลสรุปครูผู้ช่วย รายงานตัว"
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-data-table
                  color="success"
                  :loading="loading"
                  :headers="headersCount"
                  :items="manage_assistant_teacherCount"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      color="info"
                      @click.stop="
                        matBranchQuery(
                          item.mt_times,
                          item.mt_years,
                          item.mt_id_branch
                        )
                      "
                    >
                      mdi-account-search
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="manage_assistant_teacherCountDialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import AssisstantBar from "../../components/college/assisstantBar.vue";

export default {
  name: "HRvecManageAssistantTeacher",
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      mt_admissions_file: null,
      addmanage_assistant_teacherdialog: false,
      editmanage_assistant_teacherdialog: false,
      manage_assistant_teacherCountDialog: false,
      editmanage_assistant_teacherCCdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_assistant_teachers: [],
      addmanage_assistant_teacher: {},
      editmanage_assistant_teacher: {},
      colleges: [],
      userstatus: [],
      search: "",
      pagination: {},

      headers: [
        { text: "รอบที่", align: "center", value: "mt_timeyear" },
        { text: "สาขาวิชา", align: "center", value: "mt_id_branch" },
        /* { text: "รหัสบัตร", align: "left", value: "mt_id_card" }, */
        { text: "ชื่อ-นามสกุล", align: "left", value: "mt_name" },
        { text: "วันเกิด", align: "left", value: "mt_hbd" },
        { text: "โทร", align: "left", value: "mt_tel_p" },
        { text: "วุฒิการศึกษา", align: "left", value: "mt_ed_abb" },
        { text: "เอกวิชา", align: "left", value: "mt_ed_name" },
        { text: "วันที่บรรจุ", align: "left", value: "mt_appoin" },
        { text: "คำสั่ง", align: "left", value: "mt_order_app" },
        { text: "วิธีการ", align: "left", value: "mt_examtype" },
        { text: "ประเภท", align: "left", value: "mt_type_personnel" },
        {
          text: "รับรายงานตัว",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        { text: "สถานะ", align: "left", value: "mt_admissions_status" },
        { text: "ไฟล์", align: "left", value: "mt_admissions_file" },
        { text: "วันที่", align: "left", value: "mt_admissions_date" },
        { text: "ประเภทวิชา", align: "left", value: "subject_typeName" },
        { text: "สาขาวิชา", align: "left", value: "branch_name_th" },
        { text: "สาขาวงาน", align: "left", value: "branch_sub_name_th" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      college: {},
      provinces: [],
      prefectures: [],
      collgegs: [],
      manage_assistant_teacherstatus: [],
      regions: [],
      region_ena: true,
      data_syslog: {},
      branchs: [],
      periodassteachs: [],
      headersCount: [
        { text: "รอบที่", align: "left", value: "mt_times" },
        { text: "ปี", align: "left", value: "mt_years" },
        { text: "รหัส", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "branchName" },
        { text: "จำนวน", align: "left", value: "branchGroup" },
        { text: "แสดง", align: "left", value: "actions" }
      ],
      manage_assistant_teacherCount: [],
      mt_admissions_status_select: [
        { text: "มารายงานตัว", value: "receipt" },
        { text: "ไม่มารายงานตัว", value: "missing" }
      ],
      mt_examtype_select: [
        { text: "คัดเลือก(ภายใน)", value: "select" },
        { text: "แข่งขัน(ภายนอก)", value: "recruit" }
      ],
      mt_examgroup_select: [
        { text: "ทั่วไป", value: "general" },
        { text: "ชายแดนภาคใต้", value: "southern" }
      ],
      collegeCheck: [],
      rules: [
        value => !value || value.size < 1048576 || "ไฟล์ไม่เกิน 1 MB / 1024 KB",
        value => !!value || ""
      ],
      rate_work_course_stds: [],
      rate_work_course_stdsSub: [],
      mt_branch_sub_general: [{ text: "ภาษาไทย", value: "" }],
      subject_types: [],
      linealerts: {},
      manage_assistant_teacherstan:[],
      manage_assistant_teacherstanscoutn:'',
    };
  },
  async mounted() {
    await this.collegeQuery();
    await this.periodassteachQuery();
    await this.branchQuery();
    await this.matCountQuery();
    await this.manage_assistant_teacherQueryTranAll();
  },
  methods: {
    async subject_typeQuery() {
      let result = await this.$http.post("subject_type.php", {
        ApiKey: this.ApiKey
      });
      this.subject_types = result.data;
    },
    async rate_work_course_stdQuery() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey,
        id_type_course: this.editmanage_assistant_teacher.mt_subject,
        showNewCourse: "Ok"
      });
      this.rate_work_course_stds = result.data;
    },
    async rate_work_course_stdSubQuery() {
      let result = await this.$http.post("rate_work_course_std.php", {
        ApiKey: this.ApiKey,
        id_course_branch: this.editmanage_assistant_teacher.mt_branch,
        searchGroupID: "Ok"
      });
      this.rate_work_course_stdsSub = result.data;
    },
    async manage_assistant_teacherCC() {
      this.editmanage_assistant_teacherCCdialog = true;
    },
    async editmanage_assistant_teacherCCdialogSubmit() {
      this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
      this.editmanage_assistant_teacher.mt_collegeCC = "CC";
      let result = await this.$http.post(
        "manage_assistant_teacher.update.php",
        this.editmanage_assistant_teacher
      );
      if (result.data.status == true) {
        this.manage_assistant_teacher = result.data;
        Swal.fire({
          icon: "success",
          title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.linealerts.ApiKey = this.ApiKey;
        this.linealerts.college_name = this.collegeCheck.college_name;
        this.linealerts.details =
          "รายงานตัวครูผู้ช่วย : ขอยกเลิกการรับรายงานตัวครูผู้ช่วย";
        this.linealerts.datetime = this.date_today;

        let result_line = await this.$http.post(
          "linenotify_hr.php",
          this.linealerts
        );

        this.manage_assistant_teacherQueryAll();
        this.manage_assistant_teacherQueryTranAll();

        
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.manage_assistant_teacherQueryAll();
      this.manage_assistant_teacherQueryTranAll();

      this.editmanage_assistant_teacherCCdialog = false;
      this.editmanage_assistant_teacherdialog = false;
    },
    async collegeQuery() {
      let result = {};
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("college.php", {
        college_code: userSession.user_code,
        ApiKey: this.ApiKey
      });
      this.collegeCheck = result.data;
    },
    async matBranchQuery(mt_times, mt_years, mt_id_branch) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_times: mt_times,
        mt_years: mt_years,
        mt_id_branch: mt_id_branch,
        mt_college_code: this.collegeCheck.college_code
      });
      this.manage_assistant_teachers = result.data;
      this.manage_assistant_teacherCountDialog = false;
    },
    async matCountQuery() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_times: this.periodassteachs.periodAssTeachTimes,
        mt_years: this.periodassteachs.periodAssTeachYear,
        mt_college_code: this.collegeCheck.college_code,
        mt_count: "Ok"
      });
      this.manage_assistant_teacherCount = result.data;
    },
    async periodassteachQuery() {
      let result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnable: "1",
        periodAssTeachID: "102"
      });
      this.periodassteachs = result.data;
      if (this.periodassteachs.periodAssTeachEnable == "1") {
        await this.manage_assistant_teacherQueryAll();        
      } 

    },
    async branchQuery() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branchs = result.data;
    },
    async manage_assistant_teacherTotal() {
      await this.matCountQuery();
      this.manage_assistant_teacherCountDialog = true;
    },
    async sweetAlertLoading() {
      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then(result => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
        }
      });
    },
    async manage_assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          mt_college_code: this.collegeCheck.college_code
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
      let data = this.manage_assistant_teachers;
      let counts = 0;
      data.forEach(value => {
        counts += 1;
      });
      if (counts < 1) {
        Swal.fire({
          icon: "error",
          title: "ไม่พบรายการครูผู้ช่วยในรอบนี้",
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    },
    async manage_assistant_teacherQueryTranAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_type_personnel: "transfer_position",
          mt_college_code: this.collegeCheck.college_code
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teacherstan = result.data;
      let data = this.manage_assistant_teacherstan;
      let counts = 0;
      data.forEach(value => {
        counts += 1;
      });
      this.manage_assistant_teacherstanscoutn=counts
      if (counts > 0) {
        Swal.fire({
          icon: "success",
          title: "พบข้อมูลครูโอนย้าย",
          allowOutsideClick: false,
          allowEscapeKey: false
        });
      }
    },

    async manage_assistant_teacherEdit(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.subject_typeQuery();
      this.editmanage_assistant_teacherdialog = true;
    },
    async editmanage_assistant_teacherSubmit() {
      if (this.$refs.editmanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        this.editmanage_assistant_teacher.mt_admissions_status = this.editmanage_assistant_teacher.mt_admissions_statusSelect;
        this.editmanage_assistant_teacher.mt_admissions_date = this.date_today_log;
        let result = "";
        let uploaded = null;
        if (this.mt_admissions_file != "") {
          let formData = new FormData();
          let filename =
            this.periodassteachs.periodAssTeachTimes +
            this.periodassteachs.periodAssTeachYear +
            this.collegeCheck.college_code +
            +this.editmanage_assistant_teacher.mt_id_card +
            this.time_stamp +
            "mat.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.mt_admissions_file);
          formData.append("filename", "../HRvecfiles/" + filename);
          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.editmanage_assistant_teacher.mt_admissions_file = filename;
          } else {
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
        result = await this.$http.post(
          "manage_assistant_teacher.update.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        this.manage_assistant_teacherQueryTranAll();

        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editmanage_assistant_teacherdialog = false;
      }
    }
  },
  computed: {
    time_stamp() {
      let time = Date.now();
      return time;
    },
    color() {
      return "blue-grey";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  },
  components: { AssisstantBar }
};
</script>

<style lang="scss" scoped></style>
